import "./ForgotPassword.scss";

import React, { useEffect, useState } from "react";

import AlertBar from "../../../components/alert/Alert";
import AuthCarosel from "../carosel/AuthCarosel";
import Backdrop from "@mui/material/Backdrop";
import ButtonLinkGrey from "../../../components/buttons/link-grey/ButtonLinkGrey";
import ButtonPrimary from "../../../components/buttons/button-primary/ButtonPrimary";
import CircularProgress from "@mui/material/CircularProgress";
import CustomPrimaryButton from "../../../components/buttons/primary/CustomPrimaryButton";
import Grid from '@mui/material/Grid';
import Input from "../../../components/inputs/email/Input";
import PCLiveLoader from "../../../components/loader/PCLiveLoader";
import mail from "../../../assests/icons/mail-16.svg";
import pcLiveLogo from "../../../assests/icons/pcLiveNewLogo.svg";
import { resendOTP } from "../../../redux/slices/auth";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [isLoading, setIsloading] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    email: false,
  });
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("userEmail");
  const isValid = () => {
    let errors = {};

    if (
      email === "" ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      errors.email = true;
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const onSendOTP = () => {
    if (!isValid()) {
      return;
    }
    setIsloading(true);

    const data = {
      email: email,
    };
    const handleSuccess = (res) => {
      if(!res.data.success){
        setOpenAlert({
          ...openAlert,
          open: true,
          message: res.data.message,
          type: "error",
        });
        setIsloading(false);
        return;
      }
      setOpenAlert({
        ...openAlert,
        open: true,
        message: res.data.message,
        type: "success",
      });
      localStorage.setItem("userEmail", email);
      localStorage.setItem("otpType", "forgot-password");

      setTimeout(() => {
        navigate("/otp");
      }, 1000);
      setIsloading(false);
    };
    const handleFail = () => {
      setIsloading(true);

      setOpenAlert({
        ...openAlert,
        open: true,
        message: "Error occured, Please try again",
        type: "error",
      });
      setIsloading(false);
    };
    resendOTP(data, handleSuccess, handleFail);

  };
  useEffect(()=>{
    setEmail(userEmail);
  },[userEmail])
  const onChangeInputs = (type ,e) => {
    if(type === 'email')
    {
      setEmail(e.target.value);
      setErrors({
        ...errors,
        [type]:
                e.target.value === "" ||
                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                  e.target.value
                )
                  ? true
                  : false,
      });
    }
  };
  return (
    <div className="forgot-password">
      <AlertBar
        message={openAlert.message}
        open={openAlert.open}
        handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        alertType={openAlert.type}
      />
      <PCLiveLoader open={isLoading} /> 

      <Grid container >
      <Grid item xs={1}></Grid>

      <Grid item xs={5}>
      <div className="left-content">

      <div className="login-form">
        <div className="form-container">
        <div  className="pclive-new-logo">
                    <img src={pcLiveLogo} alt="pcLiveLogo" loading="lazy" />
                    <div className="text-xl-medium-20">PCLive247</div>
                    <hr />
                  </div>

          <div className="header-container">
              <div className="text-sm-semibold">
                <div>Forgot Password</div>
                <div className="text-sm-semibold-16" onClick={()=> navigate("/login")}>
                <ButtonLinkGrey label={" Log In"}  className="text-sm-semibold-16"/>                     

                </div>
              </div>
              <span className="text-md-medium">
                Please enter registered email to receive an OTP code.

              </span>
            </div>
          <div className="input-container">
            <div className="email">
              <Input
                placeholder={"Email"}
                label={"Email"}
                type={"email"}
                icon={mail}
                onChange={(e) => onChangeInputs('email' ,e)}
                error={errors.email}
                errorMsg={"Please enter valid email"}
                value={email}
              />
            </div>
          </div>
          <CustomPrimaryButton
          onClick={onSendOTP} 
           disabled={!email || errors.email}
           size="xl"
           fullWidth
          >
          Get OTP
          </CustomPrimaryButton>
        </div>
      </div>
      </div>
      </Grid>
      <Grid item xs={1}></Grid>

      <Grid  xs={5}>

      <AuthCarosel />
      </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;

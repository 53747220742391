import './AuctionRoom.scss';

import Checkbox from '@mui/material/Checkbox';
import CustomizedTooltips from '../tooltip/Tooltip';
import React from 'react';
import checkBoxBase from "../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../assests/icons/checkbox-checked-dark.svg";
import { useSelector } from "react-redux";
import pcLiveLogo from "../../assests/icons/pclive247.png";

const AuctionRoom = (props) => {
  const { label, isChecked, img, category, price_range } = props;
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  return (
    <div
      className={`auction-room-container text-xs-medium-14  ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      {isChecked ? (
        <Checkbox
          checkedIcon={
            <img
              src={globalDarkMode ? checkBoxPrimaryDark : checkBoxPrimary}
              alt=""
            />
          }
          icon={
            <img
              src={globalDarkMode ? checkBoxBaseDark : checkBoxBase}
              alt=""
            />
          }
          style={{
            position: "absolute",
          }}
          className="card-checkbox"
        />
      ) : (
        ""
      )}
      <img
        className="card-image"
        src={img || pcLiveLogo}
        alt="PCLive Logo"
        loading="lazy"
      />
      <p className="text-xs-medium-12">{label}</p>
      <div className="price-category">
        <sapn className="price text-xs-medium-12">{price_range}</sapn>
        <CustomizedTooltips title={category}>
          <span className="category text-xs-medium-12">
            {category?.length > 10
              ? category?.slice(0, 10).concat("...")
              : category}
          </span>
        </CustomizedTooltips>
      </div>
    </div>
  );
};
export default AuctionRoom;

import "./ChangeProfileDialog.scss";

import { Backdrop, Box, Button, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getUserInfo,
  updateUserInfo,
} from "../../../../../redux/slices/profile";
import { useDispatch, useSelector } from "react-redux";

import ButtonLight from "../../../../../components/buttons/button-light/ButtonLight";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CustomPrimaryButton from "../../../../../components/buttons/primary/CustomPrimaryButton";
import Dialog from "../../../../../components/dialog/Dialog";
import PCLiveLoader from "../../../../../components/loader/PCLiveLoader";
import pcLiveLogo from "../../../../../assests/icons/pcLiveNewLogo.svg";
import { toast } from "react-toastify";

export default function ChangeProfileDialog({ openDialog, onClose }) {
  const userInfo = useSelector((state) => state.profile);
  
  //const isLoader = useSelector((state) => state.auth.isLoader);
  const [load ,setLoad] = useState(false);
  const [errors ,setErrors]=useState(false);
  const [errorMsg , setErrorMsg]=useState('');

  const dispatch = useDispatch();
  const [userImagePath, setUserImagePath] = useState({
    path: "",
    fromPicker: false,
  });
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  useEffect(() => {
    setUserImagePath({
      path: userInfo?.data?.data?.profile_picture
        ? userInfo?.data?.data?.profile_picture
        : pcLiveLogo,
      fromPicker: false,
    });
  }, [userInfo]);

  const defaultProfileImages = [
    {
      name: "profile-1",
      path: "https://pclive-cards.s3.amazonaws.com/images/avtatars+urls/Avatar_01.png",
    },
    {
      name: "profile-2",
      path: "https://pclive-cards.s3.amazonaws.com/images/avtatars+urls/Avatar_02.png",
    },
    {
      name: "profile-3",
      path: "https://pclive-cards.s3.amazonaws.com/images/avtatars+urls/Avatar_03.png",
    },
    {
      name: "profile-4",
      path: "https://pclive-cards.s3.amazonaws.com/images/avtatars+urls/Avatar_04.png",
    },
    {
      name: "profile-5",
      path: "https://pclive-cards.s3.amazonaws.com/images/avtatars+urls/Avatar_05.png",
    },
    {
      name: "profile-6",
      path: "https://pclive-cards.s3.amazonaws.com/images/avtatars+urls/Avatar_06.png",
    },
    {
      name: "profile-7",
      path: "https://pclive-cards.s3.amazonaws.com/images/avtatars+urls/Avatar_07.png",
    },
    {
      name: "profile-8",
      path: "https://pclive-cards.s3.amazonaws.com/images/avtatars+urls/Avatar_08.png",
    },
    {
      name: "profile-9",
      path: "https://pclive-cards.s3.amazonaws.com/images/avtatars+urls/Avatar_09.png",
    },
  ];

  const updateProfile = () => {
   setLoad(true);
    let data = new FormData();
    if (typeof userImagePath.path == "string") {
      data.append("profile_pic_url", userImagePath.path);

    } else {
      data.append("profile_pic", userImagePath.path);
    }
      updateUserInfo(
        data,
        (res) => {
          if (res.data.success) {
            toast.success("Profile picture updated");
            dispatch(getUserInfo());
            onClose();
        setLoad(false);

          }
        },
        (error) => {console.log(error)
          setLoad(false);
        
        }
      );
    // }
  };

  const onCancel = () => {
    setUserImagePath({
      path: userInfo?.data?.data?.profile_picture
        ? userInfo?.data?.data?.profile_picture
        : pcLiveLogo,
      fromPicker: false,
    });
    onClose();
  };

  const uploadImg = (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("Profile Image is required");
    } else if (!["image/png", "image/jpeg"].includes(file.type)) {
      toast.error("Please select a PNG or JPG file.");
    } else if (file.size > 5 * 1024 * 1024) {
      toast.error("File size exceeds 5MB limit.");
    } else{
        setUserImagePath({
          path: e.target.files[0],
          fromPicker: true,
        });
    }
    return;
  };
  return (
    <Dialog
      openDialog={openDialog}
      onClose={onCancel}
      title={"Profile picture"}
    >
      <PCLiveLoader open={load || userInfo?.isLoading} /> 
      
      <div className={`change-profile-container  ${globalDarkMode ? 'pclive-dark-mode' : ''}`}>
        <div className="change-profile-contents">
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(event) => {
                uploadImg(event)
            }}
            id="contained-button-file"
          />
          <label htmlFor="contained-button-file">
            <div className="upload-text" htmlFor="contained-button-file">
              <CloudUploadOutlinedIcon />
              <span className=" text-sm-medium-14">Upload new picture</span>
            </div>
            <img
              src={
                userImagePath?.fromPicker
                  ? URL.createObjectURL(userImagePath?.path)
                  : userImagePath?.path
              }
              width={195}
              height={195}
              alt="userIcon"
              className="choose-img"
              loading="lazy"
            />
          </label>
        </div>
        <span className="text-sm-medium-14 choose">
          Or choose from our default avatar’s 
        </span>
        <Box
          sx={{
            width: 350,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginTop: 4,
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 3 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
          >
            {defaultProfileImages.map((item, index) => (
              <img
                className="option-img"
                loading="lazy"
                key={index}
                src={item?.path}
                width={93}
                height={93}
                alt="userIcon"
                onClick={() =>
                  setUserImagePath({
                    path: item?.path,
                    fromPicker: false,
                  })
                }
              />
            ))}
          </Stack>
        </Box>
        <div className="followers-button">
          <ButtonLight
            onClick={onCancel}
          >
            Cancel
          </ButtonLight>
          <CustomPrimaryButton
            onClick={updateProfile}
          >
            Update profile picture
          </CustomPrimaryButton>
        </div>
      </div>
    </Dialog>
  );
}

import { apiGetCall } from '../../utils/utils';
import { createSlice } from '@reduxjs/toolkit';

export const getSellerInfo = (params, handleSuccess, handleFail) => {
  apiGetCall('api/user_follow_page/?' + params, handleSuccess, handleFail);
};
export const getViewAllCards = (params, handleSuccess, handleFail) => {
  apiGetCall('api/room/cards/?' + params, handleSuccess, handleFail);
};

const auctionSlice = createSlice({
  name: 'auction',
  initialState: {
    isLoading: false,
    currentSeller:null,
    roomType:1,
  },
  reducers: {
    setCurrentSeller: (state, action) => {
      state.currentSeller = action.payload;
    },
    setRoomTypes: (state,action) =>{
      state.roomType=action.payload;
    }
  },
});
export const auctionSliceReducer = auctionSlice.reducer;
export const { setCurrentSeller ,setRoomTypes } = auctionSlice.actions;

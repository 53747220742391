import "./SellerDashboard.scss";

import { Backdrop, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getCards,
  publishBulkCard,
  unpublishBulkCard,
} from "../../../../../redux/slices/card";
import {
  getSellerCards,
  getSellerHigestBid,
} from "../../../../../redux/slices/seller";
import { setGlobalDarkMode, setSettingOptions } from "../../../../../redux/slices/profile";
import { useDispatch, useSelector } from "react-redux";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import AuctionRoom from "../../../../../components/auction-room/AuctionRoom";
import Box from "@mui/material/Box";
import ButtonGray from "../../../../../components/buttons/button-gray/ButtonGray";
import ButtonLinkGrey from "../../../../../components/buttons/link-grey/ButtonLinkGrey";
import CardDashboard from "../../../../../components/card-dashboard/CardDashboard";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CreateIcon from "@mui/icons-material/Create";
import CustomPrimaryButton from "../../../../../components/buttons/primary/CustomPrimaryButton";
import Dialog from "../../../../../components/dialog/Dialog";
import Drawer from "@mui/material/Drawer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterDropdown from "../../../../../components/filter/FilterDropdown";
import FormControlLabel from "@mui/material/FormControlLabel";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MultipleSelectCheckmarks from "../../../../../components/select/checkbox-select/CheckboxSelect";
import {NoData} from '../../../../../components/no-data/NoCards'
import PCLiveLoader from "../../../../../components/loader/PCLiveLoader";
import Pagination from "../../../../../components/pagination/Pagination";
import Popup from "../../../../../components/popup/Popup";
import RadioSelect from "../../../../../components/radio-select/RadioSelect";
import RadioSelectComponent from "../../../../../components/select/radio-select/RadioSelectComponent";
import SortIcon from "@mui/icons-material/Sort";
import { UploadCardForm } from "./upload-card/UploadCard";
import ViewCard from "./view-card/ViewCard";
import checkBoxBase from "../../../../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../../../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../../../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../../../../assests/icons/checkbox-checked-dark.svg";
import landing1 from "../../../../../assests/icons/landing-1.jpg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SellerDashboard = ({noSearchData}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.sellerCards.isLoading);
  const sellerCards = useSelector(
    (state) => state.sellerCards.allSellerCards?.data
  );
  const totalCount = useSelector(
    (state) => state.sellerCards.allSellerCards?.total_count
  );
  const totalPages = useSelector(
    (state) => state.sellerCards.allSellerCards?.total_pages
  );
  const sellerHigestBidCards = useSelector(
    (state) => state.sellerHigestBidCards.allSellerHigestBid?.data
  );
  const userInfo = useSelector((state) => state.profile?.data?.data);

  const [roomType, setRoomType] = useState(1);
  const [drawerState, toggleDrawer] = useState(false);
  const [data, setData] = useState(null);
  const [uploadDrawer, setUploadDrawer] = useState(false);
  const [editCard, isEditCard] = useState(false);
  const [checked, setChecked] = React.useState([true, false]);
  const [sorting, setSorting] = useState(JSON.stringify(["-created_at"]));
  const [cardCheckList, setCardCheckList] = useState([]);
  const [currentCard, setCurrentCard] = useState(null);
  const [apiCallAction, setApiCallAction] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [withDrawcardPopup, setWithdrawCardPopup] = useState(false);
  const [load, setLoad] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [globalChecked , setGlobalChecked] = useState(false);
  const [openPublishPopup, setOpenPublishPopup] = useState({
    type: "",
    value: false,
  });
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const handleChange1 = (event) => {
    if (sellerCards && sellerCards.length) {
      setGlobalChecked(event.target.checked);
      const selection = sellerCards.map((s) => s.id);
      setCardCheckList(event.target.checked ? selection : []);
    }
  };
  useEffect(() => {
    dispatch(getSellerHigestBid());
    setRoomType(1);
    setSorting(JSON.stringify(["-created_at"]));
  }, []);
  useEffect(() => {
    setLoad(true);
    if (currentCard && currentCard.id) {
      getCards(
        `?card_id=${currentCard.id}`,
        (body) => {
          setCurrentCard(body?.data?.data[0]);
        },
        () => {}
      );
    }
    setLoad(false);
  }, [drawerState]);
  useEffect(() => {
    const roomTypeMap = {
      1: "all",
      2: "saved",
      3: "active",
      4: "sold",
    };
    let room = roomTypeMap[roomType] || "all";
    dispatch(getSellerCards({ room: room, page: pageIndex, sortBy: sorting }));
    setCardCheckList([]);
    setGlobalChecked(false);
  }, [roomType, sorting, apiCallAction, pageIndex]);
  useEffect(() => {
    var myDiv = document.getElementById("seller-dashboard-container");
    var sold = document.getElementById("dashboard-right-content-seller");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
    if (sold) {
      sold.scrollTop = 0;
    }
  }, [pageIndex, sorting]);

  const handleCheck = (e, card) => {
    setCurrentCard(card);
    let list = [...cardCheckList];
    let found = list.findIndex((f) => f == card.id);
    if (found > -1) {
      list.splice(found, 1);
    } else {
      list.push(card.id);
    }
    setCardCheckList(list);
  };
  const onWithdraw = () => {
    let data;
    if (currentCard && currentCard?.id) {
      data = [{ card_id: currentCard.id }];
    } else data = cardCheckList.map((c) => ({ card_id: c }));
    unpublishBulkCard(
      data,
      (body) => {
        if (body.data.success) {
          toast.success(body.data.message);
          setApiCallAction(!apiCallAction);
          setCardCheckList([]);
          setWithdrawCardPopup(false);
          toggleDrawer(false);
          const roomTypeMap = {
            1: "all",
            2: "saved",
            3: "active",
            4: "sold",
          };
          let room = roomTypeMap[roomType] || "all";
          dispatch(
            getSellerCards({ room: room, page: pageIndex, sortBy: sorting })
          );
        } else {
          toast.error(body.data.message);
        }
      },
      (body) => {
        toast.error(body.data.message);
      }
    );
  };
  const onPublish = () => {
    let data = cardCheckList.map((c) => ({ card_id: c }));
    publishBulkCard(
      data,
      (body) => {
        if (body.data.success) {
          toast.success(body.data.message);
          setApiCallAction(!apiCallAction);
          setCardCheckList([]);
          setGlobalChecked(false);
          setOpenPopup(false);
        } else {
          toast.error(body.data.message);
        }
      },
      (body) => {
        toast.error(body.data.message);
      }
    );
  };
  const onChangetab = (room) => {
    setRoomType(room);
    setPageIndex(1);
    setSorting(JSON.stringify(["-created_at"]));
    setCardCheckList([]);
    setGlobalChecked(false);
  };

  const onOpenUploadCard = () => {
    if (!userInfo?.seller_verified) {
      toast.error("Please verify your seller account to upload card");
      return;
    }
    setUploadDrawer(true);
  };

  const setCurrentRoom = async (id, name) => {
    let myPromise = new Promise(function (myResolve, myReject) {
      localStorage.setItem("room_id", id);
      localStorage.setItem("room_name", name);
      myResolve(navigate("/auction")); // when successful
      myReject(); // when error
    });
  };

  const publishCard = (card) => {
    publishBulkCard(
      [{ card_id: card?.id }],
      () => {
        setOpenPublishPopup({ type: "", value: false });
        toggleDrawer(false);
        setApiCallAction(!apiCallAction);
        setOpenPublishPopup({ type: "", value: false });
      },
      (error) => {
        console.log(error);
        toast.error(error?.message);
      }
    );
  };
  
  return (
    <div
      className={`seller-dashboard-container ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
      id="seller-dashboard-container"
    >
      <Popup
        openPopup={openPopup}
        onClose={() => setOpenPopup(false)}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => setOpenPopup(false),
          },
          secondary: {
            title: "Yes, Publish cards",
            onClick: () => {
              onPublish();
            },
          },
        }}
        title={"Publishing cards"}
        description="All cards that you have selected will get published in auction rooms. Are you sure you want to go ahead?"
      />
      <Popup
        openPopup={withDrawcardPopup}
        onClose={() => setWithdrawCardPopup(false)}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => setWithdrawCardPopup(false),
          },
          secondary: {
            title: "Yes, withdraw cards",
            onClick: () => {
              onWithdraw();
            },
          },
        }}
        title={"Withdraw cards"}
        description="You have selected cards will get withdrawn from published cards in auction rooms. Are you sure you want to go ahead?"
      />
      <PCLiveLoader open={isLoading || load} />

      {/* <Drawer
        anchor={"right"}
        open={uploadDrawer}
        onClose={() => {
          setUploadDrawer(false);
          isEditCard(false);
          setCardCheckList([]);
        }}
        PaperProps={{
          sx: { width: "40%" },
        }}
      > */}
      <Dialog
        openDialog={uploadDrawer}
        onClose={() => {
          setUploadDrawer(false);
          isEditCard(false);
          setCardCheckList([]);
        }}
        title={editCard ? "Edit card" : "Upload a card"}
      >
        <UploadCardForm
          setUploadDrawer={setUploadDrawer}
          editCard={editCard}
          card={currentCard}
          setApiCallAction={setApiCallAction}
          apiCallAction={apiCallAction}
          setCardCheckList={setCardCheckList}
          toggleDrawer={toggleDrawer}
        />
      </Dialog>
      <Dialog
        openDialog={drawerState}
        onClose={() => toggleDrawer(false)}
        title={"Card details"}
      >
        <ViewCard
          data={currentCard}
          toggleDrawer={toggleDrawer}
          hideBuyer={
            !currentCard?.is_sold || roomType === 2 || roomType === 3
              ? true
              : false
          }
          onWithdraw={() => {
            setWithdrawCardPopup(true);
          }}
          showWithdraw={roomType == 3}
          onClickEdit={() => {
            setUploadDrawer(true);
            isEditCard(true);
          }}
          showEdit={
            (!currentCard?.published && roomType === 1) || roomType === 2
          }
          showPublish={
            !currentCard?.is_sold && (roomType === 1 || roomType === 2)
          }
          onClickPublish={() => {
            setOpenPublishPopup({
              type: currentCard?.published ? "unpublish" : "publish",
              value: true,
            });
          }}
          roomType={roomType}
        />
      </Dialog>
      <Popup
        openPopup={openPublishPopup?.value}
        onClose={() => setOpenPublishPopup({ type: "", value: false })}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => setOpenPublishPopup({ type: "", value: false }),
          },
          secondary: {
            title: `Yes, ${openPublishPopup?.type} card`,
            onClick: () => publishCard(currentCard),
          },
        }}
        title={`${openPublishPopup?.type} card`}
        description={`Are you sure you want to ${openPublishPopup?.type} this card?`}
      />
      {!load && !isLoading && userInfo
        ? !userInfo?.seller_verified && (
            <div
              className="verification text-xs-medium-12"
              onClick={() => {
                navigate("/profile");
                dispatch(setSettingOptions(2));
              }}
            >
              Verify your seller account to sell your preferred cards!
            </div>
          )
        : ""}

      <div className="dashboard-top-filters seller">
        <div className="filter-container-left">
          <span
            className={`text-sm-semibold-14 room ${
              roomType == 1 ? "selected active" : ""
            }`}
            onClick={() => onChangetab(1)}
          >
            {roomType == 1 ? (
              <ButtonLinkGrey label={"All"} />
            ) : (
              <ButtonGray label={"All"} />
            )}
          </span>
          <span
            className={`text-sm-semibold-14 room ${
              roomType == 2 ? "selected active" : ""
            }`}
            onClick={() => onChangetab(2)}
          >
            {roomType == 2 ? (
              <ButtonLinkGrey label={"Saved cards"} />
            ) : (
              <ButtonGray label={"Saved cards"} />
            )}
          </span>
          <span
            className={`text-sm-semibold-14 room ${
              roomType == 3 ? "selected active" : ""
            }`}
            onClick={() => onChangetab(3)}
          >
            {roomType == 3 ? (
              <ButtonLinkGrey label={"Published cards"} />
            ) : (
              <ButtonGray label={"Published cards"} />
            )}
          </span>
          <span
            className={`text-sm-semibold-14 room ${
              roomType == 4 ? "selected active" : ""
            }`}
            onClick={() => onChangetab(4)}
          >
            {roomType == 4 ? (
              <ButtonLinkGrey label={"Sold cards"} />
            ) : (
              <ButtonGray label={"Sold cards"} />
            )}
          </span>
        </div>
        <div className="filter-container-right">
          <FilterDropdown
            title="Sort card"
            list={[
              {
                label: "Latest to oldest",
                value: JSON.stringify(["-created_at"]),
              },
              {
                label: "Oldest to latest",
                value: JSON.stringify(["created_at"]),
              },
              {
                label: "Price high to low",
                value: JSON.stringify(["-reserve_price"]),
              },
              {
                label: "Price low to high",
                value: JSON.stringify(["reserve_price"]),
              },
            ]}
            subtitle={"Select sorting type"}
            result={(val) => {
              setSorting(val);
              setPageIndex(1);
            }}
            type="radio"
            radioValue={sorting}
            isVerticalRadio
          />
          {/* We might need this in future releases */}
          {/* <CustomPrimaryButton
            onClick={() => onOpenUploadCard() }
          >
            Upload card to sell{" "}
            <CloudUploadOutlinedIcon style={{ marginLeft: 5 }} />
          </CustomPrimaryButton> */}
        </div>
      </div>
      {noSearchData ? (
        <div className="dataNotAvailable">
          <InfoOutlinedIcon />
          <div className="text-sm-medium-14 titleColor">
            We couldn’t find the items you were looking for! Please retry with
            different name.
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="seller-dashboard-list">
        {roomType == 1 &&
        sellerHigestBidCards &&
        sellerHigestBidCards.length ? (
          <div className="dashboard-right-content-seller height">
            <div className="room-container">
              <div className="room-list-head text-lg-semibold-18">
                Highest bid card
              </div>
              <div className="room-list-container highest">
                {sellerHigestBidCards && sellerHigestBidCards.length
                  ? sellerHigestBidCards.map((card) => {
                      return (
                        <div
                          onClick={() => {
                            toggleDrawer(true);
                            setCurrentCard(card);
                          }}
                        >
                          <CardDashboard card={card} />
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {roomType != 2 && roomType != 3 ? (
          <div
            className="dashboard-right-content-seller"
            id="dashboard-right-content-seller"
          >
            <div className="room-container">
              <div className="room-list-head text-lg-semibold-18">
                {roomType == 1 ? "All Cards" : ""}
              </div>
              <div className="room-list-container">
                {sellerCards && sellerCards.length ? (
                  sellerCards.map((card) => {
                    return (
                      <div
                        onClick={() => {
                          if (
                            roomType == 1 &&
                            card?.published &&
                            !card?.is_sold
                          ) {
                            setCurrentRoom(card?.room_id, card?.room_name);
                            return;
                          }
                          setCurrentCard(card);
                          toggleDrawer(true);
                        }}
                      >
                        <CardDashboard card={card} />
                      </div>
                    );
                  })
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="dashboard-right-content-seller">
            {roomType == 2 ? (
              <div className="right-content-buttons">
                <FormControlLabel
                  label={
                    <span className="text-sm-medium-14">Select all cards</span>
                  }
                  className="right-contents-left-head"
                  control={
                    <Checkbox
                      onChange={handleChange1}
                      checked={globalChecked}
                      checkedIcon={
                        <img
                          src={
                            globalDarkMode
                              ? checkBoxPrimaryDark
                              : checkBoxPrimary
                          }
                          alt=""
                        />
                      }
                      icon={
                        <img
                          src={globalDarkMode ? checkBoxBaseDark : checkBoxBase}
                          alt=""
                        />
                      }
                    />
                  }
                />
                <div className="button-container-action">
                  {/* We might need this in future releases */}
                  {/* <CustomPrimaryButton
                onClick={() => {
                  setUploadDrawer(true);
                  isEditCard(true);
                }}
                disabled={cardCheckList.length !== 1}
              >
                Edit Cards
                <CreateIcon style={{ marginLeft: 5 }} />
              </CustomPrimaryButton> */}

                  <CustomPrimaryButton
                    disabled={!cardCheckList.length}
                    onClick={() => setOpenPopup(true)}
                  >
                    Publish cards
                    <ArrowCircleUpIcon style={{ marginLeft: 5 }} />
                  </CustomPrimaryButton>
                </div>
              </div>
            ) : (
              ""
            )}
            {roomType == 3 && (
              <div className="right-content-buttons">
                <FormControlLabel
                  label={
                    <span className="text-sm-medium-14">Select all cards</span>
                  }
                  className="right-contents-left-head"
                  control={
                    <Checkbox
                      onChange={handleChange1}
                      checked={globalChecked}
                      checkedIcon={
                        <img
                          src={
                            globalDarkMode
                              ? checkBoxPrimaryDark
                              : checkBoxPrimary
                          }
                          alt=""
                        />
                      }
                      icon={
                        <img
                          src={globalDarkMode ? checkBoxBaseDark : checkBoxBase}
                          alt=""
                        />
                      }
                    />
                  }
                />
                <CustomPrimaryButton
                  disabled={!cardCheckList.length}
                  onClick={() => setWithdrawCardPopup(true)}
                >
                  Withdraw cards
                  <ArrowCircleUpIcon style={{ marginLeft: 5 }} />
                </CustomPrimaryButton>
              </div>
            )}
            {sellerCards && sellerCards.length ? (
              <div>
                <div className="room-container">
                  <div className="room-list-head text-lg-semibold-18"></div>
                  <div
                    className={`room-list-container ${
                      roomType == 2 || roomType == 3 ? "stickey" : ""
                    }`}
                  >
                    {sellerCards && sellerCards.length
                      ? sellerCards.map((card) => {
                          return (
                            <div
                              onClick={() => {
                                setCurrentCard(card);
                                toggleDrawer(true);
                              }}
                            >
                              <CardDashboard
                                isChecked={true}
                                card={card}
                                handleCheck={(e, card) => handleCheck(e, card)}
                                cardCheckList={cardCheckList}
                                toggleDrawer={toggleDrawer}
                              />
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            ) : (
              <NoData />
            )}
          </div>
        )}
      </div>
      {(sellerCards && sellerCards.length && roomType == 2) ||
      (sellerCards && sellerCards.length) ||
      (roomType == 1 &&
        sellerHigestBidCards &&
        sellerHigestBidCards.length &&
        !sorting) ? (
        <Pagination
          index={pageIndex}
          handleChange={(page) => setPageIndex(page)}
          totalPage={totalPages}
          isDashboard
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default SellerDashboard;

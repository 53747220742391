import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import "./TrackStatus.scss";
import moment from "moment";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import { Grid } from "@mui/material";
import usaFlag from "../../assests/icons/us-flag.svg";
import CustomPrimaryButton from "../buttons/primary/CustomPrimaryButton";
import { showData } from "../../utils/utils";

const TrackStatus = (props) => {
  const [detailView, setDetailView] = useState(false);

  const { trackStatus } = props;
  return (
    <div>
      {trackStatus && trackStatus?.data?.tracking_history?.length ? (
        <div className="shipment-tracking-modal">
          <div className="top-content">
            <Grid container>
              <Grid
                md={9}
                style={{
                  borderRight:
                    trackStatus?.data?.tracking_history[0]?.status?.toLowerCase() !==
                      "cancelled" && "1px solid #b7b7b7",
                }}
              >
                <div className="transit">
                  {trackStatus &&
                  trackStatus?.data?.tracking_history &&
                  trackStatus?.data?.tracking_history.length
                    ? showData(
                        trackStatus?.data?.tracking_history[
                          trackStatus?.data?.tracking_history.length - 1
                        ]?.status
                      )
                    : ""}
                </div>
                <p>
                  {trackStatus &&
                  trackStatus?.data?.tracking_history &&
                  trackStatus?.data?.tracking_history.length
                    ? trackStatus?.data?.tracking_history[
                        trackStatus?.data?.tracking_history.length - 1
                      ]?.status_details
                    : ""}
                </p>
              </Grid>
              {trackStatus?.data?.tracking_history[0]?.status?.toLowerCase() !==
                "cancelled" && (
                <Grid md={3} style={{ paddingLeft: 10 }}>
                  <p className="location">Location</p>
                  <p className="address">
                    {trackStatus &&
                    trackStatus?.data?.tracking_history &&
                    trackStatus?.data?.tracking_history.length ? (
                      <>
                        {`
                        ${
                          trackStatus?.data?.tracking_history[
                            trackStatus?.data?.tracking_history.length - 1
                          ]?.location?.city
                        },
                        ${
                          trackStatus?.data?.tracking_history[
                            trackStatus?.data?.tracking_history.length - 1
                          ]?.location?.state
                        },
                        ${
                          trackStatus?.data?.tracking_history[
                            trackStatus?.data?.tracking_history.length - 1
                          ]?.location?.country
                        }
                      `}
                        <img
                          style={{ marginLeft: 5 }}
                          src={usaFlag}
                          loading="lazy"
                          alt="flag"
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </Grid>
              )}
            </Grid>
          </div>
          {trackStatus?.data?.tracking_history[0]?.status?.toLowerCase() !==
            "cancelled" &&
            trackStatus?.data?.tracking_history[0]?.status?.toLowerCase() !==
              "delivered" && (
              <div className="track-summary">
                <div>
                  <div className="track-head">Track summary</div>
                  <div className="expected">
                    Expected delivery :{" "}
                    <span>
                      {moment(trackStatus?.data?.eta).format("MM/DD/YYYY")}
                    </span>
                  </div>
                </div>
                <div>
                  {trackStatus?.data?.tracking_history &&
                  trackStatus?.data?.tracking_history?.length > 2 ? (
                    <CustomPrimaryButton
                      style={{ fontSize: 10 }}
                      onClick={() => setDetailView(!detailView)}
                    >
                      {!detailView ? "view in details" : "hide details"}
                    </CustomPrimaryButton>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          {trackStatus?.data?.tracking_history[0]?.status?.toLowerCase() !==
            "cancelled" &&
            trackStatus?.data?.tracking_history[0]?.status?.toLowerCase() !==
              "delivered" && (
              <div className="track-status-container">
                {trackStatus &&
                trackStatus?.data?.tracking_history &&
                trackStatus?.data?.tracking_history.length ? (
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                      [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                        fontSize: 10,
                      },
                    }}
                  >
                    {trackStatus?.data?.tracking_history.map((track, index) => {
                      if (
                        (!detailView &&
                          (index == 0 ||
                            index ==
                              trackStatus?.data?.tracking_history.length -
                                1)) ||
                        detailView
                      ) {
                        return (
                          <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                              {
                                moment(track.status_date)
                                  .format("ll")
                                  .split(",")[0]
                              }
                              <br />
                              {moment(track.status_date).format("LT")}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot color="primary" />
                              {index !=
                              trackStatus?.data?.tracking_history.length - 1 ? (
                                <>
                                  {index == 0 && !detailView && (
                                    <span className="middle-dot"></span>
                                  )}

                                  <TimelineConnector
                                    sx={{
                                      border: "1px dashed #ababab",
                                      width: "unset",
                                      backgroundColor: "#ffffff",
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </TimelineSeparator>
                            <TimelineContent>
                              <h4 style={{ margin: 0, fontSize: 10 }}>
                                {showData(track.status)}
                              </h4>

                              <p style={{ margin: 0, fontSize: 10 }}>
                                {track?.status_details}
                              </p>
                              <p style={{ margin: 0, fontSize: 10 }}>
                                Location : {track?.location?.city}{" "}
                                {track?.location?.state
                                  ? `, ${track.location.state},`
                                  : ""}{" "}
                                {track?.location?.country
                                  ? `${track.location.country},`
                                  : ""}
                              </p>
                            </TimelineContent>
                          </TimelineItem>
                        );
                      }
                    })}
                  </Timeline>
                ) : null}
              </div>
            )}
        </div>
      ) : (
        <p
          style={{ fontSize: 14, color: "#F04438", fontWeight: 500, margin: 0 }}
        >
          Unfortunately, the shipment details are not available at this moment.
          We will keep you updated as soon as we have more information.
        </p>
      )}
    </div>
  );
};

export default TrackStatus;

import './Details.scss'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccountVarificationDetails = () => {
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  const data = [
    { head: "Identity Confirmation", subhead: "To facilitate selling on PC24/7, we require confirmation of your identity during registration and may request identity confirmation at subsequent intervals. For Registered Business sellers, comprehensive information regarding your business and any beneficial owners, officers, directors, or account managers may be necessary." },
    { head: "Individual sellers (either a single person or a married couple) are required to furnish", subhead: "Full name\nAddress\nDate of birth" },
    { head: "For sellers operating as Registered Businesses (Sole proprietor/Single member LLC, Corporation/Multi member LLC, Partnership), the following details are essential",
     subhead: `Individual identification particulars for beneficial owners, officers, directors, or account managersLegal business name \n"Doing Business As" (DBA) name (if applicable)\nEmployer Identification Number (EIN)\nBusiness address\nPhone number` },
    { head: "Submission of Identification Proof",
     subhead: "In certain cases, we may request you to provide a photograph of a valid form of identification. You will receive an email notification and a banner will be displayed on your account, guiding you on the necessary steps for uploading the image." },
    { head: "Accepted forms of valid identification include",
     subhead: "Driver’s license\nFederal or state-issued ID card\nPassport" },
    {
      head: "Document Upload Guidelines",
      subhead:
        `When uploading documents to PC24/7, ensure adherence to the platform's guidelines. The names on the documents must match those on your PC24/7 account.Additionally, the files must be:\n\nIn JPEG, JPG, PDF, or PNG format\nClearly visible with all information included\nWithin a maximum file size of 4 MB\nIn color\n\nPro Tip:\nIf you sell to US buyers, please be aware that your name or company name and address may be shared with buyers in their order confirmation email and order details page to comply with US laws. Refer to our Seller Center page for more information.`,
    },
    { head: "Verification of Banking Details",
     subhead: `To facilitate payouts, you must link your checking account to your PC24/7 account, ensuring alignment of names on both accounts.\n\nFor a personal account, your first and last names should match those on your checking account.For a business account, the business name associated with your checking account should align with the business name registered on PC24/7.\n\nFor example, if the business name on your checking account is "Joe’s Hobby Shop," utilize the same name for your eBay business account registration.\n\nIt's imperative to match your account type on PC24/7 with the account type of your checking account. For instance, a business eBay account necessitates a business checking account for seller registration.` },
    { head: "To verify your bank account, we require", 
    subhead: `Name on the checking account\nBank name\nRouting number\nAccount number\nUpon successful verification, you will receive an email confirmation of your account setup.` },
  ];
  return (
    <div className={`account-verification-info ${globalDarkMode?"pclive-dark-mode":""}`}>
      <div className="text-sm-medium-14 details">
      {`When you register as a PC24/7 seller, we undertake a thorough verification process to confirm your identity, checking account details for payouts, and pertinent business information, where applicable.
      
      As a PC24/7 seller, payouts are disbursed directly to your linked checking account or Visa or Mastercard debit card. The verification of your details ensures seamless processing of your payouts, avoiding any potential delays.

      We conduct verification procedures during the registration process for a seller account and may re-verify your details if any relevant information on your account is updated.
      
      In instances where we encounter difficulty in verifying your details, we may reach out to you for additional information or display a notification banner on your PC24/7 account, outlining the necessary steps you must take. Please note that payouts may be temporarily suspended until your details are successfully verified.
      
      Pro Tip:
      Ensure the accuracy of your information before submission to preempt any delays in processing.`}
      </div>
      {data.map((item,index) => {
        return (
          <Accordion
          defaultExpanded={index==5?true:false}
                        className="text-sm-medium-14 accordion-head"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon  className="expandIcon"/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="head"
              sx={{ borderRadius: "10px", border: "none"}}
            >
              {item.head}
            </AccordionSummary>
            <AccordionDetails
              sx={{ borderRadius: "10px", border: "none" }}
              className="text-sm-medium-14 subhead"
            >
              <Divider sx={{ marginBottom: 3 }} />
              {item.subhead}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
export default AccountVarificationDetails;

import React from "react";
import "./ButtonSecondary.scss";
import { Button } from "@mui/material";
const ButtonSecondary = (props) => {
  const { disabled, label } = props;
  return (
    <div className="button-secondary">
      <Button variant="outlined" className={`text-sm-semibold-16`} {...props}>
        {label}
      </Button>
    </div>
  );
};
export default ButtonSecondary;

import "./Register.scss";
import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { register, registerValidation } from "../../../redux/slices/auth";

import AlertBar from "../../../components/alert/Alert";
import ArrowLeft from "../../../assests/icons/arrowLeft.svg";
import AuthCarosel from "../carosel/AuthCarosel";
import Backdrop from "@mui/material/Backdrop";
import ButtonLinkGrey from "../../../components/buttons/link-grey/ButtonLinkGrey";
import ButtonPrimary from "../../../components/buttons/button-primary/ButtonPrimary";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Checkbox } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CustomPrimaryButton from "../../../components/buttons/primary/CustomPrimaryButton";
import DatePicker from "react-datepicker";
import Grid from "@mui/material/Grid";
import Input from "../../../components/inputs/email/Input";
import PCLiveLoader from "../../../components/loader/PCLiveLoader";
import PhoneInput from "../../../components/inputs/phone/PhoneInput";
import Radio from "../../../components/radio/Radio";
import checkBoxBase from "../../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../../assests/icons/checkbox-checked-dark.svg";
import eye from "../../../assests/icons/eye.svg";
import eyeOff from "../../../assests/icons/eye-off.svg";
import helpCircleIcon from "../../../assests/icons/help-circle.svg";
import mail from "../../../assests/icons/mail-16.svg";
import moment from "moment/moment";
import pcLiveLogo from "../../../assests/icons/pcLiveNewLogo.svg";
import { useNavigate } from "react-router-dom";
import userIcon from "../../../assests/icons/user.svg";

const Register = () => {
  const navigate = useNavigate();
  const [nextPage, setNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openCalender, setOpenCalender] = useState(false);
  const [firstError, setFirstError] = useState({
    date_of_birth: false,
    username: false,
    first_name: false,
    last_name: false,
    isValidUsername: false,
  });
  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const [errors, setErrors] = useState({
    email: false,
    password: false,
    phone: false,
    isValidEmail: false,
    isValidPhone: false,
  });
  const [passErrors, setPassErrors] = useState({
    lengthError: true,
    numberError: true,
    uppercaseError: true,
    specialCharError: true,
  });
  const [userData, setUserData] = useState({
    first_name: "",
    username: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    email: "",
    phone: "",
    password: null,
    is_agreed_terms: false,
  });

  const globalDarkMode = useSelector((state) => state.profile.globalDarkMode);

  const validatePassword = () => {
    const lengthRegex = /^.{8,}$/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;

    const newErrors = {
      lengthError: !lengthRegex.test(userData.password),
      numberError: !numberRegex.test(userData.password),
      uppercaseError: !uppercaseRegex.test(userData.password),
      specialCharError: !specialCharRegex.test(userData.password),
    };

    setPassErrors(newErrors);
    setErrors({
      ...errors,
      password: Object.values(newErrors).some((error) => error) ? true : false,
    });
    // Return true if there are no errors
    return !Object.values(newErrors).some((error) => error);
  };

  function removeProperties(obj, keysToRemove) {
    keysToRemove.forEach((key) => {
      delete obj[key];
    });
  }
  const onRegister = () => {
    const data = { ...userData };
    var date = new Date(data.date_of_birth);
    var day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    var month = (date.getMonth() + 1)
      .toString()
      .padStart(2, "0"); 
    var year = date.getFullYear();
    var formattedDate = month + "-" + day + "-" + year;
    data.date_of_birth = formattedDate;
    let keysToRemove = [];
    if (!data.gender) {
      keysToRemove.push("gender");
    }
    removeProperties(data, keysToRemove);
    setIsLoading(true);
    const handleSuccess = (res) => {
      if (!res.data.success) {
        setOpenAlert({
          ...openAlert,
          open: true,
          message: res.data.message,
          type: "error",
        });
        setIsLoading(false);

        return;
      }
      setIsLoading(false);

      setOpenAlert({
        ...openAlert,
        open: true,
        message: res.data.message,
        type: "success",
      });
      localStorage.setItem("otpType", "register");
      localStorage.setItem("userDetails", JSON.stringify(res.data));
      localStorage.setItem("userEmail", res.data.data.email);

      setTimeout(() => {
        navigate("/otp");
      }, 1000);
    };

    const handleFail = (res) => {};

    register(data, handleSuccess, handleFail);
  };
  const onClickNext = () => {
    setNextPage(true);
  };
  const goesOnPreviousPage = () => {
    setNextPage(false);
  };

  useEffect(() => {
    if(userData.password !=null){
    validatePassword();
    }
  }, [userData.password]);
  const onChangePhoneInputs = (e, type) => {
    setUserData({ ...userData, [type]: e.target.value });
    registerValidation(
      { phone: e.target.value },
      (res) => {
        setErrors({
          ...errors,
          isValidPhone: !res.data.success ? true : false,
          phone: e.target.value.length === 10 ? false : true,
        });
      },
      () => {}
    );
  };

  const onChangeInputs = (e, type) => {
    switch (type) {
      case "last_name":
      case "first_name":
        setUserData({ ...userData, [type]: e.target.value });
        setFirstError({
          ...firstError,
          [type]:
            e.target.value === "" ||
            e.target.value.length < 1 ||
            !/^[A-Za-zÀ-ÖØ-öø-ÿ'\-!@#$%^&*()_+=,.?":{}|<>]+(\s[A-Za-zÀ-ÖØ-öø-ÿ'\-!@#$%^&*()_+=,.?":{}|<>]+)*$/.test(
              e.target.value
            )
              ? true
              : false,
        });
        break;

      case "username":
        setUserData({ ...userData, [type]: e.target.value });
        registerValidation(
          { username: e.target.value },
          (res) => {
            setFirstError({
              ...firstError,
              isValidUsername: !res.data.success ? true : false,
              username:
                e.target.value.length < 3 ||
                !/^[a-zA-Z0-9]+$/.test(e.target.value)
                  ? true
                  : false,
            });
          },
          () => {}
        );
        break;
      case "email":
        setUserData({ ...userData, [type]: e.target.value });
        registerValidation(
          { email: e.target.value },
          (res) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            setErrors({
              ...errors,
              isValidEmail: !res.data.success ? true : false,
              email:
                e.target.value === "" || !pattern.test(e.target.value)
                  ? true
                  : false,
            });
          },
          () => {}
        );
        break;
      default:
        break;
    }
    if (!nextPage) {
      const validateValue = Object.keys(userData).some((key) => {
        if (
          key != "email" ||
          key != "phone" ||
          key != "is_agreed_terms" ||
          key != "password"
        ) {
          return userData[key] === "";
        }
      });
    } else {
      const validateValue = Object.keys(userData).some((key) => {
        if (
          key == "email" ||
          key == "phone" ||
          key == "is_agreed_terms" ||
          key == "password"
        ) {
          return userData[key] === "";
        }
      });
    }
  };
  function shouldDisable() {
    if (!nextPage) {
      return (
        Object.keys(firstError).some((key) => firstError[key] === true) ||
        Object.keys(userData)
          .slice(0, 4)
          .some((key) => userData[key] === "")
      );
    } else {
      return (
        !userData.is_agreed_terms ||
        Object.keys(errors).some((key) => errors[key] === true)
      );
    }
  }
  return (
    <div className="register-container">
      <PCLiveLoader open={isLoading} />

      <AlertBar
        message={openAlert.message}
        open={openAlert.open}
        handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        alertType={openAlert.type}
      />
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <div className="left-content">
            <div className="login-form">
              <div className="form-container">
                <div item md={2} className="pclive-new-logo">
                  <img src={pcLiveLogo} alt="pcLiveLogo" loading="lazy" />
                  <div className="text-xl-medium-20">PCLive247</div>
                  <hr />
                </div>

                <div className="header-container">
                  <div className="text-sm-semibold">
                    <div>Sign up</div>
                    <div
                      className="text-sm-semibold-16"
                      onClick={() => navigate("/login")}
                    >
                      <ButtonLinkGrey
                        label={" Log In"}
                        className="text-sm-semibold-16"
                      />
                    </div>
                  </div>
                  <span className="text-md-medium">
                    Enter your details to create a free account!
                  </span>
                </div>

                {!nextPage ? (
                  <>
                    <div className="input-container">
                      <Grid container spacing={2}>
                        <Grid xs={6} item>
                          <div className="email">
                            <Input
                              placeholder={"e.g. Sarah"}
                              label={"First name"}
                              type={"text"}
                              icon={userIcon}
                              onChange={(e) => onChangeInputs(e, "first_name")}
                              error={firstError.first_name}
                              errorMsg={
                                "First name must be at least 1 character long, cannot contain numbers and can contain spaces in between words only."
                              }
                              value={userData.first_name}
                            />
                          </div>
                        </Grid>
                        <Grid xs={6} item>
                          <div className="email">
                            <Input
                              placeholder={"e.g. Miles"}
                              label={"Last name"}
                              type={"text"}
                              icon={userIcon}
                              onChange={(e) => onChangeInputs(e, "last_name")}
                              error={firstError.last_name}
                              errorMsg={
                                "Last name must be at least 1 character long, cannot contain numbers and can contain spaces in between words only."
                              }
                              value={userData.last_name}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <div className="email">
                        <Input
                          placeholder={"e.g. Sarah Miles"}
                          label={"User name"}
                          type={"text"}
                          tooltip={
                            "Enter a user friendly name. This will be used to identify yourself in the PCLive application."
                          }
                          icon={helpCircleIcon}
                          onChange={(e) => onChangeInputs(e, "username")}
                          error={
                            firstError.username || firstError.isValidUsername
                          }
                          errorMsg={
                            firstError.username
                              ? "Username must be at least 3 characters long and should not contain spaces or special characters."
                              : firstError.isValidUsername
                              ? "Username already exist"
                              : ""
                          }
                        />
                      </div>
                      <div className="email">
                        <div className="text-xs-medium-12 lable">
                          Date of birth
                        </div>
                        <DatePicker
                          selected={userData.date_of_birth}
                          value={userData.date_of_birth}
                          onClickOutside={() => setOpenCalender(false)}
                          onInputClick={() => setOpenCalender(!openCalender)}
                          open={openCalender}
                          readOnly
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onChange={(update) => {
                            setUserData({
                              ...userData,
                              date_of_birth: update ? update : "",
                            });
                            setFirstError({
                              ...firstError,
                              date_of_birth: update === "" ? true : false,
                            });
                          }}
                          dateFormat={"dd/MM/yyyy"}
                          maxDate={new Date().setFullYear(
                            new Date().getFullYear() - 13
                          )}
                          minDate={new Date("1900-01-01")}
                          icon={
                            <span>
                              <CalendarMonthIcon
                                style={{
                                  fontSize: 19,
                                  marginTop: -3,
                                }}
                              />
                            </span>
                          }
                        />
                        {firstError.date_of_birth ? (
                          <div className="text-sm-regular error">
                            {" "}
                            Please enter valid Date
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="gender-container">
                        <Radio
                          label={"Gender (optional)"}
                          data={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                            { label: "Non binary", value: "Non-Binary" },
                            {
                              label: "Prefer not to say",
                              value: "prefer_not_to_say",
                            },
                          ]}
                          onChange={(e) =>
                            setUserData({ ...userData, gender: e.target.value })
                          }
                          value={userData.gender}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="go-Back">
                      <img
                        src={ArrowLeft}
                        height={"20px"}
                        width={"20px"}
                        onClick={() => goesOnPreviousPage()}
                      ></img>
                      <span className="text-sm-semibold-14">
                        {" "}
                        Go back to modify information
                      </span>
                    </div>
                    <div className="input-container">
                      <div className="email">
                        <Input
                          placeholder={"e.g. Sarah Miles"}
                          label={"Email"}
                          type={"email"}
                          icon={mail}
                          onChange={(e) => onChangeInputs(e, "email")}
                          error={errors.email || errors.isValidEmail}
                          errorMsg={
                            errors.email
                              ? "Please enter a valid email."
                              : errors.isValidEmail
                              ? "Email already exist"
                              : ""
                          }
                        />
                      </div>
                      <div className="email">
                        <label
                          className="text-sm-medium-14"
                          style={{ marginBottom: 8, color: "#344054" }}
                        >
                          {"Phone number"}
                        </label>

                        <PhoneInput
                          setPhoneNumber={(data) =>
                            onChangePhoneInputs(
                              { target: { value: data } },
                              "phone"
                            )
                          }
                          error={errors.phone || errors.isValidPhone}
                        />
                        <p
                          style={{ marginTop: 8 }}
                          className="text-sm-regular error"
                        >
                          {errors.phone
                            ? "Please enter a valid phone number."
                            : errors.isValidPhone
                            ? "Phone no. already exist"
                            : ""}
                        </p>
                      </div>
                      <div className="email">
                        <Input
                          placeholder={"Strong Password"}
                          label={"Password"}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              password: e.target.value,
                            })
                          }
                          error={errors.password}
                          icon={showPassword ? eye : eyeOff}
                          type={showPassword ? "text" : "password"}
                          onClickIcon={() => setShowPassword(!showPassword)}
                          errorMsg={
                            "Password must contain: 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
                          }
                        />
                      </div>
                      <div className="password-validator text-sm-medium-14">
                        <div>
                          <ul>
                            <li
                              className={
                                passErrors.lengthError
                                  ? "list-inactive"
                                  : "list-active"
                              }
                            >
                              At least 8 characters
                            </li>
                            <br />
                            <li
                              className={
                                passErrors.numberError
                                  ? "list-inactive"
                                  : "list-active"
                              }
                            >
                              One number
                            </li>
                          </ul>
                        </div>
                        <ul>
                          <li
                            className={
                              passErrors.uppercaseError
                                ? "list-inactive"
                                : "list-active"
                            }
                          >
                            One uppercase letter
                          </li>
                          <br />
                          <li
                            className={
                              passErrors.specialCharError
                                ? "list-inactive"
                                : "list-active"
                            }
                          >
                            One special character
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                <div className="button-container">
                  {nextPage ? (
                    <div className="tandc">
                      <div>
                        <Checkbox
                          checkedIcon={
                            <img
                              src={
                                globalDarkMode
                                  ? checkBoxPrimaryDark
                                  : checkBoxPrimary
                              }
                              alt=""
                            />
                          }
                          icon={
                            <img
                              src={
                                globalDarkMode ? checkBoxBaseDark : checkBoxBase
                              }
                              alt=""
                            />
                          }
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              is_agreed_terms: e.target.checked,
                            })
                          }
                        />
                        <span className="text-sm-semibold-14">
                          By continuing, you agree to our{" "}
                          <a
                            className="text-sm-semibold-14"
                            target="_blank"
                            href="https://admin.pclive247.com/auth/terms"
                            rel="noreferrer"
                          >
                            Terms & Conditions
                          </a>{" "}
                          and{" "}
                          <a
                            target="_blank"
                            href="https://admin.pclive247.com/auth/policy"
                            className="text-sm-semibold-14"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <CustomPrimaryButton
                    onClick={() => {
                      !nextPage ? onClickNext() : onRegister();
                    }}
                    disabled={shouldDisable()}
                    size="xl"
                    fullWidth
                  >
                    {nextPage ? "Get OTP" : "Continue"}
                  </CustomPrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid xs={5}>
          <AuthCarosel />
        </Grid>
      </Grid>
    </div>
  );
};

export default Register;

import './Preferences.scss';

import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getUserInfo, setGlobalDarkMode, toggleKidsMode } from '../../../../redux/slices/profile';
import { useDispatch, useSelector } from 'react-redux';

import DarkMode from '../../../../utils/DarkMode';
import Input from '../../../../components/inputs/email/Input';
import PCLiveLoader from '../../../../components/loader/PCLiveLoader';
import PasswordPopup from '../../../../components/popup/password-popup/PasswordPopup';
import Toggle from '../../../../components/toggles/Toggle';
import { checkPassword } from '../../../../redux/slices/auth';
import eye from '../../../../assests/icons/eye.svg';
import eyeOff from '../../../../assests/icons/eye-off.svg';
import { toast } from 'react-toastify';

const Preferences = () => {
  const isDarkMode = localStorage.getItem('darkMode') === 'true';
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [password, setPassword] = useState('');
  const [darkMode, setDarkMode] = useState(() => {
  });
  const [errors, setErrors] = useState({
    password: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const userInfo = useSelector(state => state.profile.data);
  const isLoading = useSelector(state => state.profile.isLoading);

  const handleToggleDarkMode = () => {
    const darkModeEnabled = localStorage.getItem('darkMode') === 'true';
    localStorage.setItem('darkMode', darkModeEnabled ? 'false' : 'true');
    setDarkMode(!darkModeEnabled);
    dispatch(setGlobalDarkMode(!darkModeEnabled))
  };
  // Need to test on the server, hence need this code
  // useEffect(()=>{
  //   setDarkMode(isDarkMode=='true');
  //   dispatch(setGlobalDarkMode(isDarkMode=='true'))

  // },[isDarkMode])

  const isValid = () => {
    let errors = {};

    if (password === '' || password.length < 6) {
      errors.password = true;
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleContiue = () => {
    if (!isValid()) {
      return;
    }

    checkPassword(
      {
        password,
      },
      res => {
        if (res?.data?.success) {
          handleToggleKidsMode();
        } else {
          toast.error(res?.data?.message);
        }
      },
      error => console.log({ error }),
    );
  };

  const handleToggleKidsMode = () => {
    toggleKidsMode(
      {
        payment_from_wallet: userInfo?.data?.payment_from_wallet ? false : true,
      },
      res => {
        setOpenPopup(false);
        setPassword('');
        setErrors({ password: '' });
        dispatch(getUserInfo());
      },
    );
  };
  return (
    <div className="profile-preference-container">
       <DarkMode toggle={darkMode} setToggle={setDarkMode} />
      <PCLiveLoader open={isLoading} /> 
      
      <div className="text-md-medium-16 heading">Profile preferences</div>
      <Toggle
        title={'Dark mode'}
        descricption={
          'Dark mode transforms the interface into a darker color scheme for reduced brightness and eye strain in low-light conditions.'
        }
        toggleText={localStorage.getItem('darkMode') === 'true' ? 'Turn off' : 'Turn on'}
        onClick={handleToggleDarkMode}
      />
      <Toggle
        title={'Kid’s mode'}
        descricption={
          'Kids mode creates a child-friendly environment with age-appropriate content and safety features.'
        }
        toggleText={
          userInfo?.data?.payment_from_wallet ? 'Turn off' : 'Turn on'
        }
        onClick={() => setOpenPopup(true)}
      />
      <PasswordPopup
        openPopup={openPopup}
        onClose={() => {
          setOpenPopup(false);
          setPassword('');
          setErrors({ password: '' });
        }}
        actionButtons={{
          primary: {
            title: 'Cancel',
            onClick: () => {
              setOpenPopup(false);
              setPassword('');
              setErrors({ password: '' });
            },
          },
          secondary: {
            title: `${ userInfo?.data?.payment_from_wallet?"Disable":"Enable"} kid’s mode`,
            onClick: () => handleContiue(),
          },
        }}
        title={`Do you really want to ${userInfo?.data?.payment_from_wallet?"disable":"enable"} kid’s mode?`}
        description="When kid’s mode is activated, the only payment option available will be through the wallet.">
        <div style={{ marginTop: 20 }}>
          <Input
            placeholder={'Password'}
            icon={showPassword ? eye : eyeOff}
            label={'Enter password to continue'}
            type={showPassword ? 'text' : 'password'}
            onChange={e => setPassword(e.target.value)}
            onClickIcon={() => setShowPassword(!showPassword)}
            error={errors.password}
            errorMsg={'Please enter valid password'}
            value={password}
          />
        </div>
      </PasswordPopup>
    </div>
  );
};

export default Preferences;

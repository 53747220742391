import React from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "./IdentifyStep.scss";

const StepperComponent = ({
  getStep,
  handleClick,
  currentFlow,
  identifier,
  getData,
}) => {
  return (
    <Box className="stepper-container-drawer">
      <Stepper alternativeLabel>
        {getStep().step.map((label, index) => (
          <Step key={label.value} onClick={() => handleClick(label)}>
            <StepLabel
              StepIconComponent={() => (
                <span
                  className={`drawer-step-element-icon ${
                    getStep(identifier).index === index ? "active" : ""
                  }
                  ${getData(label, currentFlow) ? "success" : ""}
                  `}
                >
                  <FiberManualRecordIcon
                    style={{ fontSize: 10, marginTop: 7 }}
                  />
                </span>
              )}
            >
              <span
                className={`text-xs-medium-12  drawer-step-element-label ${
                  getStep(identifier).index === index ? "active" : ""
                }
               ${getData(label, currentFlow) ? "success" : ""}
                `}
              >
                {label.title}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperComponent;

import "./Orderdetails.scss";

import React, { useState } from "react";

import CustomizedTooltips from "../tooltip/Tooltip";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import pcliveLogo from "../../assests/icons/pclive247.png";
import moment from "moment";
import { useSelector } from "react-redux";

const Orderdetails = ({ shipments, isCorporate, status }) => {
  const [cardDetails, setCardDetail] = useState(false);
  const [orderDetails, setOrderDetail] = useState(false);
  const currentUserType = useSelector((state) => state.auth.currentUserType);
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);

  const getTax = (type) => {
    if (shipments && shipments?.tax_breakdown?.length) {
      let tax = shipments?.tax_breakdown?.find((f) => f.tax_type == type);
      return tax && tax?.percentage_decimal
        ? Number(tax?.percentage_decimal)
        : 0;
    }
  };

  const getTaxAmount = (type) => {
    if (shipments && shipments?.tax_breakdown?.length) {
      let tax = shipments?.tax_breakdown?.find((f) => f.tax_type == type);
      return tax && tax?.amount ? Number(tax.amount).toFixed(2) : 0;
    }
  };

  const getTotalBuyerAmount = () => {
    if (currentUserType == "buyer") {
      return Number(
        shipments?.delivery_charges +
          shipments?.total_tax_amount +
          shipments?.bid_price
      ).toFixed(2);
    }
  };

  return (
    <div
      className={`order-details-component  ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <div className="top-container">
        <img
          src={shipments?.front_image || pcliveLogo}
          height={100}
          loading="lazy"
          alt="card"
        />
        <div className="right-component">
          <div className="text-sm-medium-14">{shipments?.title}</div>
          <div className="dropdown">
            <div
              className={`text-sm-medium-14 dropdown-element ${
                cardDetails ? "active" : ""
              }`}
              onClick={() => {
                setCardDetail((prev) => !prev);
                setOrderDetail(false);
              }}
            >
              <span>Card details</span>{" "}
              {!cardDetails ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </div>
            <div
              className={`text-sm-medium-14 dropdown-element ${
                orderDetails ? "active" : ""
              }`}
              onClick={() => {
                setOrderDetail((prev) => !prev);
                setCardDetail(false);
              }}
            >
              <span>Order details</span>{" "}
              {!orderDetails ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </div>
          </div>
        </div>
      </div>
      {cardDetails && (
        <div className="bottom-container">
          <div className="content">
            <div className="text-xs-medium-12">Card Id </div>
            <CustomizedTooltips placement="top" title={shipments?.id}>
              <p className="text-sm-medium-14">{shipments?.id}</p>
            </CustomizedTooltips>
          </div>
          <hr />
          <div className="content">
            <div className="text-xs-medium-12">Category</div>{" "}
            <CustomizedTooltips placement="top" title={shipments?.category}>
              <p className="text-sm-medium-14">{shipments?.category}</p>
            </CustomizedTooltips>
          </div>
          <hr />
          <div className="content">
            <div className="text-xs-medium-12">Grade</div>{" "}
            <CustomizedTooltips
              placement="top"
              title={shipments?.grade === 0 ? "Authentic" : shipments?.grade}
            >
              <p className="text-sm-medium-14">
                {shipments?.grade === 0 ? "Authentic" : shipments?.grade}
              </p>
            </CustomizedTooltips>
          </div>
          <hr />
          <div
            className={`content ${
              isCorporate || !shipments?.inventory_tag_name ? "no-border" : ""
            }`}
          >
            <div className="text-xs-medium-12">Year</div>{" "}
            <CustomizedTooltips placement="top" title={shipments?.card_year}>
              <p className="text-sm-medium-14">{shipments?.card_year}</p>
            </CustomizedTooltips>
          </div>
          {!isCorporate && shipments?.inventory_tag_name && <hr />}
          {!isCorporate && shipments?.inventory_tag_name && (
            <div className="content no-border">
              <div className="text-xs-medium-12">Inventory tag</div>{" "}
              <CustomizedTooltips
                placement="top"
                title={
                  shipments?.inventory_tag_name
                    ? shipments?.inventory_tag_name
                    : "N/A"
                }
              >
                <p className="text-sm-medium-14">
                  {shipments?.inventory_tag_name
                    ? shipments?.inventory_tag_name
                    : "N/A"}
                </p>
              </CustomizedTooltips>
            </div>
          )}
        </div>
      )}
      {orderDetails &&
        (status?.toLowerCase() === "cancelled" ? (
          <div className="bottom-container">
            <p className="text-xs-medium-12 cancelled-order-details">
              No transaction has occurred due to cancellation of order
            </p>
          </div>
        ) : (
          <div className="bottom-container">
            <div className="content">
              <div className="text-xs-medium-12">
                {!shipments?.is_refunded ? "Bid price" : "Status"}{" "}
              </div>
              <p className="text-sm-medium-14">
                {!shipments?.is_refunded
                  ? `$${Number(shipments?.bid_price).toFixed(2)}`
                  : "Refunded"}
              </p>
            </div>
            <hr />
            <div className="content">
              <div className="text-xs-medium-12">
                {shipments?.is_refunded
                  ? "Date"
                  : currentUserType == "buyer"
                  ? "Shipping cost"
                  : `Tax(${getTax("Tax")}%)`}
              </div>{" "}
              <p className="text-sm-medium-14">
                {shipments?.is_refunded
                  ? moment(shipments?.refunded_at).format("lll")
                  : currentUserType == "buyer"
                  ? `$${Number(shipments?.delivery_charges).toFixed(2)}`
                  : `${currentUserType == "seller" ? "-$" : "$"}${Number(
                      getTaxAmount("Tax") / 100
                    ).toFixed(2)}`}
              </p>
            </div>
            <hr />
            <div className="content">
              <div className="text-xs-medium-12">
                {shipments?.is_refunded
                  ? "Refunded amount"
                  : currentUserType == "buyer"
                  ? `Sales tax (${getTax("sales_tax")})%`
                  : `PCLive co.(${getTax("PCLive247 Commission")}%)`}
              </div>{" "}
              <p className="text-sm-medium-14">
                {currentUserType == "seller" ? "-$" : "$"}
                {shipments?.is_refunded
                  ? parseInt(shipments?.refunded_amount) / 100
                  : currentUserType == "buyer"
                  ? `${Number(shipments?.total_tax_amount).toFixed(2)}`
                  : `${Number(
                      getTaxAmount("PCLive247 Commission") / 100
                    ).toFixed(2)}`}
              </p>
            </div>
            {!shipments?.is_refunded && <hr />}
            {!shipments?.is_refunded ? (
              <div className="content">
                <div className="text-xs-medium-12">
                  {currentUserType == "buyer" ? "Total Paid" : "Fees"}
                </div>{" "}
                <p className="text-sm-medium-14">
                  {currentUserType == "buyer"
                    ? `$${getTotalBuyerAmount()}`
                    : `${currentUserType == "seller" ? "-$" : "$"}${
                        getTaxAmount("Payment Processing Fee") / 100
                      }`}
                </p>
              </div>
            ) : (
              ""
            )}
            {!shipments?.is_refunded && currentUserType == "seller" && <hr />}
            {!shipments?.is_refunded ? (
              <div className="content no-border">
                <div className="text-xs-medium-12">
                  {currentUserType == "buyer" ? "" : "Your earning"}
                </div>{" "}
                <p className="text-sm-medium-14">
                  {currentUserType == "buyer"
                    ? ""
                    : (
                        shipments?.bid_price - shipments?.total_tax_amount
                      )?.toFixed(2)}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
    </div>
  );
};

export default Orderdetails;

import './CheckboxSelect.scss';

import * as React from 'react';

import FilterDropdown from '../../filter/FilterDropdown';

const items = [
  { label: '$1 - $5', value: '$1-$5' },
  { label: '$5 - $10', value: '$5-$10' },
  { label: '$10 - $20', value: '$10-$20' },
  { label: '$20 - $25', value: '$20-$25' },
  { label: '$25+', value: '$25+' },
  { label: '$50+', value: '$50+' },
  { label: '$100+', value: '$100+' },
  { label: '$200+', value: '$200+' },
];

export default function MultipleSelectCheckmarks({ label, onHandleChange, priceRange }) {
  const [personName, setPersonName] = React.useState([]);

  const handleChange = value => {
    onHandleChange(value);
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FilterDropdown
      title={label}
      list={items}
      subtitle={'Select price range'}
      result={handleChange}
      filtersChoosen={priceRange}
      isHorizontal
      showFocused
    />
  );
}

import "./Addresses.scss";

import React, { useState } from "react";
import {
  deleteAddress,
  getAllAddress,
  setCurrentAddress,
  updateAddress,
} from "../../../redux/slices/address";
import { useDispatch, useSelector } from "react-redux";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Grid } from "@mui/material";
import Popup from "../../popup/Popup";
import { getUserInfo } from "../../../redux/slices/profile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Addresses = ({ address, setOpenAddAddress,disableEdit,disableDelete ,isDark }) => {
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);

  const onClickEdit = () => {
    setOpenAddAddress(true);
    dispatch(setCurrentAddress(address));
  };

  const markPrimary = () => {
    const data = {
      ...address,
      address_id: address.id,
      is_primary: true,
    };
    updateAddress(
      data,
      (body) => {
        toast.success(body.message);
        dispatch(getAllAddress());
        dispatch(getUserInfo())
      },
      (body) => {
        toast.error(body.message);
      }
    );
  };

  const onDelete = () => {
   
      deleteAddress(
        { id: address.id },
        (body) => {
          toast.success(body.message);
          dispatch(getAllAddress());
        },
        (body) => {
          toast.error(body.message);
        }
      
    );
  };
  return (
    <div
      className={`address-container ${
        isDark || globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <Popup
        openPopup={openPopup}
        onClose={() => setOpenPopup(false)}
        actionButtons={{
          primary: {
            title: "Cancel",
            onClick: () => setOpenPopup(false),
          },
          secondary: {
            title: "Yes, delete address",
            onClick: () => {
              setOpenPopup(false);
              onDelete();
            },
          },
        }}
        title={"Are you sure you want to delete this address?"}
        description="Once deleted, you will have to add again to use the same address."
      />
      <div className="addresses">
        <div>
          <div className="text-sm-bold-14">
            {address?.address_name
              ? address?.address_name
              : address?.address_type}
          </div>
          <div className="text-sm-medium-14">
            {address.line1}, {address.city}, {address.state}, {address.country}
          </div>
        </div>
        <div className="right-content">
          <div
            className={`text-xs-medium-12 ${
              address.is_primary ? "success" : ""
            }`}
            onClick={() => markPrimary()}
          >
            {address.is_primary ? "primary" : "Mark as primary"}
          </div>
          {!disableEdit && (
            <div onClick={() => onClickEdit()}>
              <EditOutlinedIcon />
            </div>
          )}
          {!disableDelete && (
            <div onClick={() => setOpenPopup(true)}>
              <DeleteOutlineIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Addresses;

import "./SellerHome.scss";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker.css';

import { Dialog, IconButton } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import {
  getAllDashboardDetail,
  getEarningReports,
  getOrdersAndEarnings,
  getSoldAndOrderDetails,
} from "../../../../../../redux/slices/seller";
import { useDispatch, useSelector } from "react-redux";

import Backdrop from "@mui/material/Backdrop";
import Chart from "react-apexcharts";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedTooltips from "../../../../../../components/tooltip/Tooltip";
import DatePicker from "react-datepicker";
import FilterDropdown from "../../../../../../components/filter/FilterDropdown";
import Grid from "@mui/material/Grid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as MaximizeIcon } from "../../../../../../assests/icons/maximize.svg";
import moment from "moment";
import { setFilters } from "../../../../../../redux/slices/rooms";
import { setSettingOptions } from "../../../../../../redux/slices/profile";
import { useNavigate } from "react-router-dom";

const SellerHome = ({ roomType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let weekdateTo = moment(new Date()).format("MM-DD-YYYY");
  let weekdateFrom = moment(moment(new Date()).startOf("day"))
    .subtract(6, "d")
    .format("MM-DD-YYYY");
  const dashboardData = useSelector(
    (state) => state.dashboardData?.dashboardData?.data
  );
  const userInfo = useSelector((state) => state.profile?.data?.data);

  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const soldAndOrder = useSelector(
    (state) => state.soldAndOrder?.soldAndOrder?.data
  );
  const earningReport = useSelector(
    (state) => state.earningReport?.earningReport?.data
  );
  const sellerState = JSON.parse(localStorage.getItem("userDetail"));
  const orderAndEarning = useSelector(
    (state) => state.orderAndEarning?.orderAndEarning?.data
  );
  const isLoading = false;
  const [dateDisplay, setDateDisplay] = useState(false);
  const [currentval, setCurrentval] = useState("week");
  const [openModel, setOpenModel] = useState(false);
  const [graphState, setGraphState] = useState("");
  const [soldOrderSeries, setSoldOrderSeries] = useState([
    {
      name: "Sold Cards",
      data: [],
    },
    {
      name: "Uploaded Cards",
      data: [],
    },
  ]);

  const [soldOrderState, setSoldOrderState] = useState([
    {
      name: "Total Orders",
      data: [],
    },
  ]);
  const [earningState, setEarningState] = useState([
    {
      name: "Total Earning",
      data: [],
    },
  ]);

  const [soldCardOption, setSoldCardoption] = useState({
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ["#F79009"],
    title: {
      // text: "Total Orders",
      align: "left",
      margin: 20,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ["transparent"],
    },
    xaxis: {
      title: {
        text: "Dates",
      },
      labels: {
        style: {
          colors: globalDarkMode ? "#D0D5DD" : "", // Change the color of x-axis labels to red
        },
      },
    },
    yaxis: {
      title: {
        text: "Orders",
        style: {
          color: globalDarkMode ? "#F2F4F7" : "#475467",
          cssClass: "text-xs-medium-12",
        },
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#F2F4F7",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        formatter: function (val, index) {
          return val.toFixed(0);
        },
        style: {
          colors: globalDarkMode ? "#D0D5DD" : "", // Change the color of x-axis labels to red
        },
      },
    },
    fill: {
      opacity: 1,
    },
  });

  const [earningOption, setEarningOption] = useState({
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ["#175CD3"],
    title: {
      align: "left",
      margin: 20,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ["transparent"],
    },
    xaxis: {
      title: {
        text: "Dates",
      },
      labels: {
        style: {
          colors: globalDarkMode ? "#D0D5DD" : "", // Change the color of x-axis labels to red
        },
      },
    },
    yaxis: {
      title: {
        text: "Earnings in $",
        style: {
          color: globalDarkMode ? "#F2F4F7" : "#475467",
          cssClass: "text-xs-medium-12",
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },

      labels: {
        formatter: function (val, index) {
          return val.toFixed(2);
        },
        style: {
          colors: globalDarkMode ? "#D0D5DD" : "", // Change the color of x-axis labels to red
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val.toFixed(2);
        },
      },
    },
  });

  const [soldOrderOption, setSoldOrderOption] = useState({
    chart: {
      height: 550,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#175CD3", "#F79009"],
    legend: {
      position: "bottom",
      horizontalAlign: "right",
      onItemClick: {
        toggleDataSeries: false,
      },
      markers: {
        width: 12,
        height: 4,
      },
      labels: {
        colors: globalDarkMode ? "#D0D5DD" : "", // Change the color of legend labels to red
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      labels: {
        style: {
          colors: globalDarkMode ? "#D0D5DD" : "", // Change the color of x-axis labels to red
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val, index) {
          return val.toFixed(0);
        },
        style: {
          colors: globalDarkMode ? "#D0D5DD" : "", // Change the color of y-axis labels to blue
        },
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    title: {
      align: "left",
      margin: 20,
    },
    grid: {
      row: {
        colors: globalDarkMode ? ["#292828"] : ["#F7F8FA"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const optionsPie = {
    title: {
      text: "New & Existing Users",
      align: "left",
      margin: 30,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },

    chart: {
      width: 380,
      type: "pie",
      toolbar: {
        show: false,
      },
    },
    labels: ["New User", "Existing User"],
    plotOptions: {
      pie: {
        dataLabels: {
          minAngleToShowLabel: 0,
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            toolbar: {
              show: false,
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "left",
          },
        },
      },
    ],
  };

  useEffect(() => {
    if (roomType == 1) {
      dispatch(getAllDashboardDetail({ start: weekdateFrom, end: weekdateTo }));
      dispatch(
        getSoldAndOrderDetails({ start: weekdateFrom, end: weekdateTo })
      );
      dispatch(getOrdersAndEarnings({ start: weekdateFrom, end: weekdateTo }));
      dispatch(getEarningReports({ start: weekdateFrom, end: weekdateTo }));
    }
    dispatch(setFilters({}));
  }, [roomType]);

  useEffect(() => {
    const lineOption = { ...soldOrderOption };
    const soldSeries = [
      {
        name: "Sold ",
        data: [],
      },
      {
        name: "Upload ",
        data: [],
      },
    ];
    let xaxisdata = {
      categories: soldAndOrder?.upload_dates,
    };

    lineOption.xaxis = xaxisdata;
    setSoldOrderOption(lineOption);
    soldSeries[0].data = soldAndOrder?.sold_counts;
    soldSeries[1].data = soldAndOrder?.upload_counts;
    setSoldOrderSeries(soldSeries);
  }, [soldAndOrder]);

  useEffect(() => {
    const order = [...soldOrderState];
    const earning = [...earningState];
    const orderOp = { ...soldCardOption };
    const earningOp = { ...earningOption };
    let xaxisdata = {
      categories: orderAndEarning?.order_dates,
    };
    let earningXaxisdata = {
      categories: earningReport?.earning_dates,
    };
    order[0].data = orderAndEarning?.order_counts;
    earning[0].data = earningReport?.earning_amounts;
    orderOp.xaxis = xaxisdata;
    earningOp.xaxis = earningXaxisdata;
    orderOp.yaxis.max =
      orderAndEarning &&
      orderAndEarning.order_counts &&
      orderAndEarning.order_counts.length == 1 &&
      orderAndEarning.order_counts[0] == 0
        ? 2
        : undefined;
    if (
      orderAndEarning &&
      orderAndEarning.order_counts &&
      orderAndEarning.order_counts.length
    ) {
      //To remove console error
      setSoldOrderState(order);
      setSoldCardoption(orderOp);
    }
    if (
      earningReport &&
      earningReport.earning_amounts &&
      earningReport.earning_amounts.length
    ) {
      //To remove console error
      setEarningState(earning);
      setEarningOption(earningOp);
    }
  }, [orderAndEarning, earningReport, roomType]);

  useEffect(() => {
    if (startDate && endDate) {
      let start = moment(startDate).format("MM-DD-YYYY");
      let end = moment(endDate).endOf("day");
      end = moment(end).format("MM-DD-YYYY");

      dispatch(getAllDashboardDetail({ start, end }));
      dispatch(getSoldAndOrderDetails({ start, end }));
      dispatch(getOrdersAndEarnings({ start, end }));
      dispatch(getEarningReports({ start, end }));
    }
  }, [dispatch, startDate, endDate]);
  const onChangeSelection = (val) => {
    setCurrentval(val);
    if (val === "custom") {
      setDateDisplay(true);
      setDateRange([startDate, endDate]);
    }
    if (val === "week") {
      setDateDisplay(false);
      setDateRange([null, null]);
      dispatch(getAllDashboardDetail({ start: weekdateFrom, end: weekdateTo }));
      dispatch(
        getSoldAndOrderDetails({ start: weekdateFrom, end: weekdateTo })
      );
      dispatch(getOrdersAndEarnings({ start: weekdateFrom, end: weekdateTo }));
      dispatch(getEarningReports({ start: weekdateFrom, end: weekdateTo }));
    }
    if (val === "month") {
      let dateTo = moment(new Date()).format("MM-DD-YYYY");
      let dateFrom = moment(new Date()).subtract(29, "d").format("MM-DD-YYYY");
      setDateDisplay(false);
      setDateRange([null, null]);

      // setDateRange([dateFrom,dateTo])
      dispatch(getAllDashboardDetail({ start: dateFrom, end: dateTo }));
      dispatch(getSoldAndOrderDetails({ start: dateFrom, end: dateTo }));
      dispatch(getOrdersAndEarnings({ start: dateFrom, end: dateTo }));
      dispatch(getEarningReports({ start: dateFrom, end: dateTo }));
    }
  };
  const zoomGraph = () => {
    setOpenModel(true);
  };
  return (
    <div
      className={`dashboard-container-graph  ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      {!isLoading && userInfo
        ? !userInfo?.seller_verified && (
            <div
              className="verification text-xs-medium-12"
              onClick={() => {
                navigate("/profile");
                dispatch(setSettingOptions(2));
              }}
            >
              Verify your seller account to sell your preferred cards!
            </div>
          )
        : ""}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openModel}
        onClose={() => setOpenModel(false)}
        maxWidth="xl"
        PaperProps={{
          sx: {
            width: "100%",
            height: "600px",
            padding: "0px 45px",
            backgroundColor: globalDarkMode ? "#1C1D1D" : "",
            border: globalDarkMode ? "1px solid #292828" : "",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenModel(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            zIndex: 999,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Chart
          options={graphState.option}
          series={graphState.series}
          type={graphState.type}
          width={"100%"}
          height={500}
        />
      </Dialog>
      <div className={"figure-container seller"}>
        <div className="contants">
          <div className="text-xs-semibold-12 data">
            Total product sold
            <CustomizedTooltips
              placement="top"
              title="No. of products with successful payment"
            >
              <InfoOutlinedIcon className="icon" />
            </CustomizedTooltips>
          </div>
          <div className="text-xs-bold-24">{dashboardData?.product_sold}</div>
        </div>
        <div className="contants">
          <div className="text-xs-semibold-12 data">
            Total Orders
            <CustomizedTooltips
              placement="top"
              title="No. of successful bid placed"
            >
              <InfoOutlinedIcon className="icon" />
            </CustomizedTooltips>
          </div>
          <div className="text-xs-bold-24">{dashboardData?.order_counts}</div>
        </div>
        <div className="top-container">
          <div className="right-content">
            <div className="filter">
              <FilterDropdown
                title="Select date"
                list={[
                  { label: "Past 7 days", value: "week" },
                  { label: "Past 30 days", value: "month" },
                  { label: "Custom date", value: "custom" },
                ]}
                subtitle={"Select date"}
                result={(val) => {
                  onChangeSelection(val);
                }}
                type="radio"
                radioValue={currentval}
                isVerticalRadio
                isMiniDatePicker
              />
            </div>
            <div className="text-xs-medium-12 utcNotification">
              All graphs are as per UTC timezone
            </div>
            {dateDisplay ? (
              <div
                style={{
                  position: "relative",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                }}
              >
                <DatePicker
                  showIcon
                  calendarIconClassname="home-calender"
                  toggleCalendarOnIconClick
                  isClearable
                  calendarClassName="custom-calendar-icon"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  showYearDropdown
                  dropdownMode="scroll"
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  maxDate={new Date()}
                  minDate={new Date("2023-01-01")}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="graphs-container">
        {" "}
        <Suspense
          fallback={
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 9999,
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          <Grid container>
            <Grid item md={12} xs={12} sm={12} lg={12} xl={12}>
              <div className="graph-container line">
                <div className="graph-head">
                  <span className="text-md-semibold-16">
                    Cards Status by date
                  </span>
                  <div className="topActions">
                    <MaximizeIcon
                      onClick={() => {
                        zoomGraph(
                          setGraphState({
                            option: soldOrderOption,
                            series: soldOrderSeries,
                            type: "line",
                          })
                        );
                      }}
                      className="maximizeicon"
                    />
                    <CustomizedTooltips
                      placement="top"
                      title="Showing comparision of sold and uploaded cards"
                    >
                      <InfoOutlinedIcon className="icon" />
                    </CustomizedTooltips>
                  </div>
                </div>
                <Chart
                  options={soldOrderOption}
                  series={soldOrderSeries}
                  type="line"
                  width={"100%"}
                  height={300}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} sm={12} lg={6} xl={6}>
              <div className="graph-container">
                <div className="graph-head">
                  <span className="text-md-semibold-16">Total orders</span>
                  <div className="topActions">
                    <MaximizeIcon
                      onClick={() => {
                        zoomGraph(
                          setGraphState({
                            option: soldCardOption,
                            series: soldOrderState,
                            type: "bar",
                          })
                        );
                      }}
                      className="maximizeicon"
                    />
                    <CustomizedTooltips
                      placement="top"
                      title="Showing No. of bids placed on selected timeline"
                    >
                      <InfoOutlinedIcon className="icon" />
                    </CustomizedTooltips>
                  </div>
                </div>
                {soldOrderState &&
                soldOrderState.length &&
                soldOrderState[0] &&
                soldOrderState[0].data &&
                soldOrderState[0].data.length ? (
                  <Chart
                    options={soldCardOption}
                    series={soldOrderState}
                    type="bar"
                    width={"100%"}
                    height={300}
                  />
                ) : (
                  <div className="no-data"> </div>
                )}
              </div>
            </Grid>
            <Grid item md={6} xs={12} sm={12} lg={6} xl={6}>
              <div className="graph-container">
                <div className="graph-head">
                  <span className="text-md-semibold-16">Total earning</span>
                  <div className="topActions">
                    <MaximizeIcon
                      onClick={() => {
                        zoomGraph(
                          setGraphState({
                            option: earningOption,
                            series: earningState,
                            type: "bar",
                          })
                        );
                      }}
                      className="maximizeicon"
                    />
                    <CustomizedTooltips
                      placement="top"
                      title="Showing total earnings (Bid price - Tax - PCLive Commission - payment processing fee)"
                    >
                      <InfoOutlinedIcon className="icon" />
                    </CustomizedTooltips>
                  </div>
                </div>
                {earningState &&
                earningState.length &&
                earningState[0] &&
                earningState[0].data &&
                earningState[0].data.length ? (
                  <Chart
                    options={earningOption}
                    series={earningState}
                    type="bar"
                    width={"100%"}
                    height={300}
                  />
                ) : (
                  <div className="no-data"> </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Suspense>
      </div>
    </div>
  );
};

export default SellerHome;

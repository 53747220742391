import "./Dashboard.scss";

import React, { useEffect, useState } from "react";
import {
  getIntrestedCategories,
  getRoomsCatgories,
} from "../../../redux/slices/categories";
import { useDispatch, useSelector } from "react-redux";

import ButtonGray from "../../../components/buttons/button-gray/ButtonGray";
import ButtonLinkGrey from "../../../components/buttons/link-grey/ButtonLinkGrey";
import BuyerDashboard from "./contents/buyer/BuyerDashboard";
import CardDashboard from "../../../components/card-dashboard/CardDashboard";
import CustomPrimaryButton from "../../../components/buttons/primary/CustomPrimaryButton";
import CustomizedTooltips from "../../../components/tooltip/Tooltip";
import Dialog from "../../../components/dialog/Dialog";
import Grid from "@mui/material/Grid";
import GridViewIcon from "@mui/icons-material/GridView";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ManageCard from "./contents/seller/manage-cards/ManageCard";
import MuiCheckbox from "@mui/material/Checkbox";
import { NoData } from "../../../components/no-data/NoCards";
import Pagination from "../../../components/pagination/Pagination";
import SellerDashboard from "./contents/seller/SellerDashboard";
import SellerHome from "./contents/seller/seller-home/SellerHome";
import ViewCard from "./contents/seller/view-card/ViewCard";
import checkBoxBase from "../../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../../assests/icons/CheckboxPrimary.svg";
import { getCards } from "../../../redux/slices/card";
import { setRoomTypes } from "../../../redux/slices/auction";
import { setSearchCardsPageIndex } from "../../../redux/slices/card";
import { useNavigate } from "react-router-dom";
import CorporateCards from "./contents/seller/corporate-cards/CorporateCards";

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [isSeller, setIsSeller] = useState(false);
  const [intrestedCategories, setIntrestedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [drawerState, toggleDrawer] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  let usertype = localStorage.getItem("usertype");
  const [roomType, setRoomType] = useState(1);
  const [tabValue, setTabValue] = useState(1);
  const currentUserType = useSelector((state) => state.auth.currentUserType);
  const rommTyped = useSelector((state) => state.auction.roomType);
  const allSearchedCard = useSelector((state) => state.card.getSearchedCard);
  const totalSearchPages = useSelector((state) => state.card.totalSearchPages);
  const searchPageIndex = useSelector((state) => state.card.pageIndex);
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  {
    /* As per requirements the functionality is removed for this release,Might need in next one, hence commenting */
  }
  const sideMenu =
    currentUserType == "buyer"
      ? [
          { name: "Home", value: 1 },
          // { name: "Dealer's room", value: 2 },
          // { name: "Categorized rooms", value: 3 },
        ]
      : [
          { name: "Home", value: 1 },
          { name: "My cards", value: 2 },
          { name: "Manage cards", value: 3 },
          { name: "Corporate cards", value: 4 },
        ];
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.card.searchQuery);
  const logOut = () => {
    const setRememberedData = localStorage.getItem("allowdata");
    localStorage.clear();

    if (setRememberedData) {
      localStorage.setItem("allowdata", setRememberedData);
    }
    navigate("/login");
  };

  useEffect(() => {
    getIntrestedCategories(
      (res) => {
        if (res?.status === 200) {
          setIntrestedCategories(res.data.data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);
  useEffect(() => {
    getRoomsCatgories(
      (res) => {
        if (res?.status === 200) {
          if (res?.data?.data) {
            let filtered = res?.data?.data;
            if (res?.data?.data && intrestedCategories?.length) {
              filtered = res?.data?.data.filter(
                (obj2) =>
                  !intrestedCategories.some(
                    (obj1) => obj1.category_id === obj2.id
                  )
              );
            }
            setCategories(filtered);
          }
        }
      },
      (error) => {
        console.log({ error });
      }
    );
  }, [intrestedCategories]);

  const handleCheckbox = (event, category_id) => {
    const isChecked = event.target.checked;
    const categoryId = category_id;

    if (isChecked) {
      setCategoryFilters((prevFilters) => [...prevFilters, categoryId]);
    } else {
      setCategoryFilters((prevFilters) =>
        prevFilters.filter((id) => id !== categoryId)
      );
    }
  };
  useEffect(() => {
    if (currentCard && currentCard.id) {
      getCards(
        `?card_id=${currentCard.id}`,
        (body) => {
          setCurrentCard(body?.data?.data[0]);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [drawerState]);

  const setCurrentRoom = async (id, name) => {
    let myPromise = new Promise(function (myResolve, myReject) {
      localStorage.setItem("room_id", id);
      localStorage.setItem("room_name", name);
      myResolve(navigate("/auction")); // when successful
      myReject(); // when error
    });
  };

  const setRoomTypeFun = (num) => {
    setRoomType(num);
    localStorage.setItem("roomType", num);
    dispatch(setRoomTypes(num));
  };
  //Need this code later on upcoming changes
  // const setCurrentRoom = async (id,name) => {
  //   let myPromise = new Promise(function (myResolve, myReject) {
  //     localStorage.setItem("room_id", id);
  //     localStorage.setItem("room_name", name);
  //     myResolve(navigate("/auction")); // when successful
  //     myReject(); // when error
  //   });
  // };
  const onChangeComponent = (e, type) => {
    let component;
    if (currentUserType === "buyer") {
      component = (
        <BuyerDashboard
          categoryFilters={categoryFilters}
          noSearchData={searchQuery}
          roomTypes={roomType}
        />
      );
    } else {
      switch (roomType) {
        case 1:
          component = <SellerHome roomType={roomType} />;
          break;
        case 2:
          component = (
            <SellerDashboard
              noSearchData={searchQuery}
              globalRoomType={roomType}
            />
          );
          break;
        case 3:
          component = (
            <div>
              <ManageCard
                setRoomType={setRoomType}
                setCorporateCardsTabValue={setTabValue}
              />
            </div>
          );
          break;
        case 4:
          component = (
            <div>
              <CorporateCards tabValue={tabValue} setTabValue={setTabValue} />
            </div>
          );
          break;
        default:
          component = (
            <SellerDashboard
              noSearchData={searchQuery}
              globalRoomType={roomType}
            />
          );
      }
    }
    return component;
  };

  useEffect(() => {
    onChangeComponent();
    const localroom = localStorage.getItem("roomType");
    if (currentUserType == "seller") {
      setRoomType(localroom ? localroom : 1);
    } else {
      setRoomType(1);
    }
  }, [currentUserType]);

  useEffect(() => {
    setRoomType(rommTyped);
  }, [rommTyped]);

  return (
    <div
      className={`dashboard-container  ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <Grid container className="dashboard-content-container">
        <Grid item md={2} sm={2} className="dashboard-side-bar">
          <div>
            <div className="filter-container-left">
              {sideMenu.map((menu) => {
                return (
                  <span
                    className={`text-sm-semibold-14 room ${
                      roomType == menu.value ? "selected active" : ""
                    }`}
                    onClick={() => {
                      setRoomTypeFun(menu.value);
                      setTabValue(1);
                    }}
                  >
                    {roomType == menu.value ? (
                      <ButtonLinkGrey label={menu.name} />
                    ) : (
                      <ButtonGray label={menu.name} />
                    )}
                  </span>
                );
              })}
            </div>
          </div>
          <div className="sidebar-bottom">
            <div className="text-xs-medium-12 other-list-head">Others</div>
            <div className="text-sm-medium-14 ">
              <span className="other-list" onClick={() => navigate("/faq")}>
                <HelpOutlineIcon className="other-icon" />
                <span> FAQ's</span>
              </span>
              <span
                className="other-list"
                onClick={() => navigate("/termsandconditions")}
              >
                <TextSnippetOutlinedIcon className="other-icon" />

                <span>Terms and Conditions</span>
              </span>
              <span className="other-list" onClick={() => navigate("/policy")}>
                <ImportContactsOutlinedIcon className="other-icon" />

                <span> Privacy policy</span>
              </span>
            </div>
          </div>
        </Grid>
        <Grid item md={10} sm={10} style={{ padding: 0 }}>
          <Dialog
            openDialog={drawerState}
            onClose={() => toggleDrawer(false)}
            title={"Card details"}
          >
            <ViewCard
              data={currentCard}
              hideBuyer
              toggleDrawer={toggleDrawer}
            />
            <CustomPrimaryButton
              className="goAuctionBtn"
              onClick={() =>
                setCurrentRoom(currentCard?.room_id, currentCard?.room_name)
              }
            >
              Go to Auction Room
            </CustomPrimaryButton>
          </Dialog>
          {searchQuery ? (
            <>
              {allSearchedCard && allSearchedCard.length ? (
                <div>
                  <div className="room-list-head-dashboard text-lg-semibold-18">
                    Search results
                  </div>
                  <div className="room-list-container-dashboard search">
                    {allSearchedCard && allSearchedCard.length ? (
                      allSearchedCard?.slice(0, 10)?.map((card) => {
                        return (
                          <div
                            onClick={() => {
                              toggleDrawer(true);
                              setCurrentCard(card);
                            }}
                          >
                            <CardDashboard
                              card={card}
                              isAlert
                              toggleDrawer={toggleDrawer}
                              roomId={card.room_id}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="no-data-search-container">
                        <NoData type="search" />
                      </div>
                    )}
                  </div>
                  <Pagination
                    index={searchPageIndex}
                    handleChange={(page) =>
                      dispatch(setSearchCardsPageIndex(page))
                    }
                    totalPage={totalSearchPages}
                  />
                </div>
              ) : (
                <div className="no-data-search-container">
                  <NoData type="search" />
                </div>
              )}
            </>
          ) : (
            onChangeComponent()
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;

import "./Payment.scss";

import { Backdrop, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  addWalletTopup,
  deletePaymentBank,
  deletePaymentCard,
  getPaymentBank,
  getPaymentCards,
  getWalletBalance,
  updatePaymentCard,
} from "../../../../redux/slices/payment";
import { getUserInfo, toggleKidsMode } from "../../../../redux/slices/profile";
import { useDispatch, useSelector } from "react-redux";

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddPayment from "../../../../components/card/AddPaymentBank";
import AddPaymentBank from "../../../../components/card/AddPaymentBank";
import AddPaymentCard from "../../../../components/card/AddPaymentCard";
import Box from "@mui/material/Box";
import { BpRadio } from "../../../../components/radio/RadioIconDesign";
import ButtonGray from "../../../../components/buttons/button-gray/ButtonGray";
import ButtonLight from "../../../../components/buttons/button-light/ButtonLight";
import ButtonLinkGrey from "../../../../components/buttons/link-grey/ButtonLinkGrey";
import CircularProgress from "@mui/material/CircularProgress";
import CryptoJS from "crypto-js";
import CustomPopover from "../../../../components/popover/CustumPopover";
import CustomPrimaryButton from "../../../../components/buttons/primary/CustomPrimaryButton";
import CustomTabs from "../../../../components/tabs/CustomTabs";
import CustomizedTooltips from "../../../../components/tooltip/Tooltip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Dialog from "../../../../components/dialog/Dialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Input from "../../../../components/inputs/email/Input";
import { NoData } from "../../../../components/no-data/NoCards";
import PCLiveLoader from "../../../../components/loader/PCLiveLoader";
import PasswordPopup from "../../../../components/popup/password-popup/PasswordPopup";
import PaymentBank from "../../../../components/card/PaymentBank";
import PaymentCard from "../../../../components/card/Paymentcard";
import Popup from "../../../../components/popup/Popup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import WalletComponent from "../../../../components/wallet/Wallet";
import Warning from "../../../../components/warning/warning";
import axios from "axios";
import { checkPassword } from "../../../../redux/slices/auth";
import eye from "../../../../assests/icons/eye.svg";
import eyeOff from "../../../../assests/icons/eye-off.svg";
import stripeLogo from "../../../../assests/icons/stripe.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CardHelper, WalletHelper } from "../../../../components/heler-component/Helper";

const Payment = ({ address, setOpenAddAddress }) => {
  const dispatch = useDispatch();
  const allPaymentcards = useSelector(
    (state) => state.paymentCard.allPaymentCards?.data
  );
  const allPaymentBank = useSelector(
    (state) => state.paymentBank.allPaymentBank?.data
  );
  const walletBalance = useSelector(
    (state) => state.walletBalance?.currentWalletBalance?.data?.wallet_balance
  );
  const allPaymentcardsLoad = useSelector(
    (state) => state.paymentCard.isLoading
  );
  const allPaymentBankLoad = useSelector(
    (state) => state.paymentBank.isLoading
  );
  const walletBalanceLoad = useSelector(
    (state) => state.walletBalance?.isLoading
  );
  const currentUserType = useSelector((state) => state.auth.currentUserType);
  let tabdata =
    currentUserType == "buyer"
      ? [
          { title: "Cards", value: 1 },
          { title: "My wallet", value: 2 },
        ]
      : [
          { title: "Cards", value: 1 },
          { title: "My wallet", value: 2 },
          { title: "Banks", value: 3 },
        ];
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  const [openAddPaymentBank, setOpenAddPaymentBank] = useState(false);
  const [openAddPaymentCard, setOpenAddPaymentCard] = useState(false);
  const [addMoney, setAddMoney] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeleteBank, setOpenDeletebank] = useState(false);
  const [addWalletInput, setAddWalletInput] = useState(null);
  const [currentCard, setCurrentCard] = useState(null);
  const userInfo = useSelector((state) => state.profile?.data?.data);
  const [value, setValue] = useState(1);
  const [load, setLoad] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openPWPopup, setOpenPWPopup] = useState(false);
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    password: false,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setAddWalletInput(null)

  };
  useEffect(() => {
    setLoad(true);
    dispatch(getPaymentCards());
    dispatch(getPaymentBank());
    dispatch(getWalletBalance());
    setAddWalletInput(null)

    setLoad(false);

  }, []);
  const onSubmitWallet = () => {
    if (parseInt(addWalletInput) > 500) {
      toast.error("Maximum wallet recharge should not be more than $500");
      return;
    }
    const data = {
      amount: parseInt(addWalletInput),
      payment_method_id: currentCard,
    };
    addWalletTopup(
      data,
      (body) => {
        toast.success(body?.data?.message);
        setLoad(true);
        dispatch(getWalletBalance());
        setLoad(false);
        setAddWalletInput("");
        setAddMoney(false);
      },
      (body) => {
        toast.error(body.message);
      }
    );
  };
  const onDelete = () => {
    setLoad(true);
    deletePaymentCard(
      { id: currentCard.id },
      (body) => {
        toast.success(body.message);
        dispatch(getPaymentCards());
        setOpenPopup(false);
      },
      (body) => {
        toast.error(body.message);
      }
    );
    setLoad(false);
  };

  const onDeleteBank = () => {
    setLoad(true);
    try {
      deletePaymentBank(
        { id: currentCard.id },
        (response) => {
          if (response.data.success) {
            toast.success(response.data.message);
            setOpenDeletebank(false);
            dispatch(getPaymentBank());
          } else {
            toast.error(response.data.message);
          }
        },
        () => {}
      );
    } catch (error) {
      console.error("Error deleting bank:", error.message);
      toast.error("An error occurred while deleting the bank.");
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (allPaymentcards?.length) {
      setCurrentCard(allPaymentcards[0].id);
    }
  }, [allPaymentcards]);

  const isValid = () => {
    let errors = {};
    if (password === "" || password.length < 6) {
      errors.password = true;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleContiue = () => {
    if (!isValid()) {
      return;
    }

    checkPassword(
      {
        password,
      },
      (res) => {
        if (res?.data?.success) {
          handleToggleKidsMode();
        } else {
          toast.error(res?.data?.message);
        }
      },
      (error) => console.log({ error })
    );
  };

  const handleToggleKidsMode = () => {
    toggleKidsMode(
      {
        payment_from_wallet: userInfo?.payment_from_wallet ? false : true,
      },
      (res) => {
        setOpenPWPopup(false);
        setPassword("");
        setErrors({ password: "" });
        dispatch(getUserInfo());
      }
    );
  };
  return (
    <div className={`payment-container ${globalDarkMode ? 'pclive-dark-mode' : ''}`}>
      <PCLiveLoader
        open={
          allPaymentcardsLoad || allPaymentBankLoad || walletBalanceLoad || load
        }
      />

      {openPWPopup ? (
        <PasswordPopup
          openPopup={openPWPopup}
          onClose={() => {
            setOpenPWPopup(false);
            setPassword("");
            setErrors({ password: "" });
          }}
          icon={<AddBoxOutlinedIcon />}
          actionButtons={{
            primary: {
              title: "Cancel",
              onClick: () => {
                setOpenPWPopup(false);
                setPassword("");
                setErrors({ password: "" });
              },
            },
            secondary: {
              title: `${
                userInfo?.payment_from_wallet ? "Disable" : "Enable"
              } kid’s mode`,
              onClick: () => handleContiue(),
            },
          }}
          title={`Do you really want to ${userInfo?.payment_from_wallet ? "disable" : "enable"} kid’s mode?`}
          description="When kid’s mode is activated, the only payment option available will be through the wallet."
        >
          <div style={{ marginTop: 20 }}>
            <Input
              placeholder={"Password"}
              icon={showPassword ? eye : eyeOff}
              label={"Enter password to continue"}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              onClickIcon={() => setShowPassword(!showPassword)}
              error={errors.password}
              errorMsg={"Please enter valid password"}
              value={password}
            />
          </div>
        </PasswordPopup>
      ) : (
        ""
      )}
      <Dialog
        openDialog={openAddPaymentBank}
        onClose={() => setOpenAddPaymentBank(false)}
        title={"Add Bank"}
        rightElement={<img src={stripeLogo} />}
        isbankHelper
      >
        <AddPaymentBank setOpenAddPaymentBank={setOpenAddPaymentBank} />
      </Dialog>

      <Dialog
        openDialog={openAddPaymentCard}
        onClose={() => setOpenAddPaymentCard(false)}
        title={"Add Card"}
        rightElement={<img src={stripeLogo} />}
        isCardHelper
      >
        <AddPaymentCard setOpenAddPaymentCard={setOpenAddPaymentCard} />
      </Dialog>
      <Popup
        openPopup={openPopup}
        onClose={() => setOpenPopup(false)}
        actionButtons={{
          primary: {
            title: "Cancel",
            onClick: () => setOpenPopup(false),
          },
          secondary: {
            title: "Yes, delete card",
            onClick: () => {
              onDelete(false);
            },
          },
        }}
        title={"Are you sure you want to delete this card?"}
        description="Once deleted,  you have to add this card again to use it and all current transaction which are in processing state right now, will be terminated."
        note={currentCard?.is_primary?"The following card in the list will be designated as the primary card after this card is deleted.":""}
     />
      <Popup
        openPopup={openDeleteBank}
        onClose={() => setOpenDeletebank(false)}
        actionButtons={{
          primary: {
            title: "Cancel",
            onClick: () => setOpenDeletebank(false),
          },
          secondary: {
            title: "Delete bank",
            onClick: () => {
              setOpenDeletebank(false);
              onDeleteBank();
            },
          },
        }}
        title={"Are you sure you want to delete bank?"}
        description="Once deleted, you will have to add bank details again."
      />

      <div style={{position:'relative'}}>
        {value==2 && <div style={{position:'absolute',
                  right: 0,
                  zIndex: 1
                }}>
        <WalletHelper/>
        </div>}
        <CustomTabs
          tabData={tabdata}
          value={value}
          handleChange={(e, newval) => handleChange(e, newval)}
        >
          <TabPanel value={1} sx={{ padding: "24px 0" }}>
            {userInfo?.payment_from_wallet ? (
              <Warning
                error="To access cards related features, disable Kid's mode."
                descricption="To ensure access to cards related features, disable Kid's mode, which restricts certain actions for safety reasons."
                btnName="Turn off Kid's mode"
                onClick={() => setOpenPWPopup(true)}
              ></Warning>
            ) : (
              <>
                <div className="card-header">
                  <div className="text-md-medium-16">Available cards</div>

                  <ButtonLinkGrey
                    label={
                      <>
                        Add a new card <AddBoxOutlinedIcon />{" "}
                      </>
                    }
                    onClick={() => setOpenAddPaymentCard(true)}
                  />
                </div>
                <hr />
                {allPaymentcards && allPaymentcards.length ? 
                  <div className="main-Container">
                    {allPaymentcards.map((card) => {
                    return (
                      <div style={{ marginBottom: 10 }}>
                        <PaymentCard
                          isDelete={true}
                          setOpenPopup={setOpenPopup}
                          setCurrentCard={setCurrentCard}
                          card={card}
                        />
                      </div>
                    );
                  }) }
                  </div>
                : (
                  <NoData type="card" />
                )}
              </>
            )}
          </TabPanel>
          <TabPanel value={2} sx={{ padding: "41px 0" }}>
            <div className="main-Container">
            <WalletComponent balance={walletBalance ? walletBalance : 0} />
            {/* {!addMoney && <div
              className="add-money-toggle text-sm-medium-14"
              onClick={() =>
                userInfo?.payment_from_wallet
                  ? toast.error("Please disable kid's mode to add money")
                  : setAddMoney(true)
              }
            >
              <AddCircleOutlineOutlinedIcon />
              Add money to wallet{" "}
            </div>} */}
            <div
              className={`add-money-toggle text-md-medium-14 ${
                (allPaymentcards && allPaymentcards.length == 0) ||
                userInfo?.payment_from_wallet
                  ? "hideBtn"
                  : "showBtn"
              }`}
              onClick={() => setAddMoney(true)}
            >
              <AddCircleOutlineOutlinedIcon />
              Add money to wallet{" "}
            </div>
            {addMoney &&
            allPaymentcards &&
            allPaymentcards.length &&
            !userInfo?.payment_from_wallet ? (
              <>
                <div className="text-md-medium-16 add-money">
                  Add money to wallet
                </div>
                <hr />
                <div className="add-balance">
                  <Input
                    label="Enter amount to be added"
                    placeholder=""
                    type="text"
                    onChange={(e) => {if(/^\d{0,3}$/.test(e.target.value))setAddWalletInput(e.target.value)}}
                    value={addWalletInput}
                    errorMsg="Please enter valid value"
                    maxLength={3}
                  />
                  <ul className="balance-ul">
                    <li
                      onClick={() => setAddWalletInput(5)}
                      className="balance-li text-sm-semibold-14 "
                    >
                      $5
                    </li>
                    <li
                      onClick={() => setAddWalletInput(10)}
                      className="balance-li text-sm-semibold-14 "
                    >
                      $10
                    </li>
                    <li
                      onClick={() => setAddWalletInput(20)}
                      className="balance-li text-sm-semibold-14 "
                    >
                      $20
                    </li>
                    <li
                      onClick={() => setAddWalletInput(50)}
                      className="balance-li text-sm-semibold-14 "
                    >
                      $50
                    </li>
                    <li
                      onClick={() => setAddWalletInput(100)}
                      className="balance-li text-sm-semibold-14 "
                    >
                      $100
                    </li>
                  </ul>
                </div>
                {allPaymentcards && allPaymentcards.length ? (
                  <>
                    <FormControl className="card-list">
                      <label className="text-sm-medium-14">
                        Select payment source
                      </label>

                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        onChange={(e) => setCurrentCard(e.target.value)}
                        value={currentCard}
                      >
                        {allPaymentcards && allPaymentcards.length
                          ? allPaymentcards.map((card) => {
                              return (
                                <FormControlLabel
                                  value={card.id}
                                  control={<BpRadio />}
                                  label={
                                    <PaymentCard
                                      card={card}
                                      setOpenPopup={setOpenPopup}
                                      setCurrentCard={setCurrentCard}
                                      currentCard={currentCard}
                                    />
                                  }
                                />
                              );
                            })
                          : ""}
                      </RadioGroup>
                    </FormControl>
                    <div className="button-container">
                      <ButtonLight onClick={() => {setAddMoney(false);setAddWalletInput(null)}}>
                        Cancel
                      </ButtonLight>
                      <CustomPrimaryButton
                        onClick={() => onSubmitWallet()}
                        disabled={addWalletInput < 1}
                      >
                        Add money
                      </CustomPrimaryButton>
                    </div>
                  </>
                ) : (
                  <div className="noPaymentSource">
                    <div className="text-sm-medium-14 titleColor">
                      Payment source not found!
                    </div>
                    <hr />
                    <div className="newCard">
                      <div className="text-sm-medium-14 info">
                        We couldn’t find any card added to your payment methods!
                        Please add a valid card to continue.
                      </div>
                      <ButtonLinkGrey
                        label={
                          <>
                            Add a new card{" "}
                            <AddBoxOutlinedIcon style={{ marginLeft: 10 }} />{" "}
                          </>
                        }
                        onClick={() => setOpenAddPaymentCard(true)}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {userInfo?.payment_from_wallet ? (
                  <>
                    <Warning
                      error="Unable to add money to wallet."
                      descricption="We are unable to add money to your wallet as you are using Kid’s mode. Please turn Kid’s mode off to add money."
                      btnName="Turn off Kid's mode"
                      onClick={() => setOpenPWPopup(true)}
                    ></Warning>
                  </>
                ) : allPaymentcards && allPaymentcards?.length == 0 ? (
                  <Warning
                    error="Payment source not found."
                    descricption="We couldn’t find any card added to your payment methods! Please add a valid card to continue."
                    btnName=" Add a new card"
                    onClick={() => setOpenAddPaymentCard(true)}
                  ></Warning>
                ) : (
                  ""
                )}
              </>
            )}
            </div>
          </TabPanel>
          <TabPanel value={3} sx={{ padding: "24px 0" }}>
            {!userInfo?.payment_from_wallet ? (
              <>
                <div className="card-header">
                  <div className="text-md-medium-16">Available banks</div>

                  <ButtonLinkGrey
                    label={
                      <>
                        Add bank <AddBoxOutlinedIcon />{" "}
                      </>
                    }
                    onClick={() => setOpenAddPaymentBank(true)}
                  />
                </div>
                <hr />

                {allPaymentBank && allPaymentBank.length
                  ? <div className="main-Container">
                    {allPaymentBank.map((bank) => {
                      return (
                        <div style={{ marginBottom: 20 }}>
                          <PaymentBank
                            isDelete={true}
                            setOpenDeletebank={setOpenDeletebank}
                            bank={bank}
                            setCurrentCard={setCurrentCard}
                          />
                        </div>
                      );
                    })}
                  </div>
                  : <NoData type="bank"/>}
              </>
            ) : (
              <Warning
                error="To access banking features, disable Kid's mode."
                descricption="To ensure access to banking features, disable Kid's mode, which restricts certain actions for safety reasons."
                btnName="Turn off Kid's mode"
                onClick={() => setOpenPWPopup(true)}
              ></Warning>
            )}
          </TabPanel>
        </CustomTabs>
      </div>
    </div>
  );
};

export default Payment;

import Backdrop from "@mui/material/Backdrop";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import CustomPrimaryButton from "../../../../../components/buttons/primary/CustomPrimaryButton";
import PCLiveLoader from "../../../../../components/loader/PCLiveLoader";

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

class VerifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: "",
      isLoading: false,
      token: "",
    };
    this.handleClick = this.handleClick.bind(this);
  }
  getToken = () => {
    return localStorage.getItem("accessToken");
  };

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = localStorage.getItem("accessToken");
    this.setState({ stripe: await this.props.stripePromise, token: urlToken });
  }
  async handleClick(event) {
    // Block native event handling.
    event.preventDefault();

    const { stripe } = this.state;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    this.setState({ isLoading: true });
    // Call your backend to create the Verificimport ationSession.
    const response = await fetch(`${API_DOMAIN}/api/verify_identity/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    });
    const session = await response.json();
    this.setState({ isLoading: false });

    // Show the verification modal.
    const { error } = await stripe.verifyIdentity(session.data.client_secret);

    if (error) {
      console.log("[error]", error);
    } else {
      toast.success("Verification submitted!");
      this.props.setVarified(true);
      this.props.setOpenStripeVerification(false);
      this.props.getUserData()
      localStorage.setItem("stripe_varified", "yes");
    }
  }
  render() {
    const { stripe } = this.state;
    return (
      <>
      <PCLiveLoader open={this.state.isLoading} /> 

        <CustomPrimaryButton
          disabled={!stripe}
          onClick={this.handleClick}
        >
          Take me to the browser
        </CustomPrimaryButton>
      </>
    );
  }
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ID);

const Stripe = (props) => {
  const { setVarified, setOpenStripeVerification } = props;
  return (
    <VerifyButton
      setOpenStripeVerification={setOpenStripeVerification}
      setVarified={setVarified}
      stripePromise={stripePromise}
    />
  );
};

export default Stripe;

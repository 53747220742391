import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';


export const PrivateRoute = ({ component: RouteComponent }) => {
  const isAuthenticated = localStorage.getItem("userDetails")
  const useerID = isAuthenticated?JSON.parse(isAuthenticated)?.id:""
    if (useerID) {
      return <RouteComponent />
    }
  
    if (!useerID) {
      return  <Navigate to="/login" />
    }
}
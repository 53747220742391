import React from "react";
import "./Checkbox.scss";
const Checkbox = (props) => {
  const { label, disableLabelClick, id } = props;
  return (
    <div className="checkbox-component">
      <input
        id={id ? id : "basic-check"}
        name="basic-check"
        type="checkbox"
        {...props}
      ></input>
      <label
        style={{ cursor: disableLabelClick ? "" : "pointer" }}
        for={disableLabelClick ? "" : id ? id : "basic-check"}
        className="text-sm-medium-14"
      >
        {label}
      </label>
    </div>
  );
};
export default Checkbox;

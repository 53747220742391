import "./Profile.scss";

import React, { useEffect, useState } from "react";

import AccountSetting from "./account-setting/AccountSetting";
import AccountVarification from "./account-varification/AccountVarification";
import BasicDetails from "./basic-details/BasicDetails";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import Footer from "../../../components/footer/Footer";
import { Grid } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import MyOrders from "./my-orders/MyOrders";
import Payment from "./payment/Payment";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Preferences from "./preferences/Preferences";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { logout } from "../../../redux/slices/auth";
import moment from "moment";
import pcLiveLogo from "../../../assests/icons/pcLiveNewLogo.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllAddress } from "../../../redux/slices/address";
import { getUserInfo } from "../../../redux/slices/profile";
import PCLiveLoader from "../../../components/loader/PCLiveLoader";
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import { setFilters } from "../../../redux/slices/rooms";
const Profile = () => {
  const userInfo = useSelector((state) => state.profile);
  const [currentComponent, setCurrentComponent] = useState(1);
  const [currentInfo, setCurrentInfo] = useState("");
  const [load, setLoad] = useState(false);

  const navigation = useNavigate();
  const dispatch = useDispatch();
  const settingOption = useSelector((state)=>state.profile?.settingOption);
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
const currentUserType = useSelector((state) => state.auth?.currentUserType);

  useEffect(()=>{
      setCurrentInfo(userInfo?.data?.data)
  },[userInfo])
  const doLogout = () => {
    logout(handleSuccess, handleFail);
  };
  useEffect(()=>{
     setCurrentComponent(settingOption ? settingOption : 1);
  },[settingOption,currentUserType])

  const handleSuccess = (res) => {
    localStorage.clear();
    navigation("/login");
  };

  const handleFail = (res) => {
    localStorage.clear();
    navigation("/login");
  };

  const handleComponent = () => {
    switch (currentComponent) {
      case 1:
        return <BasicDetails />;
      case 2:
        return <AccountVarification />;
      case 3:
        return <Payment />;
      case 4:
        return <MyOrders />;
      case 5:
        return <Preferences />;
      case 6:
        return <AccountSetting />;
      default:
        return <BasicDetails />;
    }
  };
  const getClassname = () => {
    if (userInfo && userInfo.data && userInfo.data.data) {
      return userInfo.data.data.account_verified ? "active" : "verification";
    }
    return "active";
  };
  useEffect(() => {
    setLoad(true);
    dispatch(getAllAddress());
    dispatch(getUserInfo());
    dispatch(setFilters({}))
    setLoad(false);
  }, []);
  return (
    <div className={`profile-container ${globalDarkMode ? 'pclive-dark-mode' : ''}`}>
       <PCLiveLoader open={userInfo?.isLoading || load} />
      <Grid container spacing={2} sx={{height:'100%'}}>
        <Grid item md={3}>
          <div  className="profile-side-bar">
          <img
            src={
              currentInfo && currentInfo.profile_picture
                ? currentInfo && currentInfo.profile_picture 
                : pcLiveLogo
            }
            loading="lazy"
          />
          <div className="text-lg-semibold-18">
            { currentInfo && currentInfo.first_name?currentInfo.first_name:"-"} 
              {' '}
              {currentInfo && currentInfo.last_name?currentInfo.last_name:"-"}
          </div>
          <div className="text-sm-medium-14">
            Joined on {moment(userInfo.created_at).format("MMM YYYY")}
          </div>
          <div className="follower-container">
            <div className="follower-content text-sm-medium-14 left">
              <div>Followers</div>
              <div className="count text-lg-semibold-18">{userInfo?.data?.data?.followers}</div>
            </div>
            <div className="follower-content text-sm-medium-14">
              <div>Following</div>
              <div className="count text-lg-semibold-18">{userInfo?.data?.data?.following}</div>
            </div>
          </div>
          <div className="logout text-sm-medium-14" onClick={doLogout}>
            <LogoutIcon /> Logout
          </div>
          </div>
              <div className="profile-footer"> 
          <Footer/>
          </div>
        </Grid>
        <Grid md={0.05}></Grid>
        <Grid item md={8.95}>
          <div  className="profile-right-content">
          <Grid container>
            <Grid item md={3} className="left-menu text-sm-medium-14">
              <div className="menu-head">Menu</div>
              <ul>
                <li
                  className={currentComponent == 1 ? "active" : ""}
                  onClick={() => setCurrentComponent(1)}
                >
                  <PersonOutlineOutlinedIcon className="icon" /> Basic details
                </li>
                <li
                  className={ currentComponent == 2 ? getClassname():""}
                  onClick={() => setCurrentComponent(2)}
                >
                  <HowToRegOutlinedIcon /> Account verification
                </li>
                <li
                  className={currentComponent == 3 ? "active" : ""}
                  onClick={() => setCurrentComponent(3)}
                >
                  <CreditCardOutlinedIcon /> Payments
                </li>
                <li
                  className={currentComponent == 4 ? "active" : ""}
                  onClick={() => setCurrentComponent(4)}
                >
                  <ShoppingCartOutlinedIcon /> Orders
                </li>
                <li
                  className={currentComponent == 5 ? "active" : ""}
                  onClick={() => setCurrentComponent(5)}
                >
                 <BuildOutlinedIcon />   Profile preferences
                </li>
                <li
                  className={currentComponent == 6 ? "active" : ""}
                  onClick={() => setCurrentComponent(6)}
                >
                 <SettingsOutlinedIcon /> Account Settings
                </li>
              </ul>
            </Grid>
            <Grid item md={9} className="left-menu">
              {handleComponent()}
            </Grid>
          </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Profile;

import "./ButtonOutlined.scss";

import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const ButtonOutlined = (props) => {
  const { label, disabled, isDark} = props;
  
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  return (
    <div className={`button-outlined-component text-sm-semibold-14  ${globalDarkMode?"pclive-dark-mode":""}`}>
      <Button
        variant="text"
        className={`text-sm-semibold-14 ${isDark?"dark":""}`}
        {...props}
      >
        {props.children}
      </Button>
    </div>
  );
};
export default ButtonOutlined;

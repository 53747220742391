import address from './slices/address';
import { auctionSliceReducer } from './slices/auction';
import { authSliceReducer } from './slices/auth';
import { cardSliceReducer } from './slices/card';
import categories from './slices/categories';
import { configureStore } from '@reduxjs/toolkit';
import payment from './slices/payment';
import profile from './slices/profile';
import { roomsSliceReducer } from './slices/rooms';
import seller from './slices/seller';
import shipment from './slices/shipment';
import thunk from 'redux-thunk';

export const store = configureStore({
  reducer: {
    auth: authSliceReducer.auth,
    notification: authSliceReducer.notifications,
    categories,
    card: cardSliceReducer,
    rooms: roomsSliceReducer,
    auction: auctionSliceReducer,
    address,
    profile: profile.profile,
    userFollowers: profile.userFollowers,
    paymentBank: payment.paymentBank,
    paymentCard: payment.paymentCard,
    walletBalance: payment.walletBalance,
    shipmentBundle: shipment.shipmentBundle,
    shipmentCards: shipment.shipmentCards,
    recentPayouts: shipment.recentPayouts,
    recentPayoutDetails: shipment.recentPayoutDetails,
    trackingStatus: shipment.trackingStatus,
    recentTransections: shipment.recentTransections,
    recentTransectionsDetails: shipment.recentTransectionsDetails,
    sellerCards: seller.sellerCards,
    sellerHigestBidCards: seller.sellerHigestBidCards,
    inventorytag: seller.inventorytag,
    dashboardData: seller.dashboardData,
    soldAndOrder: seller.soldAndOrder,
    orderAndEarning: seller.orderAndEarning,
    earningReport: seller.earningReport,
  },
  middleware: [thunk],
});

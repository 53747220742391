import "./Auction.scss";

import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  deletePaymentCard,
  getPaymentBank,
  getPaymentCards,
  updatePaymentCard,
} from "../../../redux/slices/payment";
import {
  getAllAddress,
  setCurrentAddress,
  updateAddress,
} from "../../../redux/slices/address";
import {
  getSellerInfo,
  getViewAllCards,
  setCurrentSeller,
} from "../../../redux/slices/auction";
import { getUserInfo, toggleKidsMode } from "../../../redux/slices/profile";
import { useDispatch, useSelector } from "react-redux";

import AddAddress from "../../../components/addresses/add-address/AddAddress";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AddIcon from "@mui/icons-material/Add";
import AddPaymentCard from "../../../components/card/AddPaymentCard";
import AddressList from "../../../components/addresses/address-list/AddressList";
import Addresses from "../../../components/addresses/address/Addresses";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { BpRadio } from "../../../components/radio/RadioIconDesign";
import ButtonLight from "../../../components/buttons/button-light/ButtonLight";
import ButtonOutlined from "../../../components/buttons/button-outlined/ButtonOutlined";
import CardDashboard from "../../../components/card-dashboard/CardDashboard";
import ChatBubble from "./chat/ChatBubble";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import ConfettiExplosion from "react-confetti-explosion";
import CreateIcon from "@mui/icons-material/Create";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import CustomPrimaryButton from "../../../components/buttons/primary/CustomPrimaryButton";
import CustomizedTooltips from "../../../components/tooltip/Tooltip";
import Dialog from "../../../components/dialog/Dialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Input from "../../../components/inputs/email/Input";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { NoData } from "../../../components/no-data/NoCards";
import PCLiveLoader from "../../../components/loader/PCLiveLoader";
import Pagination from "../../../components/pagination/Pagination";
import PasswordPopup from "../../../components/popup/password-popup/PasswordPopup";
import PaymentCard from "../../../components/card/Paymentcard";
import Popup from "../../../components/popup/Popup";
import ReactImageMagnify from "react-image-magnify";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchInput from "../../../components/inputs/search/Search";
import SellerProfileDialog from "./seller-profile/SellerProfileDialog";
import ShippingAndtax from "./shipping/ShippingAndTax";
import UpcomingCards from "./upcoming-cards/UpcomingCards";
import ViewCard from "../dashboard/contents/seller/view-card/ViewCard";
import WalletComponent from "../../../components/wallet/Wallet";
import Warning from "../../../components/warning/warning";
import WebSocket from "websocket";
import { checkPassword } from "../../../redux/slices/auth";
import eye from "../../../assests/icons/eye.svg";
import eyeOff from "../../../assests/icons/eye-off.svg";
import { io } from "socket.io-client";
import pcliveLogo from "../../../assests/icons/pclive247.png";
import pcliveLogoNew from "../../../assests/icons/pcLiveNewLogo.svg";
import seller from "../../../redux/slices/seller";
import sendIcon from "../../../assests/icons/send-dark.svg";
import stripeLogo from "../../../assests/icons/stripeBlack.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import coinImg from '../../../assests/icons/coin.svg'
const currentRoom = localStorage.getItem("room_id");
const userDetails = localStorage.getItem("userDetails");
const bigExplodeProps = {
  force: 1,
  duration: 3000,
  particleCount: 500,
  floorHeight: "100vw",
  floorWidth: "100vh",
};
const demoCards = [
  {
    id: 1418,
    title: "kohli",
    front_image:
      "https://pclive-cards.s3.us-east-1.amazonaws.com/2/front_image-12-14-2023-04-54-46.webp",
    back_image: null,
    grade: 3,
    description: "Virat",
    reserve_price: 1,
    current_auction_price: null,
    is_sold: false,
    is_vintage: null,
    card_year: 2021,
    created_by: 2,
    category_id: 4,
    is_deleted: false,
    price_range: "$1-$5",
    published: true,
    condition: "slab",
    seller: "csk",
    category_name: "Testing",
    room_id: 12,
    room_name: "Graded Auctions-$1-$5",
  },
];
export default function Auction() {
  const [addBidInput, setAddBidInput] = useState(0);
  const [currentBid, setCurrentBid] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddressList, setOpenAddressList] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [shipTax, setShipTax] = useState(false);
  const [sortedAllCards, setSortedAllCards] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentSelection, setCurrentSelection] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPWPopup, setOpenPWPopup] = useState(false);
  const [currentCard, setCurrentCard] = useState({
    currentId: null,
    card: null,
  });
  const [paymentMethod, setOpenPaymentMethod] = useState(null);
  const [openAddPaymentCard, setOpenAddPaymentCard] = useState(false);
  const [viewCard, setViewcard] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [load, setLoad] = useState(false);
  const [bidLoad, setBidLoad] = useState(false);
  const [counter, setCounter] = useState(20);
  const [allData, setAllData] = useState(null);
  const [cardListState, setCardList] = useState([]);
  const [viewAllCards, setViewAllCard] = useState([]);
  const [chats, setChat] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [socketState, setSocket] = useState(null);
  const [socketChatState, setSocketChat] = useState(null);
  const [messageState, setMessage] = useState(null);
  const [typeBidding, setTypeBidding] = useState(null);
  const [auctionEnd, setAuctionEnd] = useState(null);
  const [userDetailsState, setUserDetails] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [paymentPopupDelete, setPaymentDelete] = useState(false);
  const [roomName, setRoomName] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [apiCall, getApiCall] = useState(false);
  const [searchQuery, setSearch] = useState("");
  const [switchCard, setSwitchCard] = useState(true);
  const [switchPaymentard, setSwitchPaymentCard] = useState(null);
  const [addressState, setaddressState] = useState([]);
  const [custumBidInput, setCustumBidInput] = useState(0);
  const [showPop, setShowPop] = useState(false);
  const [holdCounter, setholdCounter] = useState(5);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    password: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentAddress = useSelector((state) => state.address?.currentAddress);
  const userInfo = useSelector((state) => state.profile?.data?.data);
  const allAddress = useSelector((state) => state.address.data?.data);
  const allPaymentcards = useSelector(
    (state) => state.paymentCard.allPaymentCards?.data
  );
  const allPaymentcardsLoad = useSelector(
    (state) => state.paymentCard.isLoading
  );
  const currentUserType = useSelector((state) => state.auth.currentUserType);
  const currentSeller = useSelector((state) => state.auction.currentSeller);
  useEffect(() => {
    const currentRoomName = localStorage.getItem("room_name");
    setRoomName(currentRoomName);
    dispatch(getUserInfo());
    dispatch(getAllAddress());
    dispatch(getPaymentCards());
    dispatch(getPaymentBank());
    setPageIndex(1);
  }, []);
  useEffect(() => {
    if (cardListState && cardListState.length) {
      getSellerInfo(
        `user_id=${cardListState[0].seller_id}`,
        (res) => {
          if (res?.data?.success) {
            dispatch(setCurrentSeller(res?.data?.data));
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [cardListState]);

  useEffect(() => {
    var myDiv = document.getElementById("scrollit");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }, [pageIndex]);
  useEffect(() => {
    const currentRoom = localStorage.getItem("room_id");
    try {
      setLoad(true);
      getViewAllCards(
        `room_id=${currentRoom}&paginate=true&page=${pageIndex}&limit=10${
          searchQuery ? `&search_text=${searchQuery}` : ""
        }`,
        (res) => {
          if (res?.data?.success) {
            setViewAllCard(res?.data?.data);
            setTotalPage(res?.data?.total_pages);
            setTotalCount(res?.data?.total_count);
            setLoad(false);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
      setLoad(false);
    }
  }, [viewAll, pageIndex, apiCall, searchQuery]);

  useEffect(() => {
    setLoad(true);
    if (allData) {
      setLoad(false);
    }
  }, [allData]);
  useEffect(() => {
    const timer = setInterval(() => {
      setholdCounter((prevCountdown) =>
        prevCountdown > 1 ? prevCountdown - 1 : 1
      ); // Decrement countdown every second
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    const currentRoom = localStorage.getItem("room_id");
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      let details = JSON.parse(userDetails);
      setCurrentUser(details);
      const chatsocket = new WebSocket.w3cwebsocket(
        `wss://${process.env.REACT_APP_SOCKET_ENDPOINT}/ws/chat/${currentRoom}/`
      );
      setSocketChat(chatsocket);
      const socket = new WebSocket.w3cwebsocket(
        `wss://${
          process.env.REACT_APP_SOCKET_ENDPOINT
        }/ws/room/${currentRoom}/?user_id=${details?.id}&is_from_seller=${
          currentUserType == "buyer" ? "false" : "true"
        }}`
      );
      setSocket(socket);
    }
  }, [currentUserType, currentRoom, userDetails]);
  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    let details = JSON.parse(userDetails);
    setUserDetails(details);
    if (socketState && socketChatState) {
      socketState.onopen = () => {};

      socketChatState.onopen = () => {};
      socketChatState.onmessage = (event) => {
        const chatData = JSON.parse(event.data);
        if (chatData && chatData.type == "messages") setChat(chatData.data);
      };
      let cardData = [];
      socketState.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // Need this console log for testing
        console.log(data, "Event from socket");
        switch (data?.type) {
          case "room_message":
            setCardList(data.card_list);
            setAllData(data);
            setCustumBidInput(1);
            setAddBidInput(0);
            cardData = data.card_list;
            break;
          case "timer_message":
            setCounter(data.timer);
            if (data.timer == 19) {
              socketState.send(
                JSON.stringify({
                  event: "data_received",
                  user_id: details?.id,
                  seller_id:
                    cardData && cardData.length ? cardData[0].seller_id : 0,
                  condition:
                    cardData && cardData.length ? cardData[0].condition : "",
                  card_id: cardData && cardData.length ? cardData[0].id : 0,
                  address_id:
                    cardData && cardData.length ? cardData[0].address_id : 0,
                })
              );
            }
            setBidLoad(data.timer == 0);
            if (data.timer == 0) {
              setViewcard(false);
              setholdCounter(5);
            }
            break;
          case "bidding_false":
            toast.error(data.message);
            break;
          case "wallet_balance":
            setWalletBalance(data?.wallet_balance);
            break;
          case "auction_ended":
            setAuctionEnd(data);
            setLoad(false);
            socketState.close(1000, "auction_ended");
            break;
          case "bidding":
            setTypeBidding(data);
            setCustumBidInput(1);
            setAddBidInput(0);
            setCurrentSelection(1);
            break;
          case "bid_won":
            if (data?.buyer == details?.username) {
              if (data?.success) {
                setShowPop(true);
                toast.success(data.message);
                setTimeout(() => {
                  setShowPop(false);
                }, 5000);
              } else {
                toast.warn(data.message);
              }
            }
            break;
          case "delivery_charges":
            setDeliveryCharges(data);

            break;
          case "card_inactive":
            toast.warn(data.message);
            break;
          default:
            // Handle unexpected data type
            break;
        }
        // Handle messages received from the server
      };

      return () => {
        socketState.close(1000, "unmounted");
      };
    }
  }, [socketChatState, socketState, userDetails]);
  const onPlaceBid = () => {
    const details = JSON.parse(userDetails);
    if (!userInfo?.buyer_verified) {
      toast.error(
        "Please add atleast one address and one payment card to place bid"
      );
      return;
    }
    socketState.send(
      JSON.stringify({
        event: "bidding",
        user_id: details?.id,
        bidding_amount:
          (!typeBidding && !typeBidding?.user_name) ||
          (typeBidding && !typeBidding?.user_name)
            ? currentBid
            : (addBidInput > 0 ? addBidInput + 1 : custumBidInput) + currentBid,
        payment_from_wallet: userInfo?.payment_from_wallet,
        delivery_charges: deliveryCharges?.delivery_charge,
        card_id:
          cardListState && cardListState.length ? cardListState[0].id : "",
        current_timer: counter,
      })
    );
  };

  const onSendMessage = () => {
    const details = JSON.parse(userDetails);
    socketChatState.send(
      JSON.stringify({
        username: details?.username,
        message: messageState,
        profile_pic: details?.profile_picture,
      })
    );
    setMessage("");
  };

  const getAlertData = (allData) => {
    if (auctionEnd?.type == "auction_ended") {
      return `Auction has been ended`;
    }
    if (typeBidding?.user_name) {
      return `${typeBidding?.user_name} is winning this bid 🎉`;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && messageState) {
      // Call your function here
      onSendMessage();
    }
  };
  useEffect(() => {
    if (allAddress && allAddress.length) {
      setaddressState(allAddress);
    }
  }, [allAddress]);
  const onClickAddress = (address) => {
    const details = JSON.parse(userDetails);
    if (allAddress && allAddress.length > 1) {
      if (typeBidding && typeBidding.user_name == details?.username) {
        toast.warn("You cannot change address once bid is placed");
        return;
      }
      setLoad(true);
      const data = {
        ...address,
        address_id: address.id,
        is_primary: true,
      };
      updateAddress(
        data,
        (body) => {
          dispatch(getAllAddress());
          toast.success("Primary address updated");
          socketState.send(
            JSON.stringify({
              event: "address_updated",
              seller_id:
                cardListState && cardListState.length
                  ? cardListState[0].seller_id
                  : 0,
              condition:
                cardListState && cardListState.length
                  ? cardListState[0].condition
                  : "",
              address_id:
                cardListState && cardListState.length
                  ? cardListState[0].address_id
                  : 0,
              card_id:
                cardListState && cardListState.length ? cardListState[0].id : 0,
            })
          );
          setLoad(false);
        },
        (body) => {
          toast.error(body.message);
        }
      );
    }
  };

  const markCardAsPrimary = () => {
    setOpenPopup(false);
    setLoad(true);
    const data = {
      payment_method_id: switchPaymentard.id,
    };
    updatePaymentCard(
      data,
      (body) => {
        dispatch(getPaymentCards());
        toast.success("Payment source updated");
        setLoad(false);
      },
      (body) => {
        toast.error(body.message);
        setLoad(false);
      }
    );
  };

  useEffect(() => {
    if (custumBidInput == 1) {
      setCurrentSelection(1);
    }
  }, [custumBidInput]);

  const onDelete = () => {
    setLoad(true);
    deletePaymentCard(
      { id: currentCard.id },
      (body) => {
        toast.success(body.message);
        dispatch(getPaymentCards());
        setPaymentDelete(false);
      },
      (body) => {
        toast.error(body.message);
      }
    );
    setLoad(false);
  };

  useEffect(() => {
    let bid = 1;
    if (typeBidding?.user_name) {
      bid = typeBidding.bidding_amount;
    } else if (cardListState?.length) {
      bid = cardListState[0].reserve_price;
    }
    setCurrentBid(bid);
  }, [typeBidding, cardListState]);
  const onSwitchCard = () => {
    setSwitchCard(!switchCard);
  };

  const onClickDetails = () => {
    if (cardListState && cardListState.length && !bidLoad) {
      setViewcard(true);
      setCurrentCard(cardListState[0]);
      setPageIndex(1);
    }
  };

  const isValid = () => {
    let errors = {};
    if (password === "" || password.length < 6) {
      errors.password = true;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleContiue = () => {
    if (!isValid()) {
      return;
    }

    checkPassword(
      {
        password,
      },
      (res) => {
        if (res?.data?.success) {
          handleToggleKidsMode();
        } else {
          toast.error(res?.data?.message);
        }
      },
      (error) => console.log({ error })
    );
  };

  const handleToggleKidsMode = () => {
    toggleKidsMode(
      {
        payment_from_wallet: userInfo?.payment_from_wallet ? false : true,
      },
      (res) => {
        setOpenPWPopup(false);
        setPassword("");
        setErrors({ password: "" });
        dispatch(getUserInfo());
      }
    );
  };

  const customSelection = (number) => {
    if (typeBidding.user_name != userInfo?.username) {
      if (currentSelection != number) {
        setAddBidInput(addBidInput + number - currentSelection);
        setCustumBidInput(number);
        setCurrentSelection(number);
      }
    } else {
      toast.error("You have already placed a bid");
    }
  };

  return (
    <div className="auction-page-container">
      {showPop && (
        <div className="popper">
          <ConfettiExplosion {...bigExplodeProps} />
        </div>
      )}

      {openPWPopup ? (
        <PasswordPopup
          isDark
          openPopup={openPWPopup}
          onClose={() => {
            setOpenPWPopup(false);
            setPassword("");
            setErrors({ password: "" });
          }}
          icon={<AddBoxOutlinedIcon />}
          actionButtons={{
            primary: {
              title: "Cancel",
              onClick: () => {
                setOpenPWPopup(false);
                setPassword("");
                setErrors({ password: "" });
              },
            },
            secondary: {
              title: `${
                userInfo?.payment_from_wallet ? "Disable" : "Enable"
              } kid’s mode`,
              onClick: () => handleContiue(),
            },
          }}
          title={`Do you really want to ${
            userInfo?.payment_from_wallet ? "disable" : "enable"
          } kid’s mode?`}
          description="When kid’s mode is activated, the only payment option available will be through the wallet."
        >
          <div style={{ marginTop: 20 }}>
            <Input
              placeholder={"Password"}
              icon={showPassword ? eye : eyeOff}
              label={"Enter password to continue"}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              onClickIcon={() => setShowPassword(!showPassword)}
              error={errors.password}
              errorMsg={"Please enter valid password"}
              value={password}
              autocomplete={"off"}
              isDark
            />
          </div>
        </PasswordPopup>
      ) : (
        ""
      )}

      <Popup
        isDark
        openPopup={paymentPopupDelete}
        onClose={() => setPaymentDelete(false)}
        actionButtons={{
          primary: {
            title: "Cancel",
            onClick: () => setPaymentDelete(false),
          },
          secondary: {
            title: "Yes, delete card",
            onClick: () => {
              onDelete(false);
            },
          },
        }}
        title={"Are you sure you want to delete this card?"}
        description="Once deleted,  you have to add this card again to use it and all current transaction which are in processing state right now, will be terminated."
        note={
          currentCard?.is_primary
            ? "The following card in the list will be designated as the primary card after this card is deleted."
            : ""
        }
      />
      <PCLiveLoader open={load} />

      {openDialog && (
        <SellerProfileDialog
          openDialog={openDialog}
          isDark
          onClose={() => setOpenDialog(false)}
          seller={
            cardListState && cardListState.length
              ? cardListState[0].seller_id
              : 0
          }
        />
      )}
      <Dialog
        openDialog={viewCard}
        onClose={() => setViewcard(false)}
        title={"Card details"}
      >
        <ViewCard
          toggleDrawer={setViewcard}
          data={currentCard}
          isSeller
          hideBuyer
          isDark
        />
      </Dialog>
      <Dialog
        paperWidth={"53%"}
        openDialog={viewAll}
        onClose={() => {
          setViewAll(false);
          setPageIndex(1);
          setSearch("");
        }}
        title={"View all cards"}
        rightElement={
          <SearchInput
            onChange={(e) => setSearch(e.target.value)}
            isAuction
            isDark
            columns={[
              {
                value: "search_text",
                placeholder: "Search for cards",
              },
            ]}
          />
        }
      >
        <div className="room-list-dialog-auction">
          <div className="text-sm-semibold-14">
            {totalCount ? `Showing ${totalCount} cards` : "No more cards found"}
          </div>
          <div className="room-list-container-auction" id="scrollit">
            {viewAllCards && viewAllCards?.length ? (
              viewAllCards.map((card) => {
                let cardData = { ...card, room_id: parseInt(currentRoom) };
                return (
                  <CardDashboard
                    getApiCall={getApiCall}
                    apiCall={apiCall}
                    isAlert
                    card={cardData}
                    isAuction
                    setViewcard={setViewcard}
                    isDark
                    setCurrentCard={setCurrentCard}
                  />
                );
              })
            ) : (
              <NoData type="search" isDark />
            )}
          </div>
          {viewAllCards && viewAllCards?.length ? (
            <Pagination
              isDark
              index={pageIndex}
              handleChange={(page) => setPageIndex(page)}
              totalPage={totalPage}
            />
          ) : (
            ""
          )}
        </div>
      </Dialog>
      <Dialog
        openDialog={openAddPaymentCard}
        onClose={() => setOpenAddPaymentCard(false)}
        title={"Add Card"}
        rightElement={<img src={stripeLogo} />}
        isDark
        isCardHelper
      >
        <AddPaymentCard setOpenAddPaymentCard={setOpenAddPaymentCard} isDark />
      </Dialog>
      <Dialog
        openDialog={paymentMethod}
        onClose={() => setOpenPaymentMethod(false)}
        title={"Payment methods"}
        isDark
      >
        {userInfo?.payment_from_wallet ? (
          <div className="wallet-drawer">
            <Warning
              error="To access cards related features, disable Kid's mode."
              descricption="To ensure access to cards related features, disable Kid's mode, which restricts certain actions for safety reasons."
              btnName="Turn off Kid's mode"
              onClick={() => setOpenPWPopup(true)}
              isDark
            ></Warning>

            <WalletComponent balance={walletBalance} />
          </div>
        ) : (
          <div className="address-auction-container">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              style={{ width: "100%" }}
            >
              {allPaymentcards && allPaymentcards.length ? (
                allPaymentcards.map((card) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <FormControlLabel
                        onClick={() => {
                          setOpenPopup(true);
                          setSwitchPaymentCard(card);
                        }}
                        checked={card.is_primary}
                        control={<BpRadio />}
                        value={card.id}
                      />
                      <div style={{ width: "100%" }}>
                        <PaymentCard
                          card={card}
                          setOpenPopup={setPaymentDelete}
                          setCurrentCard={setCurrentCard}
                          currentCard={currentCard}
                          isDark
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoData isDark type="card" />
              )}
            </RadioGroup>
            <PasswordPopup
              isDark
              openPopup={openPopup}
              onClose={() => {
                setOpenPopup(false);
              }}
              actionButtons={{
                primary: {
                  title: "No",
                  onClick: () => {
                    setOpenPopup(false);
                  },
                },
                secondary: {
                  title: `Yes`,
                  onClick: () => {
                    markCardAsPrimary();
                  },
                },
              }}
              title={`Switch Payment Mode`}
              description="Do you really want to switch payment methods?"
            ></PasswordPopup>
            <div className="button-container">
              <ButtonLight onClick={() => setOpenPaymentMethod(false)} isDark>
                Cancel
              </ButtonLight>
              <CustomPrimaryButton
                onClick={() => {
                  setOpenAddPaymentCard(true);
                  setCurrentCard({ ...currentCard, card: null });
                }}
              >
                Add new Card <AddIcon />
              </CustomPrimaryButton>
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        openDialog={openAddressList}
        onClose={() => setOpenAddressList(false)}
        title={"All Address"}
        isDark
      >
        <div className="address-auction-container address">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            style={{ width: "100%" }}
          >
            {addressState && addressState.length ? (
              addressState
                .slice()
                .sort((a, b) => a.line1.localeCompare(b.line1))
                .map((address) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <FormControlLabel
                        value={address.id}
                        checked={address.is_primary}
                        control={<BpRadio />}
                        onClick={() => onClickAddress(address)}
                      />
                      <div style={{ width: "100%" }}>
                        <Addresses
                          address={address}
                          setOpenAddAddress={setOpenAddAddress}
                          disableDelete
                          isDark
                        />
                      </div>
                    </div>
                  );
                })
            ) : (
              <NoData isDark type="address" />
            )}
          </RadioGroup>
          <div className="button-container">
            <ButtonLight onClick={() => setOpenAddressList(false)} isDark>
              Cancel
            </ButtonLight>
            <CustomPrimaryButton
              onClick={() => {
                setOpenAddAddress(true);
                dispatch(setCurrentAddress(null));
              }}
            >
              Add new address <AddIcon />
            </CustomPrimaryButton>
            {/* Might need that code later// */}
            {/* <ButtonLight onClick={() => setOpenAddAddress(true)}>
              Edit Address <CreateIcon />
            </ButtonLight> */}
          </div>
        </div>
      </Dialog>
      <Dialog
        openDialog={openAddAddress}
        onClose={() => setOpenAddAddress(false)}
        title={"All Address"}
        isDark
        addressHelper
      >
        <AddAddress
          address={currentAddress}
          setOpenAddAddress={setOpenAddAddress}
          isDark
        />
      </Dialog>
      <Dialog
        openDialog={shipTax}
        onClose={() => setShipTax(false)}
        title={"Shipping and taxes details"}
        isDark
      >
        <ShippingAndtax deliveryCharges={deliveryCharges} />
      </Dialog>

      {auctionEnd ? (
        <NoData type={"auction"} />
      ) : (
        <>
          <div className="auction-navbar">
            <div className="left-nav-content">
              <span className="text-sm-semibold-14 back">
                <span
                  className="logo-container"
                  onClick={() => navigate("/dashboard")}
                >
                  <img src={pcliveLogoNew} alt="pcLiveLogo" loading="lazy" />
                  <div className="text-xl-medium-20">PCLive247</div>
                </span>
              </span>
            </div>
            {cardListState?.length &&
            cardListState[0].seller == userDetailsState?.username ? null : (
              <div className="right-nav-content">
                <ButtonOutlined
                  onClick={() => {
                    if (cardListState && cardListState.length)
                      setOpenDialog(true);
                  }}
                  isDark
                >
                  {cardListState && cardListState.length ? (
                    <span className="text-sm-semibold-14 auctionBtn">
                      {" "}
                      <img
                        src={
                          currentSeller?.profile_pic
                            ? currentSeller?.profile_pic
                            : pcliveLogo
                        }
                      />{" "}
                      Sold by{" "}
                      {cardListState && cardListState.length
                        ? cardListState[0].seller
                        : ""}{" "}
                    </span>
                  ) : (
                    <CircularProgress
                      style={{
                        width: "20px",
                        height: "4px",
                        padding: "10px",
                      }}
                    />
                  )}
                </ButtonOutlined>
                <ButtonOutlined onClick={() => setOpenAddressList(true)} isDark>
                  <span className="text-sm-semibold-14 auctionBtn">
                    {" "}
                    Address <LocationOnOutlinedIcon />
                  </span>
                </ButtonOutlined>

                <ButtonOutlined
                  onClick={() => setOpenPaymentMethod(true)}
                  isDark
                >
                  <span className="text-sm-semibold-14 auctionBtn">
                    Payment method <CreditCardOutlinedIcon />
                  </span>
                </ButtonOutlined>
              </div>
            )}
          </div>
          <div className="auction-container">
            <Grid container justifyContent="center" sx={{ height: "100%" }}>
              <Grid item md={5} className="left-container">
                <div className="container-head text-sm-semibold-14">
                  <div className="text-md-bold-16 room-name">
                    <KeyboardBackspaceOutlinedIcon
                      onClick={() => navigate("/dashboard")}
                    />
                    {roomName}
                  </div>
                </div>
                <div className="left-container-contents">
                  <div
                    className={
                      cardListState &&
                      cardListState.length &&
                      cardListState[0].back_image
                        ? "card-container"
                        : "card-container back"
                    }
                  >
                    <div className="slider-left">
                      {cardListState &&
                      cardListState.length &&
                      cardListState[0].back_image &&
                      !switchCard ? (
                        <ChevronLeftIcon onClick={onSwitchCard} />
                      ) : (
                        ""
                      )}
                    </div>

                    {switchCard && (
                      <div className="card">
                        {cardListState && cardListState.length ? (
                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "Front image",
                                isFluidWidth: true,
                                src:
                                  cardListState && cardListState.length
                                    ? cardListState[0].front_image
                                    : "",
                              },
                              largeImage: {
                                src:
                                  cardListState && cardListState.length
                                    ? cardListState[0].front_image
                                    : "",
                                width: 1800,
                                height: 1800,
                              },
                              enlargedImageContainerDimensions: {
                                width: "150%",
                                height: "120%",
                              },
                              enlargedImageStyle: {
                                position: "absolute",
                                right: 0,
                                left: 0,
                                top: 0,
                                bottom: 0,
                                zIndex: 999,
                              },
                            }}
                          />
                        ) : (
                          <CircularProgress />
                        )}
                      </div>
                    )}
                    {cardListState &&
                    cardListState.length &&
                    cardListState[0].back_image &&
                    !switchCard ? (
                      <div className="card">
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "Front image",
                              isFluidWidth: true,
                              src:
                                cardListState && cardListState.length
                                  ? cardListState[0].back_image
                                  : "",
                            },
                            largeImage: {
                              src:
                                cardListState && cardListState.length
                                  ? cardListState[0].back_image
                                  : "",
                              width: 1800,
                              height: 1800,
                            },
                            enlargedImageContainerDimensions: {
                              width: "150%",
                              height: "120%",
                            },
                            enlargedImageStyle: {
                              position: "absolute",
                              right: 0,
                              left: 0,
                              top: 0,
                              bottom: 0,
                              zIndex: 999,
                            },
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="slider-left">
                      {cardListState &&
                      cardListState.length &&
                      cardListState[0].back_image &&
                      switchCard ? (
                        <ChevronRightIcon onClick={onSwitchCard} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <UpcomingCards
                    setViewcard={setViewcard}
                    setViewAll={setViewAll}
                    cardListState={cardListState}
                    setCurrentCard={setCurrentCard}
                    viewAllCards={viewAllCards}
                    allData={allData}
                    totalCount={totalCount}
                  />
                </div>
              </Grid>
              <Grid item md={4} className="middle-container">
                <div className="container-head text-sm-semibold-14"></div>
                <div className="container-contents">
                  <div className="timer-container">
                    <div className="text-xs-medium-12 timer-text">
                      Auction timer
                    </div>
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        thickness={3}
                        sx={{
                          color: counter < 10 ? "#D92D20" : "",
                          strokeLinecap: "round",
                          strokeLinejoin: "round",
                        }}
                        className="circle"
                        variant="determinate"
                        value={Math.round((counter / 20) * 100)}
                      />
                      <CircularProgress
                        sx={{ color: "#FEE4E2" }}
                        className="circle-back"
                        variant="determinate"
                        value={100}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className={`text-xl-bold count ${
                            counter < 10 ? "error" : ""
                          }`}
                        >
                          {counter}
                        </div>
                      </Box>
                    </Box>
                  </div>

                  {getAlertData() && (
                    <div className="winning-bid">{getAlertData(allData)}</div>
                  )}
                  <div className="card-details">
                    <div className="card-info">
                      <div>
                        <span className="text-sm-semibold-16 label">
                          {cardListState && cardListState.length
                            ? cardListState[0].title
                            : ""}
                        </span>
                        <br />
                        <span className="text-sm-medium-14 description">
                          {cardListState && cardListState.length
                            ? cardListState[0].description
                            : ""}
                        </span>
                      </div>
                    </div>

                    <div className="placeholder">
                      <div>
                        <div className="box">
                          <span className="text-xs-medium-24 legend">
                            $
                            {typeBidding?.bidding_amount
                              ? typeBidding?.bidding_amount
                              : cardListState && cardListState.length
                              ? cardListState[0].reserve_price
                              : ""}
                          </span>
                          <span className="text-xs-medium-12 text">
                            Bid price
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {bidLoad || (cardListState && cardListState.length == 0) ? (
                    <></>
                  ) : (
                    <>
                      {!userInfo?.buyer_verified ? (
                        <div className="verification text-xs-medium-12">
                          Note: You are not a verified buyer. Please complete
                          the account verification process from the profile
                          section to access this functionality.
                        </div>
                      ) : (
                        <>
                          {cardListState?.length &&
                          cardListState[0].seller ==
                            userDetailsState?.username ? (
                            <div className="verification text-xs-medium-12">
                              Note: Bid options are not available. You are the
                              owner of this card.
                            </div>
                          ) : !deliveryCharges?.success ? (
                            <div className="verification text-xs-medium-12">
                              Note: Address is not deliverable yet. Please
                              change or check the address.
                            </div>
                          ) : (addBidInput > 0 ? addBidInput : custumBidInput) +
                              currentBid >
                              walletBalance && userInfo?.payment_from_wallet ? (
                            <div className="verification text-xs-medium-12">
                              Insufficient wallet Balance! Please top-up your
                              wallet.
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </>
                  )}
                  {bidLoad || (cardListState && cardListState.length == 0) ? (
                    <>
                      <div className="bid-list"></div>
                      <PCLiveLoader open isAuction />
                      {/* Need this code for testing purpose on server */}
                      {/* <div className="loader-container"> */}
                      {/* <CircularProgress color="inherit" className="icon" /> */}
                      {/* <div className="text-xs-semibold-12 loader">
                          Hold your bids!! Auction will begin shortly.
                        </div> */}
                      {/* </div> */}
                    </>
                  ) : (
                    <>
                      {typeBidding && typeBidding.user_name ? (
                        //need this code later for testing
                        //&& typeBidding.user_name != userInfo?.username
                        <div className="bid-list">
                          <div className="bid-text text-xs-medium-12">
                            Quick bid
                          </div>

                          <ul>
                            <li
                              onClick={() => {
                                customSelection(1);
                              }}
                              className={`text-xs-medium-12 ${
                                currentSelection == 1
                                  ? "current-bid-selection"
                                  : ""
                              }`}
                            >
                              <img src={coinImg} className="coin-img" />+ $1
                            </li>
                            <CustomizedTooltips
                              title={
                                <>
                                  <div
                                    onClick={() => {
                                      customSelection(5);
                                    }}
                                    className={`text-xs-medium-12 bid-list-item ${
                                      currentSelection == 5
                                        ? "current-bid-selection"
                                        : ""
                                    }`}
                                  >
                                    <img src={coinImg} className="coin-img" />+
                                    $5
                                  </div>
                                  <div
                                    onClick={() => {
                                      customSelection(4);
                                    }}
                                    className={`text-xs-medium-12 bid-list-item ${
                                      currentSelection == 4
                                        ? "current-bid-selection"
                                        : ""
                                    }`}
                                  >
                                    <img src={coinImg} className="coin-img" />+
                                    $4
                                  </div>
                                  <div
                                    onClick={() => {
                                      customSelection(3);
                                    }}
                                    className={`text-xs-medium-12 bid-list-item ${
                                      currentSelection == 3
                                        ? "current-bid-selection"
                                        : ""
                                    }`}
                                  >
                                    <img src={coinImg} className="coin-img" />+
                                    $3
                                  </div>
                                  <div
                                    onClick={() => {
                                      customSelection(2);
                                    }}
                                    className={`text-xs-medium-12 bid-list-item ${
                                      currentSelection == 2
                                        ? "current-bid-selection"
                                        : ""
                                    }`}
                                  >
                                    <img src={coinImg} className="coin-img" />+
                                    $2
                                  </div>
                                </>
                              }
                              placement="top"
                            >
                              <li className="text-xs-medium-12 width">^</li>
                            </CustomizedTooltips>
                          </ul>
                          <div
                            style={{
                              display: "flex",
                              gap: 10,
                            }}
                          >
                            <span className="text-sm-semibold-14 custom">
                              <div className="bid-text margin text-xs-medium-12">
                                Increase current bid{" "}
                              </div>
                              <RemoveIcon
                                onClick={() => {
                                  if (
                                    typeBidding?.user_name != userInfo?.username
                                  ) {
                                    if (custumBidInput > 1) {
                                      setCustumBidInput(custumBidInput - 1);
                                      setAddBidInput(0);
                                      setCurrentSelection(0);
                                    }
                                    if (custumBidInput == 1) {
                                      setCurrentSelection(1);
                                    }
                                  } else {
                                    toast.error(
                                      "You have already placed a bid"
                                    );
                                  }
                                }}
                              />

                              <input
                                type="text"
                                value={custumBidInput}
                                // onBlur={()=>setCustumBidInput(!custumBidInput?1:"")}
                                disabled={
                                  typeBidding?.user_name == userInfo?.username
                                }
                                onChange={(e) => {
                                  const value =
                                    /^(?:[1-9]\d{0,5}|999999)?$/.test(
                                      e.target.value
                                    );
                                  if (value) {
                                    setCustumBidInput(
                                      e.target.value
                                        ? parseInt(e.target.value)
                                        : ""
                                    );
                                    setCurrentSelection(0);
                                  } else {
                                    // setCustumBidInput("");
                                  }
                                }}
                              />
                              <AddIcon
                                onClick={() => {
                                  if (
                                    typeBidding?.user_name != userInfo?.username
                                  ) {
                                    setCustumBidInput(custumBidInput + 1);
                                    setAddBidInput(0);
                                    setCurrentSelection(0);
                                  } else {
                                    toast.error(
                                      "You have already placed a bid"
                                    );
                                  }
                                }}
                              />
                            </span>
                            <a> | </a>
                            <div>
                              <div className="bid-text  text-xs-medium-12">
                                Your bid
                              </div>
                              <div className="text-xs-medium-12 bid-list-item width">
                                $
                                {typeBidding &&
                                typeBidding?.user_name == userInfo?.username
                                  ? currentBid
                                  : (addBidInput > 0 && !custumBidInput
                                      ? addBidInput + 1
                                      : custumBidInput) + currentBid}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="bid-list"></div>
                      )}
                      <div className="custom-container">
                        <div className="right-section">
                          <CustomPrimaryButton
                            onClick={onPlaceBid}
                            isDark
                            disabled={
                              currentUserType !== "buyer" ||
                              !currentBid ||
                              !userInfo?.buyer_verified ||
                              (cardListState?.length &&
                                cardListState[0].seller ==
                                  userDetailsState?.username) ||
                              ((addBidInput > 0
                                ? addBidInput
                                : custumBidInput) +
                                currentBid >
                                walletBalance &&
                                userInfo?.payment_from_wallet) ||
                              (typeBidding &&
                                typeBidding?.user_name == userInfo?.username) ||
                              !counter ||
                              !deliveryCharges?.success
                            }
                            fullWidth
                          >
                            {typeBidding &&
                            typeBidding?.user_name == userInfo?.username
                              ? "Bid placed"
                              : `Place bid on this card for $${
                                  // !typeBidding && !typeBidding?.user_name ?currentBid: ((addBidInput>0?addBidInput:custumBidInput) +  currentBid)}
                                  (!typeBidding && !typeBidding?.user_name) ||
                                  (typeBidding && !typeBidding?.user_name)
                                    ? currentBid
                                    : (addBidInput > 0
                                        ? addBidInput + 1
                                        : custumBidInput) + currentBid
                                }
                            
                            `}
                            {typeBidding &&
                              typeBidding?.user_name != userInfo?.username && (
                                <ArrowCircleRightOutlinedIcon />
                              )}
                          </CustomPrimaryButton>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="text-xs-medium-12 bottom-details-section">
                    <div
                      className={`details ${bidLoad ? "disabled" : ""}`}
                      onClick={onClickDetails}
                    >
                      <span>View more card details </span> <ChevronRightIcon />
                    </div>
                    {currentUserType === "buyer" &&
                      cardListState?.length &&
                      cardListState[0]?.seller !== userDetailsState?.username &&
                      userInfo?.buyer_verified &&
                      deliveryCharges?.success && (
                        <div
                          className="details"
                          onClick={() => setShipTax(true)}
                        >
                          <span>Shipping & tax</span> <ChevronRightIcon />
                        </div>
                      )}
                  </div>
                </div>
              </Grid>
              <Grid item md={3} className="chat-container">
                <div className="chat-head text-sm-semibold-14">
                  <ChatBubbleOutlineOutlinedIcon /> Live chat with participants
                </div>
                <div className="chat-contents">
                  <ChatBubble
                    isMine={true}
                    message={chats}
                    currentUser={currentUser}
                  />
                </div>

                <div className="input-container">
                  <Input
                    placeholder={"Enter your message here"}
                    label={""}
                    type={"text"}
                    icon={sendIcon}
                    onClickIcon={onSendMessage}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    value={messageState}
                    isDark
                    isAuction
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
}

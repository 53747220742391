import "./DropDown.scss";

import * as React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MuiCheckbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";

const DropDown = (props) => {
  const {
    subtitle,
    icon,
    showBefore = true,
    padding = "16px",
    minWidth = "170px",
    position = "left",
    borderColor = "#fff",
    currentSettingOpen,
    additionalClass,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cardDetails, setCardDetail] = React.useState(false);
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setAnchorEl(null);
  }, [currentSettingOpen]);

  return (
    <div
      className={`filter-container-menu ${
        globalDarkMode ? "pclive-dark-mode" : ""
      } ${additionalClass || ""}`}
    >
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <div
          className={"dropdown-element"}
          style={{
            borderColor: borderColor,
          }}
          onClick={(e) => {
            setCardDetail(!cardDetails);
            handleClick(e);
          }}
        >
          {icon}
        </div>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            ml: 5,
            padding: padding,
            minWidth: minWidth,
            backgroundColor: globalDarkMode ? "#1C1D1D" : "#ffffff",
            borderRadius: "12px",
            zIndex: 99,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: showBefore ? "block" : "none",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
              backgroundColor: globalDarkMode ? "#1C1D1D" : "#ffffff",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: position, vertical: "bottom" }}
      >
        <div
          className={`filter-container-menu ${
            globalDarkMode ? "pclive-dark-mode" : ""
          }`}
        >
          {subtitle && (
            <div className="filter-head text-xs-semibold-12">{subtitle}</div>
          )}
          {subtitle && <Divider className="horizontal" />}
          {props.children}
        </div>
      </Menu>
    </div>
  );
};

export default DropDown;

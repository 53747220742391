import "./MyOrders.scss";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getBuyerPaymentReceipt,
  getBuyerRefundReceipt,
  getRecentPayoutDetails,
  getRecentPayouts,
  getRecentTransections,
  getRecentTransectionsDetails,
  getSellerPaymentReceipt,
  getShipmentBundle,
  getShipmentCards,
  getTrackingStatus,
} from "../../../../redux/slices/shipment";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import moment from "moment";
import { toast } from "react-toastify";
import ButtonLight from "../../../../components/buttons/button-light/ButtonLight";
import CustomPrimaryButton from "../../../../components/buttons/primary/CustomPrimaryButton";
import CustomTabs from "../../../../components/tabs/CustomTabs";
import Dialog from "../../../../components/dialog/Dialog";
import { ReactComponent as DownloadIcon } from "../../../../assests/icons/download.svg";
import { ReactComponent as SearchIcon } from "../../../../assests/icons/search-dark.svg";
import DropDown from "../../../../components/select/dropdown-select/DropDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FilterDropdown from "../../../../components/filter/FilterDropdown";
import { NoData } from "../../../../components/no-data/NoCards";
import OrderListComponent from "../../../../components/orders/OrderListComponent";
import Orderdetails from "../../../../components/orders/Orderdetails";
import PCLiveLoader from "../../../../components/loader/PCLiveLoader";
import Pagination from "../../../../components/pagination/Pagination";
import PasswordPopup from "../../../../components/popup/password-popup/PasswordPopup";
import ReactDatePicker from "react-datepicker";
import TabPanel from "@mui/lab/TabPanel";
import TrackStatus from "../../../../components/orders/TrackStatus";
import MultilevelDropdown from "../../../../components/dropdown/Dropdown";

const INITIAL_FILTER = [
  {
    menu: "Delivery Status",
    type: "check",
    subMenu: [
      { label: "Processing", value: false },
      { label: "Shipped", value: false },
      { label: "Delivered", value: false },
      { label: "Failed", value: false },
      { label: "Cancelled", value: false },
      { label: "Returned", value: false },
    ],
  },
  {
    menu: "Pickup Address Type",
    type: "check",
    subMenu: [
      { label: "Personal", value: false },
      { label: "Corporate", value: false },
      { label: "Both", value: false },
    ],
  },
];

const MyOrders = () => {
  const dispatch = useDispatch();
  const allShipmentBundle = useSelector(
    (state) => state.shipmentBundle.allShipmentBundle
  );
  const isLoading = useSelector((state) => state.shipmentBundle.isLoading);
  const allShipmentCards = useSelector(
    (state) => state.shipmentCards.allShipmentCards?.data
  );
  const allShipmentCardsLoad = useSelector(
    (state) => state.shipmentCards.allShipmentCards?.isLoading
  );
  const shipmentTrackingStatus = useSelector(
    (state) => state.trackingStatus.shipmentTrackingStatus
  );
  const shipmentTrackingStatusLoad = useSelector(
    (state) => state.trackingStatus?.isLoading
  );
  const allRecentTransections = useSelector(
    (state) => state.recentTransections.allRecentTransections
  );
  const allRecentTransectionsLoad = useSelector(
    (state) => state.recentTransections?.isLoading
  );
  const allRecentPayouts = useSelector(
    (state) => state.recentPayouts.allRecentPayouts
  );
  const allRecentPayoutsLoad = useSelector(
    (state) => state.recentPayouts?.isLoading
  );
  const recentPayoutDetail = useSelector(
    (state) => state.recentPayoutDetails.recentPayoutDetail?.data
  );
  const allRecentPayoutDetailsLoad = useSelector(
    (state) => state.recentPayoutDetails.isLoading
  );
  const recentTransectionsDetail = useSelector(
    (state) => state.recentTransectionsDetails.recentTransectionsDetail?.data
  );
  const recentTransectionsDetailLoad = useSelector(
    (state) => state.recentTransectionsDetails?.isLoading
  );

  const [value, setValue] = useState(1);
  const [orderdetailTab, setOrderdetailTab] = useState(1);
  const [openOrder, setOpenOrder] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [daysOption, setDaysOption] = useState("");
  const [openTransection, setOpenTransection] = useState(false);
  const [openPayoutDetail, setOpenPayoutDetails] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const [pickupAddressType, setPickupAddressType] = useState([]);
  const [transectionStatus, setTransectionStatus] = useState("all");
  const [dateDisplay, setDateDisplay] = useState(false);
  const [currentShipment, setCurrentShipment] = useState(null);
  const [currentTransection, setCurrentTransection] = useState(null);
  const [currentPayout, setCurrentPayout] = useState(null);
  const [currentval, setCurrentval] = useState("week");
  const [searchQuery, setSearchQuery] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentUserType = useSelector((state) => state.auth?.currentUserType);
  const [load, setLoader] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [expanded, setExpanded] = useState(0);
  const [openReceiptPopup, setOpenReceiptPopup] = useState(null);
  const [startDate, endDate] = dateRange;
  const [sortOption, setSortOption] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [priceRangeApplied, setPriceRangeApplied] = useState(false);
  const [filterData, setFilterData] = useState(INITIAL_FILTER);
  const [resetdata, setReset] = useState(false);

  let weekdateTo = moment(new Date()).format("MM-DD-YYYY");
  let weekdateFrom = moment(new Date()).subtract(6, "d").format("MM-DD-YYYY");
  let monthTo = moment(new Date()).format("MM-DD-YYYY");
  let monthFrom = moment(new Date()).subtract(29, "d").format("MM-DD-YYYY");
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const transectionFilter =
    currentUserType == "seller"
      ? [
          { label: "View all", value: "all" },
          { label: "Pending", value: "pending" },
          { label: "Refunded", value: "refunded" },
          { label: "Completed", value: "completed" },
        ]
      : [
          { label: "View all", value: "all" },
          { label: "Failed", value: "failed" },
          { label: "Refunded", value: "refunded" },
          { label: "Completed", value: "completed" },
        ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPageIndex(1);
    setOrderStatus([]);
    setPickupAddressType([]);
    setTransectionStatus("all");
    setTotalPages(
      newValue === 1
        ? allShipmentBundle?.total_pages
        : newValue === 3
        ? allRecentPayouts?.total_pages
        : allRecentTransections?.total_pages
    );
  };

  useEffect(() => {
    if (currentPayout?.id)
      dispatch(
        getRecentPayoutDetails({
          id: currentPayout?.id,
          searchQuery: searchQuery,
        })
      );
  }, [searchQuery]);

  useEffect(() => {
    let end = null;
    let start = startDate ? moment(startDate).format("MM-DD-YYYY") : null;
    end = endDate ? moment(endDate).endOf("day") : null;
    end = endDate ? moment(end).format("MM-DD-YYYY") : null;
    if (value == 2) {
      setLoader(true);
      dispatch(
        getRecentTransections({
          transectionStatus: transectionStatus,
          pageIndex: pageIndex,
          currentUserType: currentUserType,
        })
      );
      setLoader(false);
    } else if (value === 3) {
      setLoader(true);
      dispatch(
        getRecentPayouts({
          pageIndex: pageIndex,
          start: start,
          end: end,
          sort: sortOption,
          min: minPrice * 100,
          max: maxPrice * 100,
        })
      );
      setLoader(false);
    }
  }, [
    transectionStatus,
    pageIndex,
    value,
    startDate,
    endDate,
    sortOption,
    priceRangeApplied,
  ]);

  const onChangePriceFilter = () => {
    let end = null;
    let start = startDate ? moment(startDate).format("MM-DD-YYYY") : null;
    end = endDate ? moment(endDate).endOf("day") : null;
    end = endDate ? moment(end).format("MM-DD-YYYY") : null;
    setLoader(true);
    dispatch(
      getRecentPayouts({
        pageIndex: pageIndex,
        start: start,
        end: end,
        sort: sortOption,
        min: minPrice * 100,
        max: maxPrice * 100,
      })
    );
    setLoader(false);
  };

  useEffect(() => {
    if (value === 1) {
      setLoader(true);
      dispatch(
        getShipmentBundle({
          orderStatus: orderStatus,
          addressType: pickupAddressType,
          pageIndex: pageIndex,
          currentUserType: currentUserType,
        })
      );
      setLoader(false);
    }
  }, [
    orderStatus,
    pickupAddressType,
    pageIndex,
    currentUserType,
    value,
    dispatch,
  ]);

  useEffect(() => {
    setTotalPages(allShipmentBundle?.total_pages);
  }, [allShipmentBundle?.total_pages]);

  useEffect(() => {
    setTotalPages(allRecentTransections?.total_pages);
  }, [allRecentTransections?.total_pages]);

  useEffect(() => {
    setTotalPages(allRecentPayouts?.total_pages);
  }, [allRecentPayouts?.total_pages]);

  const onHandleShipment = (shipment) => {
    setOpenOrder(true);
    setCurrentShipment(shipment);
    setLoader(true);
    dispatch(getShipmentCards({ id: shipment.id, user: currentUserType }));
    dispatch(getTrackingStatus(shipment.id));
    setLoader(false);
  };

  const onHandleTransection = (transection) => {
    if (transection.status === "Failed") {
      toast.error("No details available as transection was failed.");
      return;
    }
    setOpenTransection(true);
    setCurrentTransection(transection);
    setLoader(true);
    dispatch(
      getRecentTransectionsDetails({
        id: transection.card_id,
        user: currentUserType,
      })
    );
    setLoader(false);
  };

  const onHandlePayout = (payout) => {
    setOpenPayoutDetails(true);
    setCurrentPayout(payout);
    setLoader(true);
    dispatch(
      getRecentPayoutDetails({
        id: payout.id,
        pageIndex: pageIndex,
      })
    );
    setLoader(false);
  };

  const totalAmount = () => {
    if (currentUserType == "buyer") {
      return (
        recentTransectionsDetail?.delivery_charges +
        recentTransectionsDetail?.total_tax_amount +
        recentTransectionsDetail?.bid_price
      );
    } else {
      return (
        recentTransectionsDetail?.bid_price -
        recentTransectionsDetail?.total_tax_amount
      );
    }
  };

  const hidePage = () => {
    switch (value) {
      case 1:
        return !allShipmentBundle?.data?.length;
      case 2:
        return !allRecentTransections?.data?.length;
      case 3:
        return !allRecentPayouts?.data?.length;
      default:
        return false;
    }
  };

  const downloadAction = () => {
    setOpenPopup(true);
  };

  const downLoadLable = () => {
    if (currentShipment?.label_url) {
      const link = document.createElement("a");
      link.href = currentShipment?.label_url;
      link.target = "_blank";
      link.download = "Shipment-label.csv";
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const downloadReceipt = (type) => {
    setOpenReceiptPopup(null);
    const handleSuccess = (body) => {
      const link = document.createElement("a");
      link.href = body?.data?.data;
      link.target = "_blank";
      link.download = "receipt.pdf";
      document.body.appendChild(link);
      link.click();
    };
    const handleFailure = (error) => {
      toast.error(error?.message);
    };
    setOpenReceiptPopup(true);

    currentUserType === "buyer"
      ? type === "refund"
        ? getBuyerRefundReceipt(
            currentShipment?.id,
            handleSuccess,
            handleFailure
          )
        : getBuyerPaymentReceipt(
            currentShipment?.id,
            handleSuccess,
            handleFailure
          )
      : type === "payment" &&
        getSellerPaymentReceipt(
          currentPayout?.id,
          handleSuccess,
          handleFailure
        );
  };

  const onChangeSelection = (val) => {
    setDaysOption(val);
    setSearchQuery("");
    setPageIndex(1);
    setCurrentval(val);
    if (val === "custom") {
      setDateDisplay(true);
      setDateRange([null, null]);
    }
    if (val === "week") {
      setDateDisplay(false);
      setDateRange([weekdateFrom, weekdateTo]);
    }
    if (val === "month") {
      setDateDisplay(false);
      setDateRange([monthFrom, monthTo]);
    }
  };

  const onReset = (isSearch) => {
    if (!isSearch) {
      setMinPrice();
      setMaxPrice();
      setPriceRangeApplied((prev) => !prev);
      setDateRange([null, null]);
      setSortOption("");
      setDaysOption("");
      setPageIndex(1);
      setPickupAddressType([]);
      setOrderStatus([]);
      setReset(!resetdata);
    }
  };

  const onChangefilter = (index) => {
    setPageIndex(1);
    let filteredArray = [];
    let filter_data = filterData[index].subMenu.filter((f) => f.value == true);
    if (filter_data && filter_data.length) {
      filteredArray = filter_data.map((m) => {
        return index === 0 ? m.label.toUpperCase() : m.label.toLowerCase();
      });
    }
    if (index === 0) {
      let array = [];
      if (filteredArray) {
        const filterMappings = {
          PROCESSING: "PRE_TRANSIT",
          SHIPPED: "TRANSIT",
          DELIVERED: "DELIVERED",
          RETURNED: "RETURNED",
          CANCELLED: "CANCELLED",
          FAILED: "FAILURE",
          ORDER_RECEIVED: "ORDER_RECEIVED",
        };

        Object.keys(filterMappings).forEach((filterValue) => {
          if (filteredArray.includes(filterValue)) {
            array.push(filterMappings[filterValue]);
          }
        });
      }
      setOrderStatus(array);
    } else if (index === 1) {
      if (filteredArray?.length === 1) setPickupAddressType(filteredArray[0]);
      else setPickupAddressType("both");
    }
  };

  return (
    <div
      className={`my-orders-container  ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <PCLiveLoader
        open={
          isLoading ||
          allShipmentCardsLoad ||
          shipmentTrackingStatusLoad ||
          allRecentTransectionsLoad ||
          allRecentPayoutsLoad ||
          allRecentPayoutDetailsLoad ||
          recentTransectionsDetailLoad ||
          load
        }
      />

      <Dialog
        openDialog={openTransection}
        onClose={() => setOpenTransection(false)}
        rightElement={
          currentTransection?.id ? (
            <span className="text-sm-medium-14">{`Transaction ID #${currentTransection?.id}`}</span>
          ) : (
            ""
          )
        }
        title={"Transaction details"}
      >
        <div
          className={`transections-list-container text-sm-medium-14 ${
            globalDarkMode ? "pclive-dark-mode" : ""
          }`}
        >
          <div className="transections-list">
            <div className="left-content">
              <img
                src={currentTransection?.card_front_image}
                alt="front"
                height={62}
                width={45}
                loading="lazy"
              />
              <span>{currentTransection?.card_title}</span>
            </div>
            <div className="text-sm-medium-14 success">
              {currentTransection?.status}
            </div>
          </div>
          {recentTransectionsDetail?.is_refunded ? (
            <>
              <div className="contents border-top">
                <span>Refund amount</span>
                <span>
                  ${parseInt(recentTransectionsDetail?.refunded_amount) / 100}
                </span>
              </div>
              <div className="contents ">
                <span>Refunded at</span>
                <span>
                  {moment(recentTransectionsDetail?.refunded_at).format("lll")}
                </span>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="contents border">
                <span>Purchased on</span>
                <span>
                  {moment(currentTransection?.transaction_date).format("lll")}
                </span>
              </div>
              <div className="contents ">
                <span>Bid Price</span>
                <span>${recentTransectionsDetail?.bid_price?.toFixed(2)}</span>
              </div>
              {currentUserType === "buyer" && (
                <div className="contents ">
                  <span>Shipping cost</span>
                  <span>
                    ${recentTransectionsDetail?.delivery_charges?.toFixed(2)}
                  </span>
                </div>
              )}
              {recentTransectionsDetail?.tax_breakdown?.length &&
                recentTransectionsDetail?.tax_breakdown?.map((tax, ind) => (
                  <div className="contents ">
                    <span>
                      {tax?.tax_type === "sales_tax"
                        ? "Sales Tax"
                        : tax?.tax_type}{" "}
                      {tax?.percentage_decimal
                        ? `(${tax?.percentage_decimal}%)`
                        : ""}
                    </span>
                    <span>{`${currentUserType === "seller" ? "-$" : "$"}${(
                      tax?.amount / 100
                    )?.toFixed(2)}`}</span>
                  </div>
                ))}
              <div className="contents border-top">
                <span>Total</span>
                <span className="text-md-semibold-16">
                  ${totalAmount()?.toFixed(2)}
                </span>
              </div>
            </>
          )}
        </div>
      </Dialog>
      <Dialog
        className="setting-dropdown"
        openDialog={openPayoutDetail}
        onClose={() => setOpenPayoutDetails(false)}
        rightElement={
          <ButtonLight
            onClick={() => {
              downloadReceipt("payment");
            }}
          >
            <DownloadIcon /> Receipt
          </ButtonLight>
        }
        title={"Payout details"}
      >
        <div className="payout-details-container">
          <TextField
            className={`search-box ${globalDarkMode ? "pclive-dark-mode" : ""}`}
            label=""
            placeholder="Search by card name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {recentPayoutDetail?.length ? (
            recentPayoutDetail?.map((details) => (
              <div
                className={`payouts-list-container text-sm-medium-14 ${
                  globalDarkMode ? "pclive-dark-mode" : ""
                }`}
              >
                <div className="payouts-list">
                  <div className="left-content">
                    <img
                      src={details?.front_image}
                      alt="front"
                      height={40}
                      width={40}
                      loading="lazy"
                    />
                    <span className="text-md-medium-16">
                      {details?.card_title}{" "}
                    </span>
                  </div>
                  <div className="text-sm-medium-14 delivered">Delivered</div>
                </div>
                <>
                  {" "}
                  <div className="contents border-top">
                    <span>Card ID</span>
                    <span>{details?.card_id}</span>
                  </div>
                  <div className="contents">
                    <span>Bid Price</span>
                    <span>${details?.bid_price?.toFixed(2)}</span>
                  </div>
                  {details?.tax_breakdown?.length &&
                    details?.tax_breakdown?.map((tax, ind) => (
                      <div className="contents ">
                        <span>
                          {tax?.tax_type} {`(${tax?.percentage_decimal}%)`}
                        </span>
                        <span>{`-$${(tax?.amount / 100)?.toFixed(2)}`}</span>
                      </div>
                    ))}
                  <div className="contents border-top">
                    <span>Total</span>
                    <span className="text-md-semibold-16">
                      {`$${(
                        details?.bid_price -
                        details?.tax_breakdown[0]?.amount / 100 -
                        details?.tax_breakdown[1]?.amount / 100 -
                        details?.tax_breakdown[2]?.amount / 100
                      )?.toFixed(2)}`}
                    </span>
                  </div>
                </>
              </div>
            ))
          ) : (
            <NoData type="room" />
          )}
        </div>
      </Dialog>
      <Dialog
        openDialog={openOrder}
        onClose={() => {
          setOpenOrder(false);
          setOrderdetailTab(1);
        }}
        paperWidth="40%"
        rightElement={
          <>
            {currentUserType === "buyer" && (
              <DropDown
                additionalClass="setting-dropdown"
                currentSettingOpen={openReceiptPopup}
                icon={
                  <ButtonLight>
                    <DownloadIcon /> Receipt
                  </ButtonLight>
                }
                showBefore={false}
                padding="16px 24px"
                minWidth="max-content"
                position="right"
                borderColor="#ECF6FF"
              >
                <div className="receipts-container">
                  <div
                    className="receipt text-sm-medium-14"
                    onClick={() => downloadReceipt("payment")}
                  >
                    Payment Receipt
                  </div>
                  {currentShipment?.is_any_shipment_refunded && <hr />}
                  {currentShipment?.is_any_shipment_refunded && (
                    <div
                      className="receipt text-sm-medium-14"
                      onClick={() => downloadReceipt("refund")}
                    >
                      Refund Receipt
                    </div>
                  )}
                </div>
              </DropDown>
            )}
            <CustomPrimaryButton
              onClick={() => {
                if (currentShipment?.label_url) downloadAction();
                else toast.error("Shipment label not generated yet");
              }}
              disabled={!currentShipment?.label_url}
              showToastWhileDisabled
            >
              Download shipment label
            </CustomPrimaryButton>
          </>
        }
        title={"Order details"}
        subtitle={`Order ID #${currentShipment?.id}`}
      >
        <div className="order-details-tab-container">
          <CustomTabs
            tabData={[
              {
                title: `Cards (${
                  allShipmentCards ? allShipmentCards?.length : 0
                })`,
                value: 1,
              },
              { title: "Delivery details", value: 2 },
            ]}
            value={orderdetailTab}
            handleChange={(e, newval) =>
              setOrderdetailTab(newval == 3 ? 1 : newval)
            }
          >
            <TabPanel value={1} sx={{ padding: "24px 0" }}>
              {allShipmentCards && allShipmentCards?.length ? (
                <div className="main-Container">
                  <div
                    className={`address-container ${
                      globalDarkMode ? "pclive-dark-mode" : ""
                    }`}
                  >
                    <p className="text-sm-medium-14 address">Pickup address</p>
                    <p className="text-sm-medium-14">
                      {currentShipment?.pickup_address_str}
                    </p>
                  </div>
                  {allShipmentCards?.map((shipmentCard, ind) =>
                    currentUserType === "buyer" ? (
                      <Accordion
                        className={`text-sm-medium-14 accordion-head ${
                          globalDarkMode ? "pclive-dark-mode" : ""
                        }`}
                        expanded={expanded === ind}
                        onChange={handleAccordionChange(ind)}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          {expanded === ind ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                          <div className="card-details">
                            <p className="text-sm-bold-14">
                              Seller: {shipmentCard?.seller}
                            </p>
                            <p className="text-sm-semibold-14">
                              {shipmentCard?.data?.length || 0}{" "}
                              {shipmentCard?.data?.length > 1
                                ? "Cards"
                                : "Card"}
                            </p>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="text-sm-medium-14">
                          {shipmentCard?.data?.map((shipment, index) => (
                            <Orderdetails
                              shipments={shipment}
                              key={index}
                              isCorporate={currentShipment?.is_corporate}
                            />
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <Orderdetails
                        shipments={shipmentCard}
                        isCorporate={currentShipment?.is_corporate}
                        status={currentShipment?.status}
                      />
                    )
                  )}
                </div>
              ) : (
                <NoData type="room" />
              )}
            </TabPanel>

            <TabPanel value={2} sx={{ padding: "24px 0" }}>
              <TrackStatus
                trackStatus={
                  shipmentTrackingStatus ? shipmentTrackingStatus : ""
                }
              />
            </TabPanel>
          </CustomTabs>
        </div>
        <PasswordPopup
          openPopup={openPopup}
          IsNormal={true}
          onClose={() => {
            setOpenPopup(false);
          }}
          actionButtons={{
            primary: {
              title: "Cancel",
              onClick: () => {
                setOpenPopup(false);
              },
            },
            secondary: {
              title: `Download shipping label`,
              onClick: () => downLoadLable(),
            },
          }}
          title={`Shipment Lable for Order ID:${currentShipment?.id}`}
          description=""
        >
          <div style={{ marginTop: 20 }}>
            <div className="text-sm-medium-14">Shipment Process:</div>
            <ol className="text-sm-medium-14 info">
              <li>
                Consolidate and bundle all of your cards into a single package.
              </li>
              <li>Download the shipping label.</li>
              <li>Print and Paste the shipping label on the package.</li>
              <li>
                Drop off the shipment at the nearest USPS office as soon as
                possible for further processing.
              </li>
            </ol>
          </div>
        </PasswordPopup>
      </Dialog>
      <CustomTabs
        tabData={
          currentUserType === "buyer"
            ? [
                { title: "Orders", value: 1 },
                { title: "Recent transactions", value: 2 },
              ]
            : [
                { title: "Orders", value: 1 },
                { title: "Recent transactions", value: 2 },
                { title: "Payouts", value: 3 },
              ]
        }
        value={value}
        handleChange={(e, newval) => handleChange(e, newval)}
      >
        <TabPanel value={1} sx={{ padding: "16px 0" }}>
          <div className="card-header">
            <div className="text-md-medium-16">
              Your orders (
              {allShipmentBundle?.total_count
                ? allShipmentBundle?.total_count
                : 0}
              )
            </div>
            <div className="filter-container">
              <MultilevelDropdown
                data={filterData}
                setFilterData={setFilterData}
                onSubmitFilter={onChangefilter}
                resetdata={resetdata}
                INITIAL_FILTER={INITIAL_FILTER}
              />
              <Button
                className="reset-button"
                variant="text"
                onClick={() => onReset(false)}
              >
                Reset all
              </Button>
            </div>
          </div>
          {allShipmentBundle?.data && allShipmentBundle?.data?.length ? (
            <div className="main-Container">
              {allShipmentBundle?.data?.map((shipment) => {
                return (
                  <div
                    className="shipment-bundle"
                    onClick={() => onHandleShipment(shipment)}
                  >
                    <OrderListComponent
                      shipment={shipment}
                      currentUserType={currentUserType}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            !isLoading && !load && <NoData type="room" />
          )}
        </TabPanel>
        <TabPanel value={2} sx={{ padding: "16px 0" }}>
          <div className="card-header">
            <div className="text-md-medium-16">
              Transactions ({allRecentTransections?.total_count})
            </div>
            <FilterDropdown
              title="Status"
              list={transectionFilter}
              subtitle="Select transaction status"
              result={(val) => {
                setTransectionStatus(val);
                setPageIndex(1);
              }}
              type="radio"
              radioValue={transectionStatus}
              isVerticalRadio
            />
          </div>
          {allRecentTransections?.data &&
          allRecentTransections?.data?.length ? (
            <div
              className={`main-Container ${
                globalDarkMode ? "pclive-dark-mode" : ""
              }`}
            >
              {allRecentTransections?.data?.map((transection) => {
                return (
                  <div
                    onClick={() => onHandleTransection(transection)}
                    className="transections-list"
                  >
                    <div className="left-content text-sm-medium-14">
                      <img
                        src={transection?.card_front_image}
                        alt="card-front"
                        height={40}
                        loading="lazy"
                      />
                      <span>{transection?.card_title}</span>
                    </div>
                    <div>
                      <div
                        className={`text-sm-medium-14 ${
                          transection?.status?.toLowerCase() == "completed"
                            ? "success"
                            : transection?.status?.toLowerCase() == "refunded"
                            ? "primary"
                            : "error"
                        }`}
                      >
                        {transection?.status}
                      </div>
                      <div className="text-xs-semibold-12">
                        {moment(transection?.transaction_date).format("lll")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            !isLoading && !load && <NoData type="room" />
          )}
        </TabPanel>
        <TabPanel value={3} sx={{ padding: "16px 0" }}>
          <div className="card-header">
            <div className="text-md-medium-16">
              Your Payouts (
              {allRecentPayouts?.total_count
                ? allRecentPayouts?.total_count
                : 0}
              )
            </div>
            <div className="right-content">
              <FilterDropdown
                title="Date range"
                list={[
                  { label: "Past 7 days", value: "week" },
                  { label: "Past 30 days", value: "month" },
                  { label: "Custom dates", value: "custom" },
                ]}
                subtitle={"Select date range"}
                result={(val) => onChangeSelection(val)}
                type="radio"
                height="225px"
                radioValue={daysOption}
                isVerticalRadio
                isCalendar
                dateRange={dateRange}
                extraComponent={
                  daysOption == "custom" ? (
                    <div
                      style={{
                        position: "relative",
                        marginRight: 10,
                        marginLeft: 8,
                        marginTop: 6,
                      }}
                    >
                      <ReactDatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setDateRange(update);
                          setPageIndex(1);
                        }}
                        maxDate={new Date()}
                        minDate={new Date("2023-01-01")}
                      />
                    </div>
                  ) : (
                    ""
                  )
                }
              />
              <FilterDropdown
                title="Price Range"
                subtitle={"Price range"}
                type="priceRange"
                minPrice={minPrice}
                maxPrice={maxPrice}
                setMinPrice={setMinPrice}
                setMaxPrice={setMaxPrice}
                onApply={() => {
                  setPageIndex(1);
                  onChangePriceFilter();
                }}
              />
              <FilterDropdown
                title="Sort"
                list={[
                  {
                    label: "Date - Latest to oldest",
                    value: JSON.stringify(["-transferred_at"]),
                  },
                  {
                    label: "Date - Oldest to latest",
                    value: JSON.stringify(["transferred_at"]),
                  },
                  {
                    label: "Price - High to low",
                    value: JSON.stringify(["-amount_in_cents"]),
                  },
                  {
                    label: "Price - Low to high",
                    value: JSON.stringify(["amount_in_cents"]),
                  },
                ]}
                subtitle={"Select sorting type"}
                result={(val) => {
                  setSortOption(val);
                  setPageIndex(1);
                }}
                type="radio"
                radioValue={sortOption}
                isVerticalRadio
              />
              <Button
                className="reset-button"
                variant="text"
                onClick={() => onReset(false)}
              >
                Reset all
              </Button>
            </div>
          </div>
          <div className="main-Container">
            {allRecentPayouts?.data && allRecentPayouts?.data.length
              ? allRecentPayouts?.data.map((payout) => {
                  return (
                    <div
                      className={`order-list-component ${
                        globalDarkMode ? "pclive-dark-mode" : ""
                      }`}
                    >
                      <div
                        className="payout-container text-xs-medium-12"
                        onClick={() => onHandlePayout(payout)}
                      >
                        <div className="bottom-left-container">
                          <div className="content">
                            <div>Date</div>{" "}
                            <p>
                              {moment(payout?.transferred_at).format("lll")}
                            </p>
                          </div>
                          <hr />
                          <div className="content">
                            <div>Total cards</div> <p>{payout?.total_cards}</p>
                          </div>
                        </div>
                        <>
                          <div className="bottom-right-container align-right">
                            <div>Total</div>
                            <p className="text-md-semibold-16">
                              ${(payout?.amount_in_cents / 100).toFixed(2)}
                            </p>
                          </div>
                        </>
                      </div>
                    </div>
                  );
                })
              : !isLoading && !load && <NoData type="room" />}
          </div>
        </TabPanel>
        {hidePage() ? (
          ""
        ) : (allRecentTransections?.data &&
            allRecentTransections?.data?.length) ||
          (allShipmentBundle?.data && allShipmentBundle?.data?.length) ? (
          <Box className="pagination-container">
            <Pagination
              alignRight
              index={pageIndex}
              handleChange={(page) => setPageIndex(page)}
              totalPage={totalPages}
            />
          </Box>
        ) : (
          ""
        )}
      </CustomTabs>
    </div>
  );
};

export default MyOrders;

import "./CorporateCards.scss";

import {
  APPROVED_REQUESTS_INITIAL_FILTER,
  BULK_CSV_INITIAL_FILTER,
  REQUESTS_INITIAL_FILTER,
  mainTableColumns,
  tableColumns,
} from "../manage-cards/constants";
import { Button, TableCell, TableRow } from "@mui/material";
import {
  doRequestActions,
  getBulkCardsList,
  getCards,
  getCsvFileCards,
  getRequests,
  publishAllBulkCards,
  publishAllCsvCard,
  publishBulkCard,
  reRequest,
  unpublishBulkCard,
} from "../../../../../../redux/slices/card";
import {
  getAllAddress,
  getAllCorporateAddress,
} from "../../../../../../redux/slices/address";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ButtonGray from "../../../../../../components/buttons/button-gray/ButtonGray";
import ButtonLinkGrey from "../../../../../../components/buttons/link-grey/ButtonLinkGrey";
import { ReactComponent as CancelDarkIcon } from "../../../../../../assests/icons/cancel-dark.svg";
import { ReactComponent as CancelIcon } from "../../../../../../assests/icons/cancel.svg";
import CsvOrBulkTableHeader from "../table-header/CsvOrBulkTableHeader";
import CustomizedTables from "../../../../../../components/table/Table";
import CustomizedTooltips from "../../../../../../components/tooltip/Tooltip";
import Dialog from "../../../../../../components/dialog/Dialog";
import EditIcon from "@mui/icons-material/Edit";
import { NoData } from "../../../../../../components/no-data/NoCards";
import NormalTableHeader from "../table-header/NormalTableHeader";
import PCLiveLoader from "../../../../../../components/loader/PCLiveLoader";
import Popup from "../../../../../../components/popup/Popup";
import RequestHistory from "../request-history/RequestHistory";
import { ReactComponent as RerequestDarkIcon } from "../../../../../../assests/icons/re-request-dark.svg";
import { ReactComponent as RerequestIcon } from "../../../../../../assests/icons/re-request.svg";
import { UploadCardForm } from "../upload-card/UploadCard";
import ViewCard from "../view-card/ViewCard";
import { ReactComponent as ViewHistoryDarkIcon } from "../../../../../../assests/icons/device_reset.svg";
import { ReactComponent as ViewHistoryIcon } from "../../../../../../assests/icons/history.svg";
import moment from "moment";
import { toast } from "react-toastify";

const CorporateCards = ({ tabValue, setTabValue }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isCsvSingle, setIsCsvSingle] = useState(false);
  const [isBulkSingle, setIsBulkSingle] = useState(false);
  const [checkedArray, setCheckedArray] = useState([]);
  const [isPublishSelected, setIsPublishSelected] = useState(false);
  const [tableTitle, setTableTitle] = useState("Approved Cards");
  const [tableData, setTableData] = useState();
  const [openCardDetails, setOpenCardDetails] = useState(false);
  const [openRequestHistory, setOpenRequestHistory] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [openEditCard, setOpenEditCard] = useState(false);
  const [editCard, isEditCard] = useState(false);
  const [allPublishModal, setAllPublishModal] = useState({
    type: "",
    value: false,
  });
  const [filterData, setFilterData] = useState(
    isCsvSingle || isBulkSingle
      ? BULK_CSV_INITIAL_FILTER
      : tabValue === 1
      ? APPROVED_REQUESTS_INITIAL_FILTER
      : REQUESTS_INITIAL_FILTER
  );
  const [searchQuery, setSearchQuery] = useState(null);
  const [currentSetData, getCurrentSetData] = useState(false);
  const [selectSearch, setSelectSearch] = useState("");
  const [resetdata, setReset] = useState(false);
  const [requests, setRequests] = useState([]);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [currentCardId, setCurrentCardId] = useState("");
  const [title, setTitle] = useState("");
  const [openCancelRequestPopup, setOpenCancelRequestPopup] = useState(false);
  const [openRerequestPopup, setOpenRerequestPopup] = useState(false);
  const [openPublishAllPopup, setOpenPublishAllPopup] = useState({
    type: "",
    value: false,
  });
  const [openPublishPopup, setOpenPublishPopup] = useState({
    type: "",
    value: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [uploadTypeFilter, setUploadTypeFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [addressFilter, setAddressFilter] = useState([]);
  const [priceFilter, setPriceFilter] = useState([]);
  const [gradeFilter, setGradeFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [titleDescription, setTitleDescription] = useState("");
  const [tag, setTag] = useState("");
  const [seller, setSeller] = useState("");
  const [defaultSearch, setDefaultSearch] = useState("");
  const [isUpdateAddress, setIsUpdateAddress] = useState(false);

  const allCatagories = useSelector((state) => state.categories.data?.data);
  const personalAddresses = useSelector((state) => state.address.data?.data);
  const corporateAddresses = useSelector(
    (state) => state.address.corporateAddresses
  );
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);

  const dispatch = useDispatch();

  const tabs = [
    { name: "Approved Cards", value: 1 },
    { name: "Requests", value: 2 },
  ];

  useEffect(() => {
    dispatch(getAllAddress());
    dispatch(getAllCorporateAddress());
  }, [dispatch]);

  useEffect(() => {
    setSortData(
      isBulkSingle || isCsvSingle
        ? [{ type: "reserve_price", value: false }]
        : [{ type: "request_date", value: false }]
    );
  }, [isBulkSingle, isCsvSingle]);

  useEffect(() => {
    if (!isBulkSingle && !isCsvSingle) {
      switch (tabValue) {
        case 1:
          getApprovedRequests();
          break;
        case 2:
          getAllRequests();
          break;
        default:
          getApprovedRequests();
          break;
      }
    }
  }, [
    currentPage,
    tabValue,
    uploadTypeFilter,
    statusFilter,
    addressFilter,
    sortData,
    isBulkSingle,
    isCsvSingle,
    currentSetData,
  ]);

  useEffect(() => {
    setIsLoading(true);
    let end = null;
    let start = null;
    const priceArray = priceFilter.map((item) => item.replace(/\+/g, "%2B"));
    const gradeArray = gradeFilter.map((item) =>
      item.replace("authentic", "0")
    );
    let sortArry = sortData?.map((f) => (f.value ? f.type : `-${f.type}`));
    if (isBulkSingle) {
      const data = {
        bulk_name: title,
      };
      getBulkCardsList(
        (body) => {
          setTableData(body?.data);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
        data,
        currentPage,
        searchQuery,
        start,
        end,
        sortArry,
        priceArray,
        gradeArray,
        categoryFilter,
        selectSearch
      );
    } else if (isCsvSingle) {
      const data = {
        file_link: title,
      };
      getCsvFileCards(
        (body) => {
          setTableData(body?.data);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
        data,
        currentPage,
        searchQuery,
        start,
        end,
        sortArry,
        priceArray,
        gradeArray,
        categoryFilter,
        selectSearch
      );
    } else if (tabValue === 2) {
      setTableData(requests);
      setIsLoading(false);
    } else {
      setTableData(approvedRequests);
      setIsLoading(false);
    }
  }, [
    isBulkSingle,
    isCsvSingle,
    requests,
    approvedRequests,
    tabValue,
    title,
    priceFilter,
    gradeFilter,
    categoryFilter,
    uploadTypeFilter,
    statusFilter,
    addressFilter,
    currentPage,
    titleDescription,
    tag,
    seller,
    defaultSearch,
    sortData,
    currentSetData,
  ]);

  const onChangefilter = (index) => {
    setIsLoading(true);
    setCurrentPage(1);
    let filteredArray = [];
    let filter_data = filterData[index].subMenu.filter((f) => f.value == true);
    if (filter_data && filter_data.length) {
      filteredArray = filter_data.map((m) => {
        return m.label.toLowerCase();
      });
    }
    if (index === 0) {
      isCsvSingle || isBulkSingle
        ? setPriceFilter(filteredArray)
        : setUploadTypeFilter(filteredArray);
    }
    if (index === 1) {
      isCsvSingle || isBulkSingle
        ? setGradeFilter(filteredArray)
        : setStatusFilter(filteredArray);
    }
    if (
      (index === 2 || (index === 1 && tabValue === 1)) &&
      !isCsvSingle &&
      !isBulkSingle
    ) {
      if (filter_data?.length) {
        filteredArray = filter_data.map((m) => {
          return m.id;
        });
      }
      setAddressFilter(filteredArray);
    }
  };

  const onChangetab = (value) => {
    setCurrentPage(1);
    setTabValue(value);
    setIsBulkSingle(false);
    setIsCsvSingle(false);
    const tabName = tabs.filter((tab) => tab.value === value)[0]?.name;
    setTableTitle(tabName);
    onReset();
    if (tabName?.toLowerCase() === "requests") {
      setTableData(requests);
    } else {
      setTableData(approvedRequests);
    }
  };

  const handleChangeChecked = (event, card) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedArray([
        ...checkedArray,
        { card_id: parseInt(card.id), comment: "", schedule_at: "" },
      ]);
    } else {
      setCheckedArray(checkedArray.filter((obj) => obj.card_id !== card.id));
    }
    if (card?.published && isChecked) setIsPublishSelected(true);
    else setIsPublishSelected(false);
  };

  const onViewcard = (id, isEdit) => {
    setIsLoading(true);
    getCards(
      `?card_id=${id}`,
      (body) => {
        setIsLoading(false);
        setCurrentCard(body?.data?.data[0]);
        setCurrentCardId(
          body?.data?.data[0]?.uploaded_from?.toLowerCase() === "normal"
            ? body?.data?.data[0]?.id
            : body?.data?.data[0]?.title
        );
        if (isEdit) {
          setOpenEditCard(true);
          isEditCard(true);
        } else {
          setOpenCardDetails(true);
        }
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  const onClickEdit = (catagory) => {
    setCurrentCard(catagory);
    setOpenEditCard(true);
    isEditCard(true);
  };

  const resetSearchQueries = () => {
    setTitleDescription("");
    setTag("");
    setSeller("");
    setDefaultSearch("");
  };

  const searchTypeHandlers = {
    title_description: () => {
      resetSearchQueries();
      setTitleDescription(searchQuery);
    },
    inventory_tag: () => {
      resetSearchQueries();
      setTag(searchQuery);
    },
  };

  const onSearch = () => {
    setCurrentPage(1);
    if (searchTypeHandlers[selectSearch]) searchTypeHandlers[selectSearch]();
    else {
      resetSearchQueries();
      setDefaultSearch(searchQuery);
    }
    onReset(true);
  };

  const onReset = (isSearch) => {
    setIsLoading(true);
    if (!isSearch) {
      setCurrentPage(1);
      setSearchQuery("");
      setSelectSearch("");
      setUploadTypeFilter([]);
      setStatusFilter([]);
      setAddressFilter([]);
      setPriceFilter([]);
      setGradeFilter([]);
      setCategoryFilter([]);
      setReset(!resetdata);
      setFilterData(
        isCsvSingle || isBulkSingle
          ? BULK_CSV_INITIAL_FILTER
          : tabValue === 1
          ? APPROVED_REQUESTS_INITIAL_FILTER
          : REQUESTS_INITIAL_FILTER
      );
    } else if (!isBulkSingle && !isCsvSingle) {
      tabValue === 1 ? getApprovedRequests() : getAllRequests();
    }
  };

  const getAllRequests = () => {
    setIsLoading(true);
    let sortArry = sortData?.map((f) => (f.value ? f.type : `-${f.type}`));
    getRequests(
      (body) => {
        setRequests(body?.data);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      },
      currentPage,
      null,
      uploadTypeFilter,
      statusFilter,
      addressFilter,
      searchQuery,
      sortArry
    );
  };

  const getApprovedRequests = () => {
    setIsLoading(true);
    let sortArry = sortData?.map((f) => (f.value ? f.type : `-${f.type}`));
    getRequests(
      (body) => {
        setApprovedRequests(body?.data);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      },
      currentPage,
      ["approved"],
      uploadTypeFilter,
      statusFilter,
      addressFilter,
      searchQuery,
      sortArry
    );
  };

  const requestActions = (state) => {
    const data = {
      request_type: currentCard?.request_type || currentCard?.uploaded_from,
      request_state: state,
      card_id: currentCard?.request_type
        ? currentCard?.request_type?.toLowerCase() === "normal"
          ? currentCard?.card_details?.id
          : currentCard?.card_details?.title
        : currentCard?.id,
    };
    if (state === "reverted") {
      if (currentCard.address_id) data.address_id = currentCard.address_id;
      else {
        toast.error("Please select a pickup address");
        return;
      }
    }
    setOpenRerequestPopup(false);
    doRequestActions(
      data,
      (body) => {
        toast.success(body?.data?.message);
        if (isCsvSingle || isBulkSingle) {
          onChangetab(tabValue);
        } else {
          getAllRequests();
          setOpenCardDetails(false);
        }
      },
      (error) => {
        console.log(error);
        toast.error(error?.message);
      }
    );
  };

  const rerequestCard = () => {
    const data = {
      request_type: currentCard?.request_type || currentCard?.uploaded_from,
      address_id: currentCard?.address_id || currentCard?.address?.id,
      identifier: currentCard?.request_type
        ? currentCard?.request_type?.toLowerCase() === "normal"
          ? currentCard?.card_details?.id
          : currentCard?.card_details?.title
        : currentCard?.id,
    };
    setOpenRerequestPopup(false);
    reRequest(
      data,
      (body) => {
        toast.success(body?.data?.message);
        setOpenCardDetails(false);
        if (isCsvSingle || isBulkSingle) {
          setSortData([]);
          onChangetab(tabValue);
        } else getAllRequests();
      },
      (error) => {
        console.log(error);
        toast.error(error?.message);
      }
    );
  };

  const publishAllCards = (type) => {
    setIsLoading(true);
    let end = null;
    let start = null;
    const priceArray = priceFilter.map((item) => item.replace(/\+/g, "%2B"));
    const gradeArray = gradeFilter.map((item) =>
      item.replace("authentic", "0")
    );
    let sortArry = sortData?.map((f) => (f.value ? f.type : `-${f.type}`));
    if (isCsvSingle) {
      const data = {
        csv_file_link: title,
        status: type,
        seller: currentCard.seller,
      };
      publishAllCsvCard(
        data,
        (body) => {
          getCsvFileCards(
            (body) => {
              setTableData(body?.data);
              setIsLoading(false);
            },
            () => {
              setIsLoading(false);
            },
            { file_link: title },
            1,
            searchQuery,
            start,
            end,
            sortArry,
            priceFilter,
            gradeArray,
            categoryFilter,
            selectSearch
          );
          setIsLoading(false);
          toast.success(body?.data?.message);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
          toast.error(error?.data?.message);
        }
      );
    } else if (isBulkSingle) {
      const data = {
        bulk_entry: title,
        status: type,
        seller: currentCard.seller,
      };
      publishAllBulkCards(
        data,
        (body) => {
          getBulkCardsList(
            (body) => {
              setTableData(body?.data);
              setIsLoading(false);
            },
            () => {
              setIsLoading(false);
            },
            { bulk_name: title },
            1,
            searchQuery,
            start,
            end,
            sortArry,
            priceArray,
            gradeArray,
            categoryFilter,
            selectSearch
          );
          setIsLoading(false);
          toast.success(body?.data?.message);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
          toast.error(error?.data?.message);
        }
      );
    }
  };

  const findIndex = (array, val, key) => {
    return array.findIndex((item) => item[key] === val);
  };

  const onSortData = (index, val) => {
    const newData = [...sortData];
    const found = findIndex(newData, val, "type");
    newData[found].value = !newData[found].value;
    const indexElement = newData.findIndex((obj) => obj.type === val);
    const element = newData.splice(indexElement, 1)[0];
    newData.unshift(element);
    setSortData(newData);
    setCurrentPage(1);
  };

  const extractFilename = (url) => {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const filename = pathname
      .substring(pathname.lastIndexOf("/") + 1)
      .split("_")
      .pop()
      .replace(/%20/g, " ");
    return filename;
  };

  return (
    <div
      className={`corporate-cards-container ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <Dialog
        openDialog={openRequestHistory}
        onClose={() => setOpenRequestHistory(false)}
        title={"Request History"}
      >
        <RequestHistory cardId={currentCardId} />
      </Dialog>
      <Dialog
        openDialog={openCardDetails}
        onClose={() => setOpenCardDetails(false)}
        title={"Card details"}
        showStatus
        status={currentCard?.request_state}
        fullWidth={true}
      >
        <ViewCard
          toggleDrawer={openCardDetails}
          data={currentCard}
          isSeller
          hideBuyer={!currentCard?.is_sold}
          showEdit={!currentCard?.published && !currentCard?.is_sold}
          showHistory={
            tabValue === 2 &&
            currentCard?.uploaded_from?.toLowerCase() === "normal"
          }
          showPublish={
            tabValue === 1 &&
            currentCard?.request_state?.toLowerCase() === "approved" &&
            !currentCard?.is_sold
          }
          onClickEdit={() => {
            setOpenEditCard(true);
            isEditCard(true);
          }}
          onClickRerequest={() => {
            setOpenRerequestPopup(true);
          }}
          onClickRequestHistory={() => {
            setOpenRequestHistory(true);
          }}
          onClickCancelRequest={() => {
            setOpenCancelRequestPopup(true);
          }}
          onClickPublish={() => {
            setOpenPublishPopup({
              type: currentCard?.published ? "withdraw" : "publish",
              value: true,
            });
          }}
        />
      </Dialog>
      <Dialog
        openDialog={openEditCard}
        onClose={() => {
          setOpenEditCard(false);
          isEditCard(false);
        }}
        title="Edit card"
        showStatus
        status={currentCard?.request_state}
        fullWidth={true}
      >
        <UploadCardForm
          setUploadDrawer={setOpenEditCard}
          editCard={editCard}
          card={currentCard}
          setApiCallAction={getCurrentSetData}
          apiCallAction={currentSetData}
          onChangetab={onChangetab}
          setCardDetailsDrawer={setOpenCardDetails}
          setLoad={setIsLoading}
        />
      </Dialog>
      <Popup
        openPopup={openCancelRequestPopup}
        onClose={() => setOpenCancelRequestPopup(false)}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => setOpenCancelRequestPopup(false),
          },
          secondary: {
            title: "Yes, cancel request",
            onClick: () => {
              setOpenCancelRequestPopup(false);
              requestActions("cancelled");
            },
          },
        }}
        isError={true}
        title={"Cancel request?"}
        description="Are you sure you want to cancel the request?"
      />
      <Popup
        openPopup={openRerequestPopup}
        onClose={() => setOpenRerequestPopup(false)}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => {
              setOpenRerequestPopup(false);
              setCurrentCard((prev) => {
                return {
                  ...prev,
                  is_corporate:
                    currentCard?.request_state?.toLowerCase() !== "reverted",
                };
              });
            },
          },
          secondary: {
            title: !isUpdateAddress ? "Yes, re-request" : "Yes, update",
            onClick: () => {
              !isUpdateAddress ? rerequestCard() : requestActions("reverted");
            },
          },
        }}
        title={!isUpdateAddress ? "Re-request?" : "Update pickup address"}
        description={
          !isUpdateAddress
            ? "This action will place the request again. Do you want to re-request?"
            : "Are you sure you want to change pickup address from corporate to personal?"
        }
        showAddressSelect
        addressId={
          isUpdateAddress &&
          (currentCard?.request_state?.toLowerCase() !== "reverted" ||
            currentCard?.is_corporate)
            ? ""
            : currentCard?.address_id || currentCard?.address?.id
        }
        isCorporate={currentCard?.request_state?.toLowerCase() !== "reverted"}
        setCurrentCard={setCurrentCard}
        isUpdateAddress={isUpdateAddress}
      />
      <Popup
        openPopup={openPublishAllPopup?.value}
        onClose={() => setOpenPublishAllPopup({ type: "", value: false })}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => setOpenPublishAllPopup({ type: "", value: false }),
          },
          secondary: {
            title: `Yes, ${openPublishAllPopup?.type} all cards`,
            onClick: () => {
              publishAllCards(openPublishAllPopup?.type);
              setOpenPublishAllPopup({ type: "", value: false });
            },
          },
        }}
        title={`${openPublishAllPopup?.type} all cards`}
        description={`Are you sure you want to ${openPublishAllPopup?.type} all cards?`}
      />
      <Popup
        openPopup={openPublishPopup?.value}
        onClose={() => setOpenPublishPopup({ type: "", value: false })}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => setOpenPublishPopup({ type: "", value: false }),
          },
          secondary: {
            title: `Yes, ${openPublishPopup?.type} card`,
            onClick: () => {
              currentCard?.published
                ? unpublishBulkCard(
                    [{ card_id: currentCard?.id }],
                    () => {
                      setOpenPublishPopup({ type: "", value: false });
                      setOpenCardDetails(false);
                      let end = null;
                      let start = null;
                      const priceArray = priceFilter.map((item) =>
                        item.replace(/\+/g, "%2B")
                      );
                      const gradeArray = gradeFilter.map((item) =>
                        item.replace("authentic", "0")
                      );
                      let sortArry = sortData?.map((f) =>
                        f.value ? f.type : `-${f.type}`
                      );
                      if (isBulkSingle) {
                        const data = {
                          bulk_name: title,
                        };
                        getBulkCardsList(
                          (body) => {
                            setTableData(body?.data);
                            setIsLoading(false);
                          },
                          () => {
                            setIsLoading(false);
                          },
                          data,
                          currentPage,
                          searchQuery,
                          start,
                          end,
                          sortArry,
                          priceArray,
                          gradeArray,
                          categoryFilter,
                          selectSearch
                        );
                      } else if (isCsvSingle) {
                        const data = {
                          file_link: title,
                        };
                        getCsvFileCards(
                          (body) => {
                            setTableData(body?.data);
                            setIsLoading(false);
                          },
                          () => {
                            setIsLoading(false);
                          },
                          data,
                          currentPage,
                          searchQuery,
                          start,
                          end,
                          sortArry,
                          priceArray,
                          gradeArray,
                          categoryFilter,
                          selectSearch
                        );
                      } else {
                        getApprovedRequests();
                      }
                    },
                    (error) => {
                      console.log(error);
                    }
                  )
                : publishBulkCard(
                    [{ card_id: currentCard?.id }],
                    () => {
                      setOpenPublishPopup({ type: "", value: false });
                      setOpenCardDetails(false);
                      let end = null;
                      let start = null;
                      const priceArray = priceFilter.map((item) =>
                        item.replace(/\+/g, "%2B")
                      );
                      const gradeArray = gradeFilter.map((item) =>
                        item.replace("authentic", "0")
                      );
                      let sortArry = sortData?.map((f) =>
                        f.value ? f.type : `-${f.type}`
                      );
                      if (isBulkSingle) {
                        const data = {
                          bulk_name: title,
                        };
                        getBulkCardsList(
                          (body) => {
                            setTableData(body?.data);
                            setIsLoading(false);
                          },
                          () => {
                            setIsLoading(false);
                          },
                          data,
                          currentPage,
                          searchQuery,
                          start,
                          end,
                          sortArry,
                          priceArray,
                          gradeArray,
                          categoryFilter,
                          selectSearch
                        );
                      } else if (isCsvSingle) {
                        const data = {
                          file_link: title,
                        };
                        getCsvFileCards(
                          (body) => {
                            setTableData(body?.data);
                            setIsLoading(false);
                          },
                          () => {
                            setIsLoading(false);
                          },
                          data,
                          currentPage,
                          searchQuery,
                          start,
                          end,
                          sortArry,
                          priceArray,
                          gradeArray,
                          categoryFilter,
                          selectSearch
                        );
                      } else {
                        getApprovedRequests();
                      }
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
            },
          },
        }}
        title={`${openPublishPopup?.type} card`}
        description={`Are you sure you want to ${openPublishPopup?.type} this card?`}
      />
      <PCLiveLoader open={isLoading} />
      <div className="dashboard-top-filters seller">
        <div className="filter-container-left">
          {tabs.map((tab) => (
            <span
              key={tab.value}
              className={`text-sm-semibold-14 room ${
                tabValue === tab.value ? "selected active" : ""
              }`}
              onClick={() => onChangetab(tab.value)}
            >
              {tabValue === tab.value ? (
                <ButtonLinkGrey label={tab.name} />
              ) : (
                <ButtonGray label={tab.name} />
              )}
            </span>
          ))}
        </div>
      </div>
      {isCsvSingle || isBulkSingle ? (
        <div className="tab-content">
          <CustomizedTables
            totalPages={tableData?.total_pages}
            pageIndex={currentPage}
            setPageIndex={setCurrentPage}
            columns={tableColumns?.filter(
              (column) =>
                column?.toLowerCase() !== "tag" &&
                column?.toLowerCase() !== "change status" &&
                column?.toLowerCase() !== "pickup address" &&
                column?.toLowerCase() !== "uploaded by" &&
                column?.toLowerCase() !== "upload type" &&
                column?.toLowerCase() !== "seller"
            )}
            title={
              <CsvOrBulkTableHeader
                title={tableTitle}
                setTableTitle={setTableTitle}
                checkedArray={checkedArray}
                isPublishSelected={isPublishSelected}
                setAllPublishModal={setAllPublishModal}
                setCurrentPage={setCurrentPage}
                tabValue={tabValue}
                filterData={filterData}
                setFilterData={setFilterData}
                INITIAL_FILTER={BULK_CSV_INITIAL_FILTER}
                onChangefilter={onChangefilter}
                resetdata={resetdata}
                allCatagories={allCatagories}
                setCategoryFilter={setCategoryFilter}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                onSearch={onSearch}
                selectSearch={selectSearch}
                setSelectSearch={setSelectSearch}
                onReset={onReset}
                isBulkSingle={isBulkSingle}
                setIsBulkSingle={setIsBulkSingle}
                isCsvSingle={isCsvSingle}
                setIsCsvSingle={setIsCsvSingle}
                currentSetData={currentSetData}
                getCurrentSetData={getCurrentSetData}
                setOpenRerequestPopup={setOpenRerequestPopup}
                setOpenPublishAllPopup={setOpenPublishAllPopup}
                setIsUpdateAddress={setIsUpdateAddress}
                currentCard={currentCard}
                isPublishAll={tableData?.publish_all}
                isUnpublishAll={tableData?.unpublish_all}
                personalAddresses={personalAddresses}
                corporateAddresses={corporateAddresses}
                setSortData={setSortData}
                data={tableData.data}
              />
            }
            priceSort={
              sortData[findIndex(sortData, "reserve_price", "type")]?.value ? (
                <ArrowDropDownIcon
                  onClick={() => onSortData(1, "reserve_price")}
                />
              ) : (
                <ArrowDropUpIcon
                  onClick={() => onSortData(1, "reserve_price")}
                />
              )
            }
          >
            {tableData && tableData.data && tableData.data.length ? (
              tableData.data.map((column) => {
                return (
                  <TableRow
                    className="text-xs-medium-12 table-row"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    onClick={() => {
                      setOpenCardDetails(true);
                      onViewcard(column.id);
                    }}
                  >
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column?.id}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      <img
                        width={30}
                        alt="card"
                        height={40}
                        src={column?.front_image}
                      />
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column?.title}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column?.grade === 0 ? "Authentic" : column?.grade}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column?.reserve_price}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column?.description}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {moment(
                        tabValue == 2 ? column.upload_date : column.created_at
                      ).format("lll")}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "155px",
                          justifyContent: "space-between",
                        }}
                      >
                        {tabValue === 1 &&
                          column?.request_state?.toLowerCase() === "approved" &&
                          (column?.is_sold ? (
                            "Sold"
                          ) : (
                            <Button
                              className="text-md-semibold-14"
                              onClick={(e) => {
                                e.stopPropagation();
                                setCurrentCard(column);
                                setOpenPublishPopup({
                                  type: column?.published
                                    ? "unpublish"
                                    : "publish",
                                  value: true,
                                });
                              }}
                            >
                              {column.published ? "Unpublish" : "Publish"}
                            </Button>
                          ))}
                        <CustomizedTooltips
                          placement="top"
                          title={
                            column.published ||
                            (column?.uploaded_from?.toLowerCase() !==
                              "normal" &&
                              (column?.request_state?.toLowerCase() ===
                                "pending" ||
                                column?.request_state?.toLowerCase() ===
                                  "declined" ||
                                column?.request_state?.toLowerCase() ===
                                  "reverted"))
                              ? "Cannot edit this card"
                              : "Edit"
                          }
                        >
                          <EditIcon
                            className="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                !column.published &&
                                (column?.uploaded_from?.toLowerCase() ===
                                  "normal" ||
                                  (column?.request_state?.toLowerCase() !==
                                    "pending" &&
                                    column?.request_state?.toLowerCase() !==
                                      "declined" &&
                                    column?.request_state?.toLowerCase() !==
                                      "reverted"))
                              ) {
                                onClickEdit(column);
                                onViewcard(column.id, true);
                              }
                            }}
                            style={
                              column.published ||
                              (column?.uploaded_from?.toLowerCase() !==
                                "normal" &&
                                (column?.request_state?.toLowerCase() ===
                                  "pending" ||
                                  column?.request_state?.toLowerCase() ===
                                    "declined" ||
                                  column?.request_state?.toLowerCase() ===
                                    "reverted"))
                                ? {
                                    color: "#c0c0c0",
                                    cursor: "default",
                                  }
                                : { cursor: "pointer" }
                            }
                          />
                        </CustomizedTooltips>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <tr>
                <td colSpan={11}>
                  <NoData type="room" />
                </td>
              </tr>
            )}
          </CustomizedTables>
        </div>
      ) : (
        <div className="tab-content">
          <CustomizedTables
            totalPages={tableData?.total_pages}
            pageIndex={currentPage}
            setPageIndex={setCurrentPage}
            columns={
              tabValue === 2
                ? mainTableColumns
                : mainTableColumns
                    ?.map((column) => {
                      if (column?.toLowerCase() === "updated at") {
                        return "Approved At";
                      }
                      return column;
                    })
                    ?.filter(
                      (column) =>
                        column?.toLowerCase() !== "status" &&
                        column?.toLowerCase() !== "action"
                    )
            }
            title={
              <NormalTableHeader
                title={tableTitle}
                checkedArray={checkedArray}
                isPublishSelected={isPublishSelected}
                setAllPublishModal={setAllPublishModal}
                setCurrentPage={setCurrentPage}
                tabValue={tabValue}
                filterData={filterData}
                setFilterData={setFilterData}
                INITIAL_FILTER={
                  tabValue === 1
                    ? APPROVED_REQUESTS_INITIAL_FILTER
                    : REQUESTS_INITIAL_FILTER
                }
                onChangefilter={onChangefilter}
                resetdata={resetdata}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                onSearch={onSearch}
                selectSearch={selectSearch}
                setSelectSearch={setSelectSearch}
                onReset={onReset}
                isBulkSingle={isBulkSingle}
                setIsBulkSingle={setIsBulkSingle}
                isCsvSingle={isCsvSingle}
                setIsCsvSingle={setIsCsvSingle}
                currentSetData={currentSetData}
                getCurrentSetData={getCurrentSetData}
                setOpenRerequestPopup={setOpenRerequestPopup}
                personalAddresses={personalAddresses}
                corporateAddresses={corporateAddresses}
              />
            }
            dataSort={
              sortData[findIndex(sortData, "request_date", "type")]?.value ? (
                <ArrowDropDownIcon
                  onClick={() => onSortData(1, "request_date")}
                />
              ) : (
                <ArrowDropUpIcon
                  onClick={() => onSortData(1, "request_date")}
                />
              )
            }
          >
            {tableData && tableData?.data && tableData?.data?.length ? (
              tableData?.data?.map((column) => {
                return (
                  <TableRow
                    className="text-xs-medium-12 table-row"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    onClick={() => {
                      console.log(column, column?.request_type?.toLowerCase());
                      if (column?.request_type?.toLowerCase() === "normal") {
                        setOpenCardDetails(true);
                        onViewcard(column?.card_details?.id);
                      } else {
                        setSortData([]);
                        setCurrentPage(1);
                        setCurrentCard(column);
                        column?.request_type?.toLowerCase() === "bulk"
                          ? setIsBulkSingle(true)
                          : setIsCsvSingle(true);
                        setTableTitle(
                          `File name: ${
                            column?.request_type?.toLowerCase() === "bulk"
                              ? column?.card_details?.title
                              : extractFilename(column?.card_details?.title)
                          }`
                        );
                        setTitle(column?.card_details?.title);
                      }
                    }}
                  >
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column?.id || "N/A"}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell overflow"
                      align="left"
                    >
                      <CustomizedTooltips
                        title={
                          column?.request_type?.toLowerCase() !== "csv"
                            ? column?.card_details?.title
                            : extractFilename(column?.card_details?.title)
                        }
                      >
                        <div className="value longName overflow">
                          {column?.request_type?.toLowerCase() !== "csv"
                            ? column?.card_details?.title
                            : extractFilename(column?.card_details?.title)}
                        </div>
                      </CustomizedTooltips>
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column?.request_type}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      <CustomizedTooltips
                        className="capitalize"
                        title={`${
                          column?.request_state?.toLowerCase() === "reverted"
                            ? "Personal"
                            : "Corporate"
                        } : ${
                          column?.address?.address_name ||
                          column?.address?.address_type
                        } : ${column?.address?.line1}, ${
                          column?.address?.city
                        }, ${column?.address?.state}, ${
                          column?.address?.country
                        }, ${column?.address?.postal_code}`}
                      >
                        <div className="value longName capitalize overflow">
                          {`${
                            column?.request_state?.toLowerCase() === "reverted"
                              ? "Personal"
                              : "Corporate"
                          } : ${
                            column?.address?.address_name ||
                            column?.address?.address_type
                          } : ${column?.address?.line1}, ${
                            column?.address?.city
                          }, ${column?.address?.state}, ${
                            column?.address?.country
                          }, ${column?.address?.postal_code}`}
                        </div>
                      </CustomizedTooltips>
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {moment(column.request_date).format("lll")}
                    </TableCell>
                    {tabValue === 2 && (
                      <TableCell
                        className={"text-sm-semibold-14 table-cell capitalize"}
                        align="left"
                      >
                        {column?.request_state}
                      </TableCell>
                    )}
                    {tabValue === 2 && (
                      <TableCell
                        className="text-xs-medium-12 table-cell"
                        align="left"
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: 8,
                          }}
                        >
                          <CustomizedTooltips
                            placement="top"
                            title="View Request History"
                          >
                            {globalDarkMode ? (
                              <ViewHistoryDarkIcon
                                className="icon"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpenRequestHistory(true);
                                  setCurrentCardId(
                                    column?.request_type?.toLowerCase() ===
                                      "normal"
                                      ? column?.card_details?.id
                                      : column?.card_details?.title
                                  );
                                }}
                              />
                            ) : (
                              <ViewHistoryIcon
                                className="icon"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpenRequestHistory(true);
                                  setCurrentCardId(
                                    column?.request_type?.toLowerCase() ===
                                      "normal"
                                      ? column?.card_details?.id
                                      : column?.card_details?.title
                                  );
                                }}
                              />
                            )}
                          </CustomizedTooltips>
                          {column?.request_state?.toLowerCase() ===
                          "pending" ? (
                            <CustomizedTooltips
                              placement="top"
                              title="Cancel Request"
                            >
                              {globalDarkMode ? (
                                <CancelDarkIcon
                                  className="icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentCard(column);
                                    setOpenCancelRequestPopup(true);
                                  }}
                                />
                              ) : (
                                <CancelIcon
                                  className="icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentCard(column);
                                    setOpenCancelRequestPopup(true);
                                  }}
                                />
                              )}
                            </CustomizedTooltips>
                          ) : column?.request_state?.toLowerCase() ===
                            "cancelled" ? (
                            <CustomizedTooltips
                              placement="top"
                              title="Re-request"
                            >
                              {globalDarkMode ? (
                                <RerequestDarkIcon
                                  className="icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentCard(column);
                                    setOpenRerequestPopup(true);
                                  }}
                                />
                              ) : (
                                <RerequestIcon
                                  className="icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentCard(column);
                                    setOpenRerequestPopup(true);
                                  }}
                                />
                              )}
                            </CustomizedTooltips>
                          ) : (
                            ""
                          )}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <tr>
                <td colSpan={11}>
                  <NoData type="room" />
                </td>
              </tr>
            )}
          </CustomizedTables>
        </div>
      )}
    </div>
  );
};

export default CorporateCards;

import './DatePicker.scss'

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePicker from "react-datepicker";
import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

const ReactdatePicker = ({cardState,setCardState,isCsv,setAdminCsvData,adminCsvData}) => {
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  const [openCalender, setOpenCalender] = useState(false) 
  const selecteddate = ()=>{
    if(isCsv){
      return adminCsvData?.csvDate? new Date(adminCsvData?.csvDate):""   
    }else{
     return  cardState?.schedule_at ? new Date(cardState?.schedule_at) : ""
    }
  }
  return (
    <div className={`datepicker-custom-input ${globalDarkMode? "pclive-dark-mode" :""}`}>
      <DatePicker
        selected={
          selecteddate()
        }
        onClickOutside={()=> setOpenCalender(false)}
        onInputClick={()=> setOpenCalender(!openCalender)}
        open={openCalender}
        readOnly
        onChange={(update) => {
          if(!isCsv){
          setCardState({ ...cardState, schedule_at: update ? update : "" });
          }
          else{
            setAdminCsvData({ ...adminCsvData, csvDate: update?update:"" });
          }
          if(update){
          let time = update;
          if (new Date(update) <= new Date()) {
            time = moment(update).set("hour", moment(new Date()).format("HH"));
            time = moment(time).set("minute", moment(new Date()).format("mm"));
          }
          if(!isCsv){
          setCardState({ ...cardState, schedule_at: time });
          setOpenCalender(false)

        }
        else{
          setAdminCsvData({ ...adminCsvData, csvDate: time });
          setOpenCalender(false)
        }
        }
        }}
        dateFormat={"dd/MM/yyyy - hh:mm a"}
        icon={
          <span>
            <CalendarMonthIcon
              style={{
                fontSize: 19,
                marginTop: -3,
              }}
            />
          </span>
        }
        timeIntervals={1}
        showTimeSelect
        minDate={new Date()}
        minTime={
          new Date(isCsv? adminCsvData?.csvDate:cardState?.schedule_at) < new Date()
            ? new Date(
                0,
                0,
                0,
                moment(new Date()).format("HH"),
                moment(new Date()).format("mm")
              )
            : new Date(0, 0, 0, 0, 0)
        }
        maxTime={new Date(0, 0, 0, 23, 59)}
      />
    </div>
  );
};


export default ReactdatePicker
import "./AddPaymentCard.scss";

import { Button, Grid } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useMemo, useState } from "react";
import {
  addPaymentCardAction,
  getPaymentCards,
} from "../../redux/slices/payment";

import Backdrop from "@mui/material/Backdrop";
import ButtonLight from "../buttons/button-light/ButtonLight";
import Checkbox from "../checkbox/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import CustomPrimaryButton from "../buttons/primary/CustomPrimaryButton";
import Input from "../inputs/email/Input";
import PCLiveLoader from "../loader/PCLiveLoader";
import { getUserInfo } from "../../redux/slices/profile";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux"; //create react app typescript redux toolkit
import { useSelector } from "react-redux";

const INITIAL_STATE = {
  Card_number: null,
  exp_month: null,
  exp_year: null,
  cvc: null,
  is_primary: true,
  exp: null,
  card_type: null,
};
const AddPaymentCard = ({ address, setOpenAddPaymentCard, accVerify, tab , isDark}) => {
  const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize: 14,
            color: "#667085",
            letterSpacing: "0.025em",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#9e2146",
          },
        },
      }),
      []
    );

    return options;
  };
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [load, isLoad] = useState(false);
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  const [data, setData] = useState({
    number:false,
    exp:false,
    cvc:false
  });
  const [cardDetails, setCardDetail] = useState(INITIAL_STATE);

  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    isLoad(true);

    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      console.log(error);
      toast.error(error?.message);
    } else {
      addPaymentCardAction(
        { card_token: token?.id },
        (body) => {
          if (body.data.success) {
            toast.success(body.data.message);
            setOpenAddPaymentCard(false);
            dispatch(getPaymentCards());
            if(accVerify){
              dispatch(getUserInfo())
            }
          } else {
            toast.error(body.data.message);
          }
        },
        (body) => {
          toast.error(body.data.message);
        }
      );
    }
    isLoad(false);
  };

  return (
    <div className={`payment-add-card-container ${(globalDarkMode || isDark) ? 'pclive-dark-mode' : ''}`}>
      <PCLiveLoader open={load} /> 

      {/* Need this code later on... */}
      {/* <div className="input-space">
        <Input
          placeholder={"e.g. Personal card"}
          label={"Card type"}
          type={"text"}
          error={errors.line1}
          value={cardDetails.line1}
          errorMsg={"Please enter account holder name"}
        />
      </div> */}
         <Grid container>
        <Grid item md={12} sx={12} sm={12} >
      <div className="input-space">
        <label className="text-sm-medium-14">Card number</label>
        <CardNumberElement className="input-class card" options={options} onChange={(e)=> setData({...data,number:e.complete})} isDark />
   
      </div>

      </Grid>
        </Grid>
      <Grid container spacing={3} justifyContent={"flex-start"}>
        <Grid sm={6} md={6} item sx={{ justifyContent: "flex-start" }}>
          <div className="input-space">
            <label className="text-sm-medium-14">Expiry date</label>

            <CardExpiryElement className="input-class exp" options={options}  onChange={(e)=> setData({...data,exp:e.complete})} isDark />
          </div>
        </Grid>
        <Grid item sm={6} md={6}>
          <div className="input-space spaces">
            <label  className="text-sm-medium-14">
              CVV or CSC (Security code)
            </label>

            <CardCvcElement className="input-class cvc" options={options} onChange={(e)=> setData({...data,cvc:e.complete})} isDark />
          </div>
        </Grid>
      </Grid>
      {/* Need this code later on... */}
      {/* <Checkbox
        disableLabelClick={true}
        label={<span>Save this as my primary card</span>}
        checked={cardDetails.is_primary}
        onChange={(e) =>
          setCardDetail({ ...cardDetails, is_primary: e.target.checked })
        }
        value={cardDetails.is_primary}
      /> */}

      <div className="button-container">
        <ButtonLight
          onClick={() => setOpenAddPaymentCard(false)}
          isDark={isDark}
        >
          Cancel
        </ButtonLight>
        <CustomPrimaryButton
        isDark={isDark}
        disabled={ Object.keys(data).some((key) => data[key] === false)}
          onClick={handleSubmit}
        >
          {accVerify && tab == 1 ? "Save Card & complete" : "Save card"}
        </CustomPrimaryButton>
      </div>
    </div>
  );
};

export default AddPaymentCard;

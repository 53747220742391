import "./Login.scss";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AlertBar from "../../../components/alert/Alert";
import AuthCarosel from "../carosel/AuthCarosel";
import Backdrop from "@mui/material/Backdrop";
import ButtonLinkGrey from "../../../components/buttons/link-grey/ButtonLinkGrey";
import ButtonPrimary from "../../../components/buttons/button-primary/ButtonPrimary";
import { Checkbox } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CryptoJS from "crypto-js";
import CustomPrimaryButton from "../../../components/buttons/primary/CustomPrimaryButton";
import Grid from "@mui/material/Grid";
import Input from "../../../components/inputs/email/Input";
import PCLiveLoader from "../../../components/loader/PCLiveLoader";
import checkBoxBase from "../../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../../assests/icons/checkbox-checked-dark.svg";
import eye from "../../../assests/icons/eye.svg";
import eyeOff from "../../../assests/icons/eye-off.svg";
import { login } from "../../../redux/slices/auth";
import mail from "../../../assests/icons/mail-16.svg";
import pcLiveLogo from "../../../assests/icons/pcLiveNewLogo.svg";

const Login = () => {
  const userdata = localStorage.getItem("allowdata");
  const bytes = userdata ? CryptoJS.AES.decrypt(userdata, "allowdata") : "";
  var decryptedData = bytes
    ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    : "";
  const isLoading = useSelector((state) => state.auth.isLoading);
  const globalDarkMode = useSelector((state) => state.profile.globalDarkMode);

  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const onLogin = () => {
    setIsLoadingState(true);
    const data = {
      email,
      password,
    };

    const dataToRemember = {
      email: remember ? email : "",
      password: remember ? password : "",
      remember,
    };
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(dataToRemember),
      "allowdata"
    ).toString();
    localStorage.setItem("allowdata", ciphertext);
    const handleSuccess = (res) => {
      if (!res.data.success) {
        setOpenAlert({
          ...openAlert,
          open: true,
          message: res.data.message,
          type: "error",
        });
        setIsLoadingState(false);

        return;
      }
      if (!res.data.data.is_superuser) {
        localStorage.setItem("userEmail", email);

        setOpenAlert({
          ...openAlert,
          open: true,
          message: "OTP sent successfully !!",
          type: "success",
        });
        localStorage.setItem("otpType", "login");

        setTimeout(() => {
          navigate("/otp");
        }, 1000);
        setIsLoadingState(false);

        return;
      }
      localStorage.setItem("userDetails", JSON.stringify(res.data.data));

      localStorage.setItem("userEmail", res.data.email);
      setOpenAlert({
        ...openAlert,
        open: true,
        message: "Login success !!",
        type: "success",
      });
      setTimeout(() => {
        if (res.data && res.data.data.session_token) {
          localStorage.setItem("accessToken", res.data.data.session_token);
          localStorage.setItem("userDetails", JSON.stringify(res.data.data));
          navigate("/landing");
        }
      }, 1000);
      setIsLoadingState(false);
    };
    const handleFail = (err) => {
      console.error(err, "err");
      setIsLoadingState(false);
    };
    login(data, handleSuccess, handleFail);
  };

  useEffect(() => {
    if (decryptedData && decryptedData.email) {
      setEmail(decryptedData.email);
      setPassword(decryptedData.password);
      setRemember(decryptedData.remember);
    }
  }, []);

  const onChangeInputs = (type, e) => {
    if (type === "email") {
      setEmail(e.target.value);

      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" || !pattern.test(e.target.value) ? true : false,
      });
    }
    if (type === "password") {
      setPassword(e.target.value);
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" || e.target.value.length < 6 ? true : false,
      });
    }
  };
  function shouldDisable() {
    return (
      Object.keys(errors).some((key) => errors[key] === true) ||
      email == "" ||
      password == ""
    );
  }
  return (
    <div className="login-container">
      <PCLiveLoader open={isLoading || isLoadingState} />

      <AlertBar
        message={openAlert.message}
        open={openAlert.open}
        handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        alertType={openAlert.type}
      />
      <Grid container>
        <Grid xs={1}></Grid>
        <Grid item xs={5}>
          <div className="left-content">
            <div className="login-form">
              <div className="form-container">
                <div>
                  <div item md={2} className="pclive-new-logo">
                    <img src={pcLiveLogo} alt="pcLiveLogo" loading="lazy" />
                    <div className="text-xl-medium-20">PCLive247</div>
                    <hr />
                  </div>
                </div>

                <div className="header-container">
                  <div className="text-sm-semibold">
                    <div>Log In</div>
                    <div
                      className="text-sm-semibold-16"
                      onClick={() => navigate("/register")}
                    >
                      <ButtonLinkGrey
                        label="Sign up"
                        className="text-sm-semibold-16"
                      />
                    </div>
                  </div>
                  <span className="text-md-medium">
                    Welcome back! Please enter your details.
                  </span>
                </div>
                <div className="input-container">
                  <div className="email">
                    <Input
                      placeholder={"e.g.sarahmile@gmail.com"}
                      label={"Email"}
                      type={"email"}
                      icon={mail}
                      onChange={(e) => onChangeInputs("email", e)}
                      error={errors.email}
                      value={email}
                      errorMsg={"Please enter a valid email."}
                    />
                  </div>
                  <div className="password">
                    <Input
                      placeholder="••••••••"
                      icon={showPassword ? eye : eyeOff}
                      label={"Password"}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => onChangeInputs("password", e)}
                      onClickIcon={() => setShowPassword(!showPassword)}
                      error={errors.password}
                      errorMsg={"Please enter valid password"}
                      value={password}
                    />
                  </div>
                  <div className="bottom-actions">
                    <div className="checkboxs">
                      <Checkbox
                        checkedIcon={
                          <img
                            src={
                              globalDarkMode
                                ? checkBoxPrimaryDark
                                : checkBoxPrimary
                            }
                            alt=""
                          />
                        }
                        icon={
                          <img
                            src={
                              globalDarkMode ? checkBoxBaseDark : checkBoxBase
                            }
                            alt=""
                          />
                        }
                        onChange={(e) => setRemember(e.target.checked)}
                      />
                      <span className="text-sm-medium-14">Remember Me</span>
                    </div>
                    <ButtonLinkGrey
                      onClick={() => navigate("/forgotPassword")}
                      label=" Forgot password?"
                    />
                  </div>
                </div>
                <CustomPrimaryButton
                  onClick={onLogin}
                  disabled={shouldDisable()}
                  size="xl"
                  fullWidth
                >
                  Get OTP
                </CustomPrimaryButton>
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={1}></Grid>

        <Grid xs={5}>
          <AuthCarosel />
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;

import { Navigate, useNavigate } from "react-router-dom";

import axios from "axios";

export const getToken = () => {
  return localStorage.getItem("accessToken");
};

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 410) {
      localStorage.clear();
      return (window.location.href = "/login");
    }
  }
);
export const apiPostCall = async (
  endpoint,
  userData,
  handleSuccess,
  handleFail,
  isFormData
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
      userData,
      {
        headers: {
          "Content-Type": isFormData ? "" : "application/json",
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    handleSuccess(response);
  } catch (error) {
    handleFail(error);
    console.error(error);
  }
};

export const apiPutCall = async (
  endpoint,
  userData,
  handleSuccess,
  handleFail
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    handleSuccess(response);
  } catch (error) {
    handleFail(error);
    console.error(error);
  }
};

export const apiPutCallForFormData = async (
  endpoint,
  userData,
  handleSuccess,
  handleFail
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
      userData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    handleSuccess(response);
  } catch (error) {
    handleFail(error);
    console.error(error);
  }
};

export const apiGetCall = async (endpoint, handleSuccess, handleFail) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    if (response?.data?.success) handleSuccess(response);
    else handleFail(response?.data);
  } catch (error) {
    handleFail(error);
    console.error(error);
  }
};

export const apiDeleteCall = async (endpoint, handleSuccess, handleFail) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      }
    );

    handleSuccess(response);
  } catch (error) {
    handleFail(error);
    console.error(error);
  }
};

export const apiPatchCall = async (
  endpoint,
  userData,
  handleSuccess,
  handleFail
) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      }
    );

    handleSuccess(response);
  } catch (error) {
    handleFail(error);
    console.log(error);
  }
};

export const showData = (data) => {
  switch (data) {
    case "PRE_TRANSIT":
      return "PROCESSING";
    case "TRANSIT":
      return "SHIPPED";
    case "DELIVERED":
      return "DELIVERED";
    case "RETURNED":
      return "RETURNED";
    case "CANCELLED":
      return "CANCELLED";
    case "FAILURE":
      return "FAILED";
    case "ORDER_RECEIVED":
      return "ORDER RECEIVED";
    case "UNKNOWN":
      return "ORDER RECEIVED";
    default:
      return data;
  }
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiPostCall, getToken } from "../../utils/utils";
import axios from "axios";

export const getRooms = (filterData, page, handleSuccess, handleFail) => {
  filterData.page = page
  if (filterData && filterData.graded == "") {
    delete filterData.graded;
  } else if (filterData && filterData.graded == "false") {
    filterData.graded = "";
  }
  apiPostCall(
    "api/mobile/dashboard/",
    filterData,
    handleSuccess,
    handleFail
  );
};

export const getCategorisedRooms = (
  filterData,
  handleSuccess,
  handleFail,
  categoryId,pageIndex
) => {
  const data = {
    paginate: true,
    limit: 10,
    page:pageIndex,
    category_id: filterData?.seller_username ? filterData.categoryId : categoryId,
  };
  
  if (!filterData?.seller_username) {
    if (filterData.graded) {
      data.graded = filterData.graded;
    }
    if (filterData.price_range) {
      data.price_range = filterData.price_range
    }
  } else {
    data.seller_username = filterData.seller_username;
    data.category_id = filterData.categoryId;
  }
  apiPostCall(
    "api/mobile/dashboard/categorized/dealers_auction/",
    data,
    handleSuccess,
    handleFail
  );
};

export const getDealersRooms = createAsyncThunk(
  "getDealersRooms",
  async (data, handleSuccess, handleFail) => {
    return await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}api/mobile/dashboard/dealers_auction?paginate=true&page=${data?.pageIndex}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      }
    );
  }
);

const roomsSlice = createSlice({
  name: "rooms",
  initialState: {
    isLoading: false,
    allDealersRoom: null,
    filterInfo:{}
  },
  reducers: {
    setFilters: (state, action) => {
      state.filterInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDealersRooms.pending, (state, action) => {
      state.isLoading = true;
      state.allDealersRoom = null;
    });
    builder.addCase(getDealersRooms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allDealersRoom = action.payload.data;
    });
    builder.addCase(getDealersRooms.rejected, (state, action) => {
      state.isLoading = false;
      state.allDealersRoom = action.error;
    });
  },
});
export const {setFilters} = roomsSlice.actions;
export const roomsSliceReducer = roomsSlice.reducer;

import './FollowingDialog.scss';

import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  getUserDetails,
  getUserFollowers,
  getUserInfo,
  updateUserFollower,
} from '../../../../../redux/slices/profile';
import { useDispatch, useSelector } from 'react-redux';

import ButtonLight from '../../../../../components/buttons/button-light/ButtonLight';
import Dialog from '../../../../../components/dialog/Dialog';
import { NoData } from "../../../../../components/no-data/NoCards";
import PCLiveLoader from '../../../../../components/loader/PCLiveLoader';
import Pagination from "../../../../../components/pagination/Pagination";
import Popup from '../../../../../components/popup/Popup';
import SearchIcon from '@mui/icons-material/Search';
import pclive from '../../../../../assests/icons/pclive247.png';

export default function FollowingDialog({ openDialog, userId, onClose ,isDark }) {
  const [openPopup, setOpenPopup] = useState(false);
  const [currentData, setCurrentData] = useState({ isFollowing: false, id: 0 });

  const dispatch = useDispatch();
  const userFollowers = useSelector(state => state.userFollowers.followersInfo);
  const isLoading = useSelector(state => state.userFollowers.isLoading);
  const userInfo = useSelector((state) => state.profile?.data?.data);
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  const [pageIndex, setIndex] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const totalPages = useSelector(
    (state) => state.userFollowers?.totalPages);
  useEffect(() => {
    if (userId) {
      if (openDialog)
        dispatch(getUserFollowers(`?is_follow=false&user_id=${userId}&paginate=true&page=${pageIndex}&limit=10`));
    } else {
      const userDetails = getUserDetails();
      const response = JSON.parse(userDetails);
      if (openDialog)
        dispatch(getUserFollowers(`?is_follow=false&user_id=${response?.id}&paginate=true&page=${pageIndex}&limit=10`));
    }
    setSearchQuery('');
  }, [openDialog, dispatch, pageIndex]);

  useEffect(() => {
    if (userId) {
      if (openDialog)
        setTimeout(() => {
          dispatch(getUserFollowers(`?is_follow=false&user_id=${userId}&paginate=true&page=${pageIndex}&limit=10&search_text=${searchQuery}`));
        }, 1000);
      } else {
      const userDetails = getUserDetails();
      const response = JSON.parse(userDetails);
      if (openDialog)
        setTimeout(() => {
          dispatch(getUserFollowers(`?is_follow=false&user_id=${response?.id}&paginate=true&page=${pageIndex}&limit=10&search_text=${searchQuery}`));
        }, 1000);
      }
  }, [searchQuery]);

  const setPageIndex = () =>{
    if(totalPages == pageIndex  && userFollowers?.data?.length == 1){
      if(pageIndex > 1){
        setIndex(pageIndex - 1)
        return pageIndex -1;
      } else {
        setIndex(1);
        return 1;
    }
    } else
    {
      return pageIndex;
    }
  }
  const updateFollower = (is_follow, user_id) => {
    const userDetails = getUserDetails();
    const response = JSON.parse(userDetails);
    updateUserFollower(
      {
        is_follow,
        user_id,
      },
      res => {
        setPageIndex();
        if (userId) {
          dispatch(getUserInfo())
          setOpenPopup(false)
          dispatch(getUserFollowers(`?is_follow=false&user_id=${userId}&paginate=true&page=${setPageIndex() }&limit=10`));
        } else {
          dispatch(getUserFollowers(`?is_follow=false&user_id=${response?.id}&paginate=true&page=${setPageIndex()}&limit=10`));
          dispatch(getUserInfo())
          setOpenPopup(false)
        }
      },
      err => console.log({ err }),
    );
  };
  const onCloseDialog = () =>{
    setIndex(1);
    onClose();
  }
  return (
    <Dialog
      openDialog={openDialog}
      onClose={onCloseDialog}
      title={'Following'}
      need this code later
      rightElement={
        <TextField
          type="search"
          size="small"
          placeholder="Search for name"
          id="input-with-icon-textfield"
          label=""
          value={searchQuery}
          sx={{
            backgroundColor:(globalDarkMode || isDark) ? '#292828' : "white",
           borderRadius: "0.5rem",
           input: { color: (globalDarkMode || isDark) ? '#F9FAFB' : ''} 
          }}
          onChange={(e) => { setSearchQuery(e.target.value); }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ fill: (globalDarkMode || isDark) ? 'white' :'black'}}/>
              </InputAdornment>
            ),
          }}
        />
      }
    >
      <>
        {userFollowers?.data && userFollowers?.data?.length ? userFollowers?.data?.map((item, index) => (
          <div key={item?.id}>
            <div className={`followers-container ${(globalDarkMode || isDark) ? 'pclive-dark-mode' : ''}`}>
              <div className="profile-image-container">
                <img
                  src={item?.profile_pic ? item?.profile_pic : pclive}
                  className={'user-icon'}
                  alt="userIcon"
                  height={58}
                  width={58}
                  loading='lazy'
                />
                <div className="name">
                  <div className="text-sm-medium-14">
                    {item?.first_name + ' ' + item?.last_name}
                  </div>
                  <div className="text-sm-medium-14 username">
                    {item?.username}
                  </div>
                </div>
              </div>
              <div>
                <div className="followers-button">
                  {item?.id != userInfo?.id && <ButtonLight
                    onClick={() => {
                      const isFollowing = item?.following ? false : true;
                      if (item?.following) {
                        setOpenPopup(true)
                      }else {
                        updateFollower(isFollowing, item?.id);
                      }
                      setCurrentData({ isFollowing, id: item?.id })
                    }}
                  // onClick={() => setOpenPopup(true)}
                  >
                    {item?.following ? 'Unfollow' : 'Follow'}
                  </ButtonLight>}
                </div>
              </div>
            </div>
            <Divider variant="fullWidth" />
          </div>
        )) : !isLoading ? <NoData type="room" /> : ""}
        <Popup
          openPopup={openPopup}
          onClose={() => setOpenPopup(false)}
          actionButtons={{
            primary: {
              title: 'No, go back',
              onClick: () => {setOpenPopup(false)},
            },
            secondary: {
              title: 'Yes, unfollow',
              onClick: () => {
                updateFollower(currentData.isFollowing, currentData.id);
              },
            },
          }}
          title={'Are you sure you want to unfollow this user?'}
          description="Once unfollowed, you will not be able to see their information or cards."
        />
        {userFollowers?.data && userFollowers?.data?.length ? <Pagination
          index={pageIndex}
          handleChange={(page) => setIndex(page)}
          totalPage={totalPages}
          isDark = {isDark}
        />:""}
      </>
    </Dialog>
  );
}

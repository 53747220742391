import React, { useState } from "react";
import "./StripeVerfication.scss";
import { Button } from "@mui/material";
import Stripe from "./LoadStripe";
import ButtonLight from "../../../../../components/buttons/button-light/ButtonLight";
import { useSelector } from "react-redux";

const StripeVerfication = ({ setOpenStripeVerification, getUserData }) => {
  const [varified, setVarified] = useState(false);

  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);

  return (
    <div
      className={`stripe-verification ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <div className="text-content text-sm-medium-14">
        For this step, you will be redirected to your default browser for
        further verification at Stripe.
        <br />
        <br />
        Please keep one the following type of the document (Image in png, jpg
        format) with you or in your computer, to complete the verification
        process.
      </div>
      <br />
      <br />
      <div className="items">Driver license</div>
      <div className="items">Identity card</div>
      <div className="items">Passport</div>

      <div className="button-container">
        <ButtonLight onClick={() => setOpenStripeVerification(false)}>
          Cancel
        </ButtonLight>
        <Stripe
          setVarified={setVarified}
          setOpenStripeVerification={setOpenStripeVerification}
          getUserData={getUserData}
        />
      </div>
    </div>
  );
};

export default StripeVerfication;

import "./aboutUs.scss";

import React from "react";
import {useSelector} from "react-redux";

const AboutUS = () => {
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  return (
    <div className={`terms-container ${globalDarkMode ? 'pclive-dark-mode' : ''}`}>
      <div className="contents">
        <div className="span-container">
          <span className="text-sm-medium-14">
          As fellow hobby/business enthusiasts, we're on a mission to shake up the buying and selling scene. Imagine this: flipping your favourite finds faster than you can say 'treasure hunt.' With our state-of-the-art tech, auctions zip by in minutes, satisfying both the hardcore collectors and the casual browsers.<br/><br/>
But here's the kicker: we're not just about deals; we're about creating a space where the whole family can join in on the fun. We're proudly kid-friendly! It's not just about buying and selling; it's about sparking joy and igniting passions in every generation. So, are you ready to embark on the ultimate trading adventure, where everyone's invited?
          </span>
        </div>
      </div>
    </div>
  );
};

export default AboutUS;

import "./UpcomingCards.scss";
import React, { useState } from "react";
import ButtonLinkGrey from "../../../../components/buttons/link-grey/ButtonLinkGrey";
import CardCarosel from "../../../../components/carosel/CardCarosel";
import CustomPrimaryButton from "../../../../components/buttons/primary/CustomPrimaryButton";
import pcliveLogo from "../../../../assests/icons/pclive247.png";

const UpcomingCards = ({
  setViewcard,
  setViewAll,
  cardListState,
  setCurrentCard,
  viewAllCards,
  allData,
  totalCount,
}) => {
  const [imgLoad, setImgload] = useState(false);

  const handleImageLoaded = (e) => {
    setImgload(true);
  };

  return (
    <div className="upcoming-cards">
      <div className="upcoming-card-container">
        <div className="text-sm-semibold-14 upcoming">
          <div>
            Upcoming cards ({allData?.live_count ? allData.live_count - 1 : 0})
          </div>
          {allData?.live_count > 4 && (
            <ButtonLinkGrey
              label="View more"
              onClick={() => setViewAll(true)}
              disabled={cardListState && cardListState.length < 2}
            />
          )}
        </div>
        <div
          className={`card-rows ${cardListState.length <= 3 ? "two-card" : ""}`}
          container
        >
          {cardListState && cardListState.length
            ? cardListState.map((card, index) => {
                if (index != 0) {
                  return (
                    <img
                      src={imgLoad ? card?.front_image : pcliveLogo}
                      alt="card"
                      height={107}
                      width={70}
                      onClick={() => {
                        setCurrentCard(card);
                        setViewcard(true);
                      }}
                      onLoad={handleImageLoaded}
                    />
                  );
                }
              })
            : ""}
        </div>
      </div>
      {/* <div>
        {
        viewAllCards && viewAllCards.length>4 &&
        <CustomPrimaryButton
          onClick={() => setViewAll(true)}
          disabled={cardListState && cardListState.length < 2}
        >
          View more
        </CustomPrimaryButton>}
      </div> */}
    </div>
  );
};

export default UpcomingCards;

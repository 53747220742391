import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button } from "@mui/material";
import ButtonLight from "../../../../../../components/buttons/button-light/ButtonLight";
import ButtonLinkGrey from "../../../../../../components/buttons/link-grey/ButtonLinkGrey";
import CustomizedTooltips from "../../../../../../components/tooltip/Tooltip";
import MultilevelDropdown from "../../../../../../components/dropdown/Dropdown";
import SearchInput from "../../../../../../components/inputs/search/Search";
import { useState } from "react";

const CsvOrBulkTableHeader = ({
  checkedArray,
  isPublishSelected,
  setAllPublishModal,
  setCurrentPage,
  tabValue,
  filterData,
  setFilterData,
  INITIAL_FILTER,
  onChangefilter,
  resetdata,
  allCatagories,
  setCategoryFilter,
  searchQuery,
  setSearchQuery,
  onSearch,
  selectSearch,
  setSelectSearch,
  onReset,
  isBulkSingle,
  setIsBulkSingle,
  isCsvSingle,
  setIsCsvSingle,
  currentSetData,
  getCurrentSetData,
  title,
  setTableTitle,
  setOpenRerequestPopup,
  setOpenPublishAllPopup,
  setIsUpdateAddress,
  currentCard,
  isPublishAll,
  isUnpublishAll,
  personalAddresses,
  corporateAddresses,
  setSortData,
  data,
}) => {
  const [showSearchInput, setShowSearchInput] = useState(false);

  return (
    <div className="table-head-elements">
      <div className="left-content">
        {isBulkSingle || isCsvSingle ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {" "}
            <ArrowBackIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (isBulkSingle) {
                  setIsBulkSingle(false);
                  tabValue === 1
                    ? setTableTitle("Approved Requests")
                    : setTableTitle("Requests");
                } else {
                  setIsCsvSingle(false);
                  tabValue === 1
                    ? setTableTitle("Approved Requests")
                    : setTableTitle("Requests");
                }
                onReset();
                setCurrentPage(1);
                setSortData([{ type: "request_date", value: false }]);
                getCurrentSetData(!currentSetData);
              }}
            />{" "}
            <CustomizedTooltips title={title}>
              <div className="value longName overflow">{title}</div>
            </CustomizedTooltips>
          </Box>
        ) : (
          title
        )}
      </div>
      <div className="right-contents">
        <div className="right-top-contents">
          {checkedArray && checkedArray.length ? (
            <>
              {!isPublishSelected ? (
                <ButtonLight
                  onClick={() =>
                    setAllPublishModal({ type: "publish", value: true })
                  }
                >
                  Publish selected cards
                </ButtonLight>
              ) : (
                <ButtonLight
                  onClick={() =>
                    setAllPublishModal({ type: "unpublish", value: true })
                  }
                >
                  Unpublish selected cards
                </ButtonLight>
              )}
            </>
          ) : (
            <>
              {!showSearchInput && data?.length ? (
                <>
                  {tabValue === 2
                    ? currentCard?.request_state?.toLowerCase() ===
                        "cancelled" && (
                        <ButtonLight
                          onClick={() => {
                            setIsUpdateAddress(true);
                            setOpenRerequestPopup(true);
                          }}
                        >
                          Update Pickup Address
                        </ButtonLight>
                      )
                    : isPublishAll && (
                        <ButtonLight
                          onClick={() => {
                            setOpenPublishAllPopup({
                              type: "publish",
                              value: true,
                            });
                          }}
                        >
                          Publish All
                        </ButtonLight>
                      )}
                  {tabValue === 2
                    ? currentCard?.request_state?.toLowerCase() ===
                        "cancelled" && (
                        <ButtonLight
                          onClick={() => {
                            setIsUpdateAddress(false);
                            setOpenRerequestPopup(true);
                          }}
                        >
                          Re-request
                        </ButtonLight>
                      )
                    : isUnpublishAll && (
                        <ButtonLight
                          onClick={() => {
                            setOpenPublishAllPopup({
                              type: "unpublish",
                              value: true,
                            });
                          }}
                        >
                          Unpublish All
                        </ButtonLight>
                      )}
                </>
              ) : null}
              <MultilevelDropdown
                data={filterData}
                setFilterData={setFilterData}
                onSubmitFilter={onChangefilter}
                resetdata={resetdata}
                allCatagories={allCatagories}
                INITIAL_FILTER={INITIAL_FILTER}
                searchData={
                  allCatagories && allCatagories.length
                    ? allCatagories
                        .filter((f) => f.status)
                        .map((cat) => {
                          return {
                            label: cat.category_name,
                            value: false,
                            id: cat.id,
                          };
                        })
                    : []
                }
                setCategoryFilter={setCategoryFilter}
                personalAddresses={personalAddresses}
                corporateAddresses={corporateAddresses}
              />
              <SearchInput
                viewIcon
                isSelect
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                onSearch={onSearch}
                setSelectSearch={(val) => setSelectSearch(val)}
                radioValue={selectSearch}
                onCancle={() => onReset(false)}
                columns={[
                  {
                    label: "Title & Description",
                    value: "title_description",
                    placeholder: "Title / Description",
                  },
                ]}
                showInputField={showSearchInput}
                setShowInputField={setShowSearchInput}
                source="requests"
              />
            </>
          )}
          <Button
            className="reset-button"
            variant="text"
            onClick={() => onReset(false)}
          >
            Reset all
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CsvOrBulkTableHeader;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { generateRealTimeToken } from "./redux/slices/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7dNavXJ0RYR-kzNKezktXBqFG5F8tRnA",
  authDomain: "pclive247app.firebaseapp.com",
  projectId: "pclive247app",
  storageBucket: "pclive247app.appspot.com",
  messagingSenderId: "1038051254697",
  appId: "1:1038051254697:web:948faef027dcb6e21724b8",
  measurementId: "G-T37ZP7X2JK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BOS9IHp0LltWH2JvmcFCjjEgxmhpN2SC6VAt3ZA7hdkuXL2NcaHD0cSC4hlYXTZ_8kMMguDgB0OrNJJkAfv6reA",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        generateRealTimeToken(
          {
            web_device_token: currentToken,
          },
          (body) => {},
          (body) => {
            toast.error(body.message);
          }
        );
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
      toast.success(payload?.notification?.title);
    });
  });

import React from "react";
import "./BorderlessButton.scss";
import { Button } from "@mui/material";
const ButtonBorderless = (props) => {
  const { label, disabled } = props;
  return (
      <Button
        variant="text"
        className={`text-sm-semibold-14 button-borderless-component`}
        {...props}
      >
        {props.children}
      </Button>
  );
};
export default ButtonBorderless;

import { apiGetCall, apiPostCall, getToken } from '../../utils/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

export const getCategories = createAsyncThunk(
  'getCategories',
  async (data, handleSuccess, handleFail) => {
    return await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}api/categories/${data?.paginate?`?paginate=true&limit=12&page=${data?.page}${data?.searchQuery ? "&search_text=" + data?.searchQuery : ''}`:""}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken(),
        },
      },
    );
  },
);

export const saveIntrestedCategories = (
  userData,
  handleSuccess,
  handleFail,
) => {
  apiPostCall(
    'api/interested_categories/',
    userData,
    handleSuccess,
    handleFail,
  );
};

export const getIntrestedCategories = (handleSuccess, handleFail) => {
  apiGetCall('api/interested_categories/', handleSuccess, handleFail);
};

export const getRoomsCatgories = (handleSuccess, handleFail) => {
  apiGetCall(`api/categories/`, handleSuccess, handleFail);
};

const categories = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    data: null,
  },
  extraReducers: builder => {
    builder.addCase(getCategories.pending, (state, action) => {
      state.isLoading = true;
      state.data = null;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.data = action.error;
    });
  },
});

export default categories.reducer;

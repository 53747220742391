import "./ButtonLinkGrey.scss";

import { useDispatch, useSelector } from "react-redux";

import React from "react";

const ButtonLinkGrey = (props) => {
  const { label,icon } = props;
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  return (
    <div
      style={{ cursor: "pointer" }}
      className={`text-sm-semibold-14 button-link-grey ${globalDarkMode ? "pclive-dark-mode":""}`}
      {...props}
    >
      {label} {icon}
    </div>
  );
};
export default ButtonLinkGrey;

import "./ManageCard.scss";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  Modal,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  styled,
} from "@mui/material";
import {
  BULK_CSV_INITIAL_FILTER,
  INITIAL_CSV,
  INITIAL_ERROR,
  INITIAL_FILTER,
  MAX_FILE_SIZE_BYTES,
  columnsCsvList,
  tableColumns,
} from "./constants";
import React, { useEffect, useRef, useState } from "react";
import {
  deleteCard,
  editAddressAndInventoryTag,
  getAllBulkList,
  getAllCsvList,
  getBulkCardsList,
  getCards,
  getCsvFileCards,
  postCSV,
  publishAllCsvCard,
  publishBulkCard,
  reRequest,
  unpublishBulkCard,
  updateBulkSchedule,
  updateSchedule,
} from "../../../../../../redux/slices/card";
import { useDispatch, useSelector } from "react-redux";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ButtonGray from "../../../../../../components/buttons/button-gray/ButtonGray";
import ButtonLight from "../../../../../../components/buttons/button-light/ButtonLight";
import ButtonLinkGrey from "../../../../../../components/buttons/link-grey/ButtonLinkGrey";
import { ReactComponent as CrossIcon } from "../../../../../../assests/icons/cross.svg";
import CustomPrimaryButton from "../../../../../../components/buttons/primary/CustomPrimaryButton";
import CustomizedTables from "../../../../../../components/table/Table";
import CustomizedTooltips from "../../../../../../components/tooltip/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "../../../../../../components/dialog/Dialog";
import { ReactComponent as DownArrow } from "../../../../../../assests/icons/chevron-down.svg";
import EditIcon from "@mui/icons-material/Edit";
import FilterDropdown from "../../../../../../components/filter/FilterDropdown";
import MultilevelDropdown from "../../../../../../components/dropdown/Dropdown";
import { NoData } from "../../../../../../components/no-data/NoCards";
import PCLiveLoader from "../../../../../../components/loader/PCLiveLoader";
import Papa from "papaparse";
import Popup from "../../../../../../components/popup/Popup";
import ReactDatePicker from "react-datepicker";
import { S3 } from "aws-sdk";
import SearchInput from "../../../../../../components/inputs/search/Search";
import { UploadBulk } from "../upload-card/UploadBulk";
import { UploadCardForm } from "../upload-card/UploadCard";
import UploadCsv from "../upload-card/UploadCsv";
import ViewCard from "../view-card/ViewCard";
import axios from "axios";
import checkBoxBase from "../../../../../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../../../../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../../../../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../../../../../assests/icons/checkbox-checked-dark.svg";
import { getAllInventoryTag } from "../../../../../../redux/slices/seller";
import moment from "moment";
import { setSettingOptions } from "../../../../../../redux/slices/profile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledIcon = styled("span")(({ darkTheme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  border: darkTheme ? "1px solid #3B3A3A" : "1px solid #D0D5DD",
  marginLeft: "8px",
  backgroundColor: darkTheme ? "#3B3A3A" : "#f5f8fa",
  backgroundImage: darkTheme
    ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
    : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #004ABC",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: darkTheme ? "#3B3A3A" : "#fff",
    boxShadow: darkTheme
      ? "0px 0px 0px 4px #605F5F"
      : "0px 0px 0px 4px #E7F0FF",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: darkTheme ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
  },
}));

const StyledCheckedIcon = styled(StyledIcon)(({ darkTheme }) => ({
  backgroundColor: darkTheme ? "#3B3A3A" : "#ffffff",
  backgroundImage: darkTheme
    ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
    : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: "20px",
    height: "20px",
    backgroundImage: darkTheme
      ? "radial-gradient(#6F91E8,#6F91E8 28%,#292828 32%)"
      : "radial-gradient(#0E54CA,#0E54CA 28%,#fff 32%)",
    content: "''",
    border: darkTheme ? "1px solid #6F91E8" : "1px solid #0E54CA",
    borderRadius: "100%",
    marginLeft: "-1px",
    marginTop: "-1px",
  },
  "input:hover ~ &": {
    backgroundColor: darkTheme ? "#3B3A3A" : "#ffffff",
    filter: darkTheme
      ? "drop-shadow(0px 0px 8px #605F5F)"
      : "drop-shadow(0px 0px 10px #E7F0FF)",
  },
}));

const ManageCard = ({ setRoomType, setCorporateCardsTabValue }) => {
  const INITIAL_STATE = {
    front_image: undefined,
    back_image: undefined,
    title: "",
    description: "",
    category_id: "",
    grade: 0,
    card_year: "",
    condition: "raw",
    reserve_price: "",
    published: false,
    show_notification: false,
    isSchedule: false,
    inventory_tag_name: undefined,
    inventory_tag: undefined,
    schedule_at: undefined,
    address_id: "",
    is_corporate: false,
  };
  let weekdateTo = moment(new Date()).format("MM-DD-YYYY");
  let weekdateFrom = moment(new Date()).subtract(6, "d").format("MM-DD-YYYY");
  let monthTo = moment(new Date()).format("MM-DD-YYYY");
  let monthFrom = moment(new Date()).subtract(29, "d").format("MM-DD-YYYY");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const csvRef = useRef();
  const userDetail = localStorage.getItem("userDetails");
  const allTagList = useSelector(
    (state) => state.inventorytag.allTagList?.data
  );
  const allCatagories = useSelector((state) => state.categories.data?.data);
  const personalAddresses = useSelector((state) => state.address.data?.data);
  const corporateAddresses = useSelector(
    (state) => state.address.corporateAddresses
  );
  const userInfo = useSelector((state) => state.profile?.data?.data);
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);

  const [tabValue, setTabValue] = useState(1);
  const [addOpen, setAddOpen] = useState(false);
  const [addOpenMulti, setAddOpenMulti] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isPublish, setPublish] = useState({ value: false, type: "" });
  const [isEdit, setIsEdit] = useState(false);
  const [isPrivateRoom, setIsPrivateRoom] = useState(false);
  const [unpublishModal, setUnpublishModal] = useState(false);
  const [addAlert, setAddAlert] = useState(false);
  const [addMultiAlert, setMultiAddAlert] = useState(false);
  const [addAddress, setAddaddress] = useState(false);
  const [cardState, setCardState] = useState(INITIAL_STATE);
  const [multiCard, setMultiCard] = useState([INITIAL_STATE]);
  const [bulkState, setBulkState] = useState({
    address_id: 0,
    is_corporate: false,
  });
  const [isView, setIsView] = useState(false);
  const [load, setLoad] = useState(true);
  const [resetdata, setReset] = useState(false);
  const [publishComment, setPublishComment] = useState("");
  const [totalPageArr, setPageArr] = useState([]);
  const [adminCsvError, setAdminCsvError] = useState([]);
  const [sellerCsvCategory, setSellerCsvCategory] = useState([]);
  const [sellerCsvCategoryError, setSellercategoryError] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [currentCsvData, setCurrentCsvData] = useState([]);
  const [adminCsv, setAdminCsv] = useState(false);
  const [adminCsvData, setAdminCsvData] = useState(INITIAL_CSV);
  const [errors, setErrors] = useState([]);
  const [multiError, setMultiErrors] = useState([INITIAL_ERROR]);
  const [multiAddressError, setMultiAddressError] = useState(false);
  const [isSeller, setIseller] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [daysOption, setDaysOption] = useState("");
  const [dateDisplay, setDateDisplay] = useState(false);
  const [filterData, setFilterData] = useState(INITIAL_FILTER);
  const [searchQuery, setSearchQuery] = useState(null);
  const [priceFilter, setPriceFilter] = useState([]);
  const [gradeFilter, setGradeFilter] = useState([]);
  const [addressFilter, setAddressFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [currentSetData, getCurrentSetData] = useState(false);
  const [csvList, toggleCsvList] = useState(true);
  const [selectSearch, setSelectSearch] = useState("");
  const [updateCsvSchedule, setUpdateSchedule] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [pageIndex, setPageIndex] = React.useState(1);
  const [tableHeader, setTableHeader] = React.useState(null);
  const [sortData, setSortData] = useState([]);
  const [currentCsvFile, setCurrentCsvFile] = useState({
    file: "",
    name: "",
    isPrivate: false,
    schedule_at: "",
    seller: null,
  });
  const [currentBulkFile, setCurrentBulkFile] = useState({
    file: "",
    name: "",
    isPrivate: false,
    schedule_at: "",
    seller: null,
  });
  const [editCard, isEditCard] = useState(false);
  const [uploadDrawer, setUploadDrawer] = useState(false);
  const [tagValue, setTagValue] = useState(null);
  const [currentSchedule, setCurrentSchedule] = useState("");
  const [checkedArray, setCheckedArray] = useState([]);
  const [bulkList, setBulkList] = useState(true);
  const [currentCard, setCurrentCard] = useState(null);
  const [csvCardIsPrivate, setCsvCardIsPrivate] = useState(null);
  const [publishAll, setPublishAll] = useState(null);
  const [unpublishAll, setUnpublishAll] = useState(null);
  const [scheduleAt, setScheduleAt] = useState(null);
  const [privateRoomType, setPrivateRoomType] = useState("home");
  const [cardUploadType, onChangeUpload] = useState("");
  const [radioUpload, closeRadioUpload] = useState(false);
  const [allPublishModal, setAllPublishModal] = useState({
    type: "",
    value: false,
  });
  const [categories, setCategories] = useState([]);
  const [checkAllCards, setCheckAllCards] = useState(false);
  const [isPublishSelected, setIsPublishSelected] = useState(false);
  const [publishRadio, setPublishRadio] = useState("");
  const [titleDescription, setTitleDescription] = useState("");
  const [tag, setTag] = useState("");
  const [seller, setSeller] = useState("");
  const [openUpdateAddressPopup, setOpenUpdateAddressPopup] = useState(false);
  const [openUpdateInventoryTagPopup, setOpenUpdateInventoryTagPopup] =
    useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [defaultSearch, setDefaultSearch] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const tabs = [
    { name: "Single upload", value: 1 },
    { name: "Bulk upload", value: 2 },
    { name: "CSV upload", value: 3 },
  ];
  const getToken = () => {
    return localStorage.getItem("accessToken");
  };
  const PRIVATE_ROOM_COUNT = process.env.REACT_APP_PRIVATE_ROOM_COUNT;
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  const REGION = process.env.REACT_APP_REGION;
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
  const onChangetab = (room) => {
    setCurrentPage(1);
    setTabValue(room);
  };

  useEffect(() => {
    allCatagories?.length &&
      setCategories(
        allCatagories
          ?.filter((cat) => !cat.is_deleted && cat.status)
          ?.map((category) => category?.category_name?.toLowerCase())
      );
  }, [allCatagories]);

  useEffect(() => {
    !checkAllCards && handleClose();
  }, [checkAllCards]);

  useEffect(() => {
    dispatch(getAllInventoryTag());
  }, [dispatch]);

  useEffect(() => {
    setLoad(true);

    let end = null;
    let start = startDate ? moment(startDate).format("MM-DD-YYYY") : null;
    end = endDate ? moment(endDate).endOf("day") : null;
    end = endDate ? moment(end).format("MM-DD-YYYY") : null;
    const priceArray = priceFilter.map((item) => item.replace(/\+/g, "%2B"));
    const gradeArray = gradeFilter.map((item) =>
      item.replace("AUTHENTIC", "0")
    );

    if (tabValue == 3 && !csvList) {
      let sortArry = sortData
        ?.filter((item) => !item?.type?.includes("total_cards"))
        ?.map((f) => (f.value ? f.type : `-${f.type}`));

      const data = {
        file_link: currentCsvFile.file,
      };
      getCsvFileCards(
        (body) => {
          setTableData(body?.data);
          setLoad(false);
          setCsvCardIsPrivate(body?.data?.is_private_room);
          setUnpublishAll(body?.data?.unpublish_all);
          setPublishAll(body?.data?.publish_all);
          setScheduleAt(body?.data?.scheduled_at);
        },
        () => {
          setLoad(false);
        },
        data,
        currentPage,
        searchQuery,
        start,
        end,
        sortArry,
        priceFilter,
        gradeArray,
        categoryFilter,
        selectSearch
      );
      return;
    }
    if (tabValue == 2 && !bulkList) {
      let sortArry = sortData
        ?.filter((item) => !item?.type?.includes("total_cards"))
        ?.map((f) => (f.value ? f.type : `-${f.type}`));

      const data = {
        bulk_name: currentBulkFile.file,
      };
      getBulkCardsList(
        (body) => {
          setTableData(body?.data);
          setLoad(false);
          setUnpublishAll(body?.data?.unpublish_all);
          setPublishAll(body?.data?.publish_all);
          setScheduleAt(body?.data?.scheduled_at);
        },
        () => {
          setLoad(false);
        },
        data,
        currentPage,
        searchQuery,
        start,
        end,
        sortArry,
        priceFilter,
        gradeArray,
        categoryFilter,
        selectSearch
      );

      return;
    }
    if (tabValue == 2 && bulkList) {
      const newData = sortData.map((item) =>
        item.type === "created_at" ? { ...item, type: "upload_date" } : item
      );
      const filterSort = newData.filter(
        (item) => item?.type !== "reserve_price"
      );
      let sortArry = filterSort?.map((f) => (f.value ? f.type : `-${f.type}`));
      let url = `?paginate=true&limit=10&page=${currentPage}&is_corporate=False${
        searchQuery ? `&${selectSearch || "search_text"}=${searchQuery}` : ""
      }${start && end ? `&start_date=${start}&end_date=${end}` : ""}${
        sortArry && sortArry.length
          ? `&order_by=${JSON.stringify(sortArry)}`
          : ""
      }`;
      getAllBulkList(
        url,
        (body) => {
          setTableData(body?.data);
          setLoad(false);
        },
        () => {
          setLoad(false);
        }
      );

      return;
    }
    if (tabValue == 1) {
      let sortArry = sortData
        ?.filter((item) => !item?.type?.includes("total_cards"))
        ?.map((f) => (f.value ? f.type : `-${f.type}`));
      let url = `?paginate=true&limit=10&page=${currentPage}&is_corporate=False${
        searchQuery ? `&${selectSearch || "search_text"}=${searchQuery}` : ""
      }${start && end ? `&start_date=${start}&end_date=${end}` : ""}${
        sortArry && sortArry.length
          ? `&order_by=${JSON.stringify(sortArry)}`
          : ""
      }${
        priceArray && priceArray.length
          ? `&price_range=${JSON.stringify(priceArray)}`
          : ""
      }${
        gradeArray && gradeArray.length
          ? `&grade=${JSON.stringify(gradeArray)}`
          : ""
      }${
        addressFilter && addressFilter.length
          ? `&address_ids=${JSON.stringify(addressFilter)}`
          : ""
      }${
        categoryFilter && categoryFilter.length
          ? `&category=${JSON.stringify(categoryFilter)}`
          : ""
      }${`&uploaded_from=${JSON.stringify(["NORMAL"])}`}`;

      getCards(
        url,
        (body) => {
          setTableData(body?.data);
          setLoad(false);
        },
        (body) => {
          setLoad(false);
        }
      );
    }
    if (tabValue == 3) {
      let sortArry = sortData?.map((f) => (f.value ? f.type : `-${f.type}`));

      const filterSort =
        sortArry && sortArry.length
          ? sortArry.filter((item) => !item?.type?.includes("reserve_price"))
          : [];
      let url = `?paginate=true&limit=10&page=${currentPage}&is_corporate=False${
        searchQuery ? `&search_text=${searchQuery}` : ""
      }${start && end ? `&start_date=${start}&end_date=${end}` : ""}${
        filterSort && filterSort.length
          ? `&order_by=${JSON.stringify(
              filterSort.filter((f) => !f.includes("reserve_price"))
            )}`
          : ""
      }`;
      getAllCsvList(
        url,
        (body) => {
          setTableData(body?.data);
          setLoad(false);
        },
        (body) => {
          setLoad(false);
        }
      );
    }
  }, [
    dispatch,
    currentPage,
    startDate,
    endDate,
    priceFilter,
    gradeFilter,
    addressFilter,
    categoryFilter,
    currentSetData,
    sortData,
    bulkList,
    csvList,
    currentBulkFile.file,
    currentCsvFile.file,
    tabValue,
    titleDescription,
    tag,
    seller,
    defaultSearch,
  ]);

  useEffect(() => {
    if (scheduleAt) {
      setCurrentSchedule(new Date(scheduleAt));
    } else {
      setCurrentSchedule("");
    }
  }, [scheduleAt]);
  const onClickCsv = (csv) => {
    let end = null;
    let start = startDate ? moment(startDate).format("MM-DD-YYYY") : null;
    end = endDate ? moment(endDate).endOf("day") : null;
    end = endDate ? moment(end).format("MM-DD-YYYY") : null;
    const gradeArray = gradeFilter.map((item) =>
      item.replace("AUTHENTIC", "0")
    );
    let sortArry = sortData
      ?.filter((item) => !item?.type?.includes("total_cards"))
      ?.map((f) => (f.value ? f.type : `-${f.type}`));

    setLoad(true);

    if (tabValue == 2) {
      setBulkList(false);
      setCurrentPage(1);
      setCurrentBulkFile({
        ...currentBulkFile,
        name: csv.bulk_entry,
        file: csv.bulk_entry,
        isPrivate: csv.is_private_room,
        schedule_at: "",
        seller: csv.created_by_id,
        address_id: csv?.pickup_address_id,
        is_corporate: csv?.is_corporate,
      });
      const data = {
        bulk_name: csv.bulk_entry,
      };
      getBulkCardsList(
        (body) => {
          setTableData(body?.data);
          setCsvCardIsPrivate(body?.data?.is_private_room);
          setUnpublishAll(body?.data?.unpublish_all);
          setPublishAll(body?.data?.publish_all);
          setScheduleAt(body?.data?.scheduled_at);
          setLoad(false);
        },
        () => {
          setLoad(false);
        },
        data,
        1,
        searchQuery,
        start,
        end,
        sortArry,
        priceFilter,
        gradeArray,
        categoryFilter,
        selectSearch
      );
      return;
    }

    toggleCsvList(false);
    setCurrentPage(1);
    setCurrentCsvFile({
      ...currentCsvFile.file,
      name: csv.file_name,
      file: csv.csv_file,
      isPrivate: csv.is_private_room,
      schedule_at: "",
      seller: csv.created_by_id,
      address_id: csv?.pickup_address_id,
      is_corporate: csv?.is_corporate,
    });
    const data = {
      file_link: csv.csv_file,
    };
    getCsvFileCards(
      (body) => {
        setTableData(body?.data);
        setCsvCardIsPrivate(body?.data?.is_private_room);
        setUnpublishAll(body?.data?.unpublish_all);
        setPublishAll(body?.data?.publish_all);
        setScheduleAt(body?.data?.scheduled_at);
        setLoad(false);
      },
      () => {
        setLoad(false);
      },
      data,
      1,
      searchQuery,
      start,
      end,
      sortArry,
      priceFilter,
      gradeArray,
      categoryFilter,
      selectSearch
    );
  };
  const onChangefilter = (index) => {
    setLoad(true);

    setCurrentPage(1);
    let filteredArray = [];
    let filter_data = filterData[index].subMenu.filter((f) => f.value == true);
    if (filter_data && filter_data.length) {
      filteredArray = filter_data.map((m) => {
        return m.label.toUpperCase();
      });
    }
    setSearchQuery("");
    if (index == 0) {
      setPriceFilter(filteredArray);
    }
    if (index == 1) {
      setGradeFilter(filteredArray);
    }
    if (index === 3) {
      if (filter_data && filter_data.length) {
        filteredArray = filter_data.map((m) => {
          return m.id;
        });
      }
      setAddressFilter(filteredArray);
    }
  };
  const findIndex = (array, val, key) => {
    return array.findIndex((item) => item[key] === val);
  };
  const onChangeSelection = (val) => {
    setDaysOption(val);
    setSearchQuery("");
    setCurrentPage(1);
    if (val === "custom") {
      setDateDisplay(true);
      setDateRange([null, null]);
    }
    if (val === "week") {
      setDateDisplay(false);
      setDateRange([weekdateFrom, weekdateTo]);
    }
    if (val === "month") {
      setDateDisplay(false);
      setDateRange([monthFrom, monthTo]);
    }
  };

  const onClickEdit = (catagory) => {
    setCurrentCard(catagory);
    setUploadDrawer(true);
    isEditCard(true);
  };

  useEffect(() => {
    let component = "";
    if ((tabValue == 3 && !csvList) || (tabValue == 2 && !bulkList)) {
      component = (
        <div className="table-head-elements">
          <div className="left-content">
            <ArrowBackIcon style={{ cursor: "pointer" }} onClick={goBack} />{" "}
            File name:{" "}
            {tabValue == 2
              ? currentBulkFile?.name
              : currentCsvFile.name
              ? currentCsvFile.name.split("_")[
                  currentCsvFile.name.split("_").length - 1
                ]
              : ""}
          </div>
          <div className="right-contents">
            <div className="right-top-contents">
              {checkedArray && checkedArray.length ? (
                <>
                  {!isPublishSelected ? (
                    <ButtonLight
                      onClick={() =>
                        setAllPublishModal({ type: "publish", value: true })
                      }
                    >
                      Publish selected cards
                    </ButtonLight>
                  ) : (
                    <ButtonLight
                      onClick={() =>
                        setAllPublishModal({ type: "unpublish", value: true })
                      }
                    >
                      Unpublish selected cards
                    </ButtonLight>
                  )}
                </>
              ) : (
                <>
                  {/* Need to test the code in server hence commented */}
                  {/* <FilterDropdown
                    title="Grade"
                    list={[
                      { label: "Past 7 days", value: "week" },
                      { label: "Past 30 days", value: "month" },
                      { label: "Custom dates", value: "custom" },
                    ]}
                    subtitle={"Select date range"}
                    result={(val) => onChangeSelection(val)}
                    type="radio"
                    radioValue={daysOption}
                    isVerticalRadio
                    isCalender
                    CustomComponent={() => (
                      <img className="filter-icon" src={calenderIcon} alt="" />
                    )}
                    extraComponent={
                      daysOption == "custom" ? (
                        <div style={{ position: "relative", marginRight: 10 }}>
                          <ReactDatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                              setDateRange(update);
                              setCurrentPage(1);
                            }}
                            maxDate={new Date()}
                            minDate={new Date("2023-01-01")}
                          />
                        </div>
                      ) : (
                        ""
                      )
                    }
                  /> */}
                  {!showSearchInput && !unpublishAll && (
                    <>
                      <ButtonLight
                        onClick={() => {
                          setOpenUpdateAddressPopup(true);
                        }}
                      >
                        Update Pickup Address
                      </ButtonLight>
                      <ButtonLight
                        onClick={() => {
                          setOpenUpdateInventoryTagPopup(true);
                        }}
                      >
                        Update Inventory Tag
                      </ButtonLight>
                    </>
                  )}
                  <MultilevelDropdown
                    data={filterData}
                    setFilterData={setFilterData}
                    onSubmitFilter={onChangefilter}
                    resetdata={resetdata}
                    allCatagories={allCatagories}
                    INITIAL_FILTER={BULK_CSV_INITIAL_FILTER}
                    searchData={
                      allCatagories && allCatagories.length
                        ? allCatagories
                            .filter((f) => f.status)
                            .map((cat) => {
                              return {
                                label: cat.category_name,
                                value: false,
                                id: cat.id,
                              };
                            })
                        : []
                    }
                    setCategoryFilter={setCategoryFilter}
                  />
                  <SearchInput
                    viewIcon
                    isSelect
                    onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery}
                    onSearch={onSearch}
                    setSelectSearch={(val) => setSelectSearch(val)}
                    radioValue={selectSearch}
                    onCancle={() => onReset(false)}
                    columns={[
                      {
                        label: "Title & Description",
                        value: "title_description",
                        placeholder: "Title / Description",
                      },
                      {
                        label: "Tag",
                        value: "inventory_tag",
                        placeholder: "Tag name",
                      },
                    ]}
                    showInputField={showSearchInput}
                    setShowInputField={setShowSearchInput}
                    source="requests"
                  />
                </>
              )}
              <Button
                className="reset-button"
                variant="text"
                onClick={() => onReset(false)}
              >
                Reset all
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      component = (
        <div className="table-head-elements">
          <div className="left-content">
            <span>Cards</span>
          </div>
          <div className="right-contents">
            <div className="right-top-contents">
              {checkedArray && checkedArray.length ? (
                <>
                  {!isPublishSelected ? (
                    <ButtonLight
                      onClick={() =>
                        setAllPublishModal({ type: "publish", value: true })
                      }
                    >
                      Publish selected cards
                    </ButtonLight>
                  ) : (
                    <ButtonLight
                      onClick={() =>
                        setAllPublishModal({ type: "unpublish", value: true })
                      }
                    >
                      Unpublish selected cards
                    </ButtonLight>
                  )}
                </>
              ) : (
                <>
                  <FilterDropdown
                    title="Date range"
                    list={[
                      { label: "Past 7 days", value: "week" },
                      { label: "Past 30 days", value: "month" },
                      { label: "Custom dates", value: "custom" },
                    ]}
                    subtitle={"Select date range"}
                    result={(val) => onChangeSelection(val)}
                    type="radio"
                    height="225px"
                    radioValue={daysOption}
                    isVerticalRadio
                    isCalendar
                    dateRange={dateRange}
                    extraComponent={
                      daysOption == "custom" ? (
                        <div
                          style={{
                            position: "relative",
                            marginRight: 10,
                            marginLeft: 8,
                            marginTop: 6,
                          }}
                        >
                          <ReactDatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                              setDateRange(update);
                              setCurrentPage(1);
                            }}
                            maxDate={new Date()}
                            minDate={new Date("2023-01-01")}
                          />
                        </div>
                      ) : (
                        ""
                      )
                    }
                  />
                  {tabValue == 1 && (
                    <MultilevelDropdown
                      data={filterData}
                      setFilterData={setFilterData}
                      onSubmitFilter={onChangefilter}
                      resetdata={resetdata}
                      allCatagories={allCatagories}
                      INITIAL_FILTER={INITIAL_FILTER}
                      searchData={
                        allCatagories && allCatagories.length
                          ? allCatagories
                              .filter((f) => f.status)
                              .map((cat) => {
                                return {
                                  label: cat.category_name,
                                  value: false,
                                  id: cat.id,
                                };
                              })
                          : []
                      }
                      setCategoryFilter={setCategoryFilter}
                      personalAddresses={personalAddresses}
                    />
                  )}
                  {tabValue == 1 && (
                    <SearchInput
                      viewIcon
                      isSelect
                      onChange={(e) => setSearchQuery(e.target.value)}
                      value={searchQuery}
                      onSearch={onSearch}
                      setSelectSearch={(val) => setSelectSearch(val)}
                      radioValue={selectSearch}
                      onCancle={() => onReset(false)}
                      columns={[
                        {
                          label: "Title & Description",
                          value: "title_description",
                          placeholder: "Title / Description",
                        },
                        {
                          label: "Tag",
                          value: "inventory_tag",
                          placeholder: "Tag name",
                        },
                      ]}
                    />
                  )}
                </>
              )}
              <Button
                className="reset-button"
                variant="text"
                onClick={() => onReset(false)}
              >
                Reset all
              </Button>
            </div>
          </div>
        </div>
      );
    }
    setTableHeader(component);
  }, [
    tabValue,
    csvList,
    allCatagories,
    filterData,
    daysOption,
    dateDisplay,
    startDate,
    endDate,
    searchQuery,
    bulkList,
    currentBulkFile,
    currentCsvFile,
    checkedArray,
    isPublish,
    csvCardIsPrivate,
    updateCsvSchedule,
    currentSchedule,
    selectSearch,
    showSearchInput,
    unpublishAll,
  ]);

  const goBack = () => {
    if (tabValue == 2) {
      setBulkList(true);
      setCurrentCsvFile({
        name: "",
        file: "",
        isPrivate: "",
        schedule_at: "",
        seller: "",
        address_id: "",
        is_corporate: "",
      });
    } else {
      toggleCsvList(true);
      setCurrentCsvFile({
        name: "",
        file: "",
        isPrivate: "",
        schedule_at: "",
        seller: "",
        address_id: "",
        is_corporate: "",
      });
    }
    setSearchQuery("");
    setSelectSearch("");
    const newFilter = filterData.map((item) => ({
      ...item,
      subMenu: item?.subMenu?.map((subItem) => ({
        ...subItem,
        value: false,
      })),
    }));
    setFilterData(newFilter);
    setPriceFilter([]);
    setGradeFilter([]);
    setDateDisplay(false);
    setAddressFilter([]);
    setCategoryFilter([]);
    setReset(!resetdata);
    setLoad(false);
    setCheckedArray([]);
    setCheckAllCards(false);
    setPublishRadio("");
    setIsPublishSelected("");
    setCurrentPage(1);
    getCurrentSetData(!currentSetData);
  };

  const resetSearchQueries = () => {
    setTitleDescription("");
    setTag("");
    setSeller("");
    setDefaultSearch("");
  };

  const searchTypeHandlers = {
    title_description: () => {
      resetSearchQueries();
      setTitleDescription(searchQuery);
    },
    inventory_tag: () => {
      resetSearchQueries();
      setTag(searchQuery);
    },
    seller: () => {
      resetSearchQueries();
      setSeller(searchQuery);
    },
  };

  const onSearch = () => {
    setCurrentPage(1);
    if (searchTypeHandlers[selectSearch]) searchTypeHandlers[selectSearch]();
    else {
      resetSearchQueries();
      setDefaultSearch(searchQuery);
    }
    onReset(true);
  };
  useEffect(() => {
    setSortData(
      tabValue == 1
        ? [
            { type: "created_at", value: false },
            { type: "reserve_price", value: false },
          ]
        : [
            { type: "created_at", value: false },
            { type: "reserve_price", value: false },
            { type: "total_cards", value: false },
          ]
    );
    toggleCsvList(true);
    setBulkList(true);
    setSearchQuery("");
    setSelectSearch("");
    const newFilter = filterData.map((item) => ({
      ...item,
      subMenu: item?.subMenu?.map((subItem) => ({
        ...subItem,
        value: false,
      })),
    }));
    setFilterData(newFilter);
    setPriceFilter([]);
    setGradeFilter([]);
    setDateDisplay(false);
    setAddressFilter([]);
    setCategoryFilter([]);
    setReset(!resetdata);
    setLoad(false);
    setCheckedArray([]);
    setCheckAllCards(false);
    setPublishRadio("");
    setIsPublishSelected("");
  }, [tabValue]);

  const onReset = (isSearch) => {
    let end = null;
    let start = startDate ? moment(startDate).format("MM-DD-YYYY") : null;
    end = endDate ? moment(endDate).endOf("day") : null;
    end = endDate ? moment(end).format("MM-DD-YYYY") : null;
    const gradeArray = gradeFilter.map((item) =>
      item.replace("AUTHENTIC", "0")
    );
    let sortArry = sortData
      ?.filter((item) => !item?.type?.includes("total_cards"))
      ?.map((f) => (f.value ? f.type : `-${f.type}`));

    setLoad(true);
    if (!isSearch) {
      setSearchQuery("");
      setSelectSearch("");
      if (tabValue == 2 && !bulkList) {
        const data = {
          bulk_name: currentBulkFile.file,
        };

        getBulkCardsList(
          (body) => {
            setTableData(body?.data);
            setLoad(false);
            setUnpublishAll(body?.data?.unpublish_all);
            setPublishAll(body?.data?.publish_all);
            setScheduleAt(body?.data?.scheduled_at);
          },
          () => {
            setLoad(false);
          },
          data,
          1,
          searchQuery,
          start,
          end,
          sortArry,
          priceFilter,
          gradeArray,
          categoryFilter,
          selectSearch
        );
      } else if (tabValue == 2 && bulkList) {
        let url = `?paginate=true&limit=10&page=1&is_corporate=False&order_by=${JSON.stringify(
          ["-upload_date", "-total_cards"]
        )}`;
        getAllBulkList(
          url,
          (body) => {
            setTableData(body?.data);
            setLoad(false);
          },
          () => {
            setLoad(false);
          }
        );
      } else if (tabValue == 3 && !csvList) {
        const data = {
          file_link: currentCsvFile.file,
        };
        getCsvFileCards(
          (body) => {
            setTableData(body?.data);
            setCsvCardIsPrivate(body?.data?.is_private_room);
            setUnpublishAll(body?.data?.unpublish_all);
            setPublishAll(body?.data?.publish_all);
            setScheduleAt(body?.data?.scheduled_at);
          },
          () => {},
          data,
          1,
          searchQuery,
          start,
          end,
          sortArry,
          priceFilter,
          gradeArray,
          categoryFilter,
          selectSearch
        );
      } else if (tabValue == 3) {
        let url = `?paginate=true&limit=10&page=1&is_corporate=False&order_by=${JSON.stringify(
          ["-created_at", "-total_cards"]
        )}`;

        getAllCsvList(
          url,
          (body) => {
            setTableData(body?.data);
            setLoad(false);
          },
          (body) => {
            setLoad(false);
          }
        );
      }
    }
    setCurrentPage(1);
    setDateRange([null, null]);
    setDaysOption("");
    const newFilter = filterData.map((item) => ({
      ...item,
      subMenu: item?.subMenu?.map((subItem) => ({
        ...subItem,
        value: false,
      })),
    }));
    setFilterData(newFilter);
    setPriceFilter([]);
    setGradeFilter([]);
    setDateDisplay(false);
    setAddressFilter([]);
    setCategoryFilter([]);
    setReset(!resetdata);
    setLoad(false);
    setCheckedArray([]);
    setCheckAllCards(false);
    setPublishRadio("");
    setIsPublishSelected("");
  };

  const handleCsvTab = () => {
    if (tabValue == 3) {
      if (csvList) {
        return true;
      } else {
        return false;
      }
    }
    if (tabValue == 2) {
      if (bulkList) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleFileUpload = (event) => {
    if (event.target.files[0]?.type != "text/csv") {
      toast.error("Please select CSV file!");
      return;
    }
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE_BYTES) {
      toast.error("File size should be less than 5mb!");
      return;
    }

    setAdminCsvError([]);
    setAdminCsvData({ ...adminCsvData, file: event.target.files[0] });

    if (file) {
      Papa.parse(file, {
        header: false,
        complete: handleFileParsingResult,
      });
    }
  };
  const handleFileParsingResult = (result) => {
    const isAllBlank = result.data.every((row) =>
      row.every((cell) => cell === "")
    );
    const isSecondRowEmpty = result?.data[1].every((cell) => cell === "");

    let isOrderCorrect = true;
    result.data.map((row, index) => {
      if (index === 0 || index === 1) {
        if (index === 0)
          isOrderCorrect =
            isOrderCorrect &&
            (row.every((cell) => cell === "") ||
              row.every((cell) => cell !== ""));
        if (index === 1)
          isOrderCorrect = isOrderCorrect && row.every((cell) => cell === "");
      } else {
        isOrderCorrect = isOrderCorrect && row.every((cell) => cell !== "");
      }
    });

    if (isAllBlank || isSecondRowEmpty || isOrderCorrect) {
      toast.error(
        !isOrderCorrect
          ? "CSV data should start from column A and row 2"
          : "The file is empty"
      );
      setAdminCsvError([]);
      setAdminCsvData(INITIAL_CSV);
      return;
    }

    const header = result.data[0];
    const parsedData = result.data.map((row) =>
      row.reduce((acc, value, index) => {
        acc[header[index]] = value;
        return acc;
      }, {})
    );

    let msg = "";
    let missingIndex = "";
    let isMissing = false;
    const idealAdminColumns =
      // userDetail.is_superuser
      //   ? ["Year", "FrontImage", "BackImage", "Title"]
      //   :
      [
        "title",
        "category",
        "condition",
        "grade",
        "card_year",
        "reserve_price",
        "front_image",
        "back_image",
        "description",
      ];
    const filteredColumn = idealAdminColumns.filter(
      (element) => !header.includes(element)
    );

    if (filteredColumn && filteredColumn.length !== 0) {
      setAdminCsvError(filteredColumn);
      setAdminCsvData({ ...adminCsvData, file: "" });
      setColumnData([]);

      setCurrentCsvData([]);
      csvRef.current.value = "";

      return;
    }
    // if (!userDetail.is_superuser) {
    const missingProperties = parsedData.slice(1, -1).find((card, index) => {
      if (
        !card?.title ||
        !card?.title?.match(/^\w+(?:\s?[\w\-!@#$%^&*()_+.]+)*$/) ||
        card?.title?.length > 20
      ) {
        missingIndex = index;
        isMissing = !card?.title;
        msg = "title";
      }
      if (
        !card.category ||
        !categories?.includes(card.category.toLowerCase())
      ) {
        missingIndex = index;
        isMissing = !card.catagory;
        msg = "category";
      }
      if (
        !card.condition ||
        (card.condition?.toLowerCase() != "raw" &&
          card.condition?.toLowerCase() != "slab")
      ) {
        missingIndex = index;
        isMissing = !card.condition;
        msg = "condition";
      }
      if (
        !card.card_year ||
        isNaN(Number(card.card_year)) ||
        Number(card.card_year) < 1900 ||
        Number(card.card_year) > new Date().getFullYear()
      ) {
        missingIndex = index;
        isMissing = !card.card_year;
        msg = "card_year";
      }
      if (
        !card.grade ||
        (card.condition?.toLowerCase() == "raw" &&
          Number(card.grade) != 0 &&
          card.grade.toLowerCase() != "authentic") ||
        (card.condition?.toLowerCase() == "slab" &&
          (isNaN(Number(card.grade)) ||
            Number(card.grade) < 1 ||
            Number(card.grade) > 10))
      ) {
        missingIndex = index;
        isMissing = !card.grade;
        msg = "grade";
      }
      if (
        !card.reserve_price ||
        isNaN(Number(card.reserve_price)) ||
        Number(card.reserve_price) < 1 ||
        Number(card.reserve_price) >= 1000000
      ) {
        missingIndex = index;
        isMissing = !card.reserve_price;
        msg = "reserve_price";
      }
      if (
        !card.front_image ||
        !card.front_image.match(
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
        )
      ) {
        missingIndex = index;
        isMissing = !card.front_image;
        msg = "front_image";
      }
      if (
        !card.back_image ||
        !card.back_image.match(
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
        )
      ) {
        missingIndex = index;
        isMissing = !card.back_image;
        msg = "back_image";
      }
      if (!card.description) {
        missingIndex = index;
        isMissing = !card.description;
        msg = "description";
      }
      return (
        !card.title ||
        !card.title?.match(/^\w+(?:\s?[\w\-!@#$%^&*()_+.]+)*$/) ||
        card?.title?.length > 20 ||
        !card.category ||
        !categories?.includes(card.category.toLowerCase()) ||
        !card.condition ||
        (card.condition?.toLowerCase() != "raw" &&
          card.condition?.toLowerCase() != "slab") ||
        !card.card_year ||
        isNaN(Number(card.card_year)) ||
        Number(card.card_year) < 1900 ||
        Number(card.card_year) > new Date().getFullYear() ||
        !card.grade ||
        (card.condition?.toLowerCase() == "raw" &&
          Number(card.grade) != 0 &&
          card.grade.toLowerCase() != "authentic") ||
        (card.condition?.toLowerCase() == "slab" &&
          (isNaN(Number(card.grade)) ||
            Number(card.grade) < 1 ||
            Number(card.grade) > 10)) ||
        !card.reserve_price ||
        isNaN(Number(card.reserve_price)) ||
        Number(card.reserve_price) < 1 ||
        Number(card.reserve_price) >= 1000000 ||
        !card.front_image ||
        !card.front_image.match(
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
        ) ||
        !card.back_image ||
        !card.back_image.match(
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
        ) ||
        !card.description
      );
    });

    if (JSON.stringify(idealAdminColumns) !== JSON.stringify(header)) {
      setAdminCsvData({ ...adminCsvData, file: "" });
      setColumnData([]);

      setCurrentCsvData([]);
      csvRef.current.value = "";
      toast.error(
        "Please check the structure of uploaded CSV. It does not matches the structure of reference template provided."
      );
    } else if (missingProperties) {
      if (isMissing)
        toast.error(`Please check ${msg} on  row no. ${missingIndex + 2}`);
      else if (!isMissing && msg === "title")
        toast.error(
          `Please check ${msg} on  row no. ${
            missingIndex + 2
          }. ${msg} cannot exceed 20 characters and can have a whitespace between words only.`
        );
      else if (!isMissing && msg === "category")
        toast.error(
          `Please check ${msg} on  row no. ${
            missingIndex + 2
          }. ${msg} can have the categories available on the application.`
        );
      else if (!isMissing && msg === "condition")
        toast.error(
          `Please check ${msg} on  row no. ${
            missingIndex + 2
          }. ${msg} can have the values Raw and Slab only.`
        );
      else if (!isMissing && msg === "grade")
        toast.error(
          `Please check ${msg} on  row no. ${
            missingIndex + 2
          }. ${msg} can only have 0 or Authentic if the condition is Raw and a number between 1 to 10 if the condition is Slab.`
        );
      else if (!isMissing && msg === "card_year")
        toast.error(
          `Please check ${msg} on  row no. ${
            missingIndex + 2
          }. ${msg} cannot have future year.`
        );
      else if (!isMissing && msg === "reserve_price")
        toast.error(
          `Please check ${msg} on  row no. ${
            missingIndex + 2
          }. ${msg} should be between 1 to 999999.`
        );
      else if (!isMissing && msg === "front_image")
        toast.error(
          `Please check ${msg} on  row no. ${
            missingIndex + 2
          }. ${msg} should contain a URL.`
        );
      else if (!isMissing && msg === "back_image")
        toast.error(
          `Please check ${msg} on  row no. ${
            missingIndex + 2
          }. ${msg} should contain a URL.`
        );
      setAdminCsvData({ ...adminCsvData, file: "" });
      setColumnData([]);

      setCurrentCsvData([]);
      csvRef.current.value = "";
      return;
    }
    setColumnData(result.data[0]);

    setCurrentCsvData(result.data);
    // if (!userDetail.is_superuser) {
    const categoryCol = result.data
      .map((row) => row[1])
      .filter((element, index) => element !== undefined && index !== 0);
    const uniqueSet = new Set(categoryCol);
    setSellerCsvCategory([...uniqueSet]);
    // }
  };
  const getTagId = (data) => {
    if (allTagList && allTagList.length && data?.inventory_tag) {
      let found = allTagList.find(
        (tag) => tag.tag_name === data?.inventory_tag
      );
      if (found) {
        return found.id;
      }
    }
    return "";
  };
  const onSubmitCsv = async (draft) => {
    setLoad(true);
    let newdata = currentCsvData;
    newdata = currentCsvData.map((row, index) => {
      if (index !== 0) {
        row[3] = row[3]?.toLowerCase() == "authentic" ? 0 : row[3];
      }
      return row;
    });
    // }

    const newCsv = Papa.unparse(newdata, { header: false });

    const blob = new Blob([newCsv]);
    const s3 = new S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: `${process.env.REACT_APP_S3_ENVIROMENT}/${
        adminCsvData.seller
      }__${moment(new Date()).format("DD-MM-YYYY_HH:mm:ss")}_${
        adminCsvData.file.name
      }`,
      Body: blob,
    };

    try {
      const data = await s3.upload(params).promise();
      setAdminCsvData({
        ...adminCsvData,
        fileLink: data.Location,
        roomName: adminCsvData.roomName
          ? adminCsvData.roomName
          : adminCsvData.defaultRoom,
      });

      const csvData = {
        file_link: data.Location,
        is_admin: false,
        is_private: adminCsvData.privateRoomCheck,
        seller: userDetail.id,
        is_published: draft || adminCsvData.csvDate ? false : true,
        is_categorised:
          adminCsvData.isStandard == null
            ? false
            : adminCsvData.isStandard == "true"
            ? false
            : true,
        address_id: adminCsvData.address_id,
        is_corporate: adminCsvData.is_corporate,
      };
      if (!adminCsvData.is_corporate)
        csvData.inventory_tag = getTagId(adminCsvData);
      if (!adminCsvData.is_corporate && adminCsvData.csvDate) {
        csvData.schedule_at = moment
          .utc(adminCsvData.csvDate)
          .format("YYYY-MM-DD-HH-mm");
      }
      postCSV(
        csvData,
        (body) => {
          if (!body?.data?.success) {
            const errorMessage =
              body?.data?.message || "An unexpected error occurred";
            toast.error(errorMessage);
            setAdminCsvData(INITIAL_CSV);
            return;
          }
          if (adminCsvData?.is_corporate) {
            setCorporateCardsTabValue(2);
            setRoomType(4);
          } else {
            onChangetab(3);
          }
          toggleCsvList(true);
          toast.success(body?.data?.message);
          setAdminCsv(false);
          setAdminCsvData(INITIAL_CSV);
          dispatch(getAllInventoryTag());

          let url = `?paginate=true&limit=10&page=1&is_corporate=False&order_by=${JSON.stringify(
            ["-created_at", "-total_cards"]
          )}`;

          getAllCsvList(
            url,
            (body) => {
              if (tabValue === 3) setTableData(body?.data);
            },
            (body) => {}
          );
          setLoad(false);
        },
        (body) => {
          toast.error(body.message);
          setLoad(false);
        }
      );
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoad(false);
    } finally {
      setLoad(false);
    }
    setLoad(false);
  };

  const onChangeMultiInput = (value, type, index) => {
    let cardVal = [...multiCard];
    let error = [...multiError];
    // error[index][type] = false;
    let newCardVal = cardVal.map((item, idx) => {
      if (idx === index) {
        // If this is the index we're updating, create a new object with the updated value
        return { ...item, [type]: value };
      }
      // Otherwise, return the original object
      return item;
    });
    let errors = error.map((item, idx) => {
      if (idx === index) {
        // If this is the index we're updating, create a new object with the updated value
        return { ...item, [type]: false };
      }
      // Otherwise, return the original object
      return item;
    });
    // cardVal[index][type] = value;
    setMultiCard(newCardVal);
    setMultiErrors(errors);
  };
  const onChangeMultiFile = (e, type, index) => {
    let cardVal = [...multiCard];
    let error = [...multiError];
    error[index][type] = false;
    let file = e.target.files[0];
    let strAr = file.name.split(".");
    let lastChar = strAr[strAr.length - 1];
    let imageFormats = ["png", "jpeg", "jpg", "webp"];
    if (file.size > MAX_FILE_SIZE_BYTES) {
      toast.error("File size should be less than 5mb!");
      return;
    }
    if (imageFormats.includes(lastChar?.toLowerCase())) {
      cardVal[index][`${[type]}_name`] = e.target.files[0];
      getBase64(file)
        .then((result) => {
          file["base64"] = result;
          cardVal[index][type] = result;
        })
        .catch((err) => {
          console.log(err);
        });
      setCardState(cardVal);
    } else {
      toast.error("Please select image file");
    }
  };
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result.split("base64,")[1];
        resolve(baseURL);
      };
    });
  };
  const onAddMultiCard = () => {
    let cards = [...multiCard];
    let error = [...multiError];

    error.push(INITIAL_ERROR);
    cards.push(INITIAL_STATE);
    setMultiCard(cards);
    setMultiErrors(error);
  };
  const onRemoveMultiCard = (index) => {
    let cards = [...multiCard];
    let error = [...multiError];

    cards.splice(index, 1);
    error.splice(index, 1);
    setMultiCard(cards);
    setMultiErrors(error);
  };
  const isValidMulti = (isPrivate) => {
    if (!bulkState?.address_id) setMultiAddressError(true);
    let error = [...multiError];
    for (let i = 0; i < multiCard.length; i++) {
      if (multiCard[i].title.trim() === "") {
        error[i].title = "Please enter title";

        setMultiErrors(error);
      } else if (multiCard[i].title?.length > 20) {
        error[i].title = "Title cannot exceed 20 characters";

        setMultiErrors(error);
      } else {
        error[i].title = false;
      }
      if (!multiCard[i].category) {
        error[i].category = true;

        setMultiErrors(error);
      } else {
        error[i].category = false;
      }
      if (!multiCard[i].condition) {
        error[i].condition = true;

        setMultiErrors(error);
      } else {
        error[i].condition = false;
      }
      if (!multiCard[i].card_year) {
        error[i].card_year = true;

        setMultiErrors(error);
      } else {
        error[i].card_year = false;
      }
      if (
        multiCard[i].condition?.toLowerCase() !== "raw" &&
        (multiCard[i].grade === "" || multiCard[i].grade === undefined)
      ) {
        error[i].grade = true;

        setMultiErrors(error);
      } else {
        error[i].grade = false;
      }

      if (multiCard[i].front_image === undefined) {
        error[i].front_image = true;

        setMultiErrors(error);
      } else {
        error[i].front_image = false;
      }
      if (
        multiCard[i].reserve_price === null ||
        multiCard[i].reserve_price < 1 ||
        isNaN(multiCard[i].reserve_price) ||
        multiCard[i].reserve_price >= 1000000
      ) {
        error[i].reserve_price = true;

        setMultiErrors(error);
      } else {
        error[i].reserve_price = false;
      }
      if (multiCard[i].back_image === undefined) {
        error[i].back_image = true;

        setMultiErrors(error);
      } else {
        error[i].back_image = false;
      }

      if (multiCard[i].description.trim() === "") {
        error[i].description = true;

        setMultiErrors(error);
      } else {
        error[i].description = false;
      }
    }

    onAddBulk(error, isPrivate);
  };
  const onAddBulk = (error, isPrivate) => {
    let final = error.filter((obj) => Object.values(obj).includes(true));
    if (final.length === 0 && !multiAddressError) {
      setLoad(true);

      let bulkData = [];

      for (let i = 0; i < multiCard.length; i++) {
        bulkData.push({
          front_image: multiCard[i].front_image,
          back_image: multiCard[i].back_image,
          title: multiCard[i].title,
          description: multiCard[i].description,
          category: parseInt(multiCard[i].category),
          grade: multiCard[i].grade,
          condition: multiCard[i].condition,
          card_year: parseInt(multiCard[i].card_year),
          reserve_price: multiCard[i].reserve_price,
          published: bulkState.is_corporate ? false : isPrivate,
          inventory_tag: bulkState.is_corporate ? "" : getTagId(multiCard[i]),
          room_type: privateRoomType,
          bulk_name: `bulk_${moment(new Date()).format("DD_MM_YYYY_HH_mm_ss")}`,
        });
      }
      const data = {
        private_room: isPrivate,
        data: bulkData,
        address_id: bulkState.address_id,
        is_corporate: bulkState.is_corporate,
      };
      const handleSuccess = (body) => {};

      axios
        .post(`${API_DOMAIN}/api/card/bulk_upload`, data, {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.bytes) {
              setUploadProgress(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
        })
        .then((res) => {
          if (res.data && res.data.success === false) {
            toast.warn(res.data.message);
            setLoad(false);
            return;
          }
          // Need to test on the server hence commenting now
          // let url = `?paginate=true&limit=10&page=1${`&uploaded_from=${
          //   tabValue == 1
          //     ? JSON.stringify(["NORMAL"])
          //     : JSON.stringify(["BULK"])
          // }`}`;
          // getCards(
          //   url,
          //   (body) => {
          //     setTableData(body?.data);
          //   },
          //   (body) => {}
          // );
          if (bulkState?.is_corporate) {
            setCorporateCardsTabValue(2);
            setRoomType(4);
          } else {
            onChangetab(2);
          }
          setBulkList(true);
          getCurrentSetData(!currentSetData);
          setAddOpenMulti(false);
          setMultiAddAlert(false);
          setMultiCard([INITIAL_STATE]);
          setMultiErrors([INITIAL_ERROR]);
          setMultiAddressError(false);

          setIsEdit(false);
          setLoad(false);
          dispatch(getAllInventoryTag());
          toast.success(res.data.message);
          handleRemoveBulkData();
        })
        .catch((err) => {
          setLoad(false);
          toast.error("Something went wrong, Please try again !");
        });
    } else {
      toast.error("Please fill all required fields");
    }
  };

  const onSortData = (index, val) => {
    const newData = [...sortData];
    const found = findIndex(newData, val, "type");

    newData[found].value = !newData[found].value;

    const indexElement = newData.findIndex((obj) => obj.type === val);
    const element = newData.splice(indexElement, 1)[0];
    newData.unshift(element);
    setSortData(newData);
    setCurrentPage(1);
  };

  const onPublish = (type) => {
    if (type && checkedArray && checkedArray.length == 0) {
      toast.error("Please select atleast one card");
      return;
    }
    setLoad(true);
    setCheckAllCards(false);

    const data = type
      ? [...checkedArray]
      : [
          {
            card_id: cardState.id,
            comment: publishComment,
            schedule_at: cardState.schedule_at
              ? moment.utc(cardState.schedule_at).format("YYYY-MM-DD-HH-mm")
              : "",
          },
        ];
    const handleSuccess = (body) => {
      if (!body.success) {
        setLoad(false);

        toast.error(body.message);
        return;
      }
      getCurrentSetData(!currentSetData);
      toast.success(body.message);
      setCardState(INITIAL_STATE);
      setPublishComment("");
      setUnpublishModal(false);
      setCheckedArray([]);
      setIsPublishSelected("");
      setPublishRadio("");
      setAllPublishModal({ type: "", value: false });
    };
    const handleFail = () => {
      setLoad(false);

      toast.success("Something went wrong, Please try again !");
    };
    if (isPublish.type == "unpublish" || type == "unpublish") {
      const unpublishData = type
        ? [...checkedArray]
        : [
            {
              card_id: cardState.id,
              comment: publishComment,
            },
          ];

      unpublishBulkCard(
        unpublishData,
        (body) => {
          handleSuccess(body.data);
        },
        (body) => {
          toast.error(body.data.message);
          setLoad(false);
        }
      );
    }
    if (isPublish.type == "publish" || type == "publish") {
      publishBulkCard(
        data,
        (body) => {
          handleSuccess(body.data);
        },
        (body) => {
          toast.error(body.data.message);
          setLoad(false);
        }
      );
    }
  };
  {
    /* As per requirements the functionality is removed for this release,Might need in next one, hence commenting */
  }
  // useEffect(() => {
  //   if (multiCard.length < PRIVATE_ROOM_COUNT) {
  //     setIsPrivateRoom(false);
  //   }
  // }, [multiCard]);

  const onViewcard = (id, isEdit) => {
    getCards(
      `?card_id=${id}&is_corporate=False`,
      (body) => {
        setCurrentCard(body?.data?.data[0]);
        if (isEdit) {
          setUploadDrawer(true);
          isEditCard(true);
        } else {
          setIsView(true);
        }
      },
      () => {}
    );
  };
  const onDelete = () => {
    setLoad(true);
    const handleSuccess = () => {
      setDelete(false);
      setLoad(false);
      if (tabValue == 2 && !bulkList && tableData?.data?.length == 1) {
        setBulkList(!bulkList);
      }
      toast.success("Card deleted successfully !!");
      getCurrentSetData(!currentSetData);
    };
    let url = "?card_id=" + cardState.id;
    deleteCard(url, handleSuccess, () => {
      setLoad(false);
    });
  };
  const handleChangeChecked = (event, card) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedArray([
        ...checkedArray,
        { card_id: parseInt(card.id), comment: "", schedule_at: "" },
      ]);
    } else {
      setCheckedArray(checkedArray.filter((obj) => obj.card_id !== card.id));
    }
    if (card?.published && isChecked) setIsPublishSelected(true);
    else setIsPublishSelected(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRadioChange = (event) => {
    setCheckedArray([]);
    setPublishRadio(event.target.value);
    checkAll(event.target.value);
    handleClose();
  };

  const checkAll = (type) => {
    if (type === "publish") {
      setCheckAllCards(true);
      setIsPublishSelected(true);
      tableData?.data?.map((card) => {
        if (card?.published)
          setCheckedArray((prev) => [
            ...prev,
            { card_id: parseInt(card.id), comment: "", schedule_at: "" },
          ]);
      });
    } else if (type === "unpublish") {
      setCheckAllCards(true);
      setIsPublishSelected(false);
      tableData?.data?.map((card) => {
        if (!card?.published)
          setCheckedArray((prev) => [
            ...prev,
            { card_id: parseInt(card.id), comment: "", schedule_at: "" },
          ]);
      });
    }
  };

  const onPublishCsvCards = () => {
    let end = null;
    let start = startDate ? moment(startDate).format("MM-DD-YYYY") : null;
    end = endDate ? moment(endDate).endOf("day") : null;
    end = endDate ? moment(end).format("MM-DD-YYYY") : null;
    const gradeArray = gradeFilter.map((item) =>
      item.replace("AUTHENTIC", "0")
    );
    let sortArry = sortData
      ?.filter((item) => !item?.type?.includes("total_cards"))
      ?.map((f) => (f.value ? f.type : `-${f.type}`));

    setLoad(true);
    const data = {
      csv_file_link: currentCsvFile.file,
      status: isPublish.type,
      seller: currentCsvFile.seller,
    };

    publishAllCsvCard(
      data,
      (body) => {
        toast.success(body.data.message);
        getCsvFileCards(
          (body) => {
            setTableData(body?.data);
            setLoad(false);
            setPublish({ ...isPublish, value: false, type: "" });
            setCsvCardIsPrivate(body?.data?.is_private_room);
            setUnpublishAll(body?.data?.unpublish_all);
            setPublishAll(body?.data?.publish_all);
            setScheduleAt(body?.data?.scheduled_at);
          },
          () => {
            setLoad(false);
          },
          { file_link: currentCsvFile.file },
          1,
          searchQuery,
          start,
          end,
          sortArry,
          priceFilter,
          gradeArray,
          categoryFilter,
          selectSearch
        );
      },
      (body) => {
        toast.error(body.message);
      }
    );
  };
  const onSubmitCsvScheule = () => {
    if (tabValue == 2) {
      const data = {
        bulk_entry: currentBulkFile?.name,
        schedule_at: moment.utc(currentSchedule).format("YYYY-MM-DD-HH-mm"),
      };
      updateBulkSchedule(
        data,
        (body) => {
          if (body?.data?.success) {
            toast.success(body?.data?.message);
            setUpdateSchedule(false);
            setCurrentSchedule(currentSchedule);
          }
        },
        () => {}
      );
    } else {
      const data = {
        csv_file_link: currentCsvFile.file,
        schedule_at: moment.utc(currentSchedule).format("YYYY-MM-DD-HH-mm"),
      };
      updateSchedule(
        data,
        (body) => {
          if (body?.data?.success) {
            toast.success(body?.data?.message);
            setAdminCsvData(INITIAL_CSV); //
            setUpdateSchedule(false);
            setCurrentSchedule(currentSchedule);
          }
        },
        () => {}
      );
    }
  };

  const handleRemoveBulkData = () => {
    let cards = { ...INITIAL_STATE };
    cards.front_image_name = "";
    cards.back_image_name = "";
    setMultiCard([cards]);
    const initialValue = Object.keys(INITIAL_ERROR).map(([key]) => {
      return {
        [key]: false,
      };
    });
    setMultiErrors(initialValue);
    setMultiAddressError(false);
  };

  const onClickUploadType = (value) => {
    closeRadioUpload(true);
    switch (value) {
      case "single":
        setUploadDrawer(true);
        break;
      case "bulk":
        setMultiAddAlert(true);
        break;
      case "csv":
        setAdminCsv(true);
        break;
      default:
        setUploadDrawer(true);
        break;
    }
  };

  useEffect(() => {
    var myDiv = document.getElementById("custom-table-container");
    if (myDiv) {
      myDiv.scrollTop = 0;
      setPageIndex(1);
    }
  }, [sortData]);

  const updateBulkAddressDetails = (selectedAddress, isCorporate) => {
    setMultiAddressError(!selectedAddress);
    setBulkState({
      address_id: selectedAddress[0]?.id,
      is_corporate: isCorporate,
    });
  };

  const raiseRequest = () => {
    const requestData = {
      address_id:
        tabValue == 2 ? currentBulkFile.address_id : currentCsvFile.address_id,
      is_corporate:
        tabValue == 2
          ? currentBulkFile.is_corporate
          : currentCsvFile.is_corporate,
      request_type: tabValue == 2 ? "BULK" : "CSV",
      identifier: tabValue == 2 ? currentBulkFile.file : currentCsvFile?.file,
    };
    reRequest(
      requestData,
      (body) => {
        if (body?.data?.success) {
          toast.success("Pickup Address updated successfully!");
          goBack();
        }
      },
      (error) => {
        console.log(error);
        toast.error(error.data.message);
      }
    );
  };

  const editPickupAddressOrInventoryTag = (type) => {
    const data = {
      uploaded_from: tabValue == 2 ? "Bulk" : "CSV",
      name: tabValue == 2 ? currentBulkFile?.file : currentCsvFile?.file,
    };

    type === "address"
      ? (data.address_id =
          tabValue == 2
            ? currentBulkFile?.address_id
            : currentCsvFile?.address_id)
      : (data.inventory_tag =
          tabValue == 2
            ? currentBulkFile?.inventory_tag_id
            : currentCsvFile?.inventory_tag_id);

    editAddressAndInventoryTag(
      data,
      (body) => {
        if (body?.data?.success) {
          toast.success(
            `${
              type === "address" ? "Pickup Address" : "Inventory Tag"
            } updated successfully!`
          );
          if (type === "inventory") {
            let end = null;
            let start = startDate
              ? moment(startDate).format("MM-DD-YYYY")
              : null;
            end = endDate ? moment(endDate).endOf("day") : null;
            end = endDate ? moment(end).format("MM-DD-YYYY") : null;
            const gradeArray = gradeFilter.map((item) =>
              item.replace("AUTHENTIC", "0")
            );

            if (tabValue == 3 && !csvList) {
              let sortArry = sortData
                ?.filter((item) => !item?.type?.includes("total_cards"))
                ?.map((f) => (f.value ? f.type : `-${f.type}`));

              const data = {
                file_link: currentCsvFile.file,
              };
              getCsvFileCards(
                (body) => {
                  setTableData(body?.data);
                  setLoad(false);
                  setCsvCardIsPrivate(body?.data?.is_private_room);
                  setUnpublishAll(body?.data?.unpublish_all);
                  setPublishAll(body?.data?.publish_all);
                  setScheduleAt(body?.data?.scheduled_at);
                },
                () => {
                  setLoad(false);
                },
                data,
                currentPage,
                searchQuery,
                start,
                end,
                sortArry,
                priceFilter,
                gradeArray,
                categoryFilter,
                selectSearch
              );
            }
            if (tabValue == 2 && !bulkList) {
              let sortArry = sortData
                ?.filter((item) => !item?.type?.includes("total_cards"))
                ?.map((f) => (f.value ? f.type : `-${f.type}`));

              const data = {
                bulk_name: currentBulkFile.file,
              };
              getBulkCardsList(
                (body) => {
                  setTableData(body?.data);
                  setLoad(false);
                  setUnpublishAll(body?.data?.unpublish_all);
                  setPublishAll(body?.data?.publish_all);
                  setScheduleAt(body?.data?.scheduled_at);
                },
                () => {
                  setLoad(false);
                },
                data,
                currentPage,
                searchQuery,
                start,
                end,
                sortArry,
                priceFilter,
                gradeArray,
                categoryFilter,
                selectSearch
              );
            }
          } else {
            goBack();
          }
        }
      },
      (error) => {
        console.log(error);
        toast.error(error?.message);
      }
    );
  };

  const handleCancel = () => {
    setAdminCsvData(INITIAL_CSV);
    setAdminCsv(false);
  };

  return (
    <div
      className={`manage-card-container ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      {!load && userInfo
        ? !userInfo?.seller_verified && (
            <div
              className="verification text-xs-medium-12"
              onClick={() => {
                navigate("/profile");
                dispatch(setSettingOptions(2));
              }}
            >
              Verify your seller account to sell your preferred cards!
            </div>
          )
        : ""}
      <PCLiveLoader open={load} />
      <Popup
        openPopup={allPublishModal.value}
        onClose={() => setAllPublishModal({ type: "", value: false })}
        actionButtons={{
          primary: {
            title: "Cancel",
            onClick: () => setAllPublishModal({ type: "", value: false }),
          },
          secondary: {
            title: `Yes,${allPublishModal.type} cards`,
            onClick: () => {
              onPublish(allPublishModal.type);
            },
          },
        }}
        title={`Are you sure you want to ${allPublishModal.type} selected card?`}
        description={`This will not affact previously ${allPublishModal.type} card.`}
      />
      <Popup
        openPopup={openUpdateAddressPopup}
        onClose={() => setOpenUpdateAddressPopup(false)}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => setOpenUpdateAddressPopup(false),
          },
          secondary: {
            title:
              (tabValue == 2 && currentBulkFile?.is_corporate) ||
              (tabValue == 3 && currentCsvFile?.is_corporate)
                ? "Raise request"
                : "Yes, update",
            onClick: () => {
              setOpenUpdateAddressPopup(false);
              if (
                (tabValue == 2 && currentBulkFile?.is_corporate) ||
                (tabValue == 3 && currentCsvFile?.is_corporate)
              )
                raiseRequest();
              else editPickupAddressOrInventoryTag("address");
            },
          },
        }}
        title={"Update pickup address"}
        description={
          "This action will update your pickup address. Do you want to continue?"
        }
        showAddressSelect
        addressId={
          tabValue == 2
            ? currentBulkFile?.address_id
            : currentCsvFile?.address_id
        }
        isCorporate={
          tabValue == 2
            ? currentBulkFile?.is_corporate
            : currentCsvFile?.is_corporate
        }
        setCurrentCard={tabValue == 2 ? setCurrentBulkFile : setCurrentCsvFile}
      />
      <Popup
        openPopup={openUpdateInventoryTagPopup}
        onClose={() => setOpenUpdateInventoryTagPopup(false)}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => {
              setOpenUpdateInventoryTagPopup(false);
              tabValue == 2
                ? setCurrentBulkFile((prev) => {
                    return {
                      ...prev,
                      inventory_tag_name: "",
                      inventory_tag_id: "",
                    };
                  })
                : setCurrentCsvFile((prev) => {
                    return {
                      ...prev,
                      inventory_tag_name: "",
                      inventory_tag_id: "",
                    };
                  });
            },
          },
          secondary: {
            title: "Yes, update",
            onClick: () => {
              setOpenUpdateInventoryTagPopup(false);
              editPickupAddressOrInventoryTag("inventory");
            },
          },
        }}
        title={"Update inventory tag"}
        description={
          "This action will update your inventory tag. Do you want to continue?"
        }
        showInventoryTag
        inventoryTag={
          tabValue == 2
            ? currentBulkFile?.inventory_tag_name
            : currentCsvFile?.inventory_tag_name
        }
        setCurrentCard={tabValue == 2 ? setCurrentBulkFile : setCurrentCsvFile}
        allTagList={allTagList}
      />
      <Modal
        open={updateCsvSchedule}
        onClose={() => setUpdateSchedule(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <div className="mb-2">
            File name :{" "}
            <strong className="text-sm-medium-14">
              {tabValue == 2
                ? currentBulkFile?.name
                : currentCsvFile.name
                ? currentCsvFile.name.split("_")[
                    currentCsvFile.name.split("_").length - 1
                  ]
                : ""}
            </strong>
            <br />
            <br />
            <ReactDatePicker
              className="datepicker-class-calender"
              selected={currentSchedule ? new Date(currentSchedule) : ""}
              calendarClassName="custom-calendar-icon-datepicker"
              onChange={(update) => {
                setCurrentSchedule(update ? update : "");
                let time = update;
                if (new Date(update) < new Date()) {
                  time = moment(update).set(
                    "hour",
                    moment(new Date()).format("HH")
                  );
                  time = moment(time).set(
                    "minute",
                    moment(new Date()).format("mm")
                  );
                }
                setCurrentSchedule(time);
              }}
              dateFormat={"dd/MM/yyyy - hh:mm a"}
              showIcon
              iconS
              // icon={
              //   <span>
              //     <CalendarMonthIcon
              //       style={{
              //         fontSize: 19,
              //         marginTop: -3,
              //       }}
              //     />
              //   </span>
              // }
              timeIntervals={1}
              showTimeSelect
              minDate={new Date()}
              minTime={
                new Date(currentSchedule) < new Date()
                  ? new Date(
                      0,
                      0,
                      0,
                      moment(new Date()).format("HH"),
                      moment(new Date()).format("mm")
                    )
                  : new Date(0, 0, 0, 0, 0)
              }
              maxTime={new Date(0, 0, 0, 23, 59)}
            />
          </div>
          <br />
          <CustomPrimaryButton onClick={() => onSubmitCsvScheule()}>
            Submit
          </CustomPrimaryButton>
        </Box>
      </Modal>
      <Popup
        openPopup={isPublish.value}
        onClose={() => {}}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => {
              setPublish({ ...isPublish, value: false, type: "" });
            },
          },
          secondary: {
            title: `Yes`,
            onClick: () => {
              onPublishCsvCards();
            },
          },
        }}
        title={`Are you sure you want to ${isPublish.type} all cards?`}
        description={
          unpublishAll && publishAll
            ? " (This will not affact already " + isPublish.type + "ed cards)"
            : ""
        }
      />
      <Dialog
        openDialog={isView}
        onClose={() => setIsView(false)}
        title={"Card details"}
      >
        <ViewCard
          toggleDrawer={isView}
          data={currentCard}
          isSeller
          hideBuyer={!currentCard?.is_sold}
        />
      </Dialog>
      <Popup
        openPopup={isDelete}
        onClose={() => {}}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => {
              setDelete(false);
            },
          },
          secondary: {
            title: `Yes`,
            onClick: () => {
              onDelete();
            },
          },
        }}
        title={`Do you want to delete this card ?`}
        description=""
      />
      <Popup
        openPopup={unpublishModal}
        onClose={() => {}}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => {
              setUnpublishModal(false);
              setPublishComment("");
            },
          },
          secondary: {
            title: `Yes, ${
              cardState.published ? "Unpublish" : "Publish"
            } cards`,
            onClick: () => {
              onPublish();
              setUnpublishModal(false);
            },
          },
        }}
        title={`Do you want to ${
          cardState.published ? "unpublish" : "publish"
        } this card ?`}
        description=""
      />
      <Dialog
        openDialog={uploadDrawer}
        onClose={() => {
          setUploadDrawer(false);
          isEditCard(false);
        }}
        title={editCard ? "Edit card" : "Upload a card"}
      >
        <UploadCardForm
          setUploadDrawer={setUploadDrawer}
          editCard={editCard}
          card={currentCard}
          setCard={setCurrentCard}
          setApiCallAction={getCurrentSetData}
          apiCallAction={currentSetData}
          onChangetab={onChangetab}
          setRoomType={setRoomType}
          setCorporateCardsTabValue={setCorporateCardsTabValue}
          setLoad={setLoad}
        />
      </Dialog>
      <Dialog
        openDialog={adminCsv}
        onClose={() => {
          setAdminCsv(false);
          isEditCard(false);
          setAdminCsvData(INITIAL_CSV);
          setAdminCsvError([]);
        }}
        title={editCard ? "Edit card" : "Upload a card"}
      >
        <UploadCsv
          handleFileUpload={handleFileUpload}
          adminCsvError={adminCsvError}
          sellerCsvCategoryError={sellerCsvCategoryError}
          setAdminCsvData={setAdminCsvData}
          adminCsvData={adminCsvData}
          onSubmitCsv={onSubmitCsv}
          csvRef={csvRef}
          handleCancel={handleCancel}
        />
      </Dialog>
      <Dialog
        openDialog={addMultiAlert}
        onClose={() => {
          setMultiAddAlert(false);
          isEditCard(false);
          handleRemoveBulkData();
          setBulkState({
            address_id: 0,
            is_corporate: false,
          });
        }}
        title={"Upload bulk"}
        paperWidth="100%"
        showAddressSelect={true}
        updateAddressDetails={updateBulkAddressDetails}
        multiAddressError={multiAddressError}
        fullWidth={true}
      >
        <UploadBulk
          setUploadDrawer={setAddOpenMulti}
          card={currentCard}
          setApiCallAction={getCurrentSetData}
          apiCallAction={currentSetData}
          addOpenMulti={addOpenMulti}
          multiCard={multiCard}
          onChangeMultiFile={onChangeMultiFile}
          onChangeMultiInput={onChangeMultiInput}
          allCatagories={allCatagories}
          multiError={multiError}
          onAddMultiCard={onAddMultiCard}
          onRemoveMultiCard={onRemoveMultiCard}
          isPrivateRoom={isPrivateRoom}
          setIsPrivateRoom={setIsPrivateRoom}
          isValidMulti={isValidMulti}
          setMultiAddAlert={setMultiAddAlert}
          setMultiErrors={setMultiErrors}
          setPrivateRoomType={setPrivateRoomType}
          privateRoomType={privateRoomType}
          handleResetBulkData={handleRemoveBulkData}
          bulkState={bulkState}
        />
      </Dialog>
      <div className="dashboard-top-filters seller">
        <div className="filter-container-left">
          {tabs.map((room) => (
            <span
              key={room.value}
              className={`text-sm-semibold-14 room ${
                tabValue === room.value ? "selected active" : ""
              }`}
              onClick={() => onChangetab(room.value)}
            >
              {tabValue === room.value ? (
                <ButtonLinkGrey label={room.name} />
              ) : (
                <ButtonGray label={room.name} />
              )}
            </span>
          ))}
        </div>
        {userInfo?.seller_verified && (
          <div className="filter-container-right">
            <FilterDropdown
              title="Upload new card to sell"
              list={[
                { label: "Upload single card", value: "single" },
                { label: "Upload bulk cards", value: "bulk" },
                { label: "Upload a CSV", value: "csv" },
              ]}
              subtitle={"Select upload type"}
              result={(val) => {
                onChangeUpload(val);
                onClickUploadType(val);
                setTimeout(() => {
                  onChangeUpload("");
                }, 2000);
              }}
              type="radio"
              radioValue={cardUploadType}
              radioUpload={radioUpload}
              closeRadioUpload={closeRadioUpload}
              isVerticalRadio
              noBorder
              isUpload
            />
          </div>
        )}
      </div>
      {handleCsvTab() ? (
        <div className="tab-content">
          <CustomizedTables
            totalPages={tableData?.total_pages}
            pageIndex={currentPage}
            setPageIndex={setCurrentPage}
            columns={
              (!csvList && tabValue == 3 && !csvCardIsPrivate) ||
              (!bulkList && tabValue == 2 && !csvCardIsPrivate) ||
              tabValue == 1
                ? ["", ...columnsCsvList]
                : columnsCsvList.filter(
                    (column) => column?.toLowerCase() !== "actions"
                  )
            }
            title={tableHeader}
            dateSort={
              sortData &&
              sortData[findIndex(sortData, "created_at", "type")]?.value ? (
                <ArrowDropDownIcon
                  onClick={() => {
                    onSortData(0, "created_at");
                  }}
                />
              ) : (
                <ArrowDropUpIcon
                  onClick={() => {
                    onSortData(0, "created_at");
                  }}
                />
              )
            }
            countSort={
              sortData &&
              sortData[findIndex(sortData, "total_cards", "type")]?.value ? (
                <ArrowDropDownIcon
                  onClick={() => {
                    onSortData(0, "total_cards");
                  }}
                />
              ) : (
                <ArrowDropUpIcon
                  onClick={() => {
                    onSortData(0, "total_cards");
                  }}
                />
              )
            }
          >
            {tableData && tableData.data && tableData.data.length ? (
              tableData.data.map((column) => {
                return (
                  <TableRow
                    className="text-xs-medium-12 table-row"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    onClick={() => onClickCsv(column)}
                  >
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {tabValue == 2
                        ? column.bulk_entry
                        : column.file_name
                        ? column.file_name
                            .split("_")
                            [column.file_name.split("_").length - 1].replace(
                              /%20/g,
                              " "
                            )
                        : ""}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column.total_cards}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell overflow"
                      align="left"
                    >
                      <CustomizedTooltips
                        title={column?.pickup_address_str || "N/A"}
                        className="capitalize"
                      >
                        <div className="value longName capitalize overflow">
                          {column?.pickup_address_str || "N/A"}
                        </div>
                      </CustomizedTooltips>
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column.created_by_id__username}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {moment(
                        tabValue == 2 ? column.upload_date : column.created_at
                      ).format("lll")}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <tr>
                <td colSpan={11}>
                  <NoData type="room" />
                </td>
              </tr>
            )}
          </CustomizedTables>
        </div>
      ) : (
        <div className="tab-content">
          <CustomizedTables
            totalPages={tableData?.total_pages}
            pageIndex={currentPage}
            setPageIndex={setCurrentPage}
            columns={
              (!csvList && tabValue == 3 && !csvCardIsPrivate) ||
              (!bulkList && tabValue == 2 && !csvCardIsPrivate) ||
              tabValue == 1
                ? [
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& svg": {
                            zIndex: checkAllCards ? 9 : 1,
                          },
                        }}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                      >
                        <Checkbox
                          checkedIcon={
                            <img
                              src={
                                globalDarkMode
                                  ? checkBoxPrimaryDark
                                  : checkBoxPrimary
                              }
                              alt=""
                            />
                          }
                          icon={
                            <img
                              src={
                                globalDarkMode ? checkBoxBaseDark : checkBoxBase
                              }
                              alt=""
                            />
                          }
                          checked={checkAllCards}
                        />
                        {checkAllCards ? (
                          <CrossIcon
                            onClick={() => {
                              setCheckAllCards(false);
                              setCheckedArray([]);
                              setIsPublishSelected("");
                              setPublishRadio("");
                            }}
                          />
                        ) : (
                          <DownArrow />
                        )}
                      </Box>
                      <Menu
                        id="publish-radio"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            padding: 3,
                            minWidth: 170,
                            backgroundColor: globalDarkMode
                              ? "#292828"
                              : "#ffffff",
                            borderRadius: "8px",
                          },
                        }}
                      >
                        <RadioGroup
                          value={publishRadio}
                          onChange={handleRadioChange}
                        >
                          <FormControlLabel
                            value="publish"
                            control={
                              <Radio
                                icon={<StyledIcon darkTheme={globalDarkMode} />}
                                checkedIcon={
                                  <StyledCheckedIcon
                                    darkTheme={globalDarkMode}
                                  />
                                }
                              />
                            }
                            label="Select all published"
                          />
                          <FormControlLabel
                            value="unpublish"
                            control={
                              <Radio
                                icon={<StyledIcon darkTheme={globalDarkMode} />}
                                checkedIcon={
                                  <StyledCheckedIcon
                                    darkTheme={globalDarkMode}
                                  />
                                }
                              />
                            }
                            label="Select all unpublished"
                          />
                        </RadioGroup>
                      </Menu>
                    </>,
                    ...tableColumns?.filter((column) => {
                      if (tabValue === 1) return column;
                      else
                        return (
                          column?.toLowerCase() !== "seller" &&
                          column?.toLowerCase() !== "pickup address" &&
                          column?.toLowerCase() !== "uploaded by"
                        );
                    }),
                  ]
                : tableColumns
            }
            title={tableHeader}
            priceSort={
              sortData[findIndex(sortData, "reserve_price", "type")]?.value ? (
                <ArrowDropDownIcon
                  onClick={() => onSortData(1, "reserve_price")}
                />
              ) : (
                <ArrowDropUpIcon
                  onClick={() => onSortData(1, "reserve_price")}
                />
              )
            }
            dateSort={
              (!bulkList && tabValue == 2) || (!csvList && tabValue == 3) ? (
                <></>
              ) : (
                <>
                  {sortData &&
                  sortData[findIndex(sortData, "created_at", "type")]?.value ? (
                    <ArrowDropDownIcon
                      onClick={() => {
                        onSortData(0, "created_at");
                      }}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      onClick={() => {
                        onSortData(0, "created_at");
                      }}
                    />
                  )}
                </>
              )
            }
          >
            {tableData && tableData?.data && tableData?.data?.length ? (
              tableData?.data?.map((column) => {
                return (
                  <TableRow
                    className="text-xs-medium-12 table-row"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    onClick={() => {
                      setIsView(true);
                      onViewcard(column.id);
                    }}
                  >
                    {(!csvList && tabValue == 3 && !csvCardIsPrivate) ||
                    (!bulkList && tabValue == 2 && !csvCardIsPrivate) ||
                    tabValue == 1 ? (
                      <TableCell
                        className="text-xs-medium-12 table-cell"
                        align="left"
                      >
                        <Checkbox
                          checkedIcon={
                            <img
                              src={
                                globalDarkMode
                                  ? checkBoxPrimaryDark
                                  : checkBoxPrimary
                              }
                              alt=""
                            />
                          }
                          icon={
                            <img
                              src={
                                globalDarkMode ? checkBoxBaseDark : checkBoxBase
                              }
                              alt=""
                            />
                          }
                          disabled={
                            column.is_sold ||
                            (column.published &&
                              checkedArray &&
                              checkedArray.length &&
                              !isPublishSelected) ||
                            (!column.published &&
                              checkedArray &&
                              checkedArray.length &&
                              isPublishSelected)
                          }
                          value={column.id}
                          checked={checkedArray.some(
                            (obj) => obj.card_id === column.id
                          )}
                          sx={{
                            opacity:
                              column?.is_sold ||
                              (column.published &&
                                checkedArray &&
                                checkedArray.length &&
                                !isPublishSelected) ||
                              (!column.published &&
                                checkedArray &&
                                checkedArray.length &&
                                isPublishSelected)
                                ? 0.3
                                : 1,
                          }}
                          onChange={(e) => handleChangeChecked(e, column)}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </TableCell>
                    ) : null}
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column.id}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      <img width={30} height={40} src={column.front_image} />
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell overflow capitalize"
                      align="left"
                    >
                      <CustomizedTooltips title={column.title}>
                        <div className="value longName capitalize overflow">
                          {column.title}
                        </div>
                      </CustomizedTooltips>
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column.inventory_tag_name
                        ? column.inventory_tag_name
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column?.grade == 0 ? "Authentic" : column?.grade}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {column.reserve_price}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell overflow"
                      align="left"
                    >
                      <CustomizedTooltips title={column.description}>
                        <div className="value longName capitalize overflow">
                          {column.description}
                        </div>
                      </CustomizedTooltips>
                    </TableCell>
                    {tabValue === 1 && (
                      <TableCell
                        className="text-xs-medium-12 table-cell overflow"
                        align="left"
                      >
                        <CustomizedTooltips
                          title={column?.pickup_address_str || "N/A"}
                          className="capitalize"
                        >
                          <div className="value longName capitalize overflow">
                            {column?.pickup_address_str || "N/A"}
                          </div>
                        </CustomizedTooltips>
                      </TableCell>
                    )}
                    {tabValue === 1 && (
                      <TableCell
                        className="text-xs-medium-12 table-cell"
                        align="left"
                      >
                        {column?.upload_by || "N/A"}
                      </TableCell>
                    )}
                    {tabValue === 1 && (
                      <TableCell
                        className="text-xs-medium-12 table-cell"
                        align="left"
                      >
                        {column?.seller}
                      </TableCell>
                    )}
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      {moment(column.created_at).format("lll")}
                    </TableCell>
                    <TableCell
                      className="text-xs-medium-12 table-cell"
                      align="left"
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                        }}
                      >
                        <CustomizedTooltips
                          placement="top"
                          title={
                            column.published ||
                            (column.published &&
                              checkedArray &&
                              checkedArray.length &&
                              !isPublishSelected) ||
                            (!column.published &&
                              checkedArray &&
                              checkedArray.length &&
                              isPublishSelected)
                              ? "Can not edit published card"
                              : "Edit"
                          }
                        >
                          <EditIcon
                            className="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!column.published) {
                                onClickEdit(column);
                                onViewcard(column.id, true);
                              }
                            }}
                            style={
                              column.published ||
                              (column.published &&
                                checkedArray &&
                                checkedArray.length &&
                                !isPublishSelected) ||
                              (!column.published &&
                                checkedArray &&
                                checkedArray.length &&
                                isPublishSelected)
                                ? {
                                    color: "#c0c0c0",
                                    cursor: "default",
                                  }
                                : { cursor: "pointer" }
                            }
                          />
                        </CustomizedTooltips>
                        <CustomizedTooltips
                          placement="top"
                          title={
                            column.published ||
                            (column.published &&
                              checkedArray &&
                              checkedArray.length &&
                              !isPublishSelected) ||
                            (!column.published &&
                              checkedArray &&
                              checkedArray.length &&
                              isPublishSelected)
                              ? "Can not delete published card"
                              : "Delete"
                          }
                        >
                          <DeleteIcon
                            className="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!column.published) {
                                setDelete(true);
                                setCardState(column);
                              }
                            }}
                            style={
                              column.published ||
                              (column.published &&
                                checkedArray &&
                                checkedArray.length &&
                                !isPublishSelected) ||
                              (!column.published &&
                                checkedArray &&
                                checkedArray.length &&
                                isPublishSelected)
                                ? {
                                    color: "#c0c0c0",
                                    cursor: "default",
                                  }
                                : { cursor: "pointer" }
                            }
                          />
                        </CustomizedTooltips>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "0.875rem",
                        padding: column.is_sold ? "6px 8px" : 0,
                      }}
                      className={"text-sm-semibold-14 table-cell"}
                      align="left"
                    >
                      {column.is_sold ? (
                        "Sold"
                      ) : csvCardIsPrivate ? (
                        column.published ? (
                          "Published"
                        ) : (
                          "Unpublished"
                        )
                      ) : column.published ? (
                        <Button
                          className="text-sm-semibold-14"
                          onClick={(e) => {
                            e.stopPropagation();
                            setPublish({
                              ...isPublish,
                              type: column.published ? "unpublish" : "publish",
                            });
                            setCardState(column);
                            setUnpublishModal(true);
                          }}
                          disabled={
                            checkedArray &&
                            checkedArray.length &&
                            !isPublishSelected
                          }
                        >
                          Unpublish
                        </Button>
                      ) : (
                        <Button
                          className="text-sm-semibold-14"
                          onClick={(e) => {
                            e.stopPropagation();
                            setPublish({
                              ...isPublish,
                              type: column.published ? "unpublish" : "publish",
                            });
                            setCardState(column);
                            setUnpublishModal(true);
                          }}
                          disabled={
                            checkedArray &&
                            checkedArray.length &&
                            isPublishSelected
                          }
                        >
                          Publish
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <tr>
                <td colSpan={11}>
                  <NoData type="room" />
                </td>
              </tr>
            )}
          </CustomizedTables>
        </div>
      )}
    </div>
  );
};

export default ManageCard;

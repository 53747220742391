import './ActionBar.scss';

import Label from '../label/Label';
import React from 'react';
import { useSelector } from "react-redux";

export default function ActionBar({ title, actionLabel, actionIcon, onClick }) {
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  return (
    <div className={`action-bar-container ${globalDarkMode?"pclive-dark-mode":""}`}>
      <div className="main-container">
        <div className="text-md-medium-16  caption">{title}</div>
        <Label label={actionLabel} icon={actionIcon} onClick={onClick} />
      </div>
      <div className="horizontal-line"></div>
    </div>
  );
}

import "./AccountVarification.scss";

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllAddress, updateAddress } from "../../../../redux/slices/address";
import {
  getPaymentCards,
  updatePaymentCard,
} from "../../../../redux/slices/payment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AccountVarificationDetails from "./account-varification-details/Details";
import AddAddress from "../../../../components/addresses/add-address/AddAddress";
import AddIcon from "@mui/icons-material/Add";
import AddPaymentBank from "../../../../components/card/AddPaymentBank";
import AddPaymentCard from "../../../../components/card/AddPaymentCard";
import Addresses from "../../../../components/addresses/address/Addresses";
import Box from "@mui/material/Box";
import ButtonBorderless from "../../../../components/buttons/borderless-button/BorderlessButton";
import ButtonLight from "../../../../components/buttons/button-light/ButtonLight";
import CustomPrimaryButton from "../../../../components/buttons/primary/CustomPrimaryButton";
import CustomTabs from "../../../../components/tabs/CustomTabs";
import Dialog from "../../../../components/dialog/Dialog";
import PCLiveLoader from "../../../../components/loader/PCLiveLoader";
import PaymentCard from "../../../../components/card/Paymentcard";
import StepperComponent from "./identify-step/IdentifyStep";
import StripeVerfication from "./stripe-verification/StripeVerification";
import TabPanel from "@mui/lab/TabPanel";
import { getUserInfo } from "../../../../redux/slices/profile";
import stripeBlackLogo from "../../../../assests/icons/stripeBlack.svg";
import stripeLogo from "../../../../assests/icons/stripe.svg";
import DoneGif from "../../../../assests/gif/tick.gif";

const AccountVarification = () => {
  const dispatch = useDispatch();
  const [varificationTab, setVarificationTab] = useState(1);
  const [openDetails, setopenDetails] = useState(false);
  const [openAddress, setOpenAddAddress] = useState(false);
  const [openAddressList, setOpenAddressList] = useState(false);
  const [openCardList, setOpenCardList] = useState(false);
  const [openAddPaymentCard, setOpenAddPaymentCard] = useState(false);
  const [openStripeVerification, setOpenStripeVerification] = useState(false);
  const [openAddBank, setOpenAddBank] = useState(false);
  const [currentAddressState, setCurrentAddressState] = useState(null);
  const [currentCardState, setCurrentCardState] = useState(null);
  const [currentBankState, setCurrentBankState] = useState(null);
  const [currentFlow, setCurrentFlow] = useState("buyer");
  const [load, setLoad] = useState(false);
  const userInfo = useSelector((state) => state.profile?.data?.data);
  const isLoading = useSelector((state) => state.profile?.isLoading);
  const allAddress = useSelector((state) => state.address.data?.data);
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const allPaymentcards = useSelector(
    (state) => state.paymentCard.allPaymentCards?.data
  );
  const steps = [
    { title: "Add address", value: 1 },
    { title: "Add card", value: 2 },
  ];

  const sellerStep = [
    { title: "Add address", value: 1 },
    { title: "Add bank", value: 2 },
    { title: "Account verification", value: 3 },
  ];
  const bothStep = [
    { title: "Add address", value: 1 },
    { title: "Add bank", value: 2 },
    { title: "Add card", value: 3 },
    { title: "Account verification", value: 4 },
  ];
  useEffect(() => {
    setLoad(true);
    dispatch(getAllAddress());
    dispatch(getPaymentCards());
    dispatch(getUserInfo());
  }, []);
  const handleClick = (item) => {
    return;
    switch (varificationTab) {
      case "1":
        if (item.value === 1) setOpenAddressList(true);
        else if (item.value === 2) setOpenCardList(true);
        break;
      case "2":
        if (item.value === 1) setOpenAddressList(true);
        else if (item.value === 2) setOpenAddBank(true);
        else if (item.value === 3) setOpenStripeVerification(true);
        break;
      case "3":
        if (item.value === 1) setOpenAddressList(true);
        else if (item.value === 2) setOpenAddBank(true);
        else if (item.value === 3) setOpenCardList(true);
        else if (item.value === 4) setOpenStripeVerification(true);
        break;
      default:
        break;
    }
  };

  const getData = (label, type) => {
    switch (type) {
      case "buyer":
        if (label.value === 1 && userInfo?.address_added)
          steps[0].title = "Address added";
        else if (label.value === 2 && userInfo?.card_added)
          steps[1].title = "Card added";
        return (
          (label.value === 1 && userInfo?.address_added) ||
          (label.value === 2 && userInfo?.card_added)
        );
      case "seller":
        if (label.value === 1 && userInfo?.address_added)
          sellerStep[0].title = "Address added";
        else if (label.value === 2 && userInfo?.bank_added)
          sellerStep[1].title = "Bank added";
        else if (label.value === 3 && userInfo?.seller_verified)
          sellerStep[2].title = "Seller verified";
        return (
          (label.value === 1 && userInfo?.address_added) ||
          (label.value === 2 && userInfo?.bank_added) ||
          (label.value === 3 && userInfo?.seller_verified)
        );
      case "both":
        if (label.value === 1 && userInfo?.address_added)
          bothStep[0].title = "Address added";
        else if (label.value === 2 && userInfo?.bank_added)
          bothStep[1].title = "Bank added";
        else if (label.value === 3 && userInfo?.card_added)
          bothStep[2].title = "Card added";
        else if (label.value === 4 && userInfo?.account_verified)
          bothStep[3].title = "Account verified";
        return (
          (label.value === 1 && userInfo?.address_added) ||
          (label.value === 2 && userInfo?.bank_added) ||
          (label.value === 3 && userInfo?.card_added) ||
          (label.value === 4 && userInfo?.account_verified)
        );
      default:
        return false;
    }
  };

  const markPrimaryAddress = () => {
    const address = allAddress.find((f) => f.id == currentAddressState);
    const data = {
      ...address,
      address_id: currentAddressState,
      is_primary: true,
    };
    updateAddress(
      data,
      (body) => {
        toast.success(body.message);
        setLoad(true);
        dispatch(getAllAddress());
        dispatch(getUserInfo());
        setLoad(false);
      },
      (body) => {
        toast.error(body.message);
      }
    );
  };
  const markPrimaryCard = () => {
    const data = {
      payment_method_id: currentCardState,
    };
    updatePaymentCard(
      data,
      (body) => {
        toast.success(body.message);
        setLoad(true);
        dispatch(getPaymentCards());
        dispatch(getUserInfo());
        setLoad(false);
      },
      (body) => {
        toast.error(body.message);
      }
    );
  };

  useEffect(() => {
    setLoad(true);
    if (allAddress?.length) {
      let primary = allAddress.find((ad) => ad.is_primary);
      setCurrentAddressState(primary?.id);
    }
    if (allPaymentcards?.length) {
      let primary = allPaymentcards.find((ad) => ad.is_primary);
      setCurrentCardState(primary?.id);
    }
    setLoad(false);
  }, [allAddress, allPaymentcards]);

  const startVerification = (type) => {
    setCurrentFlow(type);
    if (!userInfo?.address_added) {
      setOpenAddAddress(true);
      return;
    }

    switch (type) {
      case "buyer":
        if (!userInfo?.card_added) {
          setOpenAddPaymentCard(true);
          return;
        }
        break;
      case "seller":
        if (!userInfo?.bank_added) {
          setOpenAddBank(true);
          return;
        }
        if (!userInfo?.seller_verified) {
          setOpenStripeVerification(true);
          return;
        }
        break;
      case "both":
        if (!userInfo?.bank_added) {
          setOpenAddBank(true);
          return;
        }
        if (!userInfo?.card_added) {
          setOpenAddPaymentCard(true);
          return;
        }
        if (!userInfo?.account_verified) {
          setOpenStripeVerification(true);
          return;
        }
        break;
      default:
        console.error("Invalid type");
    }
  };
  const getStep = (label) => {
    let labelIndex = null;
    switch (currentFlow) {
      case "buyer":
        labelIndex = steps.findIndex((f) => f.title == label);
        return { step: steps, index: labelIndex };
      case "seller":
        labelIndex = sellerStep.findIndex((f) => f.title == label);
        return { step: sellerStep, index: labelIndex };
      case "both":
        labelIndex = bothStep.findIndex((f) => f.title == label);
        return { step: bothStep, index: labelIndex };

      default:
        return steps;
    }
  };

  const getUserData = () => {
    dispatch(getUserInfo());
  };
  return (
    <div
      className={`account-varification ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <PCLiveLoader open={load || isLoading} />

      <Dialog
        openDialog={openDetails}
        onClose={() => setopenDetails(false)}
        title={"Why account verification is important?"}
      >
        <AccountVarificationDetails />
      </Dialog>
      <Dialog
        openDialog={openAddBank}
        onClose={() => setOpenAddBank(false)}
        title={"Add bank"}
        rightElement={
          <img src={globalDarkMode ? stripeBlackLogo : stripeLogo} alt="logo" />
        }
        topElement={
          <StepperComponent
            getStep={() => getStep("Add bank")}
            handleClick={handleClick}
            currentFlow={currentFlow}
            identifier="Add bank"
            getData={getData}
          />
        }
        isbankHelper
      >
        <AddPaymentBank
          accVerify
          setOpenAddPaymentCard={setOpenAddPaymentCard}
          tab={varificationTab}
          setOpenStripeVerification={setOpenStripeVerification}
          userInfo={userInfo}
          setOpenAddPaymentBank={setOpenAddBank}
        />
      </Dialog>
      <Dialog
        openDialog={openStripeVerification}
        onClose={() => setOpenStripeVerification(false)}
        title={"Account verification"}
        rightElement={
          <img src={globalDarkMode ? stripeBlackLogo : stripeLogo} alt="logo" />
        }
        topElement={
          <StepperComponent
            getStep={() => getStep("Account verification")}
            handleClick={handleClick}
            currentFlow={currentFlow}
            identifier="Account verification"
            getData={getData}
          />
        }
      >
        <StripeVerfication
          setOpenStripeVerification={setOpenStripeVerification}
          getUserData={getUserData}
        />
      </Dialog>
      <Dialog
        openDialog={openAddress}
        onClose={() => setOpenAddAddress(false)}
        title={"Add address"}
        topElement={
          <StepperComponent
            getStep={() => getStep("Add address")}
            handleClick={handleClick}
            currentFlow={currentFlow}
            identifier="Add address"
            getData={getData}
          />
        }
        addressHelper
      >
        <AddAddress
          setOpenAddAddress={setOpenAddAddress}
          accVerify
          setOpenAddBank={setOpenAddBank}
          setOpenAddPaymentCard={setOpenAddPaymentCard}
          tab={varificationTab}
        />
      </Dialog>
      <Dialog
        openDialog={openAddressList}
        onClose={() => setOpenAddressList(false)}
        title={"Select address"}
      >
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          onChange={(e) => setCurrentAddressState(e.target.value)}
        >
          {allAddress && allAddress.length
            ? allAddress.map((address) => {
                return (
                  <FormControlLabel
                    value={address.id}
                    checked={currentAddressState == address.id}
                    control={<Radio />}
                    label={
                      <div style={{ marginBottom: 10 }}>
                        <Addresses
                          address={address}
                          setOpenAddAddress={setOpenAddAddress}
                          disableEdit
                          disableDelete
                        />
                      </div>
                    }
                  />
                );
              })
            : ""}
        </RadioGroup>
        {/* need to verify this commented code */}
        {/* {
        allAddress && allAddress.length ?
          allAddress.map(address => {
            return (
              <Addresses
                address={address}
                setOpenAddAddress={setOpenAddAddress}
              />
            )
          }) : ""} */}
        {/* <AddressList
          allAddress={allAddress}
          setOpenAddAddress={setOpenAddAddress}
          setOpenAddressList={setOpenAddressList}
        /> */}
        <div className="verification-dilog-button-container">
          <Button variant="outlined">Cancel</Button>
          <ButtonBorderless onClick={() => setOpenAddAddress(true)}>
            Add new address <AddIcon />
          </ButtonBorderless>
          <CustomPrimaryButton onClick={() => markPrimaryAddress()}>
            Save and next
          </CustomPrimaryButton>
        </div>
      </Dialog>
      <Dialog
        openDialog={openCardList}
        onClose={() => setOpenCardList(false)}
        title={"Select Card"}
      >
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          onChange={(e) => setCurrentCardState(e.target.value)}
        >
          {allPaymentcards && allPaymentcards.length
            ? allPaymentcards.map((card) => {
                return (
                  <FormControlLabel
                    checked={currentCardState == card.id}
                    value={card.id}
                    control={<Radio />}
                    label={
                      <div style={{ marginBottom: 10 }}>
                        <PaymentCard
                          card={card}
                          setOpenPopup={setOpenCardList}
                        />
                      </div>
                    }
                  />
                );
              })
            : ""}
        </RadioGroup>

        <div className="verification-dilog-button-container">
          <ButtonLight
            style={{ marginRight: 10 }}
            onClick={() => setOpenCardList(false)}
          >
            Cancel
          </ButtonLight>
          <ButtonBorderless onClick={() => setOpenAddPaymentCard(true)}>
            Add new card <AddIcon />
          </ButtonBorderless>
          <CustomPrimaryButton onClick={markPrimaryCard}>
            Save and next
          </CustomPrimaryButton>
        </div>
      </Dialog>
      <Dialog
        openDialog={openAddPaymentCard}
        onClose={() => setOpenAddPaymentCard(false)}
        title={"Add card"}
        rightElement={
          <img src={globalDarkMode ? stripeBlackLogo : stripeLogo} alt="logo" />
        }
        topElement={
          <StepperComponent
            getStep={() => getStep("Add card")}
            handleClick={handleClick}
            currentFlow={currentFlow}
            identifier="Add card"
            getData={getData}
          />
        }
        isCardHelper
      >
        <AddPaymentCard
          accVerify
          tab={varificationTab}
          setOpenAddPaymentCard={setOpenAddPaymentCard}
        />
      </Dialog>
      <div className="header-container">
        <div className="text-sm-semibold-14 heading">Account verification</div>
        <div
          onClick={() => setopenDetails(true)}
          className="text-sm-semibold-16 verification-info"
        >
          Why is this needed?
        </div>
      </div>
      <hr />
      <CustomTabs
        tabData={
          userInfo?.buyer_verified || userInfo?.seller_verified
            ? [
                { title: "Buyer", value: 1 },
                { title: "Seller", value: 2 },
              ]
            : [
                { title: "Buyer", value: 1 },
                { title: "Seller", value: 2 },
                { title: "Both", value: 3 },
              ]
        }
        value={varificationTab}
        handleChange={(e, val) => setVarificationTab(val)}
      >
        <TabPanel value={1} sx={{ padding: "27px 0" }}>
          <div className="buyer-container">
            <div className="head-contents">
              <div className="text-sm-medium-14 heading">
                Steps to verify account as a buyer
              </div>

              {userInfo?.address_added && userInfo?.card_added ? null : (
                <ButtonLight onClick={() => startVerification("buyer")}>
                  {userInfo?.address_added
                    ? "Complete verification"
                    : "Start Verification"}
                </ButtonLight>
              )}
            </div>

            <Box
              className={`stepper-container buyer ${
                globalDarkMode ? "pclive-dark-mode" : ""
              }`}
            >
              <Stepper alternativeLabel>
                {steps.map((label, index) => (
                  <Step
                    className={`buyer ${
                      getData(label, "buyer") ? "success" : ""
                    }`}
                    key={label.value}
                    onClick={() => handleClick(label)}
                  >
                    <StepLabel
                      StepIconComponent={() => {
                        return getData(label, "buyer") ? (
                          <div className="image-container">
                            <img src={DoneGif} alt="loading" />
                          </div>
                        ) : (
                          <span className="text-xs-medium-12 step-icon success">
                            0{index + 1}
                          </span>
                        );
                      }}
                    >
                      <span
                        className={`text-sm-semibold-14 step-label ${
                          getData(label, "buyer") ? "success" : ""
                        }`}
                      >
                        {label.title}
                      </span>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>
        </TabPanel>

        <TabPanel value={2} sx={{ padding: "27px 0" }}>
          <div className="buyer-container">
            <div className="head-contents">
              <div className="text-sm-medium-14 heading">
                Steps to verify account as a seller
              </div>

              {userInfo?.seller_verified ? null : (
                <ButtonLight onClick={() => startVerification("seller")}>
                  {userInfo?.address_added
                    ? "Complete verification"
                    : "Start Verification"}
                </ButtonLight>
              )}
            </div>

            <Box
              className={`stepper-container seller ${
                globalDarkMode ? "pclive-dark-mode" : ""
              }`}
            >
              <Stepper alternativeLabel>
                {sellerStep.map((label, index) => (
                  <Step
                    className={`seller ${
                      getData(label, "seller") ? "success" : ""
                    }`}
                    key={label.value}
                    onClick={() => handleClick(label)}
                  >
                    <StepLabel
                      StepIconComponent={() => {
                        return getData(label, "seller") ? (
                          <div className="image-container">
                            <img src={DoneGif} alt="loading" />
                          </div>
                        ) : (
                          <span className="text-xs-medium-12 step-icon success">
                            0{index + 1}
                          </span>
                        );
                      }}
                    >
                      <span
                        className={`text-sm-semibold-14 step-label ${
                          getData(label, "seller")
                            ? `success  ${index == 2 ? " margin" : ""}`
                            : ""
                        }`}
                      >
                        {label.title}
                      </span>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>
        </TabPanel>
        <TabPanel value={3} sx={{ padding: "27px 0" }}>
          <div className="buyer-container">
            <div className="head-contents">
              <div className="text-sm-medium-14 heading">
                Steps to verify account as a buyer as well as seller
              </div>

              {userInfo?.account_verified ? null : (
                <ButtonLight onClick={() => startVerification("both")}>
                  {userInfo?.address_added
                    ? "Complete verification"
                    : "Start Verification"}
                </ButtonLight>
              )}
            </div>

            <Box
              className={`stepper-container both ${
                globalDarkMode ? "pclive-dark-mode" : ""
              }`}
            >
              <Stepper alternativeLabel>
                {bothStep.map((label, index) => (
                  <Step
                    className={`both ${
                      getData(label, "both") ? "success" : ""
                    }`}
                    key={label.value}
                    onClick={() => handleClick(label)}
                  >
                    <StepLabel
                      StepIconComponent={() => {
                        return getData(label, "both") ? (
                          <div className="image-container">
                            <img src={DoneGif} alt="loading" />
                          </div>
                        ) : (
                          <span className="text-xs-medium-12 step-icon success">
                            0{index + 1}
                          </span>
                        );
                      }}
                    >
                      <span
                        className={`text-sm-semibold-14 step-label ${
                          getData(label, "both")
                            ? `success  ${index == 2 ? " margin" : ""}`
                            : ""
                        }`}
                      >
                        {label.title}
                      </span>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>
        </TabPanel>
      </CustomTabs>
    </div>
  );
};

export default AccountVarification;

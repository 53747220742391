import './Dialog.scss';

import * as React from 'react';

import {
  AddressHelper,
  BankHelper,
  CardHelper,
  bankHelper,
} from "../heler-component/Helper";

import AddressSelect from "../address-select/AddressSelect";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Divider } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useSelector } from "react-redux";

export default function Dialog({
  children,
  openDialog,
  onClose,
  title,
  rightElement,
  paperWidth,
  topElement,
  subtitle,
  isDark,
  isbankHelper,
  isCardHelper,
  addressHelper,
  showAddressSelect = false,
  updateAddressDetails,
  showStatus,
  status,
  multiAddressError,
  fullWidth,
}) {
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const helperComponent = () => {
    if (isbankHelper) {
      return <BankHelper />;
    }
    if (isCardHelper) {
      return <CardHelper />;
    }
    if (addressHelper) {
      return <AddressHelper />;
    } else {
      return null;
    }
  };
  return (
    <Drawer
      anchor={"right"}
      open={openDialog}
      onClose={onClose}
      ModalProps={{
        keepMounted: false,
      }}
      className="mui-drawer"
      PaperProps={{
        sx: {
          width: paperWidth ? paperWidth : "40%",
          backgroundColor: globalDarkMode || isDark ? "#1C1D1D" : "#ffffff",
          borderRadius: "12px 0 0 12px",
        },
      }}
    >
      {topElement && <div className="dilog-steps">{topElement}</div>}
      <Divider
        variant="fullWidth"
        className={globalDarkMode || isDark ? "divider" : ""}
      />

      <div
        className={`dialog-container ${
          globalDarkMode || isDark ? "pclive-dark-mode" : ""
        }`}
      >
        <div
          className="label-container"
          style={{ width: fullWidth ? "100%" : "auto" }}
        >
          <div className="left-element">
            <ArrowBackIcon fontSize="small" onClick={onClose} />
            <span className="text-md-semibold-16 ">
              {title}
              <br />
              <span className="text-sm-medium-14 subtitle">{subtitle}</span>
            </span>
          </div>
          {showStatus && (
            <span className="text-xs-medium request-status">
              Request status:{" "}
              <span className="text-xs-bold-12 capitalize">{status}</span>
            </span>
          )}
          {showAddressSelect && updateAddressDetails && (
            <div className="address-select-container">
              <AddressSelect
                updateAddressDetails={updateAddressDetails}
                isUploadBulk={true}
                showIcon={true}
              />
              {multiAddressError ? (
                <p className="text-sm-regular error">
                  Please select an address
                </p>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        {rightElement && (
          <div className="right-element-container">
            {rightElement} {helperComponent()}
          </div>
        )}
      </div>
      <Divider variant="fullWidth" className="divider" />
      <div
        className={`drawer-dialog-content ${
          globalDarkMode || isDark ? "pclive-dark-mode" : ""
        }`}
      >
        {children}
      </div>
    </Drawer>
  );
}

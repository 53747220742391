import "./OrderListComponent.scss";

import { useSelector } from "react-redux";

import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import React from "react";
import moment from "moment";
import pclive from "../../assests/icons/pclive247.png";
import { showData } from "../../utils/utils";

const OrderListComponent = ({ shipment, currentUserType }) => {
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  return (
    <div
      className={`order-list-component ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <div className="top-container text-sm-medium-14">
        <div className="left-items">
          {currentUserType === "seller" && (
            <img src={shipment?.profile_pic || pclive} alt="profile pic" />
          )}
          {currentUserType === "buyer"
            ? `Order Id #${shipment?.id}`
            : shipment?.buyer || "UNKNOWN"}
        </div>
        <div className="transit ">
          <span className={showData(shipment?.status)}>
            {showData(shipment?.status)}
          </span>{" "}
          <LocalShippingOutlinedIcon />
        </div>
      </div>
      <div className="bottom-container text-xs-medium-12">
        <div className="bottom-left-container">
          <div className="content">
            <div>
              {currentUserType === "buyer" ? "Total sellers" : "Order Id"}
            </div>
            <p>
              {currentUserType === "buyer"
                ? shipment?.total_sellers
                : `#${shipment?.id}`}
            </p>
          </div>
          <hr />
          <div className="content">
            <div>Total cards</div> <p>{shipment?.total_cards}</p>
          </div>
          <hr />
          <div className="content">
            <div>Shipping time</div>{" "}
            <p>{moment(shipment?.created_at).format("MM/DD/YYYY  hh:mm a")}</p>
          </div>
          <hr />
          <div className="content">
            <div>Pickup address</div>{" "}
            <p>{shipment?.is_corporate ? "Corporate" : "Personal"}</p>
          </div>
        </div>
        {(currentUserType !== "seller" ||
          shipment?.status?.toLowerCase() !== "cancelled") && (
          <div className="bottom-right-container">
            <div>Total paid</div>
            <p className="text-sm-semibold-16">${shipment?.total_amount}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderListComponent;

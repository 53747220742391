import "./SellerProfileDialog.scss"; // Import the SCSS file

import { Backdrop, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getSellerInfo,
  setCurrentSeller,
} from "../../../../redux/slices/auction";
import { useDispatch, useSelector } from "react-redux";

import ButtonLight from "../../../../components/buttons/button-light/ButtonLight";
import CardCarosel from "../../../../components/carosel/CardCarosel";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "../../../../components/dialog/Dialog";
import FollowersDialog from "../../profile/basic-details/followers-dialog/FollowersDialog";
import FollowingDialog from "../../profile/basic-details/following-dialog/FollowingDialog";
import PCLiveLoader from "../../../../components/loader/PCLiveLoader";
import pcliveLogo from "../../../../assests/icons/pclive247.png";
import { updateUserFollower } from "../../../../redux/slices/profile";

export default function SellerProfileDialog({ openDialog, onClose, seller ,isDark }) {
  const [data, setData] = useState(null);
  const [openFollowersDialog, setOpenFollowersDialog] = useState(false);
  const [openFollowingsDialog, setOpenFollowingsDialog] = useState(false);
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  const [imgLoad,setImgload] = useState(false)

  const dispatch = useDispatch();
  const [load , setLoad] = useState(false);
  const getSellerDetails = () => {
    setLoad(true)
    getSellerInfo(`user_id=${seller}`, (res) => {
      if (res?.data?.success) {
        setData(res?.data?.data);
        dispatch(setCurrentSeller(res?.data?.data));
        setLoad(false)
      }
    },()=>{
      setLoad(false)
    });
  };

  const updateFollower = (is_follow, user_id) => {
    updateUserFollower(
      {
        is_follow,
        user_id,
      },
      (res) => {
        getSellerDetails();
      },
      (err) => console.log({ err })
    );
  };

  useEffect(() => {
    setLoad(true);
    getSellerDetails();
    setLoad(false);
  }, []);

  const handleImageLoaded = (e) => {
    setImgload(true);
  };
  
  if (!data) return;

  return (
    <Dialog openDialog={openDialog} onClose={onClose} title={"Seller profile"} isDark={true}>
      <div className={`seller-profile-auction-container  ${(globalDarkMode || isDark)?"pclive-dark-mode":""}`}>
      <div className="seller-profile-dialog">
      <PCLiveLoader open={load} /> 

        <div className="profile-container">
          <div className="profile-info">
            <div className="profile-details">
              <div className="profile-image">
                <img
                  src={data?.profile_pic ? data?.profile_pic : pcliveLogo}
                  alt="User"
                  loading="lazy"
                />
              </div>
              <div className="user-details">
                <span className=" text-sm-medium-14">
                  {data?.first_name + " " + data?.last_name}
                </span>
                <span className="username text-sm-medium-14">
                  {data?.username}
                </span>
              </div>
            </div>
          </div>
          <div className="profile-seperator"></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              height: "50%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "30%",
              }}
            >
              <ButtonLight
                onClick={() => setOpenFollowersDialog(true)}
                isDark
              >
                Followers {data?.follower_count}
              </ButtonLight>
              <FollowersDialog
                openDialog={openFollowersDialog}
                userId = {data?.id}
                onClose={() => setOpenFollowersDialog(false)}
                isDark
              />
            </div>
            <div className="vertical-seperator"></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "30%",
              }}
            >
              <ButtonLight
                onClick={() => setOpenFollowingsDialog(true)}
                isDark
              >
                Following {data?.following_count}
              </ButtonLight>
              <FollowingDialog
                openDialog={openFollowingsDialog}
                userId = {data?.id}
                onClose={() => setOpenFollowingsDialog(false)}
                isDark
              />
            </div>
            <div className="vertical-seperator"></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "30%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 6,
                  alignItems: "center",
                }}
              >
                <div className="followers-button">
                  <ButtonLight
                    isDark
                    onClick={() => {
                      const isFollowing = data?.following ? false : true;
                      updateFollower(isFollowing, data?.id);
                    }}
                  >
                    {data?.following ? "Unfollow" : "Follow"}
                  </ButtonLight>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="text-sm-semibold-14 recent-cards">Recent cards</span>

      <div className="recent-cards-list">
        {data?.recent_cards?.map((item, index) => (
          <img
          src={item?.front_image ? (imgLoad ? item?.front_image : pcliveLogo) : pcliveLogo}
            height={107}
            width={70}
            onLoad={handleImageLoaded}
          />
        ))}
      </div>
      </div>
    </Dialog>
  );
}

import "./Wallet.scss";

import React from "react";

const WalletComponent = ({balance}) => {
  return (
    <div className="wallet-container">
      <div className="wallet-back"></div>
      <div className="wallet-back back2"></div>
      <div className="wallet">
        <div className="text-xs-semibold-24">
          $ {balance ? balance.toFixed(2) : 0}
        </div>
        <div className="text-sm-medium-14">Wallet balance</div>
      </div>
    </div>
  );
};

export default WalletComponent;

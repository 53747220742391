import "./Dropdown.scss";

import { FormControlLabel, Input } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Checkbox from "@mui/material/Checkbox";
import CustomPrimaryButton from "../buttons/primary/CustomPrimaryButton";
import Dropdown from "react-multilevel-dropdown";
import SearchInput from "../inputs/search/Search";
import checkBoxBase from "../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../assests/icons/checkbox-checked-dark.svg";
import filterDarkIcon from "../../assests/icons/darkFilter.svg";
import filterIcon from "../../assests/icons/filter.svg";

const MultilevelDropdown = ({
  data,
  setFilterData,
  onSubmitFilter,
  searchData,
  setCategoryFilter,
  resetdata,
  allCatagories,
  INITIAL_FILTER,
  personalAddresses,
  corporateAddresses,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [searchSubmit, setSeachSubmit] = useState([]);
  const [finalSubmit, setFinalSubmit] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  useEffect(() => {
    if (isFilterApplied)
      document.getElementById("multilevel-dropdown-root").click();
    setIsFilterApplied(false);
  }, [isFilterApplied]);

  const handleChangeChecked = (e, sublist, list, index, subindex) => {
    const newData = [...data];
    newData[index].subMenu[subindex].value = e.target.checked;
    setFilterData(newData);
  };
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const handleSearchCheck = (e, subindex, sublist) => {
    const newData = [...searchFilter];
    newData[subindex].value = e.target.checked;
    let filtered = newData.filter((m) => m.value);
    setSeachSubmit(filtered.map((f) => f.id));

    const searchArray = [...searchSubmit];
    if (sublist.value) {
      searchArray.push(sublist.id);
      setFinalSubmit(searchArray);
    } else {
      let arr = [...finalSubmit];
      let removeIndex = arr.indexOf(sublist.id);
      arr.splice(removeIndex, 1);
      setFinalSubmit(arr);
    }
  };

  useEffect(() => {
    if (searchFilter && searchFilter.length == 0) {
      setSearchFilter(searchData);
    }
  }, [searchData]);

  const searchItem = (val) => {
    setSearchQuery(val);
    const filterItem = searchData?.filter((f) =>
      f.label.toLowerCase().includes(val.toLowerCase())
    );

    filterItem?.map((item) => {
      searchFilter.map((f) => {
        if (f.id === item.id) {
          item.value = f.value;
        }
      });
    });
    setSearchFilter(filterItem);
  };

  const onSubmitSearch = () => {
    setCategoryFilter(finalSubmit);
  };

  useEffect(() => {
    let reset =
      allCatagories && allCatagories.length
        ? allCatagories
            .filter((f) => f.status)
            .map((cat) => {
              return {
                label: cat.category_name,
                value: false,
                id: cat.id,
              };
            })
        : [];
    const personal =
      personalAddresses && personalAddresses.length
        ? personalAddresses.map((address) => {
            return {
              name: address.address_name || address.address_type,
              label: `${address.line1}, ${address.city}, ${address.postal_code}`,
              value: false,
              id: address.id,
              type: "personal",
            };
          })
        : [];

    const corporate =
      corporateAddresses && corporateAddresses.length
        ? corporateAddresses.map((address) => {
            return {
              name: address.address_name || address.address_type,
              label: `${address.line1}, ${address.city}, ${address.postal_code}`,
              value: false,
              id: address.id,
              type: "corporate",
            };
          })
        : [];

    let newFilter = [...INITIAL_FILTER];
    newFilter = newFilter.map((item) => ({
      ...item,
      subMenu: item?.subMenu?.map((subItem) => ({
        ...subItem,
        value: false,
      })),
    }));

    let categoryIndex;
    INITIAL_FILTER?.map((filter, index) => {
      if (filter.menu === "Category") categoryIndex = index;
    });
    if (categoryIndex && newFilter && newFilter?.length) {
      newFilter[categoryIndex].subMenu = reset;
    }
    let addressIndex;
    INITIAL_FILTER?.map((filter, index) => {
      if (filter.menu === "Pickup Address") addressIndex = index;
    });
    if (addressIndex && newFilter && newFilter?.length) {
      newFilter[addressIndex].subMenu = personal.concat(corporate);
    }
    setFilterData(newFilter);
    setSearchFilter(reset);
    setFinalSubmit([]);
    setSeachSubmit([]);
    searchItem("");
    return () => {
      searchItem("");
      let categoryIndex;
      INITIAL_FILTER?.map((filter, index) => {
        if (filter.menu === "Category") categoryIndex = index;
      });
      if (categoryIndex && newFilter && newFilter?.length) {
        newFilter[categoryIndex].subMenu = reset;
      }
      let addressIndex;
      INITIAL_FILTER?.map((filter, index) => {
        if (filter.menu === "Pickup Address") addressIndex = index;
      });
      if (addressIndex && newFilter && newFilter?.length) {
        newFilter[addressIndex].subMenu = personal.concat(corporate);
      }
      setFilterData(newFilter);
      setSearchFilter(reset);
      setFinalSubmit([]);
      setSeachSubmit([]);
    };
  }, [resetdata, INITIAL_FILTER, personalAddresses, corporateAddresses]);

  return (
    <div
      className={`multilevel-dropdown-container ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <Dropdown
        id="multilevel-dropdown-root"
        className="multilevel-dropdown"
        title={
          <span>
            Filter by
            {globalDarkMode ? (
              <img className="filter-icon" src={filterDarkIcon} alt="" />
            ) : (
              <img className="filter-icon" src={filterIcon} alt="" />
            )}
          </span>
        }
        position="right"
      >
        {data && data.length
          ? data.map((list, index) => (
              <Dropdown.Item key={index}>
                {list.menu}
                {list.type === "search" ? (
                  <Dropdown.Submenu className="submenu-class">
                    <div
                      style={{
                        padding: "10px 5px",
                        backgroundColor: globalDarkMode ? "#292828" : "",
                      }}
                    >
                      <SearchInput
                        placeholder="Search for categories"
                        type="text"
                        onChange={(e) => searchItem(e.target.value)}
                        value={searchQuery}
                        onCancle={() => {
                          setSearchFilter(
                            allCatagories && allCatagories.length
                              ? allCatagories
                                  .filter((f) => f.status)
                                  .map((cat) => {
                                    return {
                                      label: cat.category_name,
                                      value: false,
                                      id: cat.id,
                                    };
                                  })
                              : []
                          );
                          searchItem("");
                        }}
                      />
                    </div>
                    <div className="list-items">
                      {searchFilter && searchFilter.length ? (
                        searchFilter.map((sublist, subindex) => (
                          <Dropdown.Item
                            key={subindex}
                            className="itemClass text-sm-medium-14"
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{ marginLeft: -1 }}
                                  checkedIcon={
                                    <img
                                      src={
                                        globalDarkMode
                                          ? checkBoxPrimaryDark
                                          : checkBoxPrimary
                                      }
                                      alt=""
                                    />
                                  }
                                  icon={
                                    <img
                                      src={
                                        globalDarkMode
                                          ? checkBoxBaseDark
                                          : checkBoxBase
                                      }
                                      alt=""
                                    />
                                  }
                                  checked={sublist.value}
                                  onChange={(e) =>
                                    handleSearchCheck(e, subindex, sublist)
                                  }
                                />
                              }
                              label={
                                <div className="text-sm-medium-14 text">
                                  {sublist.label}
                                </div>
                              }
                            />
                          </Dropdown.Item>
                        ))
                      ) : (
                        <div className="text-xs-medium-12 not-found">
                          No category found
                        </div>
                      )}
                    </div>
                    <div className="apply">
                      <CustomPrimaryButton
                        fullWidth
                        size="small"
                        onClick={() => {
                          setIsFilterApplied(true);
                          onSubmitSearch();
                        }}
                        disabled={searchFilter?.length == 0}
                      >
                        Apply
                      </CustomPrimaryButton>
                    </div>
                  </Dropdown.Submenu>
                ) : (
                  <Dropdown.Submenu className="submenu-class">
                    {list.type === "search" ? (
                      <Input
                        className="form-control-alternative"
                        id="input-postal-code"
                        placeholder="Ex: Title, Description"
                        type="text"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                      />
                    ) : (
                      ""
                    )}
                    <div className="list-items">
                      {((personalAddresses && personalAddresses?.length) ||
                        (corporateAddresses && corporateAddresses?.length)) &&
                      list.menu === "Pickup Address" ? (
                        <>
                          {personalAddresses && personalAddresses?.length && (
                            <span className="text-xs-semibold-12">
                              Personal
                            </span>
                          )}
                          {list && list.subMenu && list.subMenu.length
                            ? list.subMenu.map((sublist, subindex) => {
                                if (subindex < personalAddresses?.length) {
                                  return (
                                    <Dropdown.Item
                                      key={subindex}
                                      className="itemClass text-sm-medium-14"
                                      style={{
                                        padding: "12px",
                                      }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            sx={{ marginLeft: -1 }}
                                            checkedIcon={
                                              <img
                                                src={
                                                  globalDarkMode
                                                    ? checkBoxPrimaryDark
                                                    : checkBoxPrimary
                                                }
                                                alt=""
                                              />
                                            }
                                            icon={
                                              <img
                                                src={
                                                  globalDarkMode
                                                    ? checkBoxBaseDark
                                                    : checkBoxBase
                                                }
                                                alt=""
                                              />
                                            }
                                            checked={sublist.value}
                                            onChange={(e) =>
                                              handleChangeChecked(
                                                e,
                                                sublist,
                                                list,
                                                index,
                                                subindex
                                              )
                                            }
                                          />
                                        }
                                        label={
                                          <div className="label-container">
                                            {sublist.name ? (
                                              <span className="text-sm-bold-14 text capitalize">
                                                {sublist.name}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            <div className="text-sm-medium-14 text">
                                              {sublist.label}
                                            </div>
                                          </div>
                                        }
                                      />
                                    </Dropdown.Item>
                                  );
                                }
                              })
                            : ""}
                          {corporateAddresses && corporateAddresses?.length && (
                            <span className="text-xs-semibold-12">
                              Corporate
                            </span>
                          )}
                          {list && list.subMenu && list.subMenu.length
                            ? list.subMenu.map((sublist, subindex) => {
                                if (
                                  personalAddresses && personalAddresses?.length
                                    ? subindex >= personalAddresses?.length
                                    : subindex < list.subMenu.length
                                ) {
                                  return (
                                    <Dropdown.Item
                                      key={subindex}
                                      className="itemClass text-sm-medium-14"
                                      style={{
                                        padding: "12px",
                                      }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            sx={{ marginLeft: -1 }}
                                            checkedIcon={
                                              <img
                                                src={
                                                  globalDarkMode
                                                    ? checkBoxPrimaryDark
                                                    : checkBoxPrimary
                                                }
                                                alt=""
                                              />
                                            }
                                            icon={
                                              <img
                                                src={
                                                  globalDarkMode
                                                    ? checkBoxBaseDark
                                                    : checkBoxBase
                                                }
                                                alt=""
                                              />
                                            }
                                            checked={sublist.value}
                                            onChange={(e) =>
                                              handleChangeChecked(
                                                e,
                                                sublist,
                                                list,
                                                index,
                                                subindex
                                              )
                                            }
                                          />
                                        }
                                        label={
                                          <div className="label-container">
                                            {sublist.name ? (
                                              <span className="text-sm-bold-14 text capitalize">
                                                {sublist.name}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            <div className="text-sm-medium-14 text">
                                              {sublist.label}
                                            </div>
                                          </div>
                                        }
                                      />
                                    </Dropdown.Item>
                                  );
                                }
                              })
                            : ""}
                        </>
                      ) : list && list.subMenu && list.subMenu.length ? (
                        list.subMenu.map((sublist, subindex) => (
                          <Dropdown.Item
                            key={subindex}
                            className="itemClass text-sm-medium-14"
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{ marginLeft: -1 }}
                                  checkedIcon={
                                    <img
                                      src={
                                        globalDarkMode
                                          ? checkBoxPrimaryDark
                                          : checkBoxPrimary
                                      }
                                      alt=""
                                    />
                                  }
                                  icon={
                                    <img
                                      src={
                                        globalDarkMode
                                          ? checkBoxBaseDark
                                          : checkBoxBase
                                      }
                                      alt=""
                                    />
                                  }
                                  checked={sublist.value}
                                  onChange={(e) =>
                                    handleChangeChecked(
                                      e,
                                      sublist,
                                      list,
                                      index,
                                      subindex
                                    )
                                  }
                                />
                              }
                              label={
                                <div className="text-sm-medium-14 text">
                                  {sublist.label}
                                </div>
                              }
                            />
                          </Dropdown.Item>
                        ))
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="apply">
                      <CustomPrimaryButton
                        fullWidth
                        size="small"
                        onClick={() => {
                          setIsFilterApplied(true);
                          onSubmitFilter(index);
                        }}
                      >
                        Apply
                      </CustomPrimaryButton>
                    </div>
                  </Dropdown.Submenu>
                )}
              </Dropdown.Item>
            ))
          : ""}
      </Dropdown>
    </div>
  );
};

export default MultilevelDropdown;

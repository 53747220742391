import './PasswordPopup.scss';

import * as React from 'react';

import { useDispatch, useSelector } from "react-redux";

import Button from '@mui/material/Button';
import ButtonLight from '../../buttons/button-light/ButtonLight';
import CustomPrimaryButton from '../../buttons/primary/CustomPrimaryButton';
import Dialog from '@mui/material/Dialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function PasswordPopup({
  title,
  isDark,
  description = '',
  openPopup,
  onClose,
  children,
  IsNormal,
  actionButtons = {
    primary: {
      title: '',
      onClick: () => null,
    },
    secondary: {
      title: '',
      onClick: () => null,
    },
  },
  type
}) {
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  return (
    <Dialog
      open={openPopup}
      PaperProps={{
        sx:{
          backgroundColor:(globalDarkMode || isDark) ?'#1C1D1D' :'',
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div className={`popup-container ${(globalDarkMode || isDark)?"pclive-dark-mode":""}`}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InfoOutlinedIcon style={{ color:isDark ? "#D92D20": IsNormal ? '#0040B0' : '#D92D20' }} />
          {title && <div className={`text-sm-medium-14 ${IsNormal ?'titleNormalColor':'titleErrorColor'}`}>{title}</div>}
        </div>
        {description && (
          <div className="text-sm-medium-14 description">{description}</div>
        )}
        {children}
        {actionButtons && (
          <div className="button-container">
            {actionButtons?.primary && (
              <ButtonLight
                isDark= {isDark}
                onClick={actionButtons?.primary?.onClick}>
                {actionButtons?.primary?.title}
              </ButtonLight>
            )}
            {actionButtons?.secondary && (
              <CustomPrimaryButton
                type={type}
                onClick={actionButtons?.secondary?.onClick}>
                {actionButtons?.secondary?.title}
              </CustomPrimaryButton>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
}

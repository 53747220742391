import {
  apiDeleteCall,
  apiGetCall,
  apiPatchCall,
  apiPostCall,
  apiPutCall,
} from "../../utils/utils";

import { createSlice } from "@reduxjs/toolkit";

export const getCards = (params, handleSuccess, handleFail) => {
  apiGetCall("api/card/" + params, handleSuccess, handleFail);
};

export const deleteCard = (params, handleSuccess, handleFail) => {
  apiDeleteCall("api/card/" + params, handleSuccess, handleFail);
};
export const getSearchCards = (
  params,
  pageIndex,
  handleSuccess,
  handleFail,
  type
) => {
  apiGetCall(
    `api/search/?paginate=true&page=${pageIndex}&limit=10&${
      type == 1 ? "search_text" : "inventory_tag"
    }=${params}`,
    handleSuccess,
    handleFail
  );
};

export const getAllCsvList = (params, handleSuccess, handleFail) => {
  apiGetCall("api/csv_file/list" + params, handleSuccess, handleFail);
};

export const getAllBulkList = (params, handleSuccess, handleFail) => {
  apiGetCall("api/bulk/list" + params, handleSuccess, handleFail);
};

export const getCsvFileCards = (
  handleSuccess,
  handleFail,
  data,
  currentPage,
  query,
  start_date,
  end_date,
  sortData,
  price_filter,
  grade_filter,
  category_filter,
  selectSearch
) => {
  let userData = {
    paginate: "true",
    limit: 10,
    page: currentPage,
    file_link: data.file_link,
  };
  if (query) {
    selectSearch
      ? (userData[selectSearch] = query)
      : (userData.search_text = query);
  }
  if (start_date && end_date) {
    userData.start_date = start_date;
    userData.end_date = end_date;
  }
  if (sortData && sortData.length) {
    userData.order_by = JSON.stringify(sortData);
  }
  if (price_filter && price_filter.length) {
    userData.price_range = JSON.stringify(price_filter);
  }
  if (grade_filter && grade_filter.length) {
    userData.grade = JSON.stringify(grade_filter);
  }
  if (category_filter && category_filter.length) {
    userData.category = JSON.stringify(category_filter);
  }
  apiPostCall("api/csv_file/list", userData, handleSuccess, handleFail);
};

export const postCSV = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/csv_upload/", userData, handleSuccess, handleFail);
};

export const publishAllCsvCard = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/publish_all/", userData, handleSuccess, handleFail);
};

export const publishAllBulkCards = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/bulk/publish_all/", userData, handleSuccess, handleFail);
};

export const updateSchedule = (userData, handleSuccess, handleFail) => {
  apiPostCall(
    "api/csv_file/update_schedule",
    userData,
    handleSuccess,
    handleFail
  );
};

export const updateBulkSchedule = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/bulk/update_schedule", userData, handleSuccess, handleFail);
};

export const getBulkCardsList = (
  handleSuccess,
  handleFail,
  data,
  currentPage,
  query,
  start_date,
  end_date,
  sortData,
  price_filter,
  grade_filter,
  category_filter,
  selectSearch
) => {
  let userData = {
    paginate: "true",
    limit: 10,
    page: currentPage,
    bulk_name: data.bulk_name,
  };
  if (query) {
    selectSearch
      ? (userData[selectSearch] = query)
      : (userData.search_text = query);
  }
  if (start_date && end_date) {
    userData.start_date = start_date;
    userData.end_date = end_date;
  }
  if (sortData && sortData.length) {
    userData.order_by = JSON.stringify(sortData);
  }
  if (price_filter && price_filter.length) {
    userData.price_range = JSON.stringify(price_filter);
  }
  if (grade_filter && grade_filter.length) {
    userData.grade = JSON.stringify(grade_filter);
  }
  if (category_filter && category_filter.length) {
    userData.category = JSON.stringify(category_filter);
  }
  apiPostCall("api/bulk/list", userData, handleSuccess, handleFail);
};

export const addCardAction = (
  userData,
  handleSuccess,
  handleFail,
  isFormData
) => {
  apiPostCall("api/card/", userData, handleSuccess, handleFail, isFormData);
};

export const cardAlertAction = (
  userData,
  handleSuccess,
  handleFail,
  isFormData
) => {
  apiPostCall("api/alert/", userData, handleSuccess, handleFail, isFormData);
};

export const updateCardAction = (userData, handleSuccess, handleFail) => {
  apiPutCall("api/card/", userData, handleSuccess, handleFail);
};

export const publishBulkCard = (userData, handleSuccess, handleFail) => {
  apiPatchCall("api/card/", userData, handleSuccess, handleFail);
};

export const unpublishBulkCard = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/card/unpublish/", userData, handleSuccess, handleFail);
};

export const getRequests = (
  handleSuccess,
  handleFail,
  currentPage,
  requestState,
  uploadTypeFilter,
  statusFilter,
  addressFilter,
  searchQuery,
  sortArry
) => {
  apiGetCall(
    `api/request_list/?paginate=true&limit=10&page=${currentPage}${
      requestState && requestState?.length
        ? `&request_state=${JSON.stringify(requestState)}`
        : statusFilter && statusFilter?.length
        ? `&request_state=${JSON.stringify(statusFilter)}`
        : ""
    }${
      uploadTypeFilter && uploadTypeFilter?.length
        ? `&request_type=${JSON.stringify(uploadTypeFilter)}`
        : ""
    }${
      addressFilter && addressFilter?.length
        ? `&address_ids=${JSON.stringify(addressFilter)}`
        : ""
    }${searchQuery ? `&search_string=${searchQuery}` : ""}${
      sortArry && sortArry.length ? `&order_by=${JSON.stringify(sortArry)}` : ""
    }`,
    handleSuccess,
    handleFail
  );
};

export const getRequestHistory = (data, handleSuccess, handleFail) => {
  apiPostCall("api/request_history/", data, handleSuccess, handleFail);
};

export const doRequestActions = (data, handleSuccess, handleFail) => {
  apiPostCall("api/request_action/", data, handleSuccess, handleFail);
};

export const reRequest = (data, handleSuccess, handleFail) => {
  apiPostCall("api/re_request/", data, handleSuccess, handleFail);
};

export const editAddressAndInventoryTag = (data, handleSuccess, handleFail) => {
  apiPostCall("api/edit_bulk/", data, handleSuccess, handleFail);
};

const cardSlice = createSlice({
  name: "card",
  initialState: {
    isLoading: false,
    currentOTP: null,
    getSearchedCard: [],
    searchQuery: "",
    totalSearchPages: 1,
    pageIndex: 1,
  },
  reducers: {
    setSearchCards: (state, action) => {
      state.getSearchedCard = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
      state.pageIndex = 1;
    },
    setSearchCardsPages: (state, action) => {
      state.totalSearchPages = action.payload;
    },
    setSearchCardsPageIndex: (state, action) => {
      state.pageIndex = action.payload;
    },
  },
});
export const cardSliceReducer = cardSlice.reducer;
export const {
  setSearchCards,
  setSearchQuery,
  setSearchCardsPages,
  setSearchCardsPageIndex,
} = cardSlice.actions;

import * as React from "react";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1D2939",
    fontFamily: "Noto Sans",
    fontSize: "0.875rem",
    padding: "12px 16px",
    marginLeft: "20px",
  },
  "&.capitalize": {
    textTransform: "capitalize",
  },
}));

export default function CustomizedTooltips(props) {
  const { title, placement, className } = props;
  return (
    <div>
      <BootstrapTooltip
        title={title}
        placement={placement ? placement : "right"}
        className={className}
      >
        {props.children}
      </BootstrapTooltip>
    </div>
  );
}

import "./UploadCsv.scss";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddressSelect from "../../../../../../components/address-select/AddressSelect";
import Box from "@mui/material/Box";
import { BpRadio } from "../../../../../../components/radio/RadioIconDesign";
import Button from "@mui/material/Button";
import ButtonLight from "../../../../../../components/buttons/button-light/ButtonLight";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CustomPrimaryButton from "../../../../../../components/buttons/primary/CustomPrimaryButton";
import CustomizedTooltips from "../../../../../../components/tooltip/Tooltip";
import DatePicker from "react-datepicker";
import DownloadIcon from "@mui/icons-material/Download";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Grid } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InventoryTag from "../../../../../../components/inventory-tag/Inventorytag";
import Popup from "../../../../../../components/popup/Popup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import ReactdatePicker from "../../../../../../components/datepicker/DatePicker";
import TextField from "@mui/material/TextField";
import checkBoxBase from "../../../../../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../../../../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../../../../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../../../../../assests/icons/checkbox-checked-dark.svg";
import { getAllInventoryTag } from "../../../../../../redux/slices/seller";
import { getCategories } from "../../../../../../redux/slices/categories";
import moment from "moment";
import { toast } from "react-toastify";
import { useState } from "react";

// TODO: THIS IMPORTS WILL BE USEFUL IN FUTURE
// import ErrorMessage from "shared/error/ErrorMessage";
// import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
// import { getAllInventoryTag } from "actions/CardsAction";
const UploadCsv = (props) => {
  const dispatch = useDispatch();
  const allTagList = useSelector(
    (state) => state.inventorytag.allTagList?.data
  );
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const [validNumber, setValidNumber] = useState(false);
  const [openRaiseRequestPopup, setOpenRaiseRequestPopup] = useState(false);
  const {
    handleFileUpload,
    adminCsvError,
    isSeller,
    sellerCsvCategoryError,
    grades,
    allCatagories,
    setAdminCsvData,
    adminCsvData,
    onSubmitCsv,
    allUser,
    userDetail,
    csvRef,
    setOpenAlert,
    openAlert,
    handleCancel,
  } = props;
  //   useEffect(() => {
  //     // dispatch(getAllInventoryTag(null, adminCsvData?.seller));
  //     dispatch(getAllInventoryTag());
  //   }, [adminCsvData?.seller]);
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getAllInventoryTag());
  }, []);
  return (
    <div
      className={`admin-csv-container ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <Popup
        openPopup={openRaiseRequestPopup}
        onClose={() => setOpenRaiseRequestPopup(false)}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => {
              setOpenRaiseRequestPopup(false);
            },
          },
          secondary: {
            title: `Yes`,
            onClick: () => {
              onSubmitCsv(true);
              setOpenRaiseRequestPopup(false);
            },
          },
        }}
        title="Are you sure you want to raise request?"
        description="You will be able to publish cards with corporate address only after Admin approves it. Are you sure you want to raise request?"
      />
      <div className="add-admin-csv">
        <div className="download-template">
          <Grid container className="align-center">
            <Grid items xs="7" className="text-sm-medium-14">
              CSV template for reference{" "}
            </Grid>
            <Grid items xs="5" style={{ textAlign: "right" }}>
              <a href="https://pclive-csv.s3.amazonaws.com/default_csv/pclive-csv.csv">
                <CustomPrimaryButton>Download</CustomPrimaryButton>
              </a>
            </Grid>
          </Grid>
        </div>
        <AddressSelect
          updateAddressDetails={(selectedAddress, isCorporate) => {
            setAdminCsvData({
              ...adminCsvData,
              address_id: selectedAddress[0].id,
              is_corporate: isCorporate,
            });
          }}
        />
        <InventoryTag
          tagValue={adminCsvData?.inventory_tag}
          allTagList={allTagList && allTagList.length ? allTagList : []}
          setTagValue={(val) =>
            setAdminCsvData({ ...adminCsvData, inventory_tag: val?.title })
          }
          label="Inventory tag"
          isDisabled={adminCsvData.is_corporate}
        />
        {/* As per requirements the functionality is removed for this release,Might need in next one, hence commenting */}
        {/* <div style={{ marginLeft: "-10px" }}>
          <Checkbox
            icon={<img src={checkBoxBase} />}
            checkedIcon={<img src={checkBoxPrimary} />}
            disabled={adminCsvData?.scheduleCsv}
            checked={adminCsvData.privateRoomCheck}
            onChange={(e) =>
              setAdminCsvData({
                ...adminCsvData,
                privateRoomCheck: e.target.checked,
                isStandard: e.target.checked ? null : adminCsvData.roomType,
                csvDate: e.target.checked ? null : adminCsvData.csvDate,
                scheduleCsv: e.target.checked
                  ? false
                  : adminCsvData?.scheduleCsv,
              })
            }
          />
          <span className="text-sm-medium-14 checkText">
          Do you want a private room?
          </span>
          <div className="text-xs-medium-12 subtext">
          Using this, you can create your own auction room.
          </div>
        </div> */}

        {adminCsvData.privateRoomCheck ? (
          <>
            <RadioGroup
              defaultValue="female"
              aria-labelledby="demo-customized-radios"
              name="customized-radios"
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "2px",
              }}
              onChange={(e) =>
                setAdminCsvData({ ...adminCsvData, isStandard: e.target.value })
              }
              value={adminCsvData.isStandard}
            >
              <FormControlLabel
                value={true}
                control={<BpRadio />}
                label={
                  <div className="">
                    <span className="text-sm-medium-14 checkText">
                      Create a standard private room
                    </span>
                    <div className="text-xs-medium-12 subText">
                      Using this, all cards will be placed in a single room.
                    </div>
                  </div>
                }
              />
              <FormControlLabel
                value={false}
                control={<BpRadio />}
                label={
                  <div className="">
                    <span className="text-sm-medium-14 checkText">
                      Create a categorised private room
                    </span>
                    <div className="text-xs-medium-12 subText">
                      Here all cards will be placed in separate categorised
                      room.
                    </div>
                  </div>
                }
              />
            </RadioGroup>
          </>
        ) : (
          <>
            <div
              style={{ marginLeft: "-10px" }}
              onClick={() => {
                if (adminCsvData.is_corporate) {
                  toast.error(
                    "Publish Later feature is not available for corporate addresses"
                  );
                }
              }}
            >
              <Checkbox
                checkedIcon={
                  <img
                    src={globalDarkMode ? checkBoxPrimaryDark : checkBoxPrimary}
                    alt=""
                  />
                }
                icon={
                  <img
                    src={globalDarkMode ? checkBoxBaseDark : checkBoxBase}
                    alt=""
                  />
                }
                disabled={
                  adminCsvData.privateRoomCheck || adminCsvData.is_corporate
                }
                sx={{
                  opacity: adminCsvData.is_corporate ? 0.3 : 1,
                }}
                checked={adminCsvData.scheduleCsv}
                onChange={(e) =>
                  setAdminCsvData({
                    ...adminCsvData,
                    scheduleCsv: e.target.checked,
                    csvDate: e.target.checked ? adminCsvData.csvDate : null,
                    privateRoomCheck: e.target.checked
                      ? false
                      : adminCsvData.privateRoomCheck,
                    isStandard: e.target.checked
                      ? null
                      : adminCsvData.isStandard,
                  })
                }
              />
              <span
                className={`text-sm-medium-14 checkText ${
                  adminCsvData.is_corporate ? "disabled" : ""
                }`}
              >
                Do you want to publish this card later?
              </span>
              <div
                className={`text-xs-medium-12 subtext ${
                  adminCsvData.is_corporate ? "disabled" : ""
                }`}
              >
                Using this, you can set date and time for card to go live in an
                auction.
              </div>
            </div>
            {adminCsvData.scheduleCsv && (
              <div className="input-feild">
                <ReactdatePicker
                  setAdminCsvData={setAdminCsvData}
                  adminCsvData={adminCsvData}
                  isCsv
                />
              </div>
            )}
          </>
        )}
        <div className="image-upload-container">
          <div className="upload-div">
            <div className="text-xs-medium-12">Upload file</div>
            <CustomizedTooltips
              placement="left"
              title="To ensure proper data format, please upload a CSV file that matches the structure of the reference template provided above. Additionally, you can add images in PNG or JPG format, with a maximum size of 5MB each. Images must be provided using S3 links."
            >
              <InfoOutlinedIcon className="icon" />
            </CustomizedTooltips>
          </div>
          <input
            ref={csvRef}
            accept=".csv"
            id="contained-button-file"
            type="file"
            hidden
            onChange={handleFileUpload}
          />
          <label htmlFor="contained-button-file">
            <div
              className={`upload-area ${
                adminCsvError?.front_image ? "error" : ""
              }`}
            >
              <CloudUploadOutlinedIcon />
              <div className="text-md-medium">Click to upload CSV file</div>
              <div className="text-xs-medium-12">
                {/* Only Excel files upto 5MB are allowed to upload. */}
              </div>
              <span className="text-xs-medium-12 error">
                {/* {imgErrors.frontIMGError} */}
              </span>
              {adminCsvData?.file?.name ? (
                <span className="text-sm-semibold-16">
                  {adminCsvData.file.name}
                </span>
              ) : (
                ""
              )}
            </div>
          </label>
        </div>
        {adminCsvError && adminCsvError.length ? (
          <div className="error-message">
            <div className="error-head text-sm-medium-14">Columns missing!</div>
            <div className="desc text-xs-medium-12">
              <span>{adminCsvError?.join(", ")} </span>
              columns not found in CSV file.
            </div>
          </div>
        ) : (
          ""
        )}
        {sellerCsvCategoryError && sellerCsvCategoryError.length ? (
          <div className="error-message">
            <div className="error-head text-sm-medium-14">Columns missing!</div>
            <div className="desc text-xs-medium-12">
              <span>{adminCsvError?.join(", ")} </span>
              columns not found in CSV file.
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="button-container">
          <ButtonLight onClick={() => handleCancel()}>Cancel</ButtonLight>
          {!adminCsvData.is_corporate && (
            <CustomPrimaryButton
              onClick={() => onSubmitCsv(false)}
              className="publish"
              disabled={
                // isSeller
                //   ?
                !adminCsvData?.file?.name || !adminCsvData?.address_id
                // : Object.values(adminCsvData).includes("") ||
                //   !validNumber ||
                //   (adminCsvData.privateRoomCheck && !adminCsvData.isStandard) ||
                //   (adminCsvData.scheduleCsv && !adminCsvData.csvDate)
              }
            >
              {adminCsvData.scheduleCsv
                ? "Schedule publish for later"
                : "Publish"}
            </CustomPrimaryButton>
          )}
          {adminCsvData.scheduleCsv ? (
            ""
          ) : (
            <CustomPrimaryButton
              onClick={() =>
                !adminCsvData.is_corporate
                  ? onSubmitCsv(true)
                  : setOpenRaiseRequestPopup(true)
              }
              className="Publish"
              disabled={
                // isSeller
                //   ?
                !adminCsvData?.file?.name ||
                adminCsvData?.privateRoomCheck ||
                !adminCsvData?.address_id
                // : Object.values(adminCsvData).includes("") ||
                //   adminCsvData.privateRoomCheck
              }
            >
              {!adminCsvData.is_corporate ? "Save as draft" : "Raise request"}
            </CustomPrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadCsv;

import "./Landing.scss";

import React, { useEffect, useState } from "react";
import {
  getCategories,
  saveIntrestedCategories,
} from "../../../redux/slices/categories";
import { useDispatch, useSelector } from "react-redux"; //create react app typescript redux toolkit

import AlertBar from "../../../components/alert/Alert";
import Backdrop from "@mui/material/Backdrop";
import ButtonPrimary from "../../../components/buttons/button-primary/ButtonPrimary";
import ButtonSecondary from "../../../components/buttons/button-secondary/ButtonSecondary";
import CardCheckbox from "../../../components/card-checkbox/CardCheckbox";
import CircularProgress from "@mui/material/CircularProgress";
import landing1 from "../../../assests/icons/landing-1.jpg";
import pcliveLogo from "../../../assests/icons/pclive247.png";
import { useNavigate } from "react-router-dom";
import CustomPrimaryButton from "../../../components/buttons/primary/CustomPrimaryButton";
import PCLiveLoader from "../../../components/loader/PCLiveLoader";

const Landing = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allCategories = useSelector((state) => state.categories.data?.data);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "",
    message: "",
  });
  useEffect(() => {
    setIsLoading(true)
    dispatch(getCategories());
    setIsLoading(false)
  }, []);

  const handleCheckboxChange = (e, card) => {
    if (e.target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, card.id]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== card.id)
      );
    }
  };

  const onSave = () => {
    setIsLoading(true);
    let data =
      selectedCheckboxes && selectedCheckboxes.length
        ? selectedCheckboxes.map((id) => {
            return { category_id: id };
          })
        : [];
    const handleSuccess = () => {
      setIsLoading(false);

      setOpenAlert({
        ...openAlert,
        open: true,
        message: "Categories Updated successfully !!",
        type: "success",
      });
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    };
    const handleFail = () => {
      setIsLoading(false);
    };
    saveIntrestedCategories(data, handleSuccess, handleFail);
  };
  return (
    <div className="landing-container">
      <AlertBar
        message={openAlert.message}
        open={openAlert.open}
        handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        alertType={openAlert.type}
      />
      <PCLiveLoader open={isLoading} /> 

      <div className="image-container">
        <img src={landing1} loading='lazy'/>
        <img src={landing1} loading='lazy'/>
        <img src={landing1} loading='lazy'/>
        <img src={landing1} loading='lazy'/>
        <img src={landing1} loading='lazy'/>
        <img src={landing1} loading='lazy'/>
        <img src={landing1} loading='lazy'/>
      </div>
      <div className="modal-container">
        <div className="contents">
          <div>
            <div className="text-xs-semibold-24">
              Tell us about the cards you're interested in!
            </div>
            <div className="text-md-medium">
              We will use this information to create a personalised dashboard
              for you.
            </div>
          </div>
          <div className="cards-container">
            {allCategories && allCategories.length
              ? allCategories.map((card, index) => {
                  return (
                    <CardCheckbox
                      text={card.category_name}
                      onChange={(e) => handleCheckboxChange(e, card)}
                      value={selectedCheckboxes.includes(card.id)}
                      image={card.category_img ? card.category_img : pcliveLogo}
                    />
                  );
                })
              : ""}
          </div>

          <div className="button-container">
            <ButtonSecondary
              label={"Skip for now"}
              onClick={() => navigate("/dashboard")}
            />
      
            <CustomPrimaryButton
              onClick={onSave}
              disabled={selectedCheckboxes.length == 0}
              size="xl"
              fullWidth
            >
              Save
            </CustomPrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;

import './ShippingAndTax.scss';

import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useSelector } from 'react-redux';

const ShippingAndtax = ({deliveryCharges}) => {
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);

  return (
    <div className={`shipping-and-tax ${globalDarkMode?"pclive-dark-mode":""}`}>
      <Accordion defaultExpanded className="text-sm-medium-14 accordion-head">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Shipping
        </AccordionSummary>
        <AccordionDetails className="text-sm-medium-14">
        <Divider className="divider"/>
          Shipping cost for your this item purchased from current dealer is {" "}
          <span className="delivery-charge">{deliveryCharges && deliveryCharges.delivery_charge>0?`$${deliveryCharges.delivery_charge}`:"Free"}</span> <br/><br/> Order unlimited items in the next 24 hours (12:00 AM to 11:59
          PM) and we will bundle your purchase to provide the least expensive
          shipping possible.
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className="text-sm-medium-14 accordion-head">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Taxes
        </AccordionSummary>
        <AccordionDetails className="text-sm-medium-14">
        <Divider className="divider"/>
          We are required by law to collect sales taxes and applicable fees for
          certain tax authorities. Sales taxes vary depending on the state
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded sx={{borderRadius:2}} className="text-sm-medium-14 accordion-head">
        <AccordionSummary
        
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Help
        </AccordionSummary>
        <AccordionDetails >
        <Divider className="divider"/>

          If you need help on the above, please contact us at:
          <br/>
          <a href="mailto:support@pclive247.com"style = {{ color:'#6F91E8'}}>support@pclive247.com</a>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ShippingAndtax;

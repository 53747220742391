import "./Notifications.scss";

import DropDown from "../select/dropdown-select/DropDown";
import React from "react";
import { useSelector } from "react-redux";

const Notifications = ({ allNotifications, getCurrentDate, bell }) => {
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  return (
    <DropDown
      className="NotificationCardDropDown"
      icon={
        <div className="nav-action-icons">
          {/* Need this code on upcoming functionality */}
          {/* {allNotifications?.notification?.data.length > 0 && (
              <span className="notificationDot">.</span>
            )} */}
          <img className="notificationIcon" src={bell} alt="bellIcon" />
        </div>
      }
      subtitle={"Notification"}
    >
      {allNotifications?.notification?.data &&
      allNotifications?.notification?.data.length ? (
        allNotifications?.notification?.data.map((item, i) => {
          return (
            <div
              className={`notification-container ${
                globalDarkMode ? "pclive-dark-mode" : ""
              }`}
            >
              <div className="left-content">
                <div className="text-sm-medium-14 title">{item?.title}</div>
                <div className="text-sm-medium-14 card-no body">
                  {item?.body.split("Thank")[0]}
                </div>
              </div>
              <div className="right-content">
                <div className="text-sm-medium-14 date">
                  {getCurrentDate(item.notified_at, "date")}
                </div>
                <div className="text-sm-medium-14">
                  {getCurrentDate(item.notified_at, "time")}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <span className="text-sm-medium-14">No new notifications</span>
      )}
    </DropDown>
  );
};

export default Notifications;

import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getSearchCards,
  setSearchCards,
  setSearchCardsPages,
  setSearchQuery,
} from "../../../redux/slices/card";
import { onMessageListener, requestForToken } from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ButtonOutlined from "../../../components/buttons/button-outlined/ButtonOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import CustomAutocomplte from "../../../components/autocomplete/CustomAutocomplte";
import CustomPrimaryButton from "../../../components/buttons/primary/CustomPrimaryButton";
import CustomSearch from "../../../components/custom-search/CustomSearch";
import DropDown from "../../../components/select/dropdown-select/DropDown";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Notifications from "../../../components/notification/Notifications";
import PCLiveLoader from "../../../components/loader/PCLiveLoader";
import { ReactComponent as SearchIcon } from "../../../assests/icons/Search.svg";
import SearchInput from "../../../components/inputs/search/Search";
import { ReactComponent as Settingdark } from "../../../assests/icons/settings-dark.svg";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import bell from "../../../assests/icons/bell.svg";
import belldark from "../../../assests/icons/belldark.svg";
import { getAllNotifications } from "../../../redux/slices/auth";
import { getUserInfo } from "../../../redux/slices/profile";
import home from "../../../assests/icons/home.svg";
import homeDark from "../../../assests/icons/home-dark.svg";
import homeBlue from "../../../assests/icons/home-blue.svg";
import homeBlueLight from "../../../assests/icons/home-blue-light.svg";
import pcLiveLogo from "../../../assests/icons/pcLiveNewLogo.svg";
import { setCurrentUserType } from "../../../redux/slices/auth";
import { setRoomTypes } from "../../../redux/slices/auction";
import { setSettingOptions } from "../../../redux/slices/profile";
import { ReactComponent as Settings } from "../../../assests/icons/settings.svg";
import { toast } from "react-toastify";
import { toggleSeller } from "../../../redux/slices/seller";
import { useNavigate } from "react-router-dom";

const Header = ({ usertype, setUserType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.profile);
  const searchPageIndex = useSelector((state) => state.card.pageIndex);
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const currentUserType = useSelector((state) => state.auth.currentUserType);
  const [value, setValue] = useState();
  const [data, setData] = useState([""]);
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState(1);
  const [isShowDD, setIsDDShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [currentSettingOpen, setcurrentSetting] = useState(null);
  const allNotifications = useSelector((state) => state.notification);

  useEffect(() => {
    dispatch(setSearchQuery(""));
  }, []);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("userDetails");
    dispatch(getUserInfo());
    dispatch(getAllNotifications());
    setIsAuth(isAuthenticated);
    requestForToken();
  }, [window.location.pathname]);
  //Need this code later on
  // useEffect(()=>{
  //   onMessageListener()
  //     .then((payload) => {
  //       console.log({title: payload?.notification?.title, body: payload?.notification?.body});
  //       // toast.success(payload?.notification?.title)
  //     })
  //     .catch((err) => console.log('failed: ', err));
  // },[])
  useEffect(() => {
    getSearchCards(
      value,
      searchPageIndex,
      handleSuccess,
      handleFail,
      searchType
    );
  }, [value, searchPageIndex, searchType]);

  const handleSuccess = (res) => {
    let response = res?.data?.data.map((item) => {
      return {
        label: item.title,
        id: item.id,
      };
    });
    dispatch(setSearchCards(res?.data?.data));
    dispatch(setSearchCardsPages(res?.data?.total_pages));
    setData(response);
    setLoad(false);
  };

  const onSettingHandle = (val) => {
    navigate("/profile");
    dispatch(setSettingOptions(val));
    setcurrentSetting(!currentSettingOpen);
  };

  const getCurrentDate = (tempDate, str) => {
    const currentDate = new Date(tempDate);
    if (str === "date") {
      const currentDayOfMonth = currentDate.getDate();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      const dateString =
        currentDayOfMonth + "-" + (currentMonth + 1) + "-" + currentYear;
      return dateString;
    }
    if (str === "time") {
      const timeString = currentDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return timeString;
    }
    return "";
  };
  const handleFail = (error) => {
    setLoad(false);
    console.log(error);
  };
  const sellerToggle = (error) => {
    if (!userInfo?.is_seller) {
      toggleSeller(
        {},
        (body) => {
          if (body.data.success) {
          }
        },
        (body) => {
          toast.error("error occured!");
        }
      );
    }
  };

  const onClickSearch = (type) => {
    setLoad(true);
    setValue(search);
    setSearchType(type);
    setIsDDShow(false);
    dispatch(setSearchQuery(search));
  };
  const searchOnChange = (e) => {
    setSearch(e.target.value?.replace(/^\s+/, ""));
    if (e.target.value?.trim()) {
      setIsDDShow(true);
    } else {
      setIsDDShow(false);
      dispatch(setSearchQuery(""));
    }
  };
  const onCancles = () => {
    setSearch("");
    dispatch(setSearchQuery(""));
    setIsDDShow(false);
  };

  return isAuth ? (
    <div
      className={`navbar-container  ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <PCLiveLoader open={load} />
      <Grid container spacing={2} className="header-container">
        <Grid
          item
          md={2}
          className="logo-container"
          onClick={() => {
            localStorage.removeItem("roomType");
            navigate("/dashboard");
            dispatch(setRoomTypes(1));
          }}
        >
          <img src={pcLiveLogo} alt="pcLiveLogo" loading="lazy" />
          <div className="text-xl-medium-20">PCLive247</div>
          <hr />
        </Grid>
        <Grid item md={3}>
          <div className="seller-buyer-button">
            <span className="text-sm-medium-14">I am a</span>{" "}
            <div
              className={`button-container-switch ${
                currentUserType === "buyer" ? "" : "switch"
              }`}
            >
              <CustomPrimaryButton
                className={currentUserType === "buyer" ? "buyer" : "seller"}
                onClick={() => {
                  localStorage.removeItem("roomType");
                  dispatch(
                    setCurrentUserType(
                      currentUserType === "buyer" ? "buyer" : "seller"
                    )
                  );
                  dispatch(setRoomTypes(1));
                }}
              >
                {currentUserType === "buyer" ? "Buyer" : "Seller"}
              </CustomPrimaryButton>
              <ButtonOutlined
                className={currentUserType === "buyer" ? "seller" : "buyer"}
                onClick={() => {
                  localStorage.removeItem("roomType");
                  dispatch(setRoomTypes(1));
                  dispatch(
                    setCurrentUserType(
                      currentUserType === "buyer" ? "seller" : "buyer"
                    )
                  );
                  sellerToggle();
                }}
              >
                {currentUserType === "buyer" ? "Seller" : "Buyer"}
              </ButtonOutlined>
            </div>
          </div>
        </Grid>
        <Grid item md={7}>
          <div className="nav-right-container">
            {window.location.pathname !== "/profile" && (
              <div className="search-bar">
                <CustomSearch
                  onChange={(e) => searchOnChange(e)}
                  value={search}
                  isDDShow={isShowDD}
                  onClickSearch={onClickSearch}
                  icon={<SearchIcon />}
                  onCancle={onCancles}
                />
              </div>
            )}
            <div className="nav-action-icons">
              <div
                className={`image-container ${
                  window.location.pathname === "/dashboard" ? "home" : ""
                }`}
                onClick={() => {
                  localStorage.removeItem("roomType");
                  navigate("/dashboard");
                }}
              >
                <img
                  src={
                    window.location.pathname === "/dashboard"
                      ? globalDarkMode
                        ? homeBlueLight
                        : homeBlue
                      : globalDarkMode
                      ? homeDark
                      : home
                  }
                  alt="home"
                />
              </div>
              <Notifications
                getCurrentDate={getCurrentDate}
                allNotifications={allNotifications}
                bell={globalDarkMode ? belldark : bell}
              />
              <DropDown
                className="SettingDropDown"
                currentSettingOpen={currentSettingOpen}
                icon={
                  <div className="nav-action-icons">
                    {globalDarkMode ? <Settingdark /> : <Settings />}
                  </div>
                }
                subtitle={"Settings"}
              >
                <div
                  onClick={() => onSettingHandle(6)}
                  className="settingLinks"
                >
                  <SettingsOutlinedIcon />
                  <span className="text-sm-medium-14 linksName">
                    Account Settings
                  </span>
                </div>

                <div
                  onClick={() => onSettingHandle(3)}
                  className="settingLinks"
                >
                  <CreditCardOutlinedIcon />
                  <span className="text-sm-medium-14 linksName">
                    Manage Payments
                  </span>
                </div>

                <div
                  onClick={() => onSettingHandle(4)}
                  className="settingLinks"
                >
                  <ShoppingCartOutlinedIcon />
                  <span className="text-sm-medium-14 linksName">My orders</span>
                </div>
              </DropDown>
              <div
                className="image-container profile-pic"
                onClick={() => navigate("/profile")}
              >
                <img
                  src={
                    userInfo?.data?.data?.profile_picture
                      ? userInfo?.data?.data?.profile_picture
                      : pcLiveLogo
                  }
                  height={30}
                  alt=""
                  loading="lazy"
                  className={`profile ${
                    window.location.pathname === "/profile" ? "active" : ""
                  }`}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  ) : (
    ""
  );
};

export default Header;

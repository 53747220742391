import './AccountSetting.scss';

import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { checkPassword, deleteAccountApi } from '../../../../redux/slices/auth';
import {
  getUserInfo,
  toggleNotification,
} from '../../../../redux/slices/profile';
import { useDispatch, useSelector } from 'react-redux';

import ChangePasswordDialog from './change-password-dialog/ChangePasswordDialog';
import Input from '../../../../components/inputs/email/Input';
import PasswordPopup from '../../../../components/popup/password-popup/PasswordPopup';
import Toggle from '../../../../components/toggles/Toggle';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PCLiveLoader from '../../../../components/loader/PCLiveLoader';

const AccountSetting = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    password: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [load , setLoad] = useState(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState(false);
  const userInfo = useSelector(state => state.profile.data);
  const isLoading = useSelector(state => state.profile.isLoading);

  const handleToggleNotification = active => {
    toggleNotification(
      {
        active,
      },
      res => {
        if (res?.data?.success) {
          toast.success('Notification setting updated');
          setLoad(true);
          dispatch(getUserInfo());
          setLoad(false);
        }
      },
    );
  };

  const isValid = () => {
    let errors = {};

    if (password === '' || password.length < 6) {
      errors.password = true;
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleDeleteAccount = () => {
    if (!isValid()) {
      return;
    }

    checkPassword(
      {
        password,
      },
      res => {
        if (res?.data?.success) {
          deleteAccount();
        } else {
          toast.error(res?.data?.message);
        }
      },
      error => console.log({ error }),
    );
  };

  const deleteAccount = () => {
    deleteAccountApi(
      {},
      res => {
        if (res?.data?.success) {
          toast.success('Account deleted');
          handleSuccess();
        } else {
          toast.error(res?.data?.message);
        }
      },
      error => console.log({ error }),
    );
  };

  const handleSuccess = res => {
    localStorage.clear();
    navigation('/login');
  };

  return (
    <div className="profile-preference-container">
      <PCLiveLoader open={isLoading || load} /> 

      <div className="text-md-medium-16 heading">Account settings</div>
      <Toggle
        title={'Allow notification'}
        descricption={
          'This grants permission for the app to send you alerts and updates.'
        }
        onClick={() => handleToggleNotification(!userInfo?.data?.notify)}
        toggleText={userInfo?.data?.notify ? 'Turn off' : 'Turn on'}
      />
      <Toggle
        title={'Change password'}
        descricption={
          "This allows you to update your account's security by selecting a new password."
        }
        toggleText={'Change password'}
        onClick={() => setOpenChangePasswordDialog(true)}
      />
      <Toggle
        title={'Delete account'}
        descricption={
          'On closing your account, you may permanently loose your profile and associated data from the platform.'
        }
        toggleText={'Delete account'}
        onClick={() => setOpenPopup(true)}
        type="error"
      />
      {openChangePasswordDialog && (
        <ChangePasswordDialog
          openDialog={openChangePasswordDialog}
          onClose={() => setOpenChangePasswordDialog(false)}
        />
      )}

      <PasswordPopup
        openPopup={openPopup}
        type="error"
        onClose={() => {
          setOpenPopup(false);
          setPassword('');
          setErrors({ password: '' });
        }}
        actionButtons={{
          primary: {
            title: 'No, cancel and exit',
            onClick: () => {
              setOpenPopup(false);
              setPassword('');
              setErrors({ password: '' });
            },
          },
          secondary: {
            title: 'Delete account',
            onClick: () => handleDeleteAccount(),
          },
        }}
        title={'Are you sure about deleting your account?'}
        description="On closing your account, you may permanently loose your profile and associated data from the platform.">
        <div style={{ marginTop: 20 }}>
          <Input
            placeholder={'Password'}
            label={'Enter password to continue'}
            type={showPassword ? 'text' : 'password'}
            onChange={e => setPassword(e.target.value)}
            onClickIcon={() => setShowPassword(!showPassword)}
            error={errors.password}
            errorMsg={'Please enter valid password'}
            value={password}
          />
        </div>
      </PasswordPopup>
    </div>
  );
};

export default AccountSetting;

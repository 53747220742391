import './Pagination.scss';

import { useSelector } from "react-redux";

import { ReactComponent as ArrowBackIcon } from "../../assests/icons/arrow-back-light.svg";
import { ReactComponent as ArrowForwardIcon } from "../../assests/icons/arrow-forward-light.svg";
import { ReactComponent as ArrowBackIconDark } from "../../assests/icons/arrowBack.svg";
import { ReactComponent as ArrowForwardIconDark } from "../../assests/icons/arrowForward.svg";
import PaginationItem from "@mui/material/PaginationItem";
import Paginations from "@mui/material/Pagination";

export default function Pagination({
  index,
  onNext,
  handleChange,
  onPrevious,
  totalPage,
  alignRight,
  isDark,
  isDashboard,
}) {
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  return (
    <div
      className={`pagination-container ${alignRight ? "right" : ""} ${
        isDashboard ? "fixed" : ""
      } ${globalDarkMode || isDark ? "pclive-dark-mode" : ""}`}
    >
      <Paginations
        count={totalPage}
        page={index}
        onChange={(e, page) => handleChange(page)}
        renderItem={(item) => (
          <PaginationItem
            slots={{
              previous: globalDarkMode ? ArrowBackIconDark : ArrowBackIcon,
              next: globalDarkMode ? ArrowForwardIconDark : ArrowForwardIcon,
            }}
            {...item}
          />
        )}
      />
    </div>
  );
}

import { Radio } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

const BpIcon = styled("span")(({ darkTheme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  border: darkTheme ? "1px solid #3B3A3A" : "1px solid #D0D5DD",
  marginLeft: "8px",
  backgroundColor: darkTheme ? "#3B3A3A" : "#f5f8fa",
  backgroundImage: darkTheme
    ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
    : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #004ABC",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: darkTheme ? "#3B3A3A" : "#fff",
    boxShadow: darkTheme
      ? "0px 0px 0px 4px #605F5F"
      : "0px 0px 0px 4px #E7F0FF",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: darkTheme ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ darkTheme }) => ({
  backgroundColor: darkTheme ? "#3B3A3A" : "#ffffff",
  backgroundImage: darkTheme
    ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
    : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: "20px",
    height: "20px",
    backgroundImage: darkTheme
      ? "radial-gradient(#6F91E8,#6F91E8 28%,#292828 32%)"
      : "radial-gradient(#0E54CA,#0E54CA 28%,#fff 32%)",
    content: "''",
    border: darkTheme ? "1px solid #6F91E8" : "1px solid #0E54CA",
    borderRadius: "100%",
    marginLeft: "-1px",
    marginTop: "-1px",
  },
  "input:hover ~ &": {
    backgroundColor: darkTheme ? "#3B3A3A" : "#ffffff",
    filter: darkTheme
      ? "drop-shadow(0px 0px 8px #605F5F)"
      : "drop-shadow(0px 0px 10px #E7F0FF)",
  },
}));

export const BpRadio = (props) => {
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon darkTheme={globalDarkMode} />}
      icon={<BpIcon darkTheme={globalDarkMode} />}
      {...props}
    />
  );
};

import "./BuyerDashboard.scss";

import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCategories, getIntrestedCategories, getRoomsCatgories } from "../../../../../redux/slices/categories";
import {
  getCategorisedRooms,
  getDealersRooms,
  getRooms,
  setFilters,
} from "../../../../../redux/slices/rooms";
import { useDispatch, useSelector } from "react-redux";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AuctionRoom from "../../../../../components/auction-room/AuctionRoom";
import ButtonGray from "../../../../../components/buttons/button-gray/ButtonGray";
import ButtonLinkGrey from "../../../../../components/buttons/link-grey/ButtonLinkGrey";
import CustomizedRadios from "../../../../../components/radio/Radio";
import Dialog from "../../../../../components/dialog/Dialog";
import FilterDropdown from "../../../../../components/filter/FilterDropdown";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Input from "../../../../../components/inputs/email/Input";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import MultipleSelectCheckmarks from "../../../../../components/select/checkbox-select/CheckboxSelect";
import { NoData } from "../../../../../components/no-data/NoCards";
import PCLiveLoader from "../../../../../components/loader/PCLiveLoader";
import Pagination from "../../../../../components/pagination/Pagination";
import RadioSelect from "../../../../../components/radio-select/RadioSelect";
import RadioSelectComponent from "../../../../../components/select/radio-select/RadioSelectComponent";
import SearchInput from "../../../../../components/inputs/search/Search";
import categoryCover from "../../../../../assests/icons/category-cover.PNG";
import searchIcon from "../../../../../assests/icons/search-button.svg";
import { setSettingOptions } from "../../../../../redux/slices/profile";
import { useNavigate } from "react-router-dom";
import { stringToLowerCase } from "../../../../../utils/stringHelper";

const BuyerDashboard = ({ noSearchData , roomTypes}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [roomType, setRoomType] = useState();
  const [graded, setGraded] = useState("");
  const [load, setLoad] = useState(true);
  const [priceRange, setPriceRange] = useState(null);
  const [data, setData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentCategory, setCurrentcategory] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [homePage, setHomePage] = useState(1);
  const [categories, setCategories] = useState(null);

  const [intrestedCategories, setIntrestedCategories] = useState([]);
  const [viewAllDilog, setViewAllDilog] = useState({
    open: false,
    title: "",
    data: [],
    category: { name: "", priceRange: "" },
  });
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [intrestedCategoryFilters, setIntrestedCategoryFilters] = useState([]);
  const isLoading = useSelector((state) => state.rooms.isLoading);
  const filterInfo = useSelector((state) => state.rooms.filterInfo);
  const allDealersRoom = useSelector((state) => state.rooms.allDealersRoom);
  const allCategories = useSelector((state) => state.categories.data?.data);
  const categoryPages = useSelector((state) => state.categories.data);
  const categoryLoad = useSelector((state) => state.categories.isLoading);
  const userInfo = useSelector((state) => state.profile?.data?.data);
  const rommTyped = useSelector((state) => state.auction.roomType);
  const selectedTab = useSelector((state) => state.profile?.selectedTab);
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const noFiltersApplied =
    !filterInfo?.price_range && !filterInfo?.graded && !filterInfo?.category;

  useEffect(() => {
    if (roomType == 3) {
      if (currentCategory?.id) {
        setLoad(true);
        getCategorisedRooms(
          {
            ...(categoryFilters &&
              categoryFilters.length > 0 && {
                category: categoryFilters,
              }),
            graded,
            ...(priceRange &&
              priceRange.length > 0 && {
                price_range: priceRange,
              }),
          },
          (res) => {
            setData(res?.data);
            setLoad(false);
          },
          (error) => {
            console.log({ error });
            setLoad(false);
          },
          currentCategory.id,
          pageIndex
        );
      }
    } else if (roomType == 2) {
      dispatch(getDealersRooms({ pageIndex }));
    }
    if (roomType == 3 && !currentCategory?.id) {
      dispatch(getCategories({ paginate: true, page: pageIndex }));
    } else if (roomType == 1) {
      setLoad(true);
      getRooms(
        {
          ...(selectedTab === 1
            ? intrestedCategoryFilters &&
              intrestedCategoryFilters.length > 0 && {
                category: intrestedCategoryFilters,
              }
            : categoryFilters &&
              categoryFilters.length > 0 && {
                category: categoryFilters,
              }),
          graded,
          ...(priceRange &&
            priceRange.length > 0 && {
              price_range: priceRange,
            }),
        },
        pageIndex,
        (res) => {
          setData(res?.data);
          setLoad(false);
        },
        (error) => {
          console.log({ error });
          setLoad(false);
        }
      );
    }
  }, [
    graded,
    priceRange,
    categoryFilters,
    intrestedCategoryFilters,
    currentCategory?.id,
    pageIndex,
    roomType,
    rommTyped,
  ]);
  useEffect(() => {
    var myDiv = document.getElementById("dashboard-right-content");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }, [pageIndex]);
  // Need this code for later releases
  // useEffect(()=>{
  //   if(roomType==3){
  //   dispatch(getCategories({paginate:true,page:pageIndex}))
  //   }
  // },[roomType,pageIndex])

  useEffect(() => {
    if (filterInfo) {
      setGraded(filterInfo?.graded);
      setPriceRange(filterInfo?.price_range);
      setCategoryFilters(filterInfo?.category);
      setIntrestedCategoryFilters(filterInfo?.category);
    }
  }, [filterInfo]);

  const onReset = () => {
    setCategoryFilters([]);
    setIntrestedCategoryFilters([]);
    setGraded("");
    setPriceRange(null);
    dispatch(setFilters({}));
  };
  useEffect(() => {
    let roomNumber = localStorage.getItem("roomType");
    let currentRoom = roomNumber ? roomNumber : rommTyped;
    setRoomType(1);
    if (noFiltersApplied) {
      onReset();
    }
  }, [roomType, rommTyped]);
  useEffect(() => {
    if (allCategories && allCategories.length && searchQuery) {
      let newCategory = allCategories.filter((f) =>
        f.category_name?.toLowerCase().includes(stringToLowerCase(searchQuery))
      );
      setCategoryList(newCategory);
      return;
    }
    setCategoryList(allCategories);
  }, [allCategories, searchQuery]);
  useEffect(() => {
    setCategoryList(allCategories);
    if (allCategories && allCategories.length && searchQuery) {
      dispatch(
        getCategories({
          paginate: true,
          page: pageIndex,
          searchQuery: stringToLowerCase(searchQuery),
        })
      );
    }
    if (!searchQuery) {
      dispatch(getCategories({ paginate: true, page: pageIndex }));
    }
  }, [searchQuery]);
  const setCurrentRoom = async (id, name) => {
    let myPromise = new Promise(function (myResolve, myReject) {
      localStorage.setItem("room_id", id);
      localStorage.setItem("room_name", name);
      myResolve(navigate("/auction")); // when successful
      myReject(); // when error
    });
  };

  const viewAllClick = (ele) => {
    if (roomType == 3) {
      if (currentCategory?.id) {
        getCategorisedRooms(
          {
            seller_username: ele.dealer_username,
            categoryId: currentCategory?.id,
          },
          (res) => {
            setViewAllDilog({
              ...viewAllDilog,
              title: ele.dealer_username,
              open: true,
              data: res?.data?.data,
            });
          },
          (error) => {
            console.log({ error });
          }
        );
      }
    } else {
      let categorydata = { name: ele.category, priceRange: ele.price_range };
      let data = {
        category: [ele.category_id],
        graded,
      };
      if (priceRange && priceRange.length) {
        data.price_range = priceRange;
      }
      getRooms(
        data,
        1,
        (res) => {
          const response = res?.data?.data || [];
          const roomData = response?.data?.length
            ? response?.data[0]?.rooms || []
            : [];
          setViewAllDilog({
            ...viewAllDilog,
            title: ele.category,
            open: true,
            data: roomData,
            category: categorydata,
          });
        },
        (error) => {
          console.log({ error });
        }
      );
    }
  };
  useEffect(() => {
    if (noFiltersApplied) {
      setRoomTypeFun(roomTypes);
    }
  }, [roomTypes]);

  const setRoomTypeFun = (num) => {
    setRoomType(num);
    setPageIndex(1);
    // localStorage.setItem("roomType",num);
    setGraded("");
    setPriceRange(null);
  };
  const handleChange = (value) => {
    if (selectedTab === 1) {
      setCategoryFilters([]);
      setIntrestedCategoryFilters(value);
      dispatch(setFilters({ ...filterInfo, category: value }));
    } else {
      setIntrestedCategoryFilters([]);
      setCategoryFilters(value);
      dispatch(setFilters({ ...filterInfo, category: value }));
    }
  };

  useEffect(() => {
    getIntrestedCategories(
      (res) => {
        if (res?.status === 200) {
          let filtered = res.data.data;
          const objectArray = filtered.map((item, index) => {
            return { label: item?.category_name, value: item?.category_id };
          });
          setIntrestedCategories(objectArray);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, [selectedTab]);

  const getTotalPages = () => {
    if (roomType == 1) {
      return data?.total_pages;
    } else if (roomType == 2) {
      return allDealersRoom?.total_pages;
    } else if (roomType == 3 && currentCategory?.id) {
      return data?.total_pages;
    } else if (roomType == 3) {
      return categoryPages?.total_pages;
    }
  };
  useEffect(() => {
    getRoomsCatgories(
      (res) => {
        if (res?.status === 200) {
          if (res?.data?.data) {
            let filtered = res?.data?.data;
            if (res?.data?.data && intrestedCategories?.length) {
              filtered = res?.data?.data.filter(
                (obj2) =>
                  !intrestedCategories.some(
                    (obj1) => obj1.category_id === obj2.id
                  )
              );
            }
            const objectArray = filtered.map((item, index) => {
              return { label: item?.category_name, value: item?.id };
            });
            setCategories(objectArray);
          }
        }
      },
      (error) => {
        console.log({ error });
      }
    );
  }, [intrestedCategories, selectedTab]);

  return (
    <div
      className={`seller-dashboard-container ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      {load || isLoading || categoryLoad ? (
        <PCLiveLoader open={load || isLoading || categoryLoad} />
      ) : (
        <>
          <Dialog
            openDialog={viewAllDilog.open}
            onClose={() => setViewAllDilog({ ...viewAllDilog, open: false })}
            title={viewAllDilog.title}
          >
            <div className="view-all-dilog-container-dashboard">
              {viewAllDilog?.data?.map((value) => {
                return (
                  <div
                    onClick={() => {
                      setCurrentRoom(value?.id, value?.room_name);
                    }}
                  >
                    <AuctionRoom
                      img={value.first_card_image}
                      label={value?.room_name}
                      category={viewAllDilog?.category?.name}
                      price_range={viewAllDilog?.category?.priceRange}
                    />
                  </div>
                );
              })}
            </div>
          </Dialog>
          {userInfo && !userInfo?.buyer_verified && (
            <div
              className="verification text-xs-medium-12"
              onClick={() => {
                navigate("/profile");
                dispatch(setSettingOptions(2));
              }}
            >
              Verify your buyer account to purchase your preferred cards!
            </div>
          )}
          <div className="dashboard-top-filters">
            <div className="filter-container-left"></div>
            {roomType != 1 && currentCategory == null ? (
              <div></div>
            ) : (
              <div className="filter-container-right">
                {roomType == 1 && (
                  <FilterDropdown
                    title="Category"
                    list={selectedTab === 1 ? intrestedCategories : categories}
                    result={handleChange}
                    filtersChoosen={
                      intrestedCategoryFilters?.length > 0
                        ? intrestedCategoryFilters
                        : categoryFilters
                    }
                    showFocused
                    isCategorytab
                  />
                )}
                <FilterDropdown
                  title="Grade"
                  list={[
                    { label: "All", value: "" },
                    { label: "Graded", value: "true" },
                    { label: "Non graded", value: "false" },
                  ]}
                  subtitle={"Select grade Type"}
                  result={(val) => {
                    setGraded(val);
                    dispatch(setFilters({ ...filterInfo, graded: val }));
                  }}
                  type="radio"
                  radioValue={graded}
                  isVerticalRadio
                  showFocused
                />
                <MultipleSelectCheckmarks
                  label="Price"
                  onHandleChange={(val) => {
                    setPriceRange(val);
                    dispatch(setFilters({ ...filterInfo, price_range: val }));
                  }}
                  priceRange={priceRange}
                />
                <Button
                  className="reset-button"
                  variant="text"
                  onClick={() => onReset()}
                >
                  Reset all
                </Button>
              </div>
            )}
          </div>
          {noSearchData && (
            <div className="dataNotAvailable">
              <InfoOutlinedIcon />
              <div className="text-sm-medium-14 titleColor">
                We couldn’t find the items you were looking for! Please retry
                with different name.
              </div>
            </div>
          )}
          <div className="dashboard-right-content" id="dashboard-right-content">
            {roomType == 1 && (
              <>
                {data && data?.data?.data?.length
                  ? data?.data?.data?.map((ele) => {
                      return (
                        <div className="room-container" key={ele.category_id}>
                          <div className="room-list-head text-lg-semibold-18">
                            {ele.category}{" "}
                            {ele.view_all &&
                              (data && data.length == 1 ? (
                                ""
                              ) : (
                                <div
                                  className="text-sm-semibold-14 view-all"
                                  onClick={() => viewAllClick(ele)}
                                >
                                  View all
                                </div>
                              ))}
                          </div>
                          <div className="room-list-container">
                            {ele?.rooms?.map((value) => (
                              <div
                                onClick={() =>
                                  setCurrentRoom(value.id, value?.room_name)
                                }
                              >
                                <AuctionRoom
                                  img={value.first_card_image}
                                  label={value?.room_name}
                                  price_range={ele.price_range}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })
                  : !load &&
                    !isLoading &&
                    !categoryLoad && <NoData type="room" />}
              </>
            )}
            {roomType == 2 && (
              <div className="room-container">
                {/* <div className="dealer-view-container"><p>Dealer name</p> 
            <Button className="btn-dealers">View all</Button></div> */}
                <div className="room-list-container dealer">
                  {allDealersRoom &&
                  allDealersRoom.data &&
                  allDealersRoom.data.length ? (
                    allDealersRoom.data.map((value) => (
                      <div
                        onClick={() =>
                          setCurrentRoom(value.room_id, value?.room_name)
                        }
                      >
                        <AuctionRoom
                          img={value.card}
                          label={""}
                          category={value.seller_username}
                          price_range={value.room_name}
                        />
                      </div>
                    ))
                  ) : (
                    <NoData type="room" />
                  )}
                </div>
              </div>
            )}
            {roomType == 3 && currentCategory == null ? (
              <>
                <div
                  className="room-list-head text-lg-semibold-18 category"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <span className="select-container text-sm-semibold-14">
                    {" "}
                    Select category{" "}
                  </span>
                  <div className="div-search-container">
                    <SearchInput
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      columns={[
                        {
                          value: "search_text",
                          placeholder: "Search for category",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="room-container">
                  <div className="room-list-container categories">
                    {categoryList && categoryList.length
                      ? categoryList.map((value) => (
                          <div
                            className="category"
                            onClick={() => {
                              setCurrentcategory(value);
                              setPageIndex(1);
                            }}
                          >
                            <img
                              src={
                                value.category_img
                                  ? value.category_img
                                  : categoryCover
                              }
                              height={150}
                              width={"100%"}
                              alt="category"
                              loading="lazy"
                            />
                            <div className="text-sm-semibold-14 text">
                              {value.category_name}
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                  {!categoryLoad && categoryList?.length === 0 && (
                    <NoData type="payment" />
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            {currentCategory != null && roomType == 3 && (
              <div>
                <div className="category-room-head text-lg-semibold-18">
                  <KeyboardBackspaceOutlinedIcon
                    onClick={() => {
                      setCurrentcategory(null);
                      setData([]);
                    }}
                  />{" "}
                  {currentCategory.category_name}
                </div>
                {data && data?.data && data?.data?.length ? (
                  data?.data?.map((ele) => {
                    return (
                      <div className="room-container" key={ele.category_id}>
                        <div className="room-list-head text-lg-semibold-18 category-room">
                          {ele.dealer_username}{" "}
                          {ele?.rooms?.length > 5 && (
                            <div
                              className="text-sm-semibold-14 view-all"
                              onClick={() => viewAllClick(ele)}
                            >
                              View all
                            </div>
                          )}
                        </div>
                        <div className="room-list-container">
                          {ele?.rooms?.map((value) => (
                            <div
                              onClick={() =>
                                setCurrentRoom(value?.room_id, value?.room_name)
                              }
                            >
                              <AuctionRoom
                                img={value.card}
                                label={value?.room_name}
                                category={ele.category}
                                price_range={ele.price_range}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="room-container">
                    <NoData type="room" />
                  </div>
                )}
              </div>
            )}
            {/* {
          categoryList?.length > 0 ? */}
            <Pagination
              index={pageIndex}
              handleChange={(page) => setPageIndex(page)}
              totalPage={getTotalPages()}
              isDashboard
            />
            {/* : null
        } */}
          </div>
        </>
      )}
    </div>
  );
};
export default BuyerDashboard;

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button } from "@mui/material";
import ButtonLight from "../../../../../../components/buttons/button-light/ButtonLight";
import ButtonLinkGrey from "../../../../../../components/buttons/link-grey/ButtonLinkGrey";
import CustomizedTooltips from "../../../../../../components/tooltip/Tooltip";
import MultilevelDropdown from "../../../../../../components/dropdown/Dropdown";
import SearchInput from "../../../../../../components/inputs/search/Search";

const NormalTableHeader = ({
  checkedArray,
  isPublishSelected,
  setAllPublishModal,
  setCurrentPage,
  tabValue,
  filterData,
  setFilterData,
  INITIAL_FILTER,
  onChangefilter,
  resetdata,
  allCatagories,
  setCategoryFilter,
  searchQuery,
  setSearchQuery,
  onSearch,
  selectSearch,
  setSelectSearch,
  onReset,
  isBulkSingle,
  setIsBulkSingle,
  isCsvSingle,
  setIsCsvSingle,
  currentSetData,
  getCurrentSetData,
  currentBulkFile,
  currentCsvFile,
  title,
  setTableTitle,
  setOpenRerequestPopup,
  personalAddresses,
  corporateAddresses,
}) => {
  return (
    <div className="table-head-elements">
      <div className="left-content">
        {isBulkSingle || isCsvSingle ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {" "}
            <ArrowBackIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (isBulkSingle) {
                  setIsBulkSingle(false);
                  tabValue === 1
                    ? setTableTitle("Approved Requests")
                    : setTableTitle("Requests");
                } else {
                  setIsCsvSingle(false);
                  tabValue === 1
                    ? setTableTitle("Approved Requests")
                    : setTableTitle("Requests");
                }
                setCurrentPage(1);
                getCurrentSetData(!currentSetData);
              }}
            />{" "}
            <CustomizedTooltips title={title}>
              <div className="value longName overflow">{title}</div>
            </CustomizedTooltips>
          </Box>
        ) : (
          title
        )}
      </div>
      <div className="right-contents">
        <div className="right-top-contents">
          {checkedArray && checkedArray.length ? (
            <>
              {!isPublishSelected ? (
                <ButtonLight
                  onClick={() =>
                    setAllPublishModal({ type: "publish", value: true })
                  }
                >
                  Publish selected cards
                </ButtonLight>
              ) : (
                <ButtonLight
                  onClick={() =>
                    setAllPublishModal({ type: "unpublish", value: true })
                  }
                >
                  Unpublish selected cards
                </ButtonLight>
              )}
            </>
          ) : (
            <>
              <MultilevelDropdown
                data={filterData}
                setFilterData={setFilterData}
                onSubmitFilter={onChangefilter}
                resetdata={resetdata}
                INITIAL_FILTER={INITIAL_FILTER}
                corporateAddresses={corporateAddresses}
              />
              <SearchInput
                viewIcon
                isSelect
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                onSearch={onSearch}
                setSelectSearch={(val) => setSelectSearch(val)}
                radioValue={selectSearch}
                onCancle={() => onReset(false)}
                columns={[
                  {
                    label: "Name",
                    value: "search_string",
                    placeholder: "Search for Name",
                  },
                ]}
              />
            </>
          )}
          <Button
            className="reset-button"
            variant="text"
            onClick={() => onReset(false)}
          >
            Reset all
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NormalTableHeader;

import "./Input.scss";

import React, { useEffect, useState } from "react";

import CustomizedTooltips from "../../tooltip/Tooltip";
import errorIcon from "../../../assests/icons/error.svg";
import { useSelector } from "react-redux";

const Input = (props) => {
const globalDarkMode = useSelector(state => state.profile.globalDarkMode);
  const {
    placeholder,
    icon,
    label,
    type,
    onClickIcon,
    error,
    hint,
    errorMsg,
    hintMsg,
    tooltip,
    isDark,
    isAuction,
    additionalClassName = "",
    isDisabled = false,
    padding = "10px 42px 10px 14px",
  } = props;
  return (
    <div
      className={`default-input ${
        globalDarkMode || isDark ? "pclive-dark-mode" : ""
      }`}
    >
      {label ? <label className={`text-xs-medium-12 `}>{label}</label> : null}
      <div className="input-container">
        <input
          className={`input-class text-sm-medium-14 ${additionalClassName} ${
            error ? `error-input ` : ""
          } ${isDisabled ? "disabled" : ""}`}
          style={{ padding: padding }}
          type={type}
          placeholder={placeholder}
          {...props}
          autoComplete="off"
          disabled={isDisabled}
        />
        {tooltip ? (
          <span className="icon-class">
            <CustomizedTooltips title={tooltip}>
              <img
                alt={error ? "icon" : icon ? "icon" : ""}
                src={error ? errorIcon : icon}
                onClick={onClickIcon}
                loading="lazy"
              />
            </CustomizedTooltips>
          </span>
        ) : (
          <img
            alt={error ? "icon" : icon ? "icon" : ""}
            className={`icon-class ${
              (type == "password" || isAuction) && !error ? "" : " no-cursor"
            }`}
            src={error ? errorIcon : icon}
            onClick={error ? null : onClickIcon}
            loading="lazy"
          />
        )}
      </div>
      {hint ? <p className="text-sm-regular hint">{hintMsg}</p> : ""}
      {error ? <p className="text-sm-regular error">{errorMsg}</p> : ""}
    </div>
  );
};

export default Input;

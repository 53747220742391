import "./Label.scss";

import React from "react";

export default function Label(props) {
  const { label, icon,primary } = props;
  return (
    <div className="label-container" {...props}>
      <div className="action-container">
        <div className={`text-sm-semibold-14  action-label ${primary && 'primary'}`}>{label}{icon && icon}</div>
      </div>
    </div>
  );
}

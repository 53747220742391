import "./selectInput.scss";
import React from "react";
import { useSelector } from "react-redux";
import { FormControl, InputAdornment, MenuItem, Select } from "@mui/material";
import { ReactComponent as DistanceIcon } from "../../../assests/icons/distance.svg";

const SelectInput = ({
  data,
  label,
  onChange,
  value,
  error,
  errorMsg,
  disabled,
  isSearch,
  isDark,
  inputProps,
  onClick,
  placeholder,
  showIcon,
}) => {
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  return (
    <div
      className={`mainConatainer ${
        globalDarkMode || isDark ? "pclive-dark-mode" : ""
      }`}
    >
      {label ? (
        <label
          className="text-xs-medium-12"
          style={{ marginBottom: 10, color: "#667085" }}
        >
          {label}
        </label>
      ) : null}
      <FormControl>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          label=""
          className="selectSmall"
          onChange={onChange}
          value={value}
          error={error}
          disabled={disabled}
          inputProps={inputProps}
          onClick={onClick}
          displayEmpty
          renderValue={value || value === 0 ? undefined : () => placeholder}
          startAdornment={
            showIcon ? (
              <InputAdornment position="start">
                <DistanceIcon />
              </InputAdornment>
            ) : null
          }
          sx={{
            color:
              value || value === 0
                ? globalDarkMode
                  ? "#FCFCFD !important"
                  : "#1D2939 !important"
                : globalDarkMode
                ? "#605F5F !important"
                : "#aab7c4 !important",
            fontSize: "0.875rem",
            "&::placeholder": {
              color: globalDarkMode
                ? "#605F5F !important"
                : "#aab7c4 !important",
            },
            "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: globalDarkMode
                ? "#292828"
                : error
                ? "#D92D20"
                : "#D0D5DD",
            },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: globalDarkMode
                ? "#292828"
                : error
                ? "#D92D20"
                : "#D0D5DD",
            },
          }}
        >
          {data.map((item) => {
            return (
              <MenuItem
                sx={{
                  backgroundColor: globalDarkMode || isDark ? "#292828" : "",
                  color: globalDarkMode || isDark ? "#9D9C9C" : "",
                  "&.MuiMenuItem-root:hover": {
                    backgroundColor: globalDarkMode || isDark ? "#3B3A3A" : "",
                  },
                  "&.Mui-selected": {
                    backgroundColor: globalDarkMode || isDark ? "#1C1D1D" : "",
                  },
                }}
                hidden={data?.hidden}
                value={item.value}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
        {error ? (
          <p
            className="text-sm-regular"
            style={{
              color: "#D92D20",
              marginTop: 8,
            }}
          >
            {errorMsg}
          </p>
        ) : (
          ""
        )}
      </FormControl>
    </div>
  );
};

export default SelectInput;

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';

import Auction from './screens/pages/auction/Auction';
import DarkMode from './utils/DarkMode';
import Dashboard from './screens/pages/dashboard/Dashboard';
import FAQ from "./screens/pages/faq/FAQ.js";
import ForgotPassword from './screens/auth/forgot-password/ForgotPassword';
import Header from './screens/pages/header/Header';
import Landing from './screens/pages/landing/Landing';
import LightDark from './utils/DarkMode';
import Login from './screens/auth/login/Login';
import { NoData } from './components/no-data/NoCards.jsx';
import OTP from './screens/auth/otp/OTP';
import Policy from "./screens/pages/policy/Policy.js";
import { PrivateRoute } from './PrivateRoute';
import Profile from './screens/pages/profile/Profile';
import ReactImageMagnify from 'react-image-magnify';
import Register from './screens/auth/register/Register';
import ResetPassword from './screens/auth/reset-password/ResetPassword';
import TermsandConditions from "./screens/pages/termsConditions/TermsandConditions.js";
import { useSelector } from 'react-redux';

function App() {
  const location = useLocation();
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine)
    }
    window.addEventListener('online', handleOnlineStatusChange)
    window.addEventListener('offline', handleOnlineStatusChange)
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange)
      window.removeEventListener('offline', handleOnlineStatusChange)
    }
  }, [])
  const displayHeader = [
    "/login",
    "/register",
    "/landing",
    "/forgotPassword",
    "/resetPassword",
    "/otp",
    "/auction"
  ];
  return (
    <div className="App">
     {isOnline ? <>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={2}
      />
      <DarkMode />
      {!displayHeader.includes(location.pathname) && <Header />}
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forgotPassword" element={<ForgotPassword />} />
        <Route exact path="/resetPassword" element={<ResetPassword />} />
        <Route exact path="/otp" element={<OTP />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route
          exact
          path="/termsandconditions"
          element={<TermsandConditions />}
        />
        <Route exact path="/policy" element={<Policy />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute component={Dashboard} />}
        />
        <Route
          path="/auction"
          element={<PrivateRoute component={Auction} />}
        />
        <Route path="/profile" element={<PrivateRoute component={Profile} />} />
        <Route path="/landing" element={<PrivateRoute component={Landing} />} />
        <Route path="*" element={<Navigate to={"/dashboard"} replace />} />
      </Routes> </> 
      : <NoData type="lostConnection" />}
    </div>
  );
}
export default App;

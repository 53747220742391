import "./Inventorytag.scss";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { createTag, getAllInventoryTag } from "../../redux/slices/seller";

const filter = createFilterOptions();
const options = ["Option 1", "Option 2"];

const InventoryTag = ({
  tagValue,
  setTagValue,
  allTagList,
  label,
  isDisabled = false,
  setCardState,
}) => {
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const dispatch = useDispatch();
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);

  useEffect(() => {
    setValue(tagValue);
  }, [tagValue]);

  useEffect(() => {
    isDisabled && setInputValue("");
  }, [isDisabled]);

  return (
    <div
      className={`inventory-tag-container ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      {/* Need this code for future refrence and testing */}
      {/* <Autocomplete
      defaultValue={tagValue}
        value={
       tagValue
        }
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setTagValue({
              title: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            if (!/^[a-zA-Z0-9]{3,50}$/.test(newValue.inputValue)) {
              toast.error(
                "The tag name must consist of 3 to 50 alphanumeric characters without any spaces."
              );
              return;
            } else {
              createTag({ inventory_tag : newValue.inputValue }, (body) => {
                console.log(body, "<<<");
                if (body?.data?.success) {
                  toast.success(body?.data?.message);
                  dispatch(getAllInventoryTag());
                } else {
                }
              });
            }
            setTagValue({
              title: newValue.inputValue,
            });
          } else {
            setTagValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) =>
              inputValue.toLowerCase() === option?.title?.toLowerCase()
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add new tag "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={allTagList?.map((tg) => {
          return { id: tg.id, title: tg.tag_name };
        })}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(props, option) => <li style={{padding:0}} className="text-xs-medium-12" {...props}><p className="inventory-tag-list-option">{option.title}</p></li>}
        freeSolo
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <label className="text-xs-medium-12" onClick={()=> setTagValue({title:""})}>{label}</label>
            <input className="input-class" type="text" {...params.inputProps} value={tagValue}/>
          </div>
          // <TextField {...params} label="Free solo with text demo" />
        )}
      /> */}
      {label && (
        <label
          className="text-xs-medium-12"
          style={{ marginBottom: "10px", display: "block" }}
          onClick={() => setTagValue({ title: undefined })}
        >
          {label}
        </label>
      )}
      <div
        onClick={() => {
          if (isDisabled) {
            toast.error(
              "Inventory tag is not available for corporate addresses"
            );
          }
        }}
      >
        <Autocomplete
          value={value}
          inputValue={inputValue}
          disabled={isDisabled}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controllable-states-demo"
          options={allTagList?.map((tg) => {
            return { id: tg.id, title: tg.tag_name };
          })}
          renderOption={(props, option) => (
            <li style={{ padding: 0 }} className="text-xs-medium-12" {...props}>
              <p
                className={`inventory-tag-list-option ${
                  globalDarkMode ? "pclive-dark-mode" : ""
                }`}
              >
                {option.title}
              </p>
            </li>
          )}
          sx={{
            width: "100%",
            color: value
              ? globalDarkMode
                ? "#FCFCFD !important"
                : "#1D2939 !important"
              : globalDarkMode
              ? "#605F5F !important"
              : "#aab7c4 !important",
            fontSize: "0.875rem",
            "input::placeholder": {
              color: globalDarkMode
                ? "#605F5F !important"
                : "#aab7c4 !important",
            },
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <input
                className="input-class"
                type="text"
                disabled={isDisabled}
                placeholder="Eg: cricket"
                {...params.inputProps}
              />
            </div>
          )}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.title;
          }}
          freeSolo
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              setTagValue({
                title: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              if (!/^[a-zA-Z0-9]{3,50}$/.test(newValue.inputValue)) {
                toast.error(
                  "The tag name must consist of 3 to 50 alphanumeric characters without any spaces."
                );
                setTagValue({
                  title: undefined,
                });
                return;
              } else {
                createTag({ inventory_tag: newValue.inputValue }, (body) => {
                  if (body?.data?.success) {
                    toast.success(body?.data?.message);
                    dispatch(getAllInventoryTag());
                    setCardState &&
                      setCardState((prev) => {
                        return {
                          ...prev,
                          inventory_tag_name: body?.data?.data?.tag_name,
                          inventory_tag_id: body?.data?.data?.id,
                        };
                      });
                  } else {
                  }
                });
              }
              setTagValue({
                title: newValue.inputValue,
              });
            } else {
              setTagValue(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) =>
                inputValue.toLowerCase() === option?.title?.toLowerCase()
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add new tag "${inputValue}"`,
              });
            }
            return filtered;
          }}
          style={{ pointerEvents: isDisabled ? "none" : "auto" }}
        />
      </div>
    </div>
  );
};

export default InventoryTag;

import React from "react";
import "./ChatBubble.scss";
import moment from "moment";
import CustomizedTooltips from "../../../../components/tooltip/Tooltip";
import pclive from '../../../../assests/icons/pcLiveNewLogo.svg'
const ChatBubble = ({ message, currentUser }) => {
  return (
    <div className={`chat-bubble`}>
      <div className="chat text-sm-medium-14">
        {message && message.length
          ? message.map((chat) => {
              return (
                <div className="outer-container-chat">
                 {<div className={`username-container ${currentUser.username == chat.username?"mine":""}`}>
                    <img src={chat && chat.profile_pic? chat.profile_pic:pclive} alt=""/> <span className="text-xs-semibold-12">{chat.username}</span>
                  </div>}
                <div
                  className={`${
                    currentUser.username == chat.username ? "mine" : "yours"
                  } messages`}
                >
                  <div className="message last">{chat.message}</div>
                  <CustomizedTooltips
                    placement={
                      currentUser.username == chat.username ? "left" : "right"
                    }
                    title={moment(chat.message_time).format("LLL")}
                  >
                    <div
                      className={`time ${
                        currentUser.username == chat.username ? "right" : ""
                      }`}
                    >
                      { moment.utc(chat.message_time).local().format("LT")}
                    </div>
                  </CustomizedTooltips>
                </div>
                </div>
              );
            })
          : 
          <div className="helper-text">
        Chat with other participants during the auction and buy your favourite cards!
          </div>
          }
      </div>
    </div>
  );
};

export default ChatBubble;

import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./CardCheckbox.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import PCLiveLogo from "../../assests/icons/pclive247.png";

const CardCheckbox = (props) => {
  const { image, value, text } = props;

  return (
    <div className="card-checkbox-component">
      <FormControlLabel
        control={
          <Checkbox
            icon={<CircleIcon style={{ color: "#ffffff" }} />}
            checkedIcon={<CheckCircleIcon />}
            {...props}
          />
        }
        label={
          <span>
            <img
              src={image && image !== "null" ? image : PCLiveLogo}
              alt="category"
              height={40}
              width={40}
              loading="lazy"
            />
            <div className={`overlay ${value ? "" : "inactive"}`}>
              <span className="text-sm-medium-14">{text}</span>
            </div>
          </span>
        }
      />
    </div>
  );
};
export default CardCheckbox;

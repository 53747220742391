import 'react-phone-input-2/lib/style.css';
import './PhoneInput.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PhoneInput from 'react-phone-input-2';

const PhoneInputComponent = props => {
  const { setPhoneNumber, error, value } = props;
  const [dialCode, setDialCode] = useState('+1');
  const [phone, setPhone] = useState('');
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);

  useEffect(() => {
    if (value) setPhone(value);
  }, [value]);

  return (
    <div
      className={`phone-input ${globalDarkMode ? "pclive-dark-mode" : ""} ${
        props.disabled ? "disabled" : ""
      }`}
    >
      <span className="selected-dail text-md-regular-16">
        {dialCode} <KeyboardArrowDownIcon />
      </span>
      <PhoneInput
        {...props}
        country={"us"}
        value={phone ?? ""}
        onlyCountries={["us"]}
        onChange={(phone, data, e, formattedValue) => {
          setDialCode(`+${data.dialCode}`);
          setPhone(phone?.replace(data.dialCode));
          setPhoneNumber(phone);
        }}
        enableSearch
        autoFormat
        disableCountryCode
        disableCountryGuess
        placeholder=""
        inputClass={`${error ? "input-error" : ""} ${
          props.disabled ? "disabled" : ""
        }`}
        dropdownClass={error ? "input-error" : ""}
      />
    </div>
  );
};

export default PhoneInputComponent;

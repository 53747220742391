import './FilterDropdown.scss';

import * as React from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MuiCheckbox from '@mui/material/Checkbox';
import {useSelector} from "react-redux";

const FilterDropdown = props => {
  const { label, onHandleChange,data,subtitle,value,icon } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cardDetails, setCardDetail] = React.useState(false);
  const [listArray, setListArray] = React.useState([]);
  const open = Boolean(anchorEl);
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`filter-container-component ${globalDarkMode?"pclive-dark-mode":""}`}>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <div
          className="dropdown-element"
          onClick={e => {
            setCardDetail(!cardDetails);
            handleClick(e);
          }}>
          <span className='text-sm-semibold-14'>{label}</span>{' '}
          {label == 'Grade'? !cardDetails ? <ExpandMoreIcon /> : <ExpandLessIcon /> : icon}
        </div>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            padding: 2,
            minWidth: 170,

            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
        <div className="filter-container-dropdown">
          <div className="filter-head text-xs-semibold-12">{subtitle}</div>
          <Divider className="horizontal" />
      
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          onChange={(e)=>onHandleChange(e.target.value)}>
                            {
                              data && data.length?
                              data.map(item=>{
                                return(
                                  <FormControlLabel
                                  value={item.value}
                                  control={<Radio />}
                                  label={item.label}
                                  checked={item.value==value}
                                />
                                )
                              })
                            :''}
                        
                        </RadioGroup>
        </div>
      </Menu>
    </div>
  );
};

export default FilterDropdown;

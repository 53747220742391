import "./CustomSearch.scss";

import React from "react";
import SearchInput from "../inputs/search/Search";
import { useSelector } from "react-redux";

const CustomSearch = (props) => {
  const { onClickSearch } = props;
  const currentUserType = useSelector((state) => state.auth.currentUserType);
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);

  return (
    <div
      className={`custom-search-container  ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <SearchInput
        {...props}
        columns={[
          {
            value: "search_text",
            placeholder: "Search for cards",
          },
        ]}
      />
      {props.isDDShow && (
        <div className="show-div text-sm-medium-14">
          <span onClick={() => onClickSearch(1)}>
            <strong>{props.value}</strong> card in name, description
          </span>
          {currentUserType == "seller" && (
            <span onClick={() => onClickSearch(2)}>
              <strong>{props.value}</strong> in card inventory tag
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomSearch;

import "./warning.scss";

import React, { Children } from 'react';

import ButtonLinkGrey from '../buttons/link-grey/ButtonLinkGrey';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from "react-redux";

export  const Warning = ({ error, descricption, btnName ,icon, onClick , isDark}) => {
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  return (
    <div className={`noPaymentSource  ${(globalDarkMode || isDark) ? 'pclive-dark-mode' : ''}`}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
          <InfoOutlinedIcon style={{ color: '#D92D20', fontSize:"16px" }} />
          {error && <div className="text-sm-medium-14 titleColor">{error}</div>}
        </div>
    <hr className="hr"/>
    <div className="newCard">
      <div className="text-sm-medium-14 info">
        {descricption}
      </div>
      <ButtonLinkGrey
          label={
            <>
              {btnName}{" "}
              {icon}
            </>
          }
          onClick={onClick}
        />
    </div>
  </div>
  )
}
export default Warning;
import './Toggle.scss';

import { Button } from '@mui/material';
import ButtonLight from '../buttons/button-light/ButtonLight';
import React from 'react';
import {useSelector} from "react-redux";

const Toggle = ({ title, descricption, toggleText, onClick,type }) => {
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  return (
    <div className={`toggle-container ${globalDarkMode ? 'pclive-dark-mode' : ''}`}>
      <div className="left-content">
        <div className="text-sm-semibold-14">{title}</div>
        <div className="text-sm-regular">{descricption}</div>
      </div>
      <div className="button-container">
        <ButtonLight onClick={onClick} type={type}>
          {toggleText}
        </ButtonLight>
      </div>
    </div>
  );
};
export default Toggle;

import "./AddressSelect.scss";

import { FormControlLabel, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getAllAddress,
  getAllCorporateAddress,
} from "../../redux/slices/address";
import { useDispatch, useSelector } from "react-redux";

import { BpRadio } from "../radio/RadioIconDesign";
import CustomTabs from "../tabs/CustomTabs";
import SelectInput from "../select/select-input/SelectInput";
import { TabPanel } from "@mui/lab";
import { toast } from "react-toastify";

const AddressSelect = ({
  addressId,
  isCorporateCard,
  isDisabled,
  updateAddressDetails,
  showNote = true,
  isUpdateAddress,
  isUploadBulk = false,
  showIcon,
}) => {
  const dispatch = useDispatch();
  const personalAddresses = useSelector((state) => state.address.data?.data);
  const corporateAddresses = useSelector(
    (state) => state.address.corporateAddresses
  );
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);

  const [openlist, setOpenList] = useState(false);
  const [currentTab, setCurentTab] = useState(
    isUpdateAddress === undefined || isUpdateAddress ? 1 : 2
  );
  const [currentValue, setCurrentValue] = useState({
    label: "",
    value: "",
  });
  const [isCorporate, setIsCorporate] = useState(isCorporateCard);
  const [addressList, setAddressList] = useState([]);

  const tabData =
    isUpdateAddress === undefined
      ? [
          { title: "Personal", value: 1 },
          { title: "Corporate", value: 2 },
        ]
      : isUpdateAddress
      ? [{ title: "Personal", value: 1 }]
      : [{ title: "Corporate", value: 2 }];

  useEffect(() => {
    dispatch(getAllAddress());
    dispatch(getAllCorporateAddress());
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      const dropdownDiv = document.getElementById("anchor");
      if (
        dropdownDiv &&
        (event.target === dropdownDiv || dropdownDiv.contains(event.target))
      ) {
        // Click is inside the dropdown, don't close it
        return;
      }
      setOpenList(false);
    }

    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let is_corporate;
    if (!addressId || !personalAddresses || !corporateAddresses) return;
    let selectedAddress = personalAddresses.filter(
      (address) => address.id === addressId
    );
    is_corporate = false;
    setIsCorporate(false);
    if (selectedAddress && !selectedAddress.length) {
      selectedAddress = corporateAddresses.filter(
        (address) => address.id === addressId
      );
      is_corporate = true;
      setIsCorporate(true);
    }
    if (selectedAddress && selectedAddress.length) {
      setCurrentValue({
        label: `${
          is_corporate ? "Corporate Address :" : "Personal Address :"
        } ${
          selectedAddress[0]?.address_name
            ? selectedAddress[0]?.address_name
            : selectedAddress[0]?.address_type
        } : ${selectedAddress[0]?.line1}. ${selectedAddress[0]?.city}. ${
          selectedAddress[0]?.postal_code
        }`,
        value: addressId,
      });
    }
  }, [addressId, corporateAddresses, personalAddresses]);

  useEffect(() => {
    currentTab === 2
      ? setAddressList(corporateAddresses)
      : setAddressList(personalAddresses);

    if (
      (tabData?.length === 2 && currentTab === 2) ||
      (isUpdateAddress !== undefined && !isUpdateAddress)
    ) {
      setIsCorporate(true);
    } else {
      setIsCorporate(false);
    }
  }, [
    corporateAddresses,
    currentTab,
    dispatch,
    isUpdateAddress,
    personalAddresses,
    tabData?.length,
  ]);

  useEffect(() => {
    let is_corporate;
    if (currentValue.value) {
      let selectedAddress = personalAddresses?.filter(
        (address) => address.id === currentValue.value
      );
      is_corporate = false;
      setIsCorporate(false);
      if (selectedAddress && !selectedAddress.length) {
        selectedAddress = corporateAddresses?.filter(
          (address) => address.id === currentValue.value
        );
        is_corporate = true;
        setIsCorporate(true);
      }
      selectedAddress &&
        selectedAddress?.length &&
        updateAddressDetails(selectedAddress, is_corporate);
      setOpenList(false);
    }
  }, [currentValue.value]);

  return (
    <div
      className={`input-address-select ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <SelectInput
        label={isUploadBulk ? "" : "Select Pickup Address"}
        inputProps={{ readOnly: true }}
        onClick={() =>
          isDisabled
            ? toast.error("Pickup address cannot be changed")
            : setOpenList((prev) => !prev)
        }
        data={[currentValue]}
        value={currentValue.value}
        placeholder={isUploadBulk ? "Pickup Address" : "Select Pickup Address"}
        disabled={isDisabled}
        showIcon={showIcon}
      />
      {openlist && (
        <div className="dropdown-select-menu" id="anchor">
          {tabData?.length > 1 ? (
            <CustomTabs
              tabData={tabData}
              value={currentTab}
              handleChange={(e, newval) => setCurentTab(newval)}
            >
              {currentTab == 2 && showNote && (
                <div className="text-xs-medium-12">
                  {" "}
                  Note : <br />
                  <br />
                  1. You can publish only after admin approval. <br />
                  2. Once approved, you can't edit until the admin changes it to
                  personal.
                </div>
              )}
              {tabData?.map((tab, index) => (
                <TabPanel
                  key={index}
                  value={tab?.value}
                  sx={{
                    padding: "12px 0 0 0",
                  }}
                >
                  <RadioGroup
                    className="radio-group"
                    name="controlled-radio-buttons-group"
                  >
                    {addressList &&
                      addressList.length &&
                      addressList.map((address) => {
                        return (
                          <FormControlLabel
                            value={address.id}
                            name="radio"
                            checked={currentValue.value == address.id}
                            control={
                              <BpRadio
                                onClick={() =>
                                  setCurrentValue({
                                    label: `${
                                      isCorporate
                                        ? "Corporate Address :"
                                        : "Personal Address :"
                                    } ${
                                      address?.address_name
                                        ? address?.address_name
                                        : address?.address_type
                                    } : ${address?.line1}. ${address?.city}. ${
                                      address?.postal_code
                                    }`,
                                    value: address.id,
                                  })
                                }
                              />
                            }
                            label={
                              <span
                                className="text-sm-medium-14 address"
                                onClick={() =>
                                  setCurrentValue({
                                    label: `${
                                      isCorporate
                                        ? "Corporate Address :"
                                        : "Personal Address :"
                                    } ${
                                      address?.address_name
                                        ? address?.address_name
                                        : address?.address_type
                                    } : ${address?.line1}. ${address?.city}. ${
                                      address?.postal_code
                                    }`,
                                    value: address.id,
                                  })
                                }
                              >
                                <span className="text-sm-bold-14">
                                  {address?.address_name
                                    ? address?.address_name
                                    : address?.address_type}
                                </span>
                                <span>
                                  {`${address?.line1}, ${address?.city}, ${address?.postal_code}`}
                                </span>
                              </span>
                            }
                          />
                        );
                      })}
                  </RadioGroup>
                </TabPanel>
              ))}
            </CustomTabs>
          ) : (
            <RadioGroup
              className="radio-group"
              name="controlled-radio-buttons-group"
            >
              {addressList &&
                addressList.length &&
                addressList.map((address) => {
                  return (
                    <FormControlLabel
                      value={address.id}
                      name="radio"
                      checked={currentValue.value == address.id}
                      control={
                        <BpRadio
                          onClick={() =>
                            setCurrentValue({
                              label: `${
                                isCorporate
                                  ? "Corporate Address :"
                                  : "Personal Address :"
                              } ${
                                address?.address_name
                                  ? address?.address_name
                                  : address?.address_type
                              } : ${address?.line1}. ${address?.city}. ${
                                address?.postal_code
                              }`,
                              value: address.id,
                            })
                          }
                        />
                      }
                      label={
                        <span
                          className="text-sm-medium-14 address"
                          onClick={() =>
                            setCurrentValue({
                              label: `${
                                isCorporate
                                  ? "Corporate Address :"
                                  : "Personal Address :"
                              } ${
                                address?.address_name
                                  ? address?.address_name
                                  : address?.address_type
                              } : ${address?.line1}. ${address?.city}. ${
                                address?.postal_code
                              }`,
                              value: address.id,
                            })
                          }
                        >
                          <span className="text-sm-bold-14">
                            {address?.address_name
                              ? address?.address_name
                              : address?.address_type}
                          </span>
                          <span>
                            {`${address?.line1}, ${address?.city}, ${address?.postal_code}`}
                          </span>
                        </span>
                      }
                    />
                  );
                })}
            </RadioGroup>
          )}
        </div>
      )}
    </div>
  );
};

export default AddressSelect;

import "./ResetPassword.scss";

import React, { useEffect, useState } from "react";
import { forgotPassword, resendOTP } from "../../../redux/slices/auth";

import AlertBar from "../../../components/alert/Alert";
import AuthCarosel from "../carosel/AuthCarosel";
import Backdrop from "@mui/material/Backdrop";
import ButtonLinkGrey from "../../../components/buttons/link-grey/ButtonLinkGrey";
import ButtonPrimary from "../../../components/buttons/button-primary/ButtonPrimary";
import CircularProgress from "@mui/material/CircularProgress";
import CustomPrimaryButton from "../../../components/buttons/primary/CustomPrimaryButton";
import Grid from "@mui/material/Grid";
import Input from "../../../components/inputs/email/Input";
import PCLiveLoader from "../../../components/loader/PCLiveLoader";
import Timer from "../../../components/countdown/Countdown";
import eye from "../../../assests/icons/eye.svg";
import eyeOff from "../../../assests/icons/eye-off.svg";
import pcLiveLogo from "../../../assests/icons/pcLiveNewLogo.svg";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const useremail = localStorage.getItem("userEmail");
  const currentOTP = localStorage.getItem("currentOTP");
  const [isLoading, setIsloading] = useState(false);
  const [timer, setTimer] = useState(false);
  const [showPassword, setShowPassword] = useState({
    show1: false,
    show2: false,
  });

  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    lengthError: false,
    numberError: false,
    uppercaseError: false,
    specialCharError: false,
    matchError: false,
  });

  const validatePassword = () => {
    const lengthRegex = /^.{8,}$/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;

    const newErrors = {
      lengthError: !lengthRegex.test(password),
      numberError: !numberRegex.test(password),
      uppercaseError: !uppercaseRegex.test(password),
      specialCharError: !specialCharRegex.test(password),
      matchError: password !== confirmPassword,
    };

    setErrors(newErrors);

    // Return true if there are no errors
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = (e) => {
    setIsloading(true);
    if (validatePassword()) {
      const data = {
        email: useremail,
        otp: currentOTP,
        new_password: password,
        otp_validation:false

      };
      const handleSuccess = (res) => {
        if (!res.data.success) {
          setOpenAlert({
            ...openAlert,
            open: true,
            message: res.data.message,
            type: "error",
          });
          setIsloading(false);
          setTimeout(() => {
            navigate("/forgotPassword");
          }, 2000);
          return;
        }
        localStorage.clear();
        setOpenAlert({
          ...openAlert,
          open: true,
          message: res.data.message,
          type: "success",
        });
        setIsloading(false);
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      };
      const handleFail = (res) => {
        setOpenAlert({
          ...openAlert,
          open: true,
          message: "Something went wrong, Please try again.",
          type: "error",
        });
        setIsloading(false);
      };
      forgotPassword(data, handleSuccess, handleFail);
    }
  };

  useEffect(() => {
    validatePassword();
  }, [password, confirmPassword]);
  return (
    <div className="reset-password-container">]
      <PCLiveLoader open={isLoading} /> 

      <AlertBar
        message={openAlert.message}
        open={openAlert.open}
        handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        alertType={openAlert.type}
      />
       <Grid container>
       <Grid item xs={1}></Grid>
        <Grid item xs={5}>
      <div className="left-content">
        <div className="login-form">
          <div className="form-container">
          <div item md={2} className="pclive-new-logo">
                    <img src={pcLiveLogo} alt="pcLiveLogo" loading="lazy" />
                    <div className="text-xl-medium-20">PCLive247</div>
                    <hr />
                  </div>

            <div className="header-container">
                  <div className="text-sm-semibold">
                    <div>Reset your password</div>
                  </div>
                  <span className="text-md-medium">
                  Please enter a new password.
                  </span>
                </div>
                {timer ? (
              <div className="text-sm-medium-14 link">
                Session expired !{" "}
                <ButtonLinkGrey
                      onClick={() => navigate("/forgotPassword")}
                      label="Request new code"
                    />
                </div>
            ) : (
              <div className="text-sm-semibold-14 timer-content">
                Your session will expire in {" "}
                <span><Timer setTimer={setTimer} delayResend={180} />s</span>
              </div>
            )}
            <>
              <div className="input-container">
                <div className="email">
                  <Input
                    icon={showPassword.show1 ? eye : eyeOff}
                    label={"New Password"}
                    type={showPassword.show1 ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    onClickIcon={() =>
                      setShowPassword({
                        ...showPassword,
                        show1: !showPassword.show1,
                      })
                    }
                  />
                </div>
                <div className="email">
                  <Input
                    icon={showPassword.show2 ? eye : eyeOff}
                    label={"Confirm Password"}
                    type={showPassword.show2 ? "text" : "password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onClickIcon={() =>
                      setShowPassword({
                        ...showPassword,
                        show2: !showPassword.show2,
                      })
                    }
                    error={confirmPassword && errors.matchError}
                    errorMsg={"Passwords do not match."}
                  />
                </div>
                <div className="password-validator text-sm-medium-14">
                  <div>
                    <ul>
                      <li
                        className={
                          errors.lengthError ? "list-inactive" : "list-active"
                        }
                      >
                        At least 8 characters
                      </li>
                      <br />
                      <li
                        className={
                          errors.numberError ? "list-inactive" : "list-active"
                        }
                      >
                        One number
                      </li>
                    </ul>
                  </div>
                  <ul>
                    <li
                      className={
                        errors.uppercaseError ? "list-inactive" : "list-active"
                      }
                    >
                      One uppercase letter
                    </li>
                    <br />
                    <li
                      className={
                        errors.specialCharError
                          ? "list-inactive"
                          : "list-active"
                      }
                    >
                      One special character
                    </li>
                  </ul>
                </div>
              </div>
            </>
            <CustomPrimaryButton
               onClick={handleSubmit}
                disabled={timer || Object.values(errors).some((error) => error)}
                size="xl"
                fullWidth
                  >
                Reset password
            </CustomPrimaryButton>
          </div>
        </div>
      </div>
      </Grid>
      <Grid item xs={1}></Grid>
     
      <Grid xs={5}>

      <AuthCarosel />
      </Grid>
      </Grid>
    </div>
  );
};

export default ResetPassword;

import "./FilterDropdown.scss";

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Box from "@mui/material/Box";
import ButtonGray from "../buttons/button-gray/ButtonGray";
import ButtonLinkGrey from "../buttons/link-grey/ButtonLinkGrey";
import { ReactComponent as CalendarDarkIcon } from "../../assests/icons/darkcalendar.svg";
import { ReactComponent as CalendarIcon } from "../../assests/icons/calendar-new.svg";
import CustomizedRadios from "../radio/Radio";
import Divider from "@mui/material/Divider";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ClickAwayListener, Popper, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import checkBoxBase from "../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../assests/icons/checkbox-checked-dark.svg";
import { setSelectedTab } from "../../redux/slices/profile";
import CustomPrimaryButton from "../buttons/primary/CustomPrimaryButton";

const isArrayWithTruthyValues = (arr) => {
  return arr?.length > 0 && arr?.every((item) => !!item);
};

const FilterDropdown = (props) => {
  const {
    title,
    subtitle,
    list,
    result,
    isHorizontal,
    radioValue,
    type,
    isVerticalRadio,
    isSearch,
    extraComponent,
    radioUpload,
    closeRadioUpload,
    isCalendar = true,
    noBorder,
    filtersChoosen,
    showFocused,
    height = "auto",
    isMiniDatePicker,
    dateRange,
    isCategorytab,
    isUpload,
    minPrice,
    maxPrice,
    setMinPrice,
    setMaxPrice,
    onApply,
  } = props;
  const dispatch = useDispatch();
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cardDetails, setCardDetail] = React.useState(false);
  const [listArray, setListArray] = React.useState([]);
  const [selectTab, setelectedTab] = React.useState(1);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (radioUpload) {
      setAnchorEl(null);
    }
  }, [radioUpload]);

  React.useEffect(() => {
    if (isMiniDatePicker) {
      setAnchorEl(null);
    }
    if (radioValue !== "custom") {
      setAnchorEl(null);
      setCardDetail(!cardDetails);
    }
  }, [radioValue]);

  React.useEffect(() => {
    if (isArrayWithTruthyValues(dateRange)) {
      setAnchorEl(null);
    }
  }, [dateRange]);

  React.useEffect(() => {
    if (filtersChoosen && filtersChoosen.length) {
      setListArray(filtersChoosen);
    } else {
      setListArray([]);
    }
  }, [filtersChoosen]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (closeRadioUpload) {
      closeRadioUpload(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCardDetail(!cardDetails);
  };

  const onChangeCheck = (val, item) => {
    let newArry = [...listArray];
    let found = newArry.findIndex((f) => f == item.value);
    if (found < 0) {
      newArry.push(item.value);
    } else {
      newArry.splice(found, 1);
    }

    setListArray(newArry);
    result(newArry);
  };

  const onChangeRadio = (val, item) => {
    result(val);
    if (val !== "custom") {
      setAnchorEl(null);
      setCardDetail(!cardDetails);
    }
  };
  const updatedTab = (tabId) => {
    setelectedTab(tabId);
    dispatch(setSelectedTab(tabId));
  };

  // Prevent typing negative values from the keyboard
  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  // Prevent pasting negative values in the field
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window?.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  return (
    <div
      className={`filter-dropdown-container-component ${
        showFocused && (filtersChoosen?.length > 0 || radioValue)
          ? "selected-dropdown"
          : ""
      } ${open ? "open" : ""} ${isSearch ? "search" : ""} ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }
      ${noBorder ? "no-border" : ""}
      `}
      style={{
        borderRadius: isCalendar ? "8px" : "8px 0 0 8px",
        borderRightWidth: isCalendar ? "1" : "0",
        background: globalDarkMode
          ? isUpload
            ? "#0E54CA"
            : "#292828"
          : isUpload
          ? "#004ABC"
          : "#fff",
        color: isUpload ? "#fff" : "#475467",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <div
          className={`dropdown-element `}
          onClick={(e) => {
            setCardDetail(!cardDetails);
            handleClick(e);
          }}
          style={{
            color: isUpload ? "#fff" : "#475467",
          }}
        >
          <span className="text-sm-semibold-14">{title}</span>{" "}
          {dateRange ? (
            globalDarkMode ? (
              <CalendarDarkIcon />
            ) : (
              <CalendarIcon />
            )
          ) : !anchorEl ? (
            <ExpandMoreIcon className="chevron" />
          ) : (
            <ExpandLessIcon className="chevron" />
          )}
        </div>
      </Box>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <Popper
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            placement="bottom-end"
            sx={{
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: "10px !important",
              ml: 5,
              padding: 2,
              minWidth: 170,
              backgroundColor: globalDarkMode ? "#292828" : "#ffffff",
              height: "auto",
              maxHeight: height,
              "& .MuiAvatar-root": {
                width: 32,
                maxWidth: 72,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
                backgroundColor: globalDarkMode ? "#292828" : "#ffffff",
              },
            }}
          >
            <div
              className={`filter-container-dropdown  ${
                extraComponent && isCalendar ? "extra" : ""
              } ${globalDarkMode ? "pclive-dark-mode" : ""}`}
            >
              {isCategorytab ? (
                <div className="tabs">
                  <span
                    className={`text-sm-semibold-14 ${
                      selectTab == 1 ? "selected" : ""
                    }`}
                    onClick={() => updatedTab(1)}
                  >
                    {selectTab == 1 ? (
                      <ButtonLinkGrey label={"Favorite"} />
                    ) : (
                      <ButtonGray label={"Favorite"} />
                    )}
                  </span>
                  <span
                    className={`text-sm-semibold-14 ${
                      selectTab == 2 ? "selected" : ""
                    }`}
                    onClick={() => updatedTab(2)}
                  >
                    {selectTab == 2 ? (
                      <ButtonLinkGrey label={"All"} />
                    ) : (
                      <ButtonGray label={"All"} />
                    )}
                  </span>
                </div>
              ) : (
                subtitle && (
                  <div className="filter-head text-xs-semibold-12">
                    {subtitle}
                  </div>
                )
              )}
              {(isCategorytab || subtitle) && type !== "priceRange" && (
                <Divider className="horizontal" />
              )}
              {type == "radio" ? (
                <>
                  <CustomizedRadios
                    contentAlign="flex-start"
                    data={list}
                    onChange={(e) => onChangeRadio(e.target.value)}
                    value={radioValue}
                    isVerticalRadio={isVerticalRadio}
                  />
                  {extraComponent}
                </>
              ) : type == "priceRange" ? (
                <>
                  <div className="flex">
                    <TextField
                      type="number"
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                      onKeyDown={preventMinus}
                      onPaste={preventPasteNegative}
                      value={minPrice}
                      placeholder="Min Price"
                      onChange={(e) => setMinPrice(e.target.value)}
                    />
                    <TextField
                      type="number"
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                      onKeyDown={preventMinus}
                      onPaste={preventPasteNegative}
                      value={maxPrice}
                      placeholder="Max Price"
                      onChange={(e) => setMaxPrice(e.target.value)}
                    />
                  </div>
                  <div className="apply">
                    <CustomPrimaryButton
                      fullWidth
                      size="small"
                      onClick={() => {
                        if (!minPrice || !maxPrice)
                          toast.error("Please enter both price range values");
                        else if (Number(minPrice) === 0)
                          toast.error("Min Price should be greater than 0");
                        else if (Number(maxPrice) === 0)
                          toast.error("Max Price should be greater than 0");
                        else if (Number(minPrice) > Number(maxPrice))
                          toast.error(
                            "Min Price should be less than Max Price"
                          );
                        else if (minPrice && maxPrice) {
                          setAnchorEl(null);
                          onApply();
                        }
                      }}
                    >
                      Apply
                    </CustomPrimaryButton>
                  </div>
                </>
              ) : (
                <ul
                  className={isHorizontal ? "horizontal-list" : ""}
                  style={
                    !isHorizontal
                      ? { maxHeight: "300px", overflow: "auto" }
                      : null
                  }
                >
                  {list && list.length ? (
                    list.map((item) => {
                      return (
                        <li className="text-sm-medium-14">
                          <Checkbox
                            checkedIcon={
                              <img
                                src={
                                  globalDarkMode
                                    ? checkBoxPrimaryDark
                                    : checkBoxPrimary
                                }
                                alt=""
                              />
                            }
                            icon={
                              <img
                                src={
                                  globalDarkMode
                                    ? checkBoxBaseDark
                                    : checkBoxBase
                                }
                                alt=""
                              />
                            }
                            onChange={(e) =>
                              onChangeCheck(e.target.checked, item)
                            }
                            checked={listArray?.includes(item.value)}
                          />
                          {item.label ? item.label : item.category_name}
                        </li>
                      );
                    })
                  ) : (
                    <p className="text-sm-regular" style={{ margin: 0 }}>
                      No items to show
                    </p>
                  )}
                </ul>
              )}
            </div>
          </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default FilterDropdown;

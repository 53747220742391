import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import './AuthCarosel.scss';
import caroselImg from '../../../assests/icons/carosel1.png';
import login1 from '../../../assests/login/login-1.png';
import login2 from '../../../assests/login/login-2.png';
import login3 from '../../../assests/login/login-3.png';
import login4 from '../../../assests/login/login-4.png';
const AuthCarosel = () => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const handleChange = (index) => {
    setCurrentSlide(index);
  };
  return (
    <div className={`login-carosel ${currentSlide==2 || currentSlide==1?"dark":"light"}`}>
      <div className="carosel">
        <Carousel
        onChange={handleChange}
        animation="slide"
          navButtonsProps={{
            style: {
              display: 'none',
            },
          }}
          indicatorIconButtonProps={{
            style: {
              backgroundColor: '#99B2F8', // 2
              color: '#99B2F8', // 2
              margin: 6,
              height: '0.75rem',
              width: '0.75rem',
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: '#ffffff', // 2,
              color: '#ffffff',
              margin: 6,
              height: '0.75rem',
              width: '0.75rem',
            },
          }}>
          <div className="carosel-content">
            <div className="box">
              <img alt="carosel" src={login1} loading='lazy' />
            </div>
            <div className="text-xs-medium-24">
            Bid and win in a single swipe
            </div>
          </div>
          <div className="carosel-content">
            <div className="box">
              <img alt="carosel" src={login2} loading='lazy'/>
            </div>
            <div className="text-xs-medium-24">
            Customized viewing options - light or dark view
            </div>
          </div>
          <div className="carosel-content dark">
            <div className="box">
              <img alt="carosel" src={login3} loading='lazy' />
            </div>
            <div className="text-xs-medium-24">
            Fastest, fairest, most fun and kid-friendly auctions
            </div>
          </div>
          <div className="carosel-content">
            <div className="box">
              <img alt="carosel" src={login4} loading='lazy' />
            </div>
            <div className="text-xs-medium-24">
            Easily track all transactions in your profile
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default AuthCarosel;

import "./CardDashboard.scss";

import React, { useEffect, useState } from "react";
import { cardAlertAction, getCards } from "../../redux/slices/card";
import { useDispatch, useSelector } from "react-redux";

import { Backdrop } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import CustomizedTooltips from "../tooltip/Tooltip";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import PCLiveLoader from "../loader/PCLiveLoader";
import checkBoxBase from "../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../assests/icons/checkbox-checked-dark.svg";
import pcliveLogo from "../../assests/icons/pclive247.png";
import { toast } from "react-toastify";

const CardDashboard = (props) => {
  const dispatch = useDispatch();
  const {
    card,
    isChecked,
    handleCheck,
    cardCheckList,
    isAlert,
    toggleDrawer,
    apiCall,
    getApiCall,
    roomId,
    isAuction,
    setViewcard,
    setCurrentCard,
    isDark,
  } = props;
  const [load, setLoad] = useState(false);
  const [alert, setAlert] = useState(false);
  const isLoader = useSelector((state) => state.auth.isLoader);
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const userDetails = localStorage.getItem("userDetails");
  const userInfo = useSelector((state) => state.profile?.data?.data);
  const [imgLoad, setImgload] = useState(false);

  useEffect(() => {
    setAlert(card.is_alerted);
  }, [card]);
  const onAlert = () => {
    const room = roomId || localStorage.getItem("room_id");
    if (!userInfo?.buyer_verified) {
      toast.error(
        "You are not a verified buyer. Please complete the account verification process from the profile section to set alert "
      );
      return;
    }
    setLoad(true);
    const data = {
      card_id: card.id,
      room_id: parseInt(room),
    };
    if (alert) {
      data.is_removed = true;
    }
    cardAlertAction(
      data,
      (body) => {
        if (data.is_removed) {
          if (isAuction) {
            getApiCall(!apiCall);
          } else {
            setAlert(false);
          }
        } else {
          if (body.data.success) {
            if (isAuction) {
              getApiCall(!apiCall);
            } else {
              setAlert(true);
            }
          }
        }
        toast.success(body.data.message);
        setLoad(false);
      },
      (body) => {
        toast.error(body?.data?.message);
        setLoad(false);
      }
    );
    setLoad(false);
  };

  const goToCardDetails = (currentId) => {
    if (currentId) {
      getCards(
        `?card_id=${currentId}`,
        (body) => {
          setViewcard && setViewcard(true);
          setCurrentCard && setCurrentCard(body?.data?.data[0]);
        },
        () => {}
      );
    }
  };

  const handleCheckbox = (e, card) => {
    toggleDrawer(false);
    handleCheck(e.target.checked, card);
  };

  const handleImageLoaded = (e) => {
    setImgload(true);
  };
  return (
    <div
      className={`auction-room-container text-xs-medium-12 ${
        globalDarkMode || isDark ? "pclive-dark-mode" : ""
      }`}
      onClick={() => {
        goToCardDetails(card?.id);
      }}
    >
      <PCLiveLoader open={load} />

      {isChecked ? (
        <Checkbox
          checkedIcon={
            <img
              src={globalDarkMode ? checkBoxPrimaryDark : checkBoxPrimary}
              alt=""
            />
          }
          icon={
            <img
              src={globalDarkMode ? checkBoxBaseDark : checkBoxBase}
              alt=""
            />
          }
          style={{
            position: "absolute",
          }}
          className="card-checkbox"
          onChange={(e) => handleCheckbox(e, card)}
          checked={
            cardCheckList && cardCheckList.length
              ? cardCheckList.includes(card.id)
              : false
          }
        />
      ) : (
        ""
      )}
      {isAlert ? (
        <CustomizedTooltips title={alert ? "Remove alert" : "Set alert"}>
          <span
            className="notification"
            onClick={(e) => {
              e.stopPropagation(); // Stop event propagation here
              onAlert(); // Call onAlert directly
            }}
          >
            {alert ? <NotificationsOffIcon /> : <NotificationsIcon />}
          </span>
        </CustomizedTooltips>
      ) : (
        ""
      )}
      <img
        loading="lazy"
        className="card-image"
        src={
          card?.front_image
            ? imgLoad
              ? card?.front_image
              : pcliveLogo
            : pcliveLogo
        }
        onLoad={handleImageLoaded}
      />
      <div className="title">
        <CustomizedTooltips title={card.title}>
          <p className="text-sm-medium-12 longTitle">{card.title}</p>
        </CustomizedTooltips>
      </div>
      <div className="price-category">
        <sapn className="price">${card?.reserve_price}</sapn>
        <CustomizedTooltips title={card?.category_name}>
          <span className="category text-xs-medium-12">
            {card?.category_name}
          </span>
        </CustomizedTooltips>
      </div>
    </div>
  );
};
export default CardDashboard;

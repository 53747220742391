export const INITIAL_ERROR = {
  title: false,
  category: false,
  front_image: false,
  back_image: false,
  reserve_price: false,
  description: false,
  grade: false,
};
export const INITIAL_STATE = {
  title: "",
  front_image: undefined,
  back_image: undefined,
  quantity: null,
  description: "",
  category: "",
  grade: 0,
  card_year: new Date().getFullYear(),
  reserve_price: null,
  available_from: "",
  condition: "raw",
  front_image_name: "",
  back_image_name: "",
  category_id: 0,
  category_name: "",
  schedule_at: "",
  isSchedule: false,
  inventory_tag: "",
  show_notification: false,
  inventory_tag_id: "",
};

export const INITIAL_CSV = {
  grade: 0,
  category: "",
  condition: "",
  price: "",
  file: "",
  privateRoomCheck: false,
  isStandard: null,
  seller: "",
  roomName: "",
  fileLink: undefined,
  defaultRoom: "",
  scheduleCsv: false,
  csvDate: null,
  inventory_tag: null,
};
//states
export const INITIAL_FILTER = [
  {
    menu: "Price",
    type: "check",
    subMenu: [
      { label: "$1-$5", value: false },
      { label: "$5-$10", value: false },
      { label: "$10-$20", value: false },
      { label: "$20-$25", value: false },
      { label: "$25+", value: false },
      { label: "$50+", value: false },
      { label: "$100+", value: false },
      { label: "$200+", value: false },
    ],
  },
  {
    menu: "Grade",
    type: "check",
    subMenu: [
      { label: "Authentic", value: false },
      { label: "1", value: false },
      { label: "2", value: false },
      { label: "3", value: false },
      { label: "4", value: false },
      { label: "5", value: false },
      { label: "6", value: false },
      { label: "7", value: false },
      { label: "8", value: false },
      { label: "9", value: false },
      { label: "10", value: false },
    ],
  },
  {
    menu: "Category",
    type: "search",
    subMenu: [],
  },
  {
    menu: "Pickup Address",
    type: "check",
    subMenu: [],
  },
];

export const REQUESTS_INITIAL_FILTER = [
  {
    menu: "Upload Type",
    type: "check",
    subMenu: [
      { label: "Normal", value: false },
      { label: "Bulk", value: false },
      { label: "CSV", value: false },
    ],
  },
  {
    menu: "Status",
    type: "check",
    subMenu: [
      { label: "Pending", value: false },
      { label: "Cancelled", value: false },
      { label: "Declined", value: false },
      { label: "Reverted", value: false },
      { label: "Approved", value: false },
    ],
  },
  {
    menu: "Pickup Address",
    type: "check",
    subMenu: [],
  },
];

export const APPROVED_REQUESTS_INITIAL_FILTER = [
  {
    menu: "Upload Type",
    type: "check",
    subMenu: [
      { label: "Normal", value: false },
      { label: "Bulk", value: false },
      { label: "CSV", value: false },
    ],
  },
  {
    menu: "Pickup Address",
    type: "check",
    subMenu: [],
  },
];

export const BULK_CSV_INITIAL_FILTER = [
  {
    menu: "Price",
    type: "check",
    subMenu: [
      { label: "$1-$5", value: false },
      { label: "$5-$10", value: false },
      { label: "$10-$20", value: false },
      { label: "$20-$25", value: false },
      { label: "$25+", value: false },
      { label: "$50+", value: false },
      { label: "$100+", value: false },
      { label: "$200+", value: false },
    ],
  },
  {
    menu: "Grade",
    type: "check",
    subMenu: [
      { label: "Authentic", value: false },
      { label: "1", value: false },
      { label: "2", value: false },
      { label: "3", value: false },
      { label: "4", value: false },
      { label: "5", value: false },
      { label: "6", value: false },
      { label: "7", value: false },
      { label: "8", value: false },
      { label: "9", value: false },
      { label: "10", value: false },
    ],
  },
  {
    menu: "Category",
    type: "search",
    subMenu: [],
  },
];

export const tableColumns = [
  "ID",
  "IMAGES",
  "NAME",
  "TAG",
  "GRADE",
  "PRICE",
  "DESCRIPTION",
  "PICKUP ADDRESS",
  "UPLOADED BY",
  "SELLER",
  "CREATED AT",
  "ACTION",
  "CHANGE STATUS",
];
export const columnsCsvList = [
  "FILE NAME",
  "TOTAL CARDS",
  "PICKUP ADDRESS",
  "SELLER",
  "CREATED AT",
  "ACTIONS",
];
export const mainTableColumns = [
  "ID",
  "Name",
  "Upload Type",
  "Pickup Address",
  "Updated At",
  "Status",
  "Action",
];

export const MAX_FILE_SIZE_MB = 5; 
export const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; 
import "./UploadCard.scss";

import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  addCardAction,
  doRequestActions,
  reRequest,
  updateCardAction,
} from "../../../../../../redux/slices/card";
import { useDispatch, useSelector } from "react-redux";

import AddressSelect from "../../../../../../components/address-select/AddressSelect";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ButtonLight from "../../../../../../components/buttons/button-light/ButtonLight";
import Checkbox from "@mui/material/Checkbox";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CustomPrimaryButton from "../../../../../../components/buttons/primary/CustomPrimaryButton";
import CustomizedRadios from "../../../../../../components/radio/Radio";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Input from "../../../../../../components/inputs/email/Input";
import InventoryTag from "../../../../../../components/inventory-tag/Inventorytag";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Popup from "../../../../../../components/popup/Popup";
import ReactdatePicker from "../../../../../../components/datepicker/DatePicker";
import SelectInput from "../../../../../../components/select/select-input/SelectInput";
import checkBoxBase from "../../../../../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../../../../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../../../../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../../../../../assests/icons/checkbox-checked-dark.svg";
import { getAllInventoryTag } from "../../../../../../redux/slices/seller";
import { getCategories } from "../../../../../../redux/slices/categories";
import moment from "moment";
import { toast } from "react-toastify";

export const UploadCardForm = ({
  setUploadDrawer,
  editCard,
  card,
  setCard,
  setApiCallAction,
  apiCallAction,
  setCardCheckList,
  toggleDrawer,
  onChangetab,
  setCardDetailsDrawer,
  setCorporateCardsTabValue,
  setRoomType,
  setLoad,
}) => {
  const dispatch = useDispatch();
  const allCategories = useSelector((state) => state.categories.data?.data);
  const allTagList = useSelector(
    (state) => state.inventorytag.allTagList?.data
  );
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const INITIAL_STATE = {
    front_image: undefined,
    back_image: undefined,
    title: "",
    description: "",
    category_id: "",
    grade: 0,
    card_year: new Date().getFullYear(),
    condition: "raw",
    reserve_price: "",
    published: false,
    show_notification: false,
    isSchedule: false,
    inventory_tag_name: undefined,
    inventory_tag: undefined,
    schedule_at: undefined,
    address_id: "",
    is_corporate: false,
  };
  const [cardState, setCardState] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState({
    front_image: false,
    back_image: false,
    title: false,
    description: false,
    category_id: false,
    condition: false,
    reserve_price: false,
  });
  const [imgErrors, setImgErrors] = useState({
    frontIMGError: "",
    backImgError: "",
  });
  const [titleError, setTitleError] = useState("Please enter Card Name");
  const [openRaiseRequestPopup, setOpenRaiseRequestPopup] = useState(false);

  const getYears = () => {
    let year = [];
    for (let i = 1900; i <= new Date().getFullYear(); i++) {
      year.push({ label: i, value: i });
    }
    return year.reverse();
  };
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getAllInventoryTag());
  }, []);

  useEffect(() => {
    if (editCard) {
      let detais = { ...card };
      detais.isSchedule = card?.schedule_at;
      setCardState(detais);
    }
  }, [card, editCard]);

  function shouldDisable() {
    return (
      Object.keys(errors).some((key) => errors[key] === true) ||
      (cardState?.condition == "raw" && !cardState?.back_image) ||
      Object.keys(cardState).some((key) => cardState[key] === "")
    );
  }
  const getTagId = (data) => {
    if (allTagList && allTagList.length && data?.inventory_tag_name) {
      let found = allTagList.find(
        (tag) => tag.tag_name === data?.inventory_tag_name
      );
      if (found) {
        return found.id;
      }
    }
    return "";
  };
  const onSaveCard = (isDraft) => {
    if (!isvalid()) {
      return;
    }
    if (editCard) {
      const data = {
        title: cardState?.title,
        quantity: cardState?.quantity,
        description: cardState?.description,
        category: cardState?.category_id,
        reserve_price: cardState?.reserve_price,
        grade:
          cardState?.condition?.toLowerCase() === "slab" ? cardState?.grade : 0,
        card_id: cardState?.id,
        card_year: cardState?.card_year,
        condition: cardState?.condition?.toLowerCase(),
        published: isDraft ? false : true,
        address_id: cardState?.address_id,
        is_corporate: cardState?.is_corporate,
      };
      if (!cardState?.is_corporate) data.inventory_tag = getTagId(cardState);
      if (!cardState?.is_corporate && cardState?.schedule_at) {
        data.schedule_at = moment
          .utc(cardState?.schedule_at)
          .format("YYYY-MM-DD-HH-mm");
      }

      if (
        cardState?.is_corporate &&
        cardState?.uploaded_from?.toLowerCase() === "normal" &&
        cardState?.request_state !== "approved"
      ) {
        data.request_type = cardState?.uploaded_from;
        data.identifier =
          cardState?.uploaded_from?.toLowerCase() === "normal"
            ? cardState?.id
            : cardState?.title;
        data.category_id = cardState?.category_id;
        reRequest(
          data,
          (body) => {
            if (body.data.success) {
              toast.success(body.data.message);
              setCardState(INITIAL_STATE);
              setCard && setCard(INITIAL_STATE);
              setUploadDrawer(false);
              setCardDetailsDrawer && setCardDetailsDrawer(false);
              if (toggleDrawer) {
                toggleDrawer(false);
              }
              setApiCallAction(!apiCallAction);
              setCardCheckList && setCardCheckList([]);
            } else {
              toast.error(body?.data?.message);
            }
          },
          (body) => {
            toast.error(body?.data?.message);
          }
        );
      } else if (
        !cardState?.is_corporate &&
        cardState?.uploaded_from?.toLowerCase() === "normal" &&
        cardState?.request_state === "cancelled"
      ) {
        const data = {
          request_type: cardState?.uploaded_from,
          request_state: "reverted",
          card_id: cardState?.id,
          address_id: cardState.address_id,
          title: cardState?.title,
          quantity: cardState?.quantity,
          description: cardState?.description,
          category: cardState?.category_id,
          reserve_price: cardState?.reserve_price,
          grade:
            cardState?.condition?.toLowerCase() === "slab"
              ? cardState?.grade
              : 0,
          card_year: cardState?.card_year,
          condition: cardState?.condition?.toLowerCase(),
          published: isDraft ? false : true,
          is_corporate: cardState?.is_corporate,
        };
        if (!cardState?.is_corporate) data.inventory_tag = getTagId(cardState);
        if (!cardState?.is_corporate && cardState?.schedule_at) {
          data.schedule_at = moment
            .utc(cardState?.schedule_at)
            .format("YYYY-MM-DD-HH-mm");
        }
        doRequestActions(
          data,
          (body) => {
            if (body.data.success) {
              toast.success(body.data.message);
              setCardState(INITIAL_STATE);
              setCard && setCard(INITIAL_STATE);
              setUploadDrawer(false);
              setCardDetailsDrawer && setCardDetailsDrawer(false);
              if (toggleDrawer) {
                toggleDrawer(false);
              }
              setApiCallAction(!apiCallAction);
              setCardCheckList && setCardCheckList([]);
            } else {
              toast.error(body?.data?.message);
            }
          },
          (error) => {
            console.error(error);
            toast.error(error?.message);
          }
        );
      } else {
        updateCardAction(
          data,
          (body) => {
            if (body.data.success) {
              toast.success(body.data.message);
              setCardState(INITIAL_STATE);
              setCard && setCard(INITIAL_STATE);
              setUploadDrawer(false);
              setCardDetailsDrawer && setCardDetailsDrawer(false);
              if (toggleDrawer) {
                toggleDrawer(false);
              }
              setApiCallAction(!apiCallAction);
              setCardCheckList([]);
            } else {
              toast.error(body?.data?.message);
            }
          },
          (body) => {
            toast.error(body?.data?.message);
          }
        );
      }
    } else {
      const formData = new FormData();
      formData.append("title", cardState?.title);
      formData.append("front_image", cardState?.front_image);
      formData.append("back_image", cardState?.back_image);
      formData.append("description", cardState?.description);
      formData.append("category", cardState?.category_id);
      formData.append("reserve_price", cardState?.reserve_price);
      formData.append("card_year", cardState?.card_year);
      formData.append("condition", cardState?.condition);
      formData.append("published", isDraft ? false : true);
      !cardState?.is_corporate &&
        formData.append("inventory_tag", getTagId(cardState));
      formData.append("address_id", cardState?.address_id);
      formData.append("is_corporate", cardState?.is_corporate);
      if (!cardState?.is_corporate && cardState?.schedule_at) {
        if (new Date(cardState?.schedule_at) < new Date()) {
          toast.error(
            "The scheduled time must be set for a time in the future."
          );
          return;
        }
        formData.append(
          "schedule_at",
          moment.utc(cardState?.schedule_at).format("YYYY-MM-DD-HH-mm")
        );
      }
      formData.append(
        "show_notification",
        cardState?.show_notification ? true : false
      );

      if (cardState?.condition === "slab") {
        formData.append("grade", cardState?.grade);
      } else {
        formData.append("grade", 0);
      }
      const handleSuccess = (body) => {
        if (body?.data?.success) {
          setLoad(false);
          if (cardState?.is_corporate) {
            setCorporateCardsTabValue && setCorporateCardsTabValue(2);
            setRoomType && setRoomType(4);
          } else {
            onChangetab && onChangetab(1);
          }
          toast.success(body.data.message);
          setCardState(INITIAL_STATE);
          setCard && setCard(INITIAL_STATE);
          setApiCallAction(!apiCallAction);
          setCardCheckList([]);
          setUploadDrawer(false);
          setCardDetailsDrawer && setCardDetailsDrawer(false);
          if (toggleDrawer) {
            toggleDrawer(false);
          }
        } else {
          setLoad(false);
          toast.error(body?.data?.message);
          setUploadDrawer(false);
          if (toggleDrawer) {
            toggleDrawer(false);
          }
        }
      };
      const handleFail = (body) => {
        setLoad(false);
        toast.error(body?.data?.message);
        setUploadDrawer(false);
        if (toggleDrawer) {
          toggleDrawer(false);
        }
      };
      setLoad(true);
      addCardAction(formData, handleSuccess, handleFail, true);
    }
  };

  const handleCancel = () => {
    setCardState(INITIAL_STATE);
    setCard && setCard(INITIAL_STATE);
    setUploadDrawer(false);
  };

  useEffect(() => {
    if (cardState?.front_image) {
      setErrors((prev) => {
        return { ...prev, front_image: !cardState?.front_image };
      });
    }
  }, [cardState?.front_image]);

  useEffect(() => {
    if (cardState?.back_image) {
      setErrors((prev) => {
        return { ...prev, back_image: !cardState?.back_image };
      });
    }
  }, [cardState?.back_image]);

  const uploadImg = (e, type) => {
    let msg = "";
    const file = e.target.files[0];
    if (!file) {
      msg =
        type === "back_image" && cardState?.condition?.toLowerCase() === "raw"
          ? "Back image is required"
          : "Front image is required";
    } else if (!["image/png", "image/jpeg"].includes(file.type)) {
      msg = "Please select a PNG or JPG file.";
    } else if (file.size > 5 * 1024 * 1024) {
      msg = "File size exceeds 5MB limit.";
    }
    if (type === "front_image" || type === "back_image") {
      const errorKey =
        type === "front_image" ? "frontIMGError" : "backImgError";
      setImgErrors((prevErrors) => ({ ...prevErrors, [errorKey]: msg }));
      setErrors((prevErrors) => ({ ...prevErrors, [type]: !!msg }));
    }
    if (!msg) {
      setCardState({ ...cardState, [type]: e.target.files[0] });
    }
  };
  const onChangeInputs = (e, type) => {
    switch (type) {
      case "front_image":
      case "back_image":
        uploadImg(e, type);
        break;
      case "title":
        const val = e.target.value;
        if (val.charAt(0) === " ") {
          return;
        }
        setCardState((prevState) => ({ ...prevState, [type]: val }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [type]: !val || val?.length > 20,
        }));
        val?.length > 20
          ? setTitleError("Card name cannot exceed 20 characters")
          : setTitleError("Please enter Card Name");
        break;
      case "description":
        const newVal = e.target.value;
        if (newVal.charAt(0) === " ") {
          return;
        }
        setCardState((prevState) => ({ ...prevState, [type]: newVal }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [type]: !newVal,
        }));
        break;
      case "category_id":
      case "reserve_price":
        const value = e.target.value;
        if (!/^\d*$/.test(e.target.value)) {
          return;
        }
        setCardState((prevState) => ({ ...prevState, [type]: value }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [type]:
            type === "reserve_price"
              ? !/^\d/.test(e.target.value)
                ? true
                : value === null ||
                  value < 1 ||
                  isNaN(value) ||
                  value >= 1000000
              : !value,
        }));
        break;
      case "inventory_tag_name":
        setCardState((prevState) => ({ ...prevState, [type]: e }));
        break;
      default:
        break;
    }
  };

  const isvalid = () => {
    if (cardState?.condition === "raw") {
      if (cardState?.back_image === undefined) {
        setImgErrors((prevErrors) => ({
          ...prevErrors,
          backImgError: "Please upload back image",
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          back_image: "Please upload back image",
        }));
        return false;
      }
    }
    return true;
  };

  return (
    <div
      className={`upload-card-drawer ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <Popup
        openPopup={openRaiseRequestPopup}
        onClose={() => setOpenRaiseRequestPopup(false)}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => {
              setOpenRaiseRequestPopup(false);
            },
          },
          secondary: {
            title: `Yes`,
            onClick: () => {
              onSaveCard("draft");
              setOpenRaiseRequestPopup(false);
            },
          },
        }}
        title="Are you sure you want to raise request?"
        description="You will be able to publish cards with corporate address only after Admin approves it. Are you sure you want to raise request?"
      />
      <div className="upload-form-container">
        <div className="text-xs-medium-12">
          Upload card images (supports png, jpg files upto 5MB)
        </div>

        <div className="image-upload-container">
          <input
            accept=".jpg,.png"
            id="contained-button-file"
            type="file"
            hidden
            onChange={(e) => onChangeInputs(e, "front_image")}
            disabled={editCard}
            error={errors.front_image}
          />
          <label htmlFor="contained-button-file">
            {cardState?.front_image ? (
              <div
                className="image-container"
                onClick={() => {
                  toast.error("Front image is not editable");
                }}
              >
                <img
                  src={
                    typeof cardState?.front_image == "string"
                      ? cardState?.front_image
                      : URL.createObjectURL(cardState?.front_image)
                  }
                  width={160}
                  height={200}
                  alt="userIcon"
                  className="choose-img"
                  loading="lazy"
                />
              </div>
            ) : (
              <div
                className={`upload-area ${errors.front_image ? "error" : ""}`}
              >
                <CloudUploadOutlinedIcon />
                <div className="text-sm-medium-14">
                  Upload front of the card
                </div>
                <div className="text-xs-medium-12">
                  supports png, jpg files upto 5MB{" "}
                </div>
                {errors.front_image && (
                  <span className="text-xs-medium-12 error">
                    {imgErrors.frontIMGError}
                  </span>
                )}
              </div>
            )}
          </label>
          <input
            accept=".jpg,.png"
            id="contained-button-file-2"
            type="file"
            hidden
            onChange={(e) => onChangeInputs(e, "back_image")}
            error={errors.back_image}
            disabled={editCard}
          />
          <label htmlFor="contained-button-file-2">
            {cardState?.back_image ? (
              <div
                className="image-container"
                onClick={() => {
                  toast.error("Back image is not editable");
                }}
              >
                <img
                  src={
                    typeof cardState?.back_image == "string"
                      ? cardState?.back_image
                      : URL.createObjectURL(cardState?.back_image)
                  }
                  width={160}
                  height={200}
                  alt="userIcon"
                  className="choose-img"
                  loading="lazy"
                />
              </div>
            ) : (
              <div
                className={`upload-area ${errors.back_image ? "error" : ""}`}
              >
                <CloudUploadOutlinedIcon />
                <div className="text-sm-medium-14">Upload back of the card</div>
                <div className="text-xs-medium-12">
                  supports png, jpg files upto 5MB{" "}
                </div>
                {errors.back_image ? (
                  <span className="text-xs-medium-12 error">
                    {imgErrors.backImgError}
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
          </label>
        </div>
        <div className="input-feild">
          <Input
            additionalClassName="capitalize"
            placeholder="Eg: 2017 Bowman card"
            label="Card name"
            type="text"
            error={errors.title}
            errorMsg={titleError}
            onChange={(e) => onChangeInputs(e, "title")}
            value={cardState?.title}
          />
        </div>
        <div className="input-feild">
          <Input
            placeholder="Eg: Authentic Bowman card from 2017 basketball"
            label="Card description"
            type="text"
            error={errors.description}
            errorMsg="Please enter  description"
            onChange={(e) => onChangeInputs(e, "description")}
            value={cardState?.description}
          />
        </div>
        <div className="input-feild">
          <AddressSelect
            addressId={cardState?.address_id}
            isCorporateCard={cardState?.is_corporate}
            isDisabled={
              editCard &&
              (cardState?.request_state?.toLowerCase() === "cancelled" ||
                cardState?.request_state?.toLowerCase() === "reverted") &&
              cardState?.uploaded_from?.toLowerCase() === "normal"
                ? false
                : editCard &&
                  (cardState?.is_corporate ||
                    cardState?.uploaded_from?.toLowerCase() !== "normal" ||
                    cardState?.request_state?.toLowerCase() === "approved")
            }
            updateAddressDetails={(selectedAddress, isCorporate) => {
              isCorporate
                ? setCardState({
                    ...cardState,
                    address_id: selectedAddress[0].id,
                    is_corporate: isCorporate,
                    inventory_tag_name: undefined,
                    inventory_tag: undefined,
                    schedule_at: undefined,
                    isSchedule: false,
                  })
                : setCardState({
                    ...cardState,
                    address_id: selectedAddress?.[0]?.id,
                    is_corporate: isCorporate,
                  });
            }}
          />
        </div>
        <div className="input-feild">
          <InventoryTag
            tagValue={cardState?.inventory_tag_name}
            allTagList={allTagList && allTagList.length ? allTagList : []}
            setTagValue={(val) =>
              onChangeInputs(val?.title, "inventory_tag_name")
            }
            label="Inventory tag"
            isDisabled={cardState?.is_corporate}
          />
        </div>
        <div className="input-feild">
          <SelectInput
            label="Category"
            data={
              allCategories && allCategories.length
                ? allCategories
                    .filter((f) => f.status)
                    .map((c) => {
                      return { label: c.category_name, value: c.id };
                    })
                : []
            }
            onChange={(e) => onChangeInputs(e, "category_id")}
            error={errors.category_id}
            errorMsg={"Please select category"}
            value={cardState?.category_id}
            placeholder="Select Category"
          />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="input-feild">
              <Input
                placeholder="Eg: 25"
                label="Base price"
                type="text"
                error={errors.reserve_price}
                errorMsg="Please enter valid price"
                onChange={(e) => {
                  onChangeInputs(e, "reserve_price");
                }}
                value={cardState?.reserve_price}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="input-feild">
              <SelectInput
                label="Year"
                placeholder="Select Year"
                data={getYears()}
                onChange={(e) =>
                  setCardState({ ...cardState, card_year: e.target.value })
                }
                value={cardState?.card_year}
              />
            </div>
          </Grid>
        </Grid>
        <div className="gender-container">
          <CustomizedRadios
            label="Card condition"
            contentAlign={"flex-start"}
            data={[
              { label: "Raw", value: "raw" },
              { label: "Slab", value: "slab" },
            ]}
            onChange={(e) => {
              if (editCard) {
                toast.error("You cannot update card's condition type !!");
                return;
              }
              setCardState({
                ...cardState,
                condition: e.target.value,
                grade: e.target.value === "raw" ? 0 : cardState?.grade,
              });
              onChangeInputs(e, "condition");
            }}
            value={cardState?.condition?.toLowerCase()}
          />
        </div>
        <div className="input-feild">
          <SelectInput
            label="Grade"
            placeholder="Select Grade"
            disabled={cardState?.condition?.toLowerCase() === "raw"}
            data={[
              { label: "Authentic", value: 0 },
              { label: "1", value: 1 },
              { label: "2", value: 2 },
              { label: "3", value: 3 },
              { label: "4", value: 4 },
              { label: "5", value: 5 },
              { label: "6", value: 6 },
              { label: "7", value: 7 },
              { label: "8", value: 8 },
              { label: "9", value: 9 },
              { label: "10", value: 10 },
            ]}
            onChange={(e) =>
              setCardState({ ...cardState, grade: e.target.value })
            }
            value={cardState?.grade}
          />
        </div>
        <div
          style={{ marginLeft: "-10px" }}
          onClick={() => {
            if (cardState?.is_corporate) {
              toast.error(
                "Publish Later feature is not available for corporate addresses"
              );
            }
          }}
        >
          <Checkbox
            checkedIcon={
              <img
                src={globalDarkMode ? checkBoxPrimaryDark : checkBoxPrimary}
                alt=""
              />
            }
            icon={
              <img
                src={globalDarkMode ? checkBoxBaseDark : checkBoxBase}
                alt=""
              />
            }
            onChange={(e) =>
              setCardState({
                ...cardState,
                isSchedule: e.target.checked,
                schedule_at: !e.target.checked ? null : cardState?.schedule_at,
              })
            }
            checked={cardState?.isSchedule}
            disabled={cardState?.is_corporate}
            sx={{
              opacity: cardState?.is_corporate ? 0.3 : 1,
            }}
          />
          <span
            className={`text-sm-medium-14 checkText ${
              cardState?.is_corporate ? "disabled" : ""
            }`}
          >
            Do you want to publish this card later?
          </span>
          <div
            className={`text-xs-medium-12 subtext ${
              cardState?.is_corporate ? "disabled" : ""
            }`}
          >
            Using this, you can set date and time for card to go live in an
            auction.
          </div>
        </div>
        {cardState?.isSchedule && (
          <div className="input-feild">
            <ReactdatePicker
              cardState={cardState}
              setCardState={setCardState}
            />
          </div>
        )}
        <div style={{ marginLeft: "-10px" }} className="notify">
          <Checkbox
            checkedIcon={
              <img
                src={globalDarkMode ? checkBoxPrimaryDark : checkBoxPrimary}
                alt=""
              />
            }
            icon={
              <img
                src={globalDarkMode ? checkBoxBaseDark : checkBoxBase}
                alt=""
              />
            }
            onChange={(e) =>
              setCardState({
                ...cardState,
                show_notification: e.target.checked,
              })
            }
          />
          <span className="text-sm-medium-14 ">
            Allow notifications related to this card.
          </span>
        </div>
      </div>
      <div className="button-container">
        <ButtonLight onClick={handleCancel}>Cancel</ButtonLight>

        {(!cardState?.is_corporate || editCard) && (
          <CustomPrimaryButton
            disabled={
              !cardState?.front_image ||
              !cardState?.back_image ||
              !cardState?.category_id ||
              !cardState?.reserve_price ||
              !cardState?.description ||
              !cardState?.title ||
              !cardState?.address_id ||
              (cardState?.isSchedule && !cardState?.schedule_at) ||
              Object.keys(errors).some((key) => errors[key] === true)
            }
            // disabled={
            // shouldDisable() || (cardState?.isSchedule && !cardState?.schedule_at)}
            onClick={() => onSaveCard("draft")}
          >
            {editCard
              ? cardState?.uploaded_from?.toLowerCase() === "normal" &&
                cardState?.request_state?.toLowerCase() === "cancelled"
                ? cardState?.is_corporate
                  ? "Re-request"
                  : "Update & save card"
                : cardState?.is_corporate &&
                  cardState?.uploaded_from?.toLowerCase() === "normal" &&
                  cardState?.request_state?.toLowerCase() !== "approved"
                ? "Raise request"
                : "Update & save card"
              : "Save as draft"}
          </CustomPrimaryButton>
        )}
        {!editCard && (
          <CustomPrimaryButton
            onClick={() =>
              cardState?.is_corporate
                ? setOpenRaiseRequestPopup(true)
                : onSaveCard()
            }
            disabled={
              !cardState?.front_image ||
              !cardState?.back_image ||
              !cardState?.category_id ||
              !cardState?.reserve_price ||
              !cardState?.description ||
              !cardState?.title ||
              cardState?.isSchedule ||
              !cardState?.address_id
            }
          >
            {cardState?.is_corporate ? "Raise request" : "Publish card"}
            {!cardState?.is_corporate && <ArrowCircleUpIcon />}
          </CustomPrimaryButton>
        )}
      </div>
    </div>
  );
};

import "./AddAddress.scss";

import { Button, Checkbox, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  addAddress,
  getAllAddress,
  updateAddress,
} from "../../../redux/slices/address";
import { useDispatch, useSelector } from "react-redux"; //create react app typescript redux toolkit

import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import ButtonLight from "../../buttons/button-light/ButtonLight";
import CircularProgress from "@mui/material/CircularProgress";
import CustomPrimaryButton from "../../buttons/primary/CustomPrimaryButton";
import CustomizedRadios from "../../radio/Radio";
import Input from "../../inputs/email/Input";
import PCLiveLoader from "../../loader/PCLiveLoader";
import SelectInput from "../../select/select-input/SelectInput";
import checkBoxBase from "../../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../../assests/icons/checkbox-checked-dark.svg";
import { stateList } from "./stateList";
import { toast } from "react-toastify";

const INITIAL_STATE = {
  id: 0,
  line1: "",
  city: "",
  state: "AL",
  country: "US",
  postal_code: null,
  is_primary: false,
  address_type: "Home",
  address_name: "",
};
const AddAddress = ({
  address,
  setOpenAddAddress,
  accVerify,
  setOpenAddPaymentCard,
  setOpenAddBank,
  tab,
  isDark,
}) => {
  const dispatch = useDispatch();
  const [load, isLoad] = useState(false);
  const [addressDetails, setAddressDetail] = useState(INITIAL_STATE);

  const [errors, setErrors] = useState({
    line1: false,
    city: false,
    state: false,
    country: false,
    postal_code: false,
    address_name: false,
  });
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);

  useEffect(() => {
    setAddressDetail(address ? address : INITIAL_STATE);
  }, [address]);

  const onSubmitAddress = () => {
    isLoad(true);
    if (addressDetails.id == 0) {
      addAddress(
        addressDetails,
        (body) => {
          if (body?.data?.success) {
            toast.success(body?.data?.message);
            setAddressDetail(INITIAL_STATE);
            setOpenAddAddress(false);
            if (accVerify && tab == 1) {
              setOpenAddPaymentCard(true);
            } else if (accVerify && tab != 1) {
              setOpenAddBank(true);
            }
            dispatch(getAllAddress());
          } else {
            toast.error(body.data.message);
          }
        },
        (body) => {
          toast.error(body.data.message);
        }
      );
    } else {
      const data = {
        ...addressDetails,
        address_id: addressDetails.id,
      };
      updateAddress(
        data,
        (body) => {
          toast.success(body.message);
          setAddressDetail(INITIAL_STATE);
          setOpenAddAddress(false);
          dispatch(getAllAddress());
        },
        (body) => {
          toast.error(body.message);
        }
      );
    }
    isLoad(false);
  };

  const onChangeInputs = (e, type) => {
    if (type === "postal_code") {
      setAddressDetail({ ...addressDetails, [type]: e.target.value });
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" ||
          !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(e.target.value)
            ? true
            : false,
      });
    } else if (type === "line1") {
      setAddressDetail({ ...addressDetails, [type]: e.target.value });
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" ||
          e.target.value.length > 100 ||
          !/^[a-zA-Z0-9\s]*$/.test(e.target.value)
            ? true
            : false,
      });
    } else if (type === "city") {
      setAddressDetail({
        ...addressDetails,
        [type]: /^[a-zA-Z\s]*$/.test(e.target.value) ? e.target.value : "",
      });
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" ||
          e.target.value.length > 20 ||
          !/^[a-zA-Z\s]*$/.test(e.target.value)
            ? true
            : false,
      });
    } else if (type === "address_name") {
      setAddressDetail({ ...addressDetails, [type]: e.target.value });
      setErrors({
        ...errors,
        [type]: e.target.value.length > 50 ? true : false,
      });
    } else {
      setAddressDetail({ ...addressDetails, [type]: e.target.value });
      setErrors({
        ...errors,
        [type]: e.target.value === "" ? true : false,
      });
    }
  };
  function shouldDisable() {
    return (
      Object.keys(errors).some((key) => errors[key] === true) ||
      Object.keys(addressDetails)
        .filter((addressField) => addressField !== "address_name")
        .some((key) => addressDetails[key] === "")
    );
  }
  return (
    <div
      className={`address-add-container ${
        isDark || globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <PCLiveLoader open={load} />
      <div className="input-space">
        <Input
          placeholder={"Eg: Home, Office"}
          label="Address Name"
          type="text"
          onChange={(e) => onChangeInputs(e, "address_name")}
          error={errors.address_name}
          value={addressDetails.address_name}
          errorMsg={"Address name should be less than 50 characters"}
          isDark={isDark}
        />
      </div>
      <div className="input-space">
        <Input
          placeholder={"Enter Street Address"}
          label="Street Address"
          type="text"
          onChange={(e) => onChangeInputs(e, "line1")}
          error={errors.line1}
          value={addressDetails.line1}
          errorMsg={"Please enter valid street address"}
          isDark={isDark}
        />
      </div>
      <SelectInput
        label="Country"
        data={[{ label: "US", value: "US" }]}
        onChange={(e) => onChangeInputs(e, "country")}
        value={addressDetails.country}
        isDark={isDark}
      />
      <SelectInput
        label="State"
        data={stateList}
        onChange={(e) => onChangeInputs(e, "state")}
        value={addressDetails.state}
        isDark={isDark}
      />
      <div className="input-space">
        <Input
          placeholder={"Enter City"}
          label="City"
          type="text"
          onChange={(e) => onChangeInputs(e, "city")}
          error={errors.city}
          value={addressDetails.city}
          errorMsg={"Please enter valid city"}
          isDark={isDark}
        />
      </div>
      <div className="input-space">
        <Input
          placeholder={"Enter Postal Code"}
          label="Postal Code"
          type="number"
          onChange={(e) => onChangeInputs(e, "postal_code")}
          error={errors.postal_code}
          value={addressDetails.postal_code}
          errorMsg={"Please enter valid postal code"}
          isDark={isDark}
        />
      </div>
      <div className="address-type">
        <CustomizedRadios
          label={"Address type"}
          contentAlign="flex-start"
          data={[
            { label: "Home", value: "Home" },
            { label: "Work", value: "Work" },
            { label: "Other", value: "Other" },
          ]}
          onChange={(e) => onChangeInputs(e, "address_type")}
          value={addressDetails.address_type}
          isDark={isDark}
        />
      </div>

      {!accVerify && (
        <div>
          {" "}
          <Checkbox
            sx={{ marginLeft: -1 }}
            checkedIcon={
              <img
                src={globalDarkMode ? checkBoxPrimaryDark : checkBoxPrimary}
                alt=""
              />
            }
            icon={
              <img
                src={globalDarkMode ? checkBoxBaseDark : checkBoxBase}
                alt=""
              />
            }
            checked={addressDetails.is_primary}
            onChange={(e) =>
              setAddressDetail({
                ...addressDetails,
                is_primary: e.target.checked,
              })
            }
            value={addressDetails.is_primary}
          />
          <span className="text-sm-medium-14 primary-address">
            Save this as my primary address
          </span>
        </div>
      )}

      <div className="button-container">
        <ButtonLight onClick={() => setOpenAddAddress(false)} isDark={isDark}>
          Cancel
        </ButtonLight>
        <CustomPrimaryButton
          disabled={shouldDisable()}
          onClick={() => onSubmitAddress()}
        >
          {accVerify ? "Save address & next" : "Save address"}
        </CustomPrimaryButton>
      </div>
    </div>
  );
};

export default AddAddress;

import './Footer.scss';

import React, { useState } from "react";

import AboutUS from '../../screens/pages/AboutUs/AboutUS';
import Dialog from '../dialog/Dialog';
import FAQ from '../../screens/pages/faq/FAQ';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import Policy from "../../screens/pages/policy/Policy";
import TermsandConditions from "../../screens/pages/termsConditions/TermsandConditions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
  const navigate = useNavigate();
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const [openDetails, setOpenDetails] = useState({
    FAQ: false,
    TC: false,
    PP: false,
    AU: false,
  });

  const handleClick = (detailType) => {
    setOpenDetails({ ...openDetails, [detailType]: true });
  };

  return (
    <div
      className={`sidebar-bottom ${globalDarkMode ? "pclive-dark-mode" : ""}`}
    >
      <div className="text-xs-medium-12 other-list-head">Others</div>
      <div className="text-sm-medium-14 ">
        <span className="other-list" onClick={() => handleClick("FAQ")}>
          <HelpOutlineIcon className="other-icon" />
          <span> FAQ's</span>
        </span>
        <span className="other-list" onClick={() => handleClick("TC")}>
          <TextSnippetOutlinedIcon className="other-icon" />
          <span> Terms and Conditions</span>
        </span>
        <span className="other-list" onClick={() => handleClick("PP")}>
          <ImportContactsOutlinedIcon className="other-icon" />
          <span> Privacy policy</span>
        </span>
        <span className="other-list" onClick={() => handleClick("AU")}>
          <InfoOutlinedIcon className="other-icon" />
          <span> About US</span>
        </span>
        <a className="other-list" href="mailto:support@pclive247.com">
          <MailOutlinedIcon className="other-icon" />
          <span> Contact us</span>
        </a>
      </div>
      <Dialog
        openDialog={openDetails.FAQ}
        onClose={() => setOpenDetails({ ...openDetails, FAQ: false })}
        title={"FAQ"}
      >
        <FAQ />
      </Dialog>
      <Dialog
        openDialog={openDetails.TC}
        onClose={() => setOpenDetails({ ...openDetails, TC: false })}
        title={"Terms and Conditions for PC Live 24x7"}
      >
        <TermsandConditions />
      </Dialog>
      <Dialog
        openDialog={openDetails.PP}
        onClose={() => setOpenDetails({ ...openDetails, PP: false })}
        title={"Privacy Policy"}
      >
        <Policy />
      </Dialog>
      <Dialog
        openDialog={openDetails.AU}
        onClose={() => setOpenDetails({ ...openDetails, AU: false })}
        title={"About us"}
      >
        <AboutUS />
      </Dialog>
    </div>
  );
};

export default Footer;

import "./PaymentCard.scss";

import { getPaymentCards, updatePaymentCard } from "../../redux/slices/payment";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import JCB from "../../assests/icons/JCB.png";
import React from "react";
import Visa from "../../assests/icons/visa.svg";
import americanExpress from "../../assests/icons/american-express.png";
import dinerClub from "../../assests/icons/diners-club.svg";
import mastercard from "../../assests/icons/mastercard.svg";
import { toast } from "react-toastify";
import unionPay from "../../assests/icons/UnionPay_logo.png";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const PaymentCard = ({ isDelete, setOpenPopup, setCurrentCard, card ,currentCard ,isDark}) => {
  const dispatch = useDispatch();
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  const cardsIconList = {'Visa':Visa ,'American Express':americanExpress , 'Discover & Diners Club':dinerClub , 'Japan Credit Bureau':JCB ,'MasterCard':mastercard, 'China UnionPay':unionPay };
  const markPrimary = () => {
    const data = {
      payment_method_id: card.id,
    };
    updatePaymentCard(
      data,
      (body) => {
        toast.success(body.message);
        dispatch(getPaymentCards());
      },
      (body) => {
        toast.error(body.message);
      }
    );
  };

  const onClickDelete = (card)=>{
    setOpenPopup(true);
    setCurrentCard(card);
  }
  return (
    <div className={`payment-card-container ${currentCard?.currentId==card.id || currentCard==card?.id?"selected":""} ${(isDark || globalDarkMode) ? 'pclive-dark-mode' : ''}`}>
      {/* <span className="divider">|</span> */}
      <div className="left-content">
      {/* {Object.entries(cardsIconList).map(([key,val]) => {
       return( key == card?.brand ? <img src={val} loading='lazy'/>: '')
      })} */}
      <img src={card?.logo_url} loading='lazy'/>
        <div className="text-sm-medium-14 card-brand">{card?.brand}</div>
        <div className="text-sm-medium-14 card-no">{card?.last4} xxxx</div>
      </div>
      {isDelete ? (
        <div className="right-content">
          <div
            className={`text-xs-semibold-12 primary ${
              card.is_primary ? "success" : ""
            }`}
            onClick={markPrimary}
          >
            {card.is_primary ? "Primary card" : "Mark as primary"}
          </div>

          <div
            onClick={
              () => onClickDelete(card)
            }
          >
            <DeleteOutlineIcon />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PaymentCard;

import "./ButtonGray.scss";

import React from "react";
import { useSelector } from "react-redux";

const ButtonGray = (props) => {
  const { label } = props;
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  return (
    <div
      style={{ cursor: "pointer" }}
      className={`button-gray-container text-sm-semibold-14 button-gray  ${globalDarkMode ? "pclive-dark-mode":""}`}
      {...props}
    >
      {label}
    </div>
  );
};
export default ButtonGray;

import "./OTP.scss";

import React, { useEffect, useState } from "react";
import { forgotPassword, registerVerify, resendOTP, setCurrentOTP, verifyLoginOtp } from "../../../redux/slices/auth";

import AlertBar from "../../../components/alert/Alert";
import AuthCarosel from "../carosel/AuthCarosel";
import Backdrop from "@mui/material/Backdrop";
import ButtonPrimary from "../../../components/buttons/button-primary/ButtonPrimary";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from '@mui/material/Grid';
import OtpInput from "react-otp-input";
import Timer from "../../../components/countdown/Countdown";
import { getIntrestedCategories } from "../../../redux/slices/categories";
import pcLiveLogo from "../../../assests/icons/pcLiveNewLogo.svg";
import { useDispatch } from "react-redux"; //create react app typescript redux toolkit
import { useNavigate } from "react-router-dom";
import CustomPrimaryButton from "../../../components/buttons/primary/CustomPrimaryButton";
import PCLiveLoader from '../../../components/loader/PCLiveLoader'
const OTP = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const useremail = localStorage.getItem("userEmail");
  const userDetails = localStorage.getItem("userDetails");
  const otpType = localStorage.getItem("otpType");
  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    let inputs = document.getElementsByTagName("input");
    inputs[2].style.marginRight = "30px";
    inputs[3].style.marginLeft = "30px";

  }, []);


  const onResendOTP = () => {

    setIsloading(true);

    const data = {
      email: useremail,
    };
    const handleSuccess = (res) => {
      if(!res.data.success){
        setOpenAlert({
          ...openAlert,
          open: true,
          message: res.data.message,
          type: "error",
        });
        setIsloading(false);
        return;
      }
    setTimer(false);

      setOpenAlert({
        ...openAlert,
        open: true,
        message: res.data.message,
        type: "success",
      });

 
      setIsloading(false);
    };
    const handleFail = () => {
      setIsloading(true);

      setOpenAlert({
        ...openAlert,
        open: true,
        message: "Error occured, Please try again",
        type: "error",
      });
      setIsloading(false);
    };
    resendOTP(data, handleSuccess, handleFail);

  };
  const onSubmit = () => {
    setIsloading(true);
    if(otpType==='login'){
      const data = {
        email: useremail,
        otp,
      };

      const handleSuccess =(res)=>{
        if (!res.data.success) {
          setOpenAlert({
            ...openAlert,
            open: true,
            message: res.data.message,
            type: "error",
          });
          setIsloading(false);

          return;
        }

        localStorage.setItem("userEmail", res.data.data.email);
        localStorage.setItem("userDetails", JSON.stringify(res.data.data));
        localStorage.setItem("accessToken", res.data.data.session_token);
        sessionStorage.setItem("userDetail", JSON.stringify(res.data.data));
        localStorage.removeItem("otpType");
        
        getIntrestedCategories(
          (res) => {
            setIsloading(false);
            setOpenAlert({
              ...openAlert,
              open: true,
              message: "Login success !!",
              type: "success",
            });
            setTimeout(() => {
              navigate(
                res && res.data && res.data.data && res.data.data.length
                  ? "/dashboard"
                  : "/landing"
              );
            }, 1000);
          },
          (res) => {
            setIsloading(false);
            setOpenAlert({
              ...openAlert,
              open: true,
              message: res?.data?.message,
              type: "error",
            });
            navigate("/login");
          }
        );


      }
      const handleFail =(res)=>{
        setOpenAlert({
          ...openAlert,
          open: true,
          message: res.data.message,
          type: "error",
        });
    setIsloading(false)
      }
      verifyLoginOtp(data,handleSuccess,handleFail);
   
    
  }
  if(otpType==='register'){
    const data={
      user_id: userDetails?JSON.parse(userDetails)?.data?.id:'',
      otp: otp
    
    }
    const handleSuccess = (res) => {
      if (!res.data.success) {

      setOpenAlert({
        ...openAlert,
        open: true,
        message: res.data.message,
        type: "error",
      });
      setIsloading(false)
      return;
    }
    setOpenAlert({
      ...openAlert,
      open: true,
      message: res.data.message,
      type: "success",
    });
    localStorage.clear();

    setTimeout(() => {
      navigate("/login");
    }, 1000);
  }

    const handleFail = (res) => {

    }
    registerVerify(data,handleSuccess, handleFail)
  
  }
  if(otpType==='forgot-password'){
    dispatch(setCurrentOTP(otp));
      localStorage.setItem("currentOTP", otp);
const data={
  email:useremail,
  otp: otp,
  otp_validation:true
}
      const handleSuccess = (res) => {
        if (!res.data.success) {
          setOpenAlert({
            ...openAlert,
            open: true,
            message: res.data.message,
            type: "error",
          });
          setIsloading(false);
     
          return;
        }
        setOpenAlert({
          ...openAlert,
          open: true,
          message: res.data.message,
          type: "success",
        });
        setIsloading(false);
        setTimeout(() => {
          navigate("/resetPassword");
        }, 2000);
      }
      const handleFail = (res) => {
        setIsloading(false);

      }
      forgotPassword(data, handleSuccess, handleFail);
  }
  };
  return (
    <div className="otp-container">
      <AlertBar
        message={openAlert.message}
        open={openAlert.open}
        handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        alertType={openAlert.type}
      />
      <PCLiveLoader open={isLoading} /> 

      <Grid container >
      <Grid item xs={1}></Grid>

      <Grid item xs={5}>
      <div className="left-content">
        <div className="form-container">
        <div item md={2} className="pclive-new-logo">
                    <img src={pcLiveLogo} alt="pcLiveLogo" loading="lazy" />
                    <div className="text-xl-medium-20">PCLive247</div>
                    <hr />
                  </div>

          <div className="header-container">
              <div className="text-sm-semibold">
                <div>Verify OTP</div>
              </div>
              <span className="text-md-medium">
              Enter the code sent to your email {useremail}
              </span>
            </div>
          <div className="otp-content">
            <span className="text-sm-medium-14">Secure six digit code</span>
            <span className="saparator">-</span>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span style={{ width: "8px" }}></span>}
              renderInput={(props) => <input {...props} />}
              isInputNum={true}
              containerStyle={{
                display:'flex',
                justifyContent: 'space-between'
              }}
              inputStyle={{
                borderRadius: 8,
                border: "1px solid var(--gray-300, #D0D5DD)",
                background: "#FFF",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                height: '62px',
                width: '62px',
                marginTop: 6,
                fontSize: 30,
              }}
              
            />
            {timer ? (
              <p className="text-sm-semibold-14">
                Didn’t receive any code?{" "}
                <strong onClick={() => onResendOTP()}>Resend code</strong>
              </p>
            ) : (
              <p className="text-sm-semibold-14">
                Code sent! Resend code again in{" "}
                <span><Timer setTimer={setTimer} delayResend={60} />s</span>
              </p>
            )}
          </div>
          <CustomPrimaryButton
            onClick={onSubmit} 
            disabled={otp.length!=6}
            size="xl"
            fullWidth
          >
            {otpType==='login'?"Log In":"Validate OTP"}
          </CustomPrimaryButton>
        </div>
      </div>
      </Grid>
      <Grid item xs={1}></Grid>

      <Grid  xs={5}>

      <AuthCarosel />
      </Grid></Grid>
    </div>
  );
};

export default OTP;

import "./ViewCard.scss";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import CustomPrimaryButton from "../../../../../../components/buttons/primary/CustomPrimaryButton";
import CustomizedTooltips from "../../../../../../components/tooltip/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import pcliveLogo from "../../../../../../assests/icons/pclive247.png";
import { showData } from "../../../../../../utils/utils";
import { useSelector } from "react-redux";
import ButtonLight from "../../../../../../components/buttons/button-light/ButtonLight";

const ViewCard = (props) => {
  const {
    data,
    toggleDrawer,
    cardData,
    hideBuyer,
    onWithdraw,
    showWithdraw,
    showEdit,
    onClickEdit,
    isDark,
    showHistory = false,
    onClickRerequest,
    onClickRequestHistory,
    onClickCancelRequest,
    showPublish,
    onClickPublish,
    roomType,
  } = props;
  const [imgLoad, setImgload] = useState(false);
  const [pickupAddress, setPickupAddress] = useState({});
  const [isCorporate, setIsCorporate] = useState(false);

  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const personalAddresses = useSelector((state) => state.address.data?.data);
  const corporateAddresses = useSelector(
    (state) => state.address.corporateAddresses
  );

  useEffect(() => {
    let currentAddress = personalAddresses?.filter(
      (address) => address.id === data?.address_id
    );
    setIsCorporate(false);
    if (currentAddress && !currentAddress.length) {
      currentAddress = corporateAddresses?.filter(
        (address) => address.id === data?.address_id
      );
      setIsCorporate(true);
    }
    setPickupAddress(currentAddress?.[0]);
  }, [personalAddresses, corporateAddresses, data?.address_id]);

  const handleImageLoaded = (e) => {
    setImgload(true);
  };

  return (
    <div
      className={`view-card-container  ${
        globalDarkMode || isDark ? "pclive-dark-mode" : ""
      }`}
    >
      <div className="image-container">
        <img
          className={`card-image ${data?.front_image ? "" : "opacity"}`}
          src={data?.front_image || pcliveLogo}
          alt="card"
        />
        <img
          className={`card-image ${
            data?.back_image || imgLoad ? "" : "opacity"
          }`}
          src={imgLoad ? data?.back_image : pcliveLogo}
          alt="card"
          onLoad={handleImageLoaded}
        />
      </div>
      <div className="card-details text-sm-medium-14">
        <div className="card-name-container text-sm-medium-14">
          <div>
            <span className="head">Card name</span>
            {data?.title?.length > 250 ? (
              <CustomizedTooltips title={data?.title}>
                <div className="value longName capitalize">{data?.title}</div>
              </CustomizedTooltips>
            ) : (
              <div className="value longName capitalize">{data?.title}</div>
            )}
          </div>
          <div className="price">
            <span className="value text-md-semibold-16">
              {"$" + data?.reserve_price}
            </span>
            <div className="head">Base price</div>
          </div>
        </div>
        <div className="card-name-container card-description">
          <div>
            <span className="head">Card Description</span>
            <div className="value">{data?.description}</div>
          </div>
        </div>
        <div className="card-name-container card-sub-details">
          <div className="sub-details first">
            <span className="head">Category</span>
            {data?.category_name > 100 ? (
              <CustomizedTooltips title={data?.category_name}>
                <div className="value long capitalize">
                  {data?.category_name}
                </div>
              </CustomizedTooltips>
            ) : (
              <div className="value long">{data?.category_name}</div>
            )}
          </div>
          <div className="sub-details">
            <span className="head">Condition</span>
            <div className="value capitalize">{data?.condition}</div>
          </div>
          <div className="sub-details">
            <span className="head">Year</span>
            <div className="value">{data?.card_year}</div>
          </div>
          <div className="sub-details no-border">
            <span className="head">Inventory tag</span>
            <CustomizedTooltips title={data?.inventory_tag_name}>
              <div className="value long capitalize">
                {data?.inventory_tag_name ? data?.inventory_tag_name : "N/A"}
              </div>
            </CustomizedTooltips>
          </div>
        </div>
      </div>
      <div className="card-details text-sm-medium-14">
        <div>
          <span className="head">Pickup Address</span>
          <div className="value capitalize">
            {data?.pickup_address_str ||
              `${isCorporate ? "Corporate Address :" : "Personal Address :"} ${
                pickupAddress?.address_name
                  ? pickupAddress?.address_name
                  : pickupAddress?.address_type
              } : ${pickupAddress?.line1}. ${pickupAddress?.city}. ${
                pickupAddress?.postal_code
              }`}
          </div>
        </div>
      </div>
      {hideBuyer ? (
        ""
      ) : (
        <Accordion className="buyer-details-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="buyer-header text-sm-medium-14"
          >
            Buyer Details
          </AccordionSummary>
          <AccordionDetails className="accord-details">
            <div className="buyer-details-container text-sm-medium-14">
              <div className="buyer-detail">
                <span className="name text-sm-medium-14">
                  {data && data.buyer_username
                    ? data.buyer_username
                    : "UNKNOWN"}
                </span>
                <span>{showData(data?.shipment_status)}</span>
              </div>
              <Divider />
              <div className="buyer-detail">
                <span className="head">Sold price</span>
                <span>
                  {data?.current_auction_price
                    ? `$${data?.current_auction_price}`
                    : "N/A"}
                </span>
              </div>
              <div className="buyer-detail">
                <span className="head">Card upload date</span>
                <span>{moment(data?.created_at).format("lll")}</span>
              </div>
              <div className="buyer-detail">
                <span className="head">Card sold date</span>
                <span>
                  {data?.sold_at ? moment(data?.sold_at).format("lll") : "N/A"}
                </span>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
      {showWithdraw && (
        <CustomPrimaryButton onClick={onWithdraw} className="withdraw">
          Withdraw the card from published view <ArrowCircleUpIcon />
        </CustomPrimaryButton>
      )}
      <Box className="buttons-container">
        {showHistory ? (
          !showEdit ||
          data?.request_state?.toLowerCase() === "declined" ||
          data?.request_state?.toLowerCase() === "reverted" ? (
            <CustomPrimaryButton onClick={onClickRequestHistory}>
              View history
            </CustomPrimaryButton>
          ) : (
            <ButtonLight
              onClick={onClickRequestHistory}
              className={
                (showEdit &&
                  data?.request_state?.toLowerCase() === "cancelled") ||
                (showPublish &&
                  data?.request_state?.toLowerCase() === "approved")
                  ? "white-bg"
                  : ""
              }
            >
              View history
            </ButtonLight>
          )
        ) : (
          ""
        )}
        {showEdit &&
          (data?.request_state?.toLowerCase() === "cancelled" ||
          (showPublish &&
            (data?.request_state?.toLowerCase() === "approved" ||
              roomType === 1 ||
              roomType === 2)) ? (
            <ButtonLight onClick={onClickEdit}>Edit card info</ButtonLight>
          ) : (
            ((data?.request_state?.toLowerCase() !== "pending" &&
              data?.request_state?.toLowerCase() !== "declined" &&
              data?.request_state?.toLowerCase() !== "reverted") ||
              roomType == 2) && (
              <CustomPrimaryButton onClick={onClickEdit}>
                Edit card info
              </CustomPrimaryButton>
            )
          ))}
        {showPublish && (
          <CustomPrimaryButton onClick={onClickPublish}>
            {data?.published ? "Withdraw" : "Publish"}
          </CustomPrimaryButton>
        )}
        {showHistory && data?.request_state?.toLowerCase() === "cancelled" && (
          <CustomPrimaryButton onClick={onClickRerequest}>
            Re-request
          </CustomPrimaryButton>
        )}
        {showHistory && data?.request_state?.toLowerCase() === "pending" && (
          <CustomPrimaryButton onClick={onClickCancelRequest}>
            Cancel request
          </CustomPrimaryButton>
        )}
      </Box>
    </div>
  );
};

export default ViewCard;

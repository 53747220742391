import "./FollowersDialog.scss";

import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getUserDetails,
  getUserFollowers,
  getUserInfo,
  removeFollower,
  updateUserFollower,
} from "../../../../../redux/slices/profile";
import { useDispatch, useSelector } from "react-redux";

import ButtonLight from "../../../../../components/buttons/button-light/ButtonLight";
import Dialog from "../../../../../components/dialog/Dialog";
import { NoData } from "../../../../../components/no-data/NoCards";
import PCLiveLoader from "../../../../../components/loader/PCLiveLoader";
import Pagination from "../../../../../components/pagination/Pagination";
import Popup from "../../../../../components/popup/Popup";
import pclive from "../../../../../assests/icons/pclive247.png";
import { ReactComponent as SearchIcon } from "../../../../../assests/icons/search-light.svg";

export default function FollowersDialog({
  openDialog,
  userId,
  onClose,
  isDark,
}) {
  const [pageIndex, setIndex] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const [openRemovePopup, setOpenRemovePopup] = useState(false);
  const [currentData, setCurrentData] = useState({ isFollowing: false, id: 0 });
  const totalPages = useSelector((state) => state.userFollowers?.totalPages);
  const dispatch = useDispatch();
  const userFollowers = useSelector(
    (state) => state.userFollowers.followersInfo
  );
  const isLoading = useSelector((state) => state.userFollowers.isLoading);
  const userInfo = useSelector((state) => state.profile?.data?.data);
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const auctionDarkMode = isDark ? isDark : "";
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (userId != null && userId != undefined) {
      if (openDialog)
        setTimeout(() => {
          dispatch(
            getUserFollowers(
              `?is_follow=true&user_id=${userId}&paginate=true&page=${pageIndex}&limit=10&search_text=${searchQuery}`
            )
          );
        }, 1000);
    } else {
      const userDetails = getUserDetails();
      const response = JSON.parse(userDetails);
      if (openDialog)
        setTimeout(() => {
          dispatch(
            getUserFollowers(
              `?is_follow=true&user_id=${response?.id}&paginate=true&page=${pageIndex}&limit=10&search_text=${searchQuery}`
            )
          );
        }, 1000);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (userId != null && userId != undefined) {
      if (openDialog)
        dispatch(
          getUserFollowers(
            `?is_follow=true&user_id=${userId}&paginate=true&page=${pageIndex}&limit=10`
          )
        );
    } else {
      const userDetails = getUserDetails();
      const response = JSON.parse(userDetails);
      if (openDialog)
        dispatch(
          getUserFollowers(
            `?is_follow=true&user_id=${response?.id}&paginate=true&page=${pageIndex}&limit=10`
          )
        );
    }
    setSearchQuery("");
  }, [dispatch, openDialog, pageIndex]);

  const setPageIndex = () => {
    if (totalPages == pageIndex && userFollowers?.data?.length == 1) {
      if (pageIndex > 1) {
        setIndex(pageIndex - 1);
        return pageIndex - 1;
      } else {
        setIndex(1);
        return 1;
      }
    } else {
      return pageIndex;
    }
  };

  const updateFollower = (is_follow, user_id) => {
    const userDetails = getUserDetails();
    const response = JSON.parse(userDetails);

    updateUserFollower(
      {
        is_follow,
        user_id,
      },
      () => {
        setPageIndex();
        if (userId !== null && userId !== undefined) {
          setOpenPopup(false);
          dispatch(
            getUserFollowers(
              `?is_follow=true&user_id=${userId}&paginate=true&page=${setPageIndex()}&limit=10`
            )
          );
        } else {
          dispatch(
            getUserFollowers(
              `?is_follow=true&user_id=${
                response?.id
              }&paginate=true&page=${setPageIndex()}&limit=10`
            )
          );
          dispatch(getUserInfo());
          setOpenPopup(false);
        }
      },
      (err) => console.log({ err })
    );
  };

  const removeCurrentFollower = (user_id) => {
    if (user_id) {
      setOpenRemovePopup(false);
      const userDetails = getUserDetails();
      const response = JSON.parse(userDetails);
      const body = {
        id: user_id,
      };
      removeFollower(
        body,
        (res) => {
          console.log(res);
          if (res?.data?.success) {
            setPageIndex();
            if (userId !== null && userId !== undefined) {
              setOpenPopup(false);
              dispatch(
                getUserFollowers(
                  `?is_follow=true&user_id=${userId}&paginate=true&page=${setPageIndex()}&limit=10`
                )
              );
            } else {
              dispatch(
                getUserFollowers(
                  `?is_follow=true&user_id=${
                    response?.id
                  }&paginate=true&page=${setPageIndex()}&limit=10`
                )
              );
              dispatch(getUserInfo());
              setOpenPopup(false);
            }
          }
        },
        (err) => console.log(err)
      );
    }
  };

  const onCloseDialog = () => {
    setIndex(1);
    onClose();
  };

  return (
    <Dialog
      openDialog={openDialog}
      onClose={onCloseDialog}
      title={"Followers"}
      // Need this code later...
      rightElement={
        <TextField
          type="search"
          size="small"
          placeholder="Search for name"
          id="input-with-icon-textfield"
          label=""
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          sx={{
            backgroundColor:
              globalDarkMode || auctionDarkMode ? "#292828" : "white",
            borderRadius: "8px",
            input: {
              color: globalDarkMode || auctionDarkMode ? "#F9FAFB" : "",
              fontSize: "14px",
              fontWeight: 500,
            },
            background: globalDarkMode || auctionDarkMode ? "#292828" : "#fff",
            minWidth: "171px",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      }
    >
      <>
        <Popup
          openPopup={openPopup}
          onClose={() => setOpenPopup(false)}
          actionButtons={{
            primary: {
              title: "No, go back",
              onClick: () => {
                setOpenPopup(false);
              },
            },
            secondary: {
              title: "Yes, unfollow",
              onClick: () => {
                updateFollower(currentData.isFollowing, currentData.id);
              },
            },
          }}
          title={"Are you sure you want to unfollow this user?"}
          description="Once unfollowed, you will not be able to see their information or cards."
        />
        <Popup
          openPopup={openRemovePopup}
          onClose={() => setOpenRemovePopup(false)}
          actionButtons={{
            primary: {
              title: "No, go back",
              onClick: () => {
                setOpenRemovePopup(false);
              },
            },
            secondary: {
              title: "Yes, remove",
              onClick: () => {
                removeCurrentFollower(currentData?.id);
              },
            },
          }}
          title={"Are you sure you want to remove this user?"}
          description="Once removed, you lose this follower."
        />
        {userFollowers?.data && userFollowers?.data?.length ? (
          userFollowers?.data?.map((item, i) => (
            <div key={item?.id}>
              <div
                className={`followers-container ${
                  globalDarkMode || auctionDarkMode ? "pclive-dark-mode" : ""
                }`}
              >
                <div className="profile-image-container">
                  <img
                    src={item?.profile_pic ? item?.profile_pic : pclive}
                    className={"user-icon"}
                    alt="userIcon"
                    height={58}
                    width={58}
                    loading="lazy"
                  />
                  <div className="name">
                    <div className="text-sm-medium-14">
                      {item?.first_name + " " + item?.last_name}
                    </div>
                    <div className="text-sm-medium-14 username">
                      {item?.username}
                    </div>
                  </div>
                </div>
                <div className="followers-button">
                  {item?.id != userInfo?.id && (
                    <>
                      <ButtonLight
                        onClick={() => {
                          const isFollowing = item?.following ? false : true;
                          if (item?.following) {
                            setOpenPopup(true);
                          } else {
                            updateFollower(isFollowing, item?.id);
                          }

                          setCurrentData({ isFollowing, id: item?.id });
                        }}
                      >
                        {item?.following
                          ? "Unfollow"
                          : userId
                          ? "Follow"
                          : "Follow back"}
                      </ButtonLight>
                      <Button
                        className="remove-button"
                        onClick={() => {
                          setCurrentData({ id: item?.id });
                          setOpenRemovePopup(true);
                        }}
                      >
                        Remove
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <Divider variant="fullWidth" />
            </div>
          ))
        ) : !isLoading ? (
          <NoData type="room" />
        ) : (
          ""
        )}
        {/* need this code later on... */}
        {userFollowers?.data && userFollowers?.data?.length ? (
          <Pagination
            index={pageIndex}
            handleChange={(page) => setIndex(page)}
            totalPage={totalPages}
            isDark={isDark ? isDark : ""}
          />
        ) : (
          ""
        )}
      </>
    </Dialog>
  );
}

import './AddressList.scss';

import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import Addresses from '../address/Addresses';
import ButtonLight from '../../buttons/button-light/ButtonLight';
import CustomPrimaryButton from '../../buttons/primary/CustomPrimaryButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { NoData } from '../../no-data/NoCards';
import { setCurrentAddress } from '../../../redux/slices/address';
import { useNavigate } from 'react-router-dom';

const AddressList = ({ allAddress, setOpenAddAddress, setOpenAddressList,isProfile }) => {
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  const dispatch = useDispatch()
  return (
    <div className={`address-list-container ${globalDarkMode?"pclive-dark-mode":""}`}>
      {
        allAddress && allAddress.length ?
          allAddress.map(address => {
            return (
              <div className='addresses'>
              <Addresses
                address={address}
                setOpenAddAddress={setOpenAddAddress}
                disableDelete={allAddress?.length==1}
              />
              </div>
            )
          }) : <NoData  type = "address"/>}
          {
            isProfile &&
            <div className='button-container'>
            <ButtonLight
              onClick={() => setOpenAddressList(false)}
    
            >
              Cancel
            </ButtonLight>
            
            <CustomPrimaryButton
              onClick={() => {setOpenAddAddress(true); dispatch(setCurrentAddress(null))}}
            >
              Add new address <AddIcon />
            </CustomPrimaryButton>
          </div>
          }
    
    </div>
  )
}

export default AddressList
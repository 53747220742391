import './ChangePasswordDialog.scss';

import { Backdrop, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

import ButtonLight from '../../../../../components/buttons/button-light/ButtonLight';
import CustomPrimaryButton from '../../../../../components/buttons/primary/CustomPrimaryButton';
import Dialog from '../../../../../components/dialog/Dialog';
import Input from '../../../../../components/inputs/email/Input';
import { changePassword } from '../../../../../redux/slices/auth';
import eye from '../../../../../assests/icons/eye.svg';
import eyeOff from '../../../../../assests/icons/eye-off.svg';
import { toast } from 'react-toastify';
import PCLiveLoader from '../../../../../components/loader/PCLiveLoader';

export default function ChangePasswordDialog({ openDialog, onClose }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    password: '',
    oldPassword: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({
    oldPassword:false,
    password: false,
    confirmPassword: false,
  });
  const [passErrors, setPassErrors] = useState({
    lengthError: false,
    numberError: false,
    uppercaseError: false,
    specialCharError: false,
  });

  const validatePassword = (data) => {
    let checkData = data? data :userData.password
    const lengthRegex = /^.{8,}$/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;

    const newErrors = {
      lengthError: !lengthRegex.test(checkData),
      numberError: !numberRegex.test(checkData),
      uppercaseError: !uppercaseRegex.test(checkData),
      specialCharError: !specialCharRegex.test(checkData),
    };

    setPassErrors(newErrors);

    // Return true if there are no errors
    return !Object.values(newErrors).some(error => error);
  };

  const onChangeInputs = (e, type) => {
    setUserData({ ...userData, [type]: e.target.value });
    if(type === 'password')
    {
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" || !validatePassword(e.target.value),
      });
    }
    else if(type === 'confirmPassword')
    {
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" || e.target.value != userData.password,
      });
    }
    else{
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" ? true : false,
      });
    }
  }

  const isValid = () => {
    let errors = {};
    if(!validatePassword()){
      errors.oldPassword = true;
    }
    if (!validatePassword()) {
      errors.password = true;
    }
    if (userData.confirmPassword !== userData.password) {
      errors.confirmPassword = true;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleContinue = () => {
    if (!isValid()) {
      return;
    }
    setIsLoading(true);
    changePassword(
      {
        old_password: userData?.oldPassword,
        new_password: userData?.confirmPassword,
      },
      res => {
        setIsLoading(false);
        if (res?.data?.success) {
          toast.success(res?.data?.message);
          setUserData({
            password: '',
            oldPassword: '',
            confirmPassword: '',
          })
          onClose();
        }else{
        toast.error(res?.data?.message);
        }
      },
      error => {
        setIsLoading(false);
      },
    );
  };

  function shouldDisable() {
    return (
      Object.keys(errors).some((key) => errors[key] === true) ||
      Object.keys(userData)
        .some((key) => userData[key] === "")
    );
}

  useEffect(() => {
    validatePassword();
  }, [userData.password]);

  return (
    <Dialog openDialog={openDialog} onClose={onClose} title={'Change password'}>
      <div className="change-password-container">
      <PCLiveLoader open={isLoading} /> 

        <div className="input-container">
          <div className="email">
            <Input
              placeholder='Enter your old password'
              label='Enter your old password'
              onChange={e =>
                onChangeInputs(e,'oldPassword')
              }
              error={errors.oldPassword}
              icon={showPassword ? eye : eyeOff}
              type={showPassword ? 'text' : 'password'}
              onClickIcon={() => setShowPassword(!showPassword)}
              errorMsg={
                'Please enter valid password'
              }
            />
          </div>
          <div className="email">
            <Input
              placeholder='Enter new password'
              label='Enter new password'
              onChange={e =>
                onChangeInputs(e,'password')
              }
              error={errors.password}
              icon={showPassword2 ? eye : eyeOff}
              type={showPassword2 ? 'text' : 'password'}
              onClickIcon={() => setShowPassword2(!showPassword2)}
              errorMsg={
                userData.password === "" ? "Please enter password" : 
                'At least one uppercase letter,one lowercase letter,one number and one special character'
              }
            />
          </div>
          <div className="email">
            <Input
              placeholder='Confirm new password'
              label='Confirm new password'
              onChange={e =>
                onChangeInputs(e,'confirmPassword')
              }
              error={errors.confirmPassword}
              icon={showPassword3 ? eye : eyeOff}
              type={showPassword3 ? 'text' : 'password'}
              onClickIcon={() => setShowPassword3(!showPassword3)}
              errorMsg={'Password do not match'}
            />
          </div>
          <div className="password-validator text-sm-medium-14">
            <div>
              <ul>
                <li
                  className={
                    passErrors.lengthError ? 'list-inactive' : 'list-active'
                  }>
                  At least 8 characters
                </li>
                <br />
                <li
                  className={
                    passErrors.numberError ? 'list-inactive' : 'list-active'
                  }>
                  One number
                </li>
              </ul>
            </div>
            <ul>
              <li
                className={
                  passErrors.uppercaseError ? 'list-inactive' : 'list-active'
                }>
                One uppercase letter
              </li>
              <br />
              <li
                className={
                  passErrors.specialCharError ? 'list-inactive' : 'list-active'
                }>
                One special character
              </li>
            </ul>
          </div>

          <div className="submit-button">
            <ButtonLight
              onClick={onClose}>
              Cancel
            </ButtonLight>
            <CustomPrimaryButton
              disabled={shouldDisable()}
              type="submit"
              onClick={handleContinue}>
              Change password
            </CustomPrimaryButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

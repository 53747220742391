import "./Table.scss";
import "./Table.scss";

import * as React from "react";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Pagination from "../pagination/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {},
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));
function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}
export default function CustomizedTables({
  totalPages,
  title,
  columns,
  children,
  pageIndex,
  setPageIndex,
  hidePage,
  size,
  priceSort,
  dateSort,
  countSort,
  dataSort,
}) {
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  return (
    <>
      <Paper
        className={`table-paper-container ${
          globalDarkMode ? "pclive-dark-mode" : ""
        }`}
        sx={{ width: "100%", overflow: "hidden", borderRadius: "12px" }}
      >
        {title && <div className="table-head text-sm-semibold-14">{title}</div>}
        <TableContainer
          className="custom-table-container"
          id="custom-table-container"
        >
          <Table stickyHeader size={size} aria-label="a dense table">
            <TableHead>
              <TableRow className="text-sm-semibold-14">
                {columns?.map((column) => (
                  <StyledTableCell
                    className={"table-header-text text-xs-semibold-12"}
                  >
                    {column}{" "}
                    {typeof column === "string" &&
                    column?.toLowerCase() === "price"
                      ? priceSort
                      : typeof column === "string" &&
                        column?.toLowerCase() === "total cards"
                      ? countSort
                      : typeof column === "string" &&
                        (column?.toLowerCase() === "approved at" ||
                          column?.toLowerCase() === "updated at")
                      ? dataSort
                      : ""}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {!hidePage && (
        <Pagination
          index={pageIndex}
          handleChange={(page) => setPageIndex(page)}
          totalPage={totalPages}
          isDashboard
        />
      )}
    </>
  );
}

import "./UploadBulk.scss";

import React, { useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup, TableCell, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import AddBoxIcon from "@mui/icons-material/AddBox";
import ButtonLight from "../../../../../../components/buttons/button-light/ButtonLight";
import Checkbox from "@mui/material/Checkbox";
import CustomPrimaryButton from "../../../../../../components/buttons/primary/CustomPrimaryButton";
import CustomizedTables from "../../../../../../components/table/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "../../../../../../components/inputs/email/Input";
import InventoryTag from "../../../../../../components/inventory-tag/Inventorytag";
import SelectInput from "../../../../../../components/select/select-input/SelectInput";
import checkBoxBase from "../../../../../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../../../../../assests/icons/CheckboxPrimary.svg";
import { getAllInventoryTag } from "../../../../../../redux/slices/seller";
import { getCategories } from "../../../../../../redux/slices/categories";
import { BpRadio } from "../../../../../../components/radio/RadioIconDesign";
import CustomizedRadios from "../../../../../../components/radio/Radio";
import AddressSelect from "../../../../../../components/address-select/AddressSelect";
import Popup from "../../../../../../components/popup/Popup";

export const UploadBulk = ({
  setMultiAddAlert,
  addOpenMulti,
  multiCard,
  onChangeMultiFile,
  onChangeMultiInput,
  multiError,
  onAddMultiCard,
  onRemoveMultiCard,
  isPrivateRoom,
  setIsPrivateRoom,
  isValidMulti,
  setPrivateRoomType,
  privateRoomType,
  handleResetBulkData,
  bulkState,
}) => {
  const [openRaiseRequestPopup, setOpenRaiseRequestPopup] = useState(false);

  const dispatch = useDispatch();
  const allCategories = useSelector((state) => state.categories.data?.data);
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const allTagList = useSelector(
    (state) => state.inventorytag.allTagList?.data
  );

  const getYears = () => {
    let year = [];
    for (let i = 1900; i <= new Date().getFullYear(); i++) {
      year.push({ label: i, value: i });
    }
    return year.reverse();
  };
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getAllInventoryTag());
  }, []);

  const tableColumns = [
    "S.No",
    "Title",
    "Category",
    "Inventory tag",
    "Condition",
    "Grade",
    "Year",
    "Price",
    "Front Image",
    "Back Image",
    "Description",
    "Action",
  ];
  return (
    <div
      className={`bulk-upload-card-drawer ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <Popup
        openPopup={openRaiseRequestPopup}
        onClose={() => setOpenRaiseRequestPopup(false)}
        actionButtons={{
          primary: {
            title: "No, go back",
            onClick: () => {
              setOpenRaiseRequestPopup(false);
            },
          },
          secondary: {
            title: `Yes`,
            onClick: () => {
              isValidMulti(false);
              setOpenRaiseRequestPopup(false);
            },
          },
        }}
        title="Are you sure you want to raise request?"
        description="You will be able to publish cards with corporate address only after Admin approves it. Are you sure you want to raise request?"
      />
      <CustomizedTables
        columns={tableColumns}
        title={""}
        hidePage
        size="small"
        bordered
      >
        {multiCard && multiCard.length
          ? multiCard.map((card, index) => {
              return (
                <TableRow
                  className="text-xs-medium-12 table-row"
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                    style={{ textAlign: "center" }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                  >
                    <div className="input-feild">
                      <Input
                        type="text"
                        error={multiError[index].title}
                        errorMsg={multiError[index].title}
                        onChange={(e) =>
                          onChangeMultiInput(e.target.value, "title", index)
                        }
                        maxLength={100}
                        value={multiCard[index].title}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                  >
                    <div className="input-feild">
                      <SelectInput
                        data={
                          allCategories && allCategories.length
                            ? allCategories
                                .filter((f) => f.status)
                                .map((c) => {
                                  return {
                                    label: c.category_name,
                                    value: c.id,
                                  };
                                })
                            : []
                        }
                        error={multiError[index].category}
                        errorMsg={"Please select category"}
                        onChange={(e) =>
                          onChangeMultiInput(e.target.value, "category", index)
                        }
                        value={multiCard[index].category}
                        placeholder="Select Category"
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                  >
                    <div className="input-feild">
                      <InventoryTag
                        tagValue={multiCard[index]?.inventory_tag}
                        allTagList={
                          allTagList && allTagList.length ? allTagList : []
                        }
                        setTagValue={(val) =>
                          onChangeMultiInput(val?.title, "inventory_tag", index)
                        }
                        isDisabled={bulkState.is_corporate}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                  >
                    <div className="input-feild">
                      <SelectInput
                        data={[
                          { label: "Raw", value: "raw" },
                          { label: "Slab", value: "slab" },
                        ]}
                        error={multiError[index].condition}
                        errorMsg={"Please select condition"}
                        onChange={(e) =>
                          onChangeMultiInput(e.target.value, "condition", index)
                        }
                        value={multiCard[index].condition}
                        placeholder="Select Condition"
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                  >
                    <div className="input-feild">
                      <SelectInput
                        disabled={
                          multiCard[index].condition?.toLowerCase() === "raw"
                        }
                        data={[
                          { label: "Authentic", value: 0 },
                          { label: "1", value: 1 },
                          { label: "2", value: 2 },
                          { label: "3", value: 3 },
                          { label: "4", value: 4 },
                          { label: "5", value: 5 },
                          { label: "6", value: 6 },
                          { label: "7", value: 7 },
                          { label: "8", value: 8 },
                          { label: "9", value: 9 },
                          { label: "10", value: 10 },
                        ]}
                        onChange={(e) =>
                          onChangeMultiInput(
                            parseInt(e.target.value),
                            "grade",
                            index
                          )
                        }
                        value={multiCard[index].grade}
                        error={multiError[index].grade}
                        errorMsg={"Please select grade"}
                        placeholder="Select Grade"
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                  >
                    <div className="input-feild">
                      <SelectInput
                        data={getYears()}
                        onChange={(e) =>
                          onChangeMultiInput(e.target.value, "card_year", index)
                        }
                        value={multiCard[index].card_year}
                        error={multiError[index].card_year}
                        errorMsg={"Please select year"}
                        placeholder="Select Year"
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                  >
                    <div className="input-feild">
                      <Input
                        type="number"
                        error={multiError[index].reserve_price}
                        errorMsg="Price should be in between 0 and 1,000,000"
                        onChange={(e) =>
                          onChangeMultiInput(
                            parseInt(e.target.value),
                            "reserve_price",
                            index
                          )
                        }
                        value={multiCard[index].reserve_price}
                        min={1}
                        padding="10px 14px"
                      />
                    </div>
                  </TableCell>

                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                  >
                    <div className="card-upload">
                      <ButtonLight component="label">
                        Upload
                        <input
                          hidden
                          accept=".png, .jpg, .jpeg"
                          type="file"
                          onChange={(e) =>
                            onChangeMultiFile(e, "front_image", index)
                          }
                        />
                      </ButtonLight>
                      <span className="card-name">
                        {multiCard[index].front_image_name &&
                        multiCard[index].front_image_name.name
                          ? multiCard[index].front_image_name.name
                          : ""}
                      </span>
                      {multiError[index].front_image === true ? (
                        <span className="error text-sm-regular">
                          Please select front image
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                  >
                    <div className="card-upload">
                      <ButtonLight component="label">
                        Upload
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) =>
                            onChangeMultiFile(e, "back_image", index)
                          }
                        />
                      </ButtonLight>
                      <span className="card-name">
                        {multiCard[index].back_image_name &&
                        multiCard[index].back_image_name.name
                          ? multiCard[index].back_image_name.name
                          : ""}
                      </span>
                      {multiError[index].back_image === true ? (
                        <span className="error text-sm-regular">
                          Please select back image
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                  >
                    <div className="input-feild">
                      <Input
                        type="text"
                        error={multiError[index].description}
                        errorMsg="Please enter description"
                        onChange={(e) =>
                          onChangeMultiInput(
                            e.target.value,
                            "description",
                            index
                          )
                        }
                        maxLength={100}
                        value={multiCard[index].description}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-xs-medium-12 table-cell"
                    align="left"
                  >
                    {multiCard.length !== 1 ? (
                      <DeleteIcon
                        onClick={() => onRemoveMultiCard(index)}
                        className="icon"
                      />
                    ) : (
                      ""
                    )}
                    {multiCard.length - 1 === index ? (
                      <AddBoxIcon
                        onClick={() => onAddMultiCard()}
                        className="icon"
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          : ""}
      </CustomizedTables>
      <div className="bottom-container">
        <div>
          {/* As per requirements the functionality is removed for this release,Might need in next one, hence commenting */}
          {/* <Checkbox
            sx={{ marginLeft: -1 , opacity: multiCard?.length < 10? 0.3:1}}
            checkedIcon={<img src={checkBoxPrimary} />}
            icon={<img src={checkBoxBase} />}
            checked={isPrivateRoom}
            onChange={(e) => multiCard?.length >= 10 && setIsPrivateRoom(e.target.checked)}
            disabled={ multiCard?.length < 10}
          />
          <span className={`text-sm-medium-14 data ${multiCard?.length < 10?"disabled":""}`}>
            Create private room (must have atleast{" "}
            {process.env.REACT_APP_PRIVATE_ROOM_COUNT} cards)
          </span> */}
          {/* {isPrivateRoom && <CustomizedRadios
                          label="Select private room type"
                          data={[
                            { label: "Home", value: "home" },
                            { label: "Categorized", value: "categorized" },
                            { label: "Private", value: "private" },
                          ]}
                          onChange={(e) =>
                            setPrivateRoomType(e.target.value)
                          }
                          value={privateRoomType}
                        />} */}
        </div>
        <div>
          <ButtonLight
            onClick={() => {
              setMultiAddAlert(false);
              handleResetBulkData();
            }}
          >
            Cancel
          </ButtonLight>
          <CustomPrimaryButton
            onClick={() => {
              !bulkState.is_corporate
                ? isValidMulti(false)
                : setOpenRaiseRequestPopup(true);
            }}
          >
            {!bulkState.is_corporate ? "Save as draft" : "Raise request"}
          </CustomPrimaryButton>
          {!bulkState.is_corporate && (
            <CustomPrimaryButton
              onClick={() => {
                isValidMulti(true);
              }}
            >
              Publish
            </CustomPrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

import "./ButtonLight.scss";

import { Button } from "@mui/material";
import React from "react";
import {useSelector} from "react-redux";

const ButtonLight = (props) => {
  const { label, disabled, type, isDark, className } = props;
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  return (
    <div
      className={`text-sm-semibold-14 button-light-component ${
        globalDarkMode || isDark ? "pclive-dark-mode" : ""
      }`}
    >
      <Button
        variant="text"
        {...props}
        className={`${className} text-sm-semibold-14 ${
          type == "error" ? "error" : ""
        }`}
      >
        {props.children}
      </Button>
    </div>
  );
};
export default ButtonLight;

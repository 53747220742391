import React from "react";
import "./PaymentBank.scss";
import visa from "../../assests/icons/visa.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const PaymentBank = ({ isDelete, setOpenDeletebank, bank, setCurrentCard }) => {
  return (
    <div className="payment-bank-container">
      <div className="left-content">
        {/* <img src={visa} loading='lazy'/> */}
        <div className="text-sm-medium-14">{bank?.account_holder_name}</div>
        <div className="text-sm-medium-14 card-no">xxxx {bank?.last4}</div>
      </div>
      <div className="right-content">
        <div
          onClick={() => {
            setOpenDeletebank(true);
            setCurrentCard(bank);
          }}
        >
          <DeleteOutlineIcon />
        </div>
      </div>
    </div>
  );
};

export default PaymentBank;

import { apiPatchCall, apiPostCall } from '../../utils/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from "axios";
import {getToken} from "../../utils/utils";

export const login = (userData, handleSuccess, handleFail) => {
  apiPostCall('api/login/', userData, handleSuccess, handleFail);
};

export const register = (userData, handleSuccess, handleFail) => {
  apiPostCall('api/register/', userData, handleSuccess, handleFail);
};

export const logout = (handleSuccess, handleFail) => {
  apiPostCall('api/logout/', handleSuccess, handleFail);
};

export const registerVerify = (userData, handleSuccess, handleFail) => {
  apiPostCall('api/verify_otp/', userData, handleSuccess, handleFail);
};

export const verifyLoginOtp = (userData, handleSuccess, handleFail) => {
  apiPostCall('api/login_authentication/', userData, handleSuccess, handleFail);
};

export const resendOTP = (userData, handleSuccess, handleFail) => {
  apiPostCall('api/send_otp/', userData, handleSuccess, handleFail);
};

export const forgotPassword = (userData, handleSuccess, handleFail) => {
  apiPostCall('api/forget_password/', userData, handleSuccess, handleFail);
};

export const registerValidation = (userData, handleSuccess, handleFail) => {
  apiPostCall('api/register/validations/', userData, handleSuccess, handleFail);
};

export const checkPassword = (userData, handleSuccess, handleFail) => {
  apiPostCall('api/check_password/', userData, handleSuccess, handleFail);
};

export const changePassword = (userData, handleSuccess, handleFail) => {
  apiPatchCall('api/change_password/', userData, handleSuccess, handleFail);
};

export const deleteAccountApi = (handleSuccess, handleFail) => {
  apiPostCall('api/delete_account/', handleSuccess, handleFail);
};
export const getAllNotifications = createAsyncThunk(
  "getAllNotifications",
  async (data, handleSuccess, handleFail) => {
    return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/notification/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    });
  }
);

export const generateRealTimeToken = (userData, handleSuccess, handleFail) => {
  apiPostCall('api/device_token/', userData, handleSuccess, handleFail);
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    currentOTP: null,
    currentUserType: 'buyer',
    isLoader:false,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoader = action.payload;
    },
    setCurrentOTP: (state, action) => {
      state.currentOTP = action.payload;
    },
    setCurrentUserType: (state, action) => {
      state.currentUserType = action.payload;
    },
  },
});
const notification = createSlice({
  name: 'notification',
  initialState: {
    isLoading: false,
    notification:null
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNotifications.pending, (state, action) => {
      state.isLoading = true;
      state.notification = null;
    });
    builder.addCase(getAllNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notification = action.payload?.data;
    });
    builder.addCase(getAllNotifications.rejected, (state, action) => {
      state.isLoading = false;
      state.notification = action.error;
    });
  },
});
export const authSliceReducer = {
  auth:authSlice.reducer,
  notifications:notification.reducer
};
export const {setIsLoading, setCurrentOTP , setCurrentUserType} = authSlice.actions;

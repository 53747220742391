import "./AddPaymentBank.scss";
import React, { useState } from "react";
import {
  addPaymentbankAction,
  getPaymentBank,
} from "../../redux/slices/payment";
import { useDispatch, useSelector } from "react-redux";
import ButtonLight from "../buttons/button-light/ButtonLight";
import Checkbox from "../checkbox/Checkbox";
import CustomPrimaryButton from "../buttons/primary/CustomPrimaryButton";
import Input from "../inputs/email/Input";
import PCLiveLoader from "../loader/PCLiveLoader";
import SelectInput from "../select/select-input/SelectInput";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../redux/slices/profile";
import { toggleSeller } from "../../redux/slices/seller";
import checkBoxBase from "../../assests/icons/checkBoxBase.svg";
import checkBoxPrimary from "../../assests/icons/CheckboxPrimary.svg";
import checkBoxBaseDark from "../../assests/icons/checkbox-base-dark.svg";
import checkBoxPrimaryDark from "../../assests/icons/checkbox-checked-dark.svg";

const INITIAL_STATE = {
  account_holder_name: "",
  account_holder_type: "individual",
  routing_number: "",
  account_number: "",
  re_account_number: "",
  ssn_last4: "",
  is_primary: true,
  is_terms: false,
};
const AddPaymentBank = ({
  address,
  setOpenAddPaymentBank,
  accVerify,
  setOpenStripeVerification,
  tab,
  setOpenAddPaymentCard,
  userInfo,
}) => {
  const dispatch = useDispatch();
  const [load, isLoad] = useState(false);
  const [bankDetails, setBankDetail] = useState(INITIAL_STATE);

  const globalDarkMode = useSelector((state) => state.profile.globalDarkMode);
  const [errors, setErrors] = useState({
    account_holder_name: false,
    account_holder_type: false,
    routing_number: false,
    account_number: false,
    re_account_number: false,
    ssn_last4: false,
  });

  const onChangeInputs = (e, type) => {
    if (type === "account_holder_name") {
      setBankDetail({ ...bankDetails, [type]: e.target.value });
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" || !/^[a-zA-Z\s]*$/.test(e.target.value)
            ? true
            : false,
      });
    } else if (type === "ssn_last4") {
      setBankDetail({ ...bankDetails, [type]: e.target.value });
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" || e.target.value.length !== 4 ? true : false,
      });
    } else if (type === "re_account_number") {
      setBankDetail({ ...bankDetails, [type]: e.target.value });
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" || e.target.value != bankDetails?.account_number
            ? true
            : false,
      });
    } else if (type === "routing_number") {
      setBankDetail({ ...bankDetails, [type]: e.target.value });
      setErrors({
        ...errors,
        [type]:
          e.target.value === "" ||
          e.target.value.length != 9 ||
          !/^[0-9]+$/.test(e.target.value)
            ? true
            : false,
      });
    } else {
      setBankDetail({ ...bankDetails, [type]: e.target.value });
      setErrors({
        ...errors,
        [type]: e.target.value === "" ? true : false,
      });
    }
  };

  function shouldDisable() {
    return (
      Object.keys(errors).some((key) => errors[key] === true) ||
      Object.keys(bankDetails).some((key) => bankDetails[key] === "")
    );
  }
  const isValid = () => {
    let errors = {};
    if (bankDetails.account_number !== bankDetails.re_account_number) {
      errors.compareAcc = true;
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const onSubmitAddress = () => {
    if (!isValid()) {
      return;
    }
    isLoad(true);
    toggleSeller(
      {},
      (data) => {
        if (data?.data?.success) {
          addPaymentbankAction(
            bankDetails,
            (body) => {
              if (body.data.success) {
                toast.success(body.data.message);

                setBankDetail(INITIAL_STATE);
                setOpenAddPaymentBank(false);
                dispatch(getPaymentBank());
                dispatch(getUserInfo());
                if (accVerify && tab == 2) {
                  setOpenAddPaymentBank(false);
                  setOpenStripeVerification(true);
                }
                if (accVerify && tab == 3) {
                  setOpenAddPaymentBank(false);
                  if (userInfo?.card_added) {
                    setOpenStripeVerification(true);
                  } else {
                    setOpenAddPaymentCard(true);
                  }
                }
                isLoad(false);
              } else {
                toast.error(body.data.message);
                isLoad(false);
              }
            },
            () => {
              isLoad(false);
            }
          );
        }
      },
      (error) => {
        toast.error(error?.message);
      }
    );
  };
  return (
    <div className="payment-add-container">
      <PCLiveLoader open={load} />
      <div className="input-space">
        <Input
          label="Account holder name"
          type="text"
          onChange={(e) => onChangeInputs(e, "account_holder_name")}
          error={errors.account_holder_name}
          value={bankDetails?.account_holder_name}
          errorMsg="Please enter account holder name"
          maxLength={30}
        />
      </div>
      <div className="input-space">
        <Input
          placeholder=""
          label="Bank account no."
          type="number"
          onChange={(e) => onChangeInputs(e, "account_number")}
          error={errors?.account_number}
          value={bankDetails?.account_number}
          errorMsg="Please enter valid account number"
        />
      </div>
      <div className="input-space">
        <Input
          placeholder=""
          label="Re-enter bank account no."
          type="number"
          onChange={(e) => onChangeInputs(e, "re_account_number")}
          error={errors?.re_account_number || errors?.compareAcc}
          value={bankDetails?.re_account_number}
          errorMsg={
            errors.re_account_number
              ? "Please enter valid account number"
              : errors.compareAcc
              ? "Account number not matching"
              : ""
          }
        />
      </div>
      <div className="input-space">
        <Input
          placeholder=""
          label="Routing no."
          type="number"
          onChange={(e) => onChangeInputs(e, "routing_number")}
          error={errors?.routing_number}
          value={bankDetails?.routing_number}
          errorMsg="Please enter valid routing number"
        />
      </div>

      <div className="input-space">
        <Input
          placeholder=""
          label="Last four digits of your social security number"
          type="number"
          onChange={(e) => onChangeInputs(e, "ssn_last4")}
          error={errors?.ssn_last4}
          value={bankDetails?.ssn_last4}
          errorMsg="Please enter valid value"
        />
      </div>
      <SelectInput
        label="Select account holder type"
        data={[
          { label: "Individual", value: "individual" },
          { label: "Company", value: "company" },
        ]}
        onChange={(e) =>
          setBankDetail({ ...bankDetails, account_holder_type: e.target.value })
        }
        value={bankDetails?.account_holder_type}
      />

      <Checkbox
        disableLabelClick={true}
        label={
          <span>
            By checking this, you agree to our{" "}
            <a
              className="text-sm-medium-14"
              target="_blank"
              href="/termsandconditions"
            >
              Terms & Conditions
            </a>
          </span>
        }
        checkedIcon={
          <img
            src={globalDarkMode ? checkBoxPrimaryDark : checkBoxPrimary}
            alt=""
          />
        }
        icon={
          <img src={globalDarkMode ? checkBoxBaseDark : checkBoxBase} alt="" />
        }
        checked={bankDetails?.is_terms}
        onChange={(e) =>
          setBankDetail({ ...bankDetails, is_terms: e.target.checked })
        }
        value={bankDetails?.is_terms}
      />

      <div className="button-container">
        <ButtonLight onClick={() => setOpenAddPaymentBank(false)}>
          Cancel
        </ButtonLight>
        <CustomPrimaryButton
          disabled={!bankDetails?.is_terms || shouldDisable()}
          onClick={() => onSubmitAddress()}
        >
          {accVerify ? "Save bank and next" : "Save details"}
        </CustomPrimaryButton>
      </div>
    </div>
  );
};

export default AddPaymentBank;

import { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import "./RequestHistory.scss";
import { ReactComponent as LocationIcon } from "../../../../../../assests/icons/location.svg";
import profileLightImage from "../../../../../../assests/icons/account_circle_light.png";
import profileDarkImage from "../../../../../../assests/icons/account_circle_dark.png";
import { getRequestHistory } from "../../../../../../redux/slices/card";
import moment from "moment";
import { useSelector } from "react-redux";

export default function RequestHistory({ cardId }) {
  const [requestHistory, setRequestHistory] = useState([]);
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);

  useEffect(() => {
    getRequestHistory(
      { card_id: cardId },
      (body) => {
        setRequestHistory(body?.data?.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [cardId]);

  return (
    <Timeline
      className={`request-history-container ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
      sx={{
        padding: "32px",
        margin: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {requestHistory && requestHistory?.length
        ? requestHistory?.map((history, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot className="timeline-dot text-xs-medium">
                  {requestHistory?.length - index < 10
                    ? "0" + (requestHistory?.length - index)
                    : requestHistory?.length - index}
                </TimelineDot>
                {requestHistory?.length > index + 1 && (
                  <TimelineConnector className="timeline-connector" />
                )}
              </TimelineSeparator>
              <TimelineContent className="content" sx={{ py: "0", px: 2 }}>
                <Box className="flex">
                  <Typography className="status capitalize text-md-semibold-16">
                    {history?.request_state}
                  </Typography>
                  <Typography className="type">
                    {history?.request_state?.toLowerCase() === "reverted"
                      ? "Personal Card"
                      : "Corporate card"}
                  </Typography>
                </Box>
                {/* Commenting for now. Might need it in the future */}
                {/* <Box className="flex" sx={{ gap: "8px" }}>
                  <img
                    src={
                      history?.profile_picture ||
                      (globalDarkMode ? profileDarkImage : profileLightImage)
                    }
                    alt="profile"
                  />
                  <Typography className="info capitalize">
                    {history?.seller_name}
                  </Typography>
                </Box> */}
                <Box className="flex">
                  <LocationIcon />
                  <Typography className="address capitalize">
                    {history?.address?.address_name ||
                      history?.address?.address_type}{" "}
                    : {history?.address?.line1}, {history?.address?.city},
                    {history?.address?.state}, {history?.address?.country},
                    {history?.address?.postal_code}
                  </Typography>
                </Box>
                <Typography className="date">
                  {moment(history.request_date).format("lll")}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))
        : ""}
    </Timeline>
  );
}

import "./Popup.scss";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import AddressSelect from "../address-select/AddressSelect";
import ButtonLight from "../buttons/button-light/ButtonLight";
import CustomPrimaryButton from "../buttons/primary/CustomPrimaryButton";
import InventoryTag from "../inventory-tag/Inventorytag";

export default function Popup({
  title,
  description = "",
  openPopup,
  onClose,
  note,
  isDark,
  actionButtons = {
    primary: {
      title: "",
      onClick: () => null,
    },
    secondary: {
      title: "",
      onClick: () => null,
    },
  },
  showAddressSelect,
  addressId,
  isCorporate,
  setCurrentCard,
  isUpdateAddress,
  showInventoryTag,
  inventoryTag,
  allTagList,
  isError,
}) {
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);

  useEffect(() => {
    if (!addressId) {
      setCurrentCard &&
        setCurrentCard((prev) => {
          return {
            ...prev,
            address_id: "",
          };
        });
    }
  }, [addressId, setCurrentCard]);

  return (
    <Dialog
      open={openPopup}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 12,
          overflowY: "visible",
        },
      }}
    >
      <div
        className={`popup-container ${
          isDark || globalDarkMode ? "pclive-dark-mode" : ""
        }`}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <InfoOutlinedIcon className="icon" />
          {title && (
            <div className="text-sm-medium-14 titleColor capitalize">
              {title}
            </div>
          )}
        </div>
        {description && (
          <div className="text-sm-regular-14 description">
            {note && <p className="text-sm-semibold-14">Note: {note}</p>}
            {description}
          </div>
        )}
        {showAddressSelect ? (
          <AddressSelect
            addressId={addressId}
            isCorporateCard={isCorporate}
            updateAddressDetails={(selectedAddress, isCorporate) => {
              setCurrentCard((prev) => {
                return {
                  ...prev,
                  address_id: selectedAddress[0]?.id,
                  is_corporate: isCorporate,
                };
              });
            }}
            showNote={false}
            isUpdateAddress={isUpdateAddress}
          />
        ) : (
          ""
        )}
        {showInventoryTag ? (
          <InventoryTag
            tagValue={inventoryTag}
            allTagList={allTagList && allTagList.length ? allTagList : []}
            setTagValue={(val) =>
              setCurrentCard((prev) => {
                return {
                  ...prev,
                  inventory_tag_name: val?.title,
                  inventory_tag_id: val?.id,
                };
              })
            }
            label="Inventory tag"
            setCardState={setCurrentCard}
          />
        ) : (
          ""
        )}
        {actionButtons && (
          <div className="button-container">
            {actionButtons?.primary && (
              <ButtonLight onClick={actionButtons?.primary?.onClick}>
                {actionButtons?.primary?.title}
              </ButtonLight>
            )}
            {actionButtons?.secondary && (
              <CustomPrimaryButton
                onClick={actionButtons?.secondary?.onClick}
                isError={isError}
              >
                {actionButtons?.secondary?.title}
              </CustomPrimaryButton>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
}

import {
  apiDeleteCall,
  apiPostCall,
  apiPutCall,
  apiPutCallForFormData,
  getToken,
} from "../../utils/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

export const getUserDetails = () => {
  return localStorage.getItem("userDetails");
};

export const getUserInfo = createAsyncThunk("getUserInfo", async (data) => {
  return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/me`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
  });
});

export const updateUserInfo = (userData, handleSuccess, handleFail) => {
  apiPutCallForFormData("api/me/", userData, handleSuccess, handleFail);
};

export const updateUserFollower = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/follow_unfollow/", userData, handleSuccess, handleFail);
};

export const removeFollower = (userData, handleSuccess, handleFail) => {
  let url = `?user_id=${userData.id}`;
  apiDeleteCall(`api/follow_unfollow/${url}`, handleSuccess, handleFail);
};

export const getUserFollowers = createAsyncThunk(
  "getUserFollowers",
  async (params) => {
    return await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}api/follow_unfollow/${params}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      }
    );
  }
);

export const toggleNotification = (userData, handleSuccess, handleFail) =>
  apiPutCall('api/notification/toggle/', userData, handleSuccess, handleFail);

export const toggleKidsMode = (userData, handleSuccess, handleFail) =>
  apiPostCall('api/default_source/', userData, handleSuccess, handleFail);

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoading: false,
    userInfo: null,
    settingOption:null,
    globalDarkMode:false,
    selectedTab : 1,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setSettingOptions: (state, action) => {
      state.settingOption = action.payload;
    },
    setGlobalDarkMode: (state, action) => {
      state.globalDarkMode = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserInfo.pending, (state, action) => {
      state.isLoading = true;
      state.data = null;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.data = action.error;
    });
  },
});

const userFollowersSlice = createSlice({
  name: 'userFollowers',
  initialState: {
    isLoading: false,
    followersInfo: null,
    totalPages : 1,
  },
  reducers: {
    setUserFollowers: (state, action) => {
      state.followersInfo = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserFollowers.pending, (state, action) => {
      state.isLoading = true;
      state.followersInfo = null;
    });
    builder.addCase(getUserFollowers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.followersInfo = action.payload.data;
      state.totalCount = action.payload.data.total_count;
      state.totalPages = action.payload.data.total_pages;
    });
    builder.addCase(getUserFollowers.rejected, (state, action) => {
      state.isLoading = false;
      state.followersInfo = action.error;
    });
  },
});
export const {setSettingOptions,setGlobalDarkMode ,setSelectedTab} = profileSlice.actions;
export const profile = {
  profile: profileSlice.reducer,
  userFollowers: userFollowersSlice.reducer,
};

export default profile;

import './FAQ.scss'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import {useSelector} from "react-redux";

const FAQ = () => {
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);
  const data = [
    { head: "How do I get paid?", subhead: "You’ll receive payment 48 - 72 hours upon USPS package delivery to the buyer. This will initially show as PC 24/7 credit in your account which you can then cash out via PayPal or ACH (directly to your bank account)." },
    {head:"How does shipping work?",subhead:`Seller fees are as follows:
    <ul><li> 8% commission on the sold price of an item when it sells</li>
    <li>2.9% + 30 cents payment processing fee for the entire transaction</li>
       <ul style="list-style-type:disc"><li>Payment processing applies to the subtotal, tax, and shipping price paid by a buyer for a given order.</li>
       <li>The payment processing fee is not currently applied to any international shipping/taxes.</li></ul></li></ul>`},
    {head:"What can I sell?",subhead:"Right now only sports and trading cards, but we look to bring on other categories such as collectibles, clothing, electronics etc. in the near future"},
    {head:"Is it safe?",subhead:"Yes! \n\nPC 24/7 has a dedicated support staff to help you and your customers deal with any issues they have using the app or with their orders. Our priority has always been to build a marketplace and community with a strong foundation of trust, reliability, and safety."},
    {head:"I accidentally bid on an item. What should I do?",subhead:`All bids are binding. Please be cautious when viewing a livestream to ensure you do not misbid on an item!
    \nYou can request to cancel your order as outlined above in Submitting a Cancellation Request. If there was a technical issue that resulted in a bidding error, you must report this error by reaching out to our PC 24/7 Support team within two hours of the error occurring in order for a cancellation to be considered (we do not refund for buyer’s remorse). All errors will be investigated, so please provide as much documentation and evidence of the error as possible.`},
    {head:"How long do refunds take?",subhead:`Refunds will typically take 3-5 business days (depending on your banking institution) to show up on your original form of payment from the day the refund is initiated. Please note that this refund may either show up as a credit or it may disappear from your bank statement entirely.\n
    If you have any questions, please contact our PC 24/7 Support team through the app and we will be happy to help!`},
  ];
  return (
        <div className={`faq-container ${globalDarkMode ? 'pclive-dark-mode' : ''}`}>
      {data.map((item,index) => {
        return (
          <Accordion
          defaultExpanded={true}
                        className="text-sm-medium-14 accordion-head"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon  className="expandIcon"/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="head"
              sx={{ borderRadius: "10px", border: "none" , fontSize:"16px",fontWeight:"600"}}
            >
              {item.head}
            </AccordionSummary>
            <AccordionDetails
              sx={{ borderRadius: "10px", border: "none" }}
              className="text-sm-medium-14 subhead"
            >
              <Divider sx={{ marginBottom: 3 }} />
              <div
      dangerouslySetInnerHTML={{__html: item.subhead}}
    />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default FAQ;

import CustomPopover from "../popover/CustumPopover"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export const BankHelper = ()=>{
    return(
        <CustomPopover component={
            <HelpOutlineIcon className="other-icon" />
           }>
          <div className="text-xs-medium-12">
              <div className="text-sm-semibold-14"> Why do we need Bank Account information? </div>
              <div>Bank account information is required so we may deposit auction proceeds into a Seller’s designated bank account.<br/>  A wallet can be funded from a bank account. </div>
          </div>
           </CustomPopover>
    )
}

export const CardHelper = ()=>{
    return(
        <CustomPopover component={
            <HelpOutlineIcon className="other-icon" />
           }>
          <div className="text-xs-medium-12">
              <div className="text-sm-semibold-14"> Why do we need credit card information? </div>
              <div>Credit cards are required for a Buyer to make payment for merchandise purchased from a Seller.<br/>  A wallet can be funded with a credit card. </div>
          </div>
           </CustomPopover>
    )
}

export const WalletHelper = ()=>{
    return(
        <CustomPopover component={
            <HelpOutlineIcon className="other-icon" />
           }>
          <div className="text-xs-medium-12">
              <div className="text-sm-semibold-14">What is a Wallet?</div>
              <div>A wallet is an account that resides on the app and can be funded from adult’s bank account or credit card. <br/>  Wallets are intended to provide financial oversight over a minor’s spending on the app. <br/> Adults may also opt in to use the Wallet feature to purchase merchandise and store funds. </div>
          </div>
           </CustomPopover>
    )
}

export const AddressHelper = ()=>{
    return(
        <CustomPopover component={
            <HelpOutlineIcon className="other-icon" />
           }>
          <div className="text-xs-medium-12">
              <div className="text-sm-semibold-14">Why do we need a mailing address? </div>
              <div>Mailing addresses are required for the Seller to know where to ship sold merchandise to a Buyer. </div>
          </div>
           </CustomPopover>
    )
}
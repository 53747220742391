import "./BasicDetails.scss";

import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  getAllAddress,
  setCurrentAddress,
} from "../../../../redux/slices/address";
import { getUserInfo, updateUserInfo } from "../../../../redux/slices/profile";
import { useDispatch, useSelector } from "react-redux";

import ActionBar from "../../../../components/action-bar/ActionBar";
import AddAddress from "../../../../components/addresses/add-address/AddAddress";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddressList from "../../../../components/addresses/address-list/AddressList";
import Backdrop from "@mui/material/Backdrop";
import { Button } from "@mui/material";
import ButtonLight from "../../../../components/buttons/button-light/ButtonLight";
import ButtonLinkGrey from "../../../../components/buttons/link-grey/ButtonLinkGrey";
import ChangeProfileDialog from "./ChangeProfileDialog/ChangeProfileDialog";
import CircularProgress from "@mui/material/CircularProgress";
import CustomPrimaryButton from "../../../../components/buttons/primary/CustomPrimaryButton";
import Dialog from "../../../../components/dialog/Dialog";
import EditIcon from "../../../../assests/icons/edit.svg";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FollowersDialog from "./followers-dialog/FollowersDialog";
import FollowingDialog from "./following-dialog/FollowingDialog";
import Input from "../../../../components/inputs/email/Input";
import Label from "../../../../components/label/Label";
import PCLiveLoader from "../../../../components/loader/PCLiveLoader";
import PhoneInput from "../../../../components/inputs/phone/PhoneInput";
import ProfileBackground from "../../../../assests/icons/profile_background.png";
import pcLiveLogo from "../../../../assests/icons/pcLiveNewLogo.svg";
import { registerValidation } from "../../../../redux/slices/auth";
import { toast } from "react-toastify";

const INITIAL_VALID = {
  username: false,
  phone: false,
  phonemsg: "",
  usermsg: "",
};
const INITIAL_STATE = {
  username: "",
  phone: "",
};

export default function BasicDetails() {
  const dispatch = useDispatch();

  const allAddress = useSelector((state) => state.address.data?.data);
  const currentAddress = useSelector((state) => state.address.currentAddress);
  const userInfo = useSelector((state) => state.profile);

  const [disabled, setDisabled] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFollowersDialog, setOpenFollowersDialog] = useState(false);
  const [openFollowingsDialog, setOpenFollowingsDialog] = useState(false);
  const [openAddressList, setOpenAddressList] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [validData, setValiddata] = useState(INITIAL_VALID);
  const [userDetails, setUserDetails] = useState(INITIAL_STATE);
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);

  useEffect(() => {
    setUserDetails({
      username: userInfo?.data?.data?.username,
      phone: userInfo?.data?.data?.phone?.toString(),
    });
  }, [userInfo]);

  useEffect(() => {
    setProfilePic(
      userInfo?.data?.data?.profile_picture
        ? userInfo?.data?.data?.profile_picture
        : pcLiveLogo
    );
  }, [userInfo?.data?.data?.profile_picture]);

  const getApiVal = (type, val) => {
    if (type == "username") {
      registerValidation(
        { username: val },
        (res) => {
          if (!res.data.success) {
            setValiddata({
              ...validData,
              username: true,
              usermsg: res.data.message,
            });
          } else {
            setValiddata({ ...validData, username: false, usermsg: "" });
          }
        },
        () => {}
      );
    } else if (type == "phone") {
      registerValidation(
        { phone: val },
        (res) => {
          if (!res.data.success) {
            setValiddata({
              ...validData,
              phone: true,
              phonemsg: res.data.message,
            });
          } else {
            setValiddata({ ...validData, phone: false, phonemsg: "" });
          }
        },
        () => {}
      );
    }
  };
  const setUserDetailsFun = (type, e) => {
    if (type === "username") {
      setUserDetails({ ...userDetails, [type]: e.target.value });
      if (e.target.value.length < 3 || !/^[a-zA-Z0-9]+$/.test(e.target.value)) {
        setValiddata({
          ...validData,
          username: true,
          usermsg:
            "Username should have atleast 3 character and not contain space or spacial character",
        });
      } else if (e.target.value.length > 50) {
        setValiddata({
          ...validData,
          username: true,
          usermsg: "Username should be less than 50 characters",
        });
      } else {
        getApiVal(type, e.target.value);
      }
      return;
    } else if (type === "phone" || e.target.value === null) {
      setUserDetails({ ...userDetails, [type]: e.target.value });
      if (e.target.value == "" || e.target.value === undefined) {
        setValiddata({
          ...validData,
          phone: true,
          phonemsg: "Phone number is required",
        });
      } else {
        getApiVal(type, e.target.value);
      }
      return;
    }
  };

  const onRegister = () => {
    if (validData.phone || validData.username) {
      toast.error("Please enter valid values");
      return;
    }
    updateUserInfo(
      userDetails,
      (res) => {
        if (res.data.success) {
          let userDetailsInfo = JSON.parse(localStorage.getItem("userDetails"));
          userDetailsInfo.username = userDetails.username;
          let stringiFyObj = JSON.stringify(userDetailsInfo);
          localStorage.setItem("userDetails", stringiFyObj);
          toast.success("User details updated");
          dispatch(getUserInfo());
          setDisabled(true);
        }
      },
      (error) => toast.success("Something went wrong")
    );
  };

  function shouldDisable() {
    return Object.keys(validData).some((key) => validData[key] === true);
  }
  return (
    <div
      className={`basic-details-container  ${
        globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <Dialog
        openDialog={openAddressList}
        onClose={() => setOpenAddressList(false)}
        title={"All Addresses"}
      >
        <div className="address-list-profile-container">
          <AddressList
            allAddress={allAddress}
            setOpenAddAddress={setOpenAddAddress}
            setOpenAddressList={setOpenAddressList}
            isProfile
          />
        </div>
      </Dialog>
      <Dialog
        openDialog={openAddAddress}
        onClose={() => setOpenAddAddress(false)}
        title={`${currentAddress ? "Edit" : "Add"} Address`}
        addressHelper
      >
        <AddAddress
          address={currentAddress}
          setOpenAddAddress={setOpenAddAddress}
        />
      </Dialog>
      <div>
        <img
          src={ProfileBackground}
          alt="ProfileBackground"
          className="bg-img"
          loading="lazy"
        />
      </div>
      <div className="profile-container-besic-details">
        <div className="username-container">
          <img
            src={profilePic}
            height={106}
            width={106}
            className="user-icon"
            alt="userIcon"
            loading="lazy"
          />
          <div className="edit-photo-container">
            <img
              src={EditIcon}
              onClick={() => setOpenDialog(true)}
              alt="EditIcon"
              loading="lazy"
            />
            <ChangeProfileDialog
              openDialog={openDialog}
              onClose={() => setOpenDialog(false)}
            />
          </div>
          <div className="name">
            <div className="text-md-medium-16 fullname">
              {userInfo?.data?.data?.first_name +
                ` ` +
                userInfo?.data?.data?.last_name}
            </div>
            <div className="text-sm-medium-14 username">
              {userInfo?.data?.data?.username}
            </div>
          </div>
        </div>
        <div className="followers-button">
          <ButtonLight onClick={() => setOpenFollowersDialog(true)}>
            Followers {userInfo?.data?.data?.followers}
          </ButtonLight>
          <FollowersDialog
            openDialog={openFollowersDialog}
            onClose={() => setOpenFollowersDialog(false)}
          />
          <ButtonLight onClick={() => setOpenFollowingsDialog(true)}>
            Following {userInfo?.data?.data?.following}
          </ButtonLight>
          <FollowingDialog
            openDialog={openFollowingsDialog}
            onClose={() => setOpenFollowingsDialog(false)}
          />
        </div>
      </div>
      <ActionBar
        title={"User details"}
        actionLabel={"Edit"}
        onClick={() => setDisabled(!disabled)}
        actionIcon={<EditOutlinedIcon />}
      />
      <div className="input-containers">
        <div className="input-space">
          <Input
            placeholder={"Name"}
            label={"Name"}
            value={
              userInfo?.data?.data?.first_name +
              ` ` +
              userInfo?.data?.data?.last_name
            }
            isDisabled={true}
          />
        </div>
        <div className="input-space short-input">
          <Input
            placeholder={"Username"}
            label={"Username"}
            id={"username"}
            defaultValue={userDetails.username}
            value={userDetails.username}
            onChange={(e) => setUserDetailsFun("username", e)}
            isDisabled={disabled}
            error={validData.username}
            errorMsg={validData.usermsg}
          />
          <div className="phone-input-profile">
            <label
              className="text-xs-medium-12"
              style={{
                marginBottom: 12,
                color: globalDarkMode ? "#FCFCFD" : "#667085",
              }}
            >
              {"Phone number"}
            </label>
            <PhoneInput
              value={userDetails.phone}
              setPhoneNumber={(data) =>
                setUserDetailsFun("phone", { target: { value: data } })
              }
              disabled={disabled}
              id="phone"
              error={validData.phone}
            />
            <p style={{ marginTop: 8 }} className="text-sm-regular error">
              {validData.phonemsg}
            </p>
          </div>
        </div>
        <div>
          <Input
            placeholder={"Email"}
            label={"Email"}
            value={userInfo?.data?.data?.email}
            isDisabled={true}
          />
        </div>
        <div className="input-space">
          <Input
            placeholder={"Primary address"}
            label={"Primary address"}
            value={
              userInfo?.data?.data?.addresses?.find((ad) => ad.is_primary)
                ?.line1
            }
            isDisabled={true}
          />
        </div>
        <div className="footer-action">
          <ButtonLinkGrey
            label={"Add address"}
            icon={<AddBoxOutlinedIcon />}
            onClick={() => {
              setOpenAddAddress(true);
              dispatch(setCurrentAddress(null));
            }}
          />
          <ButtonLinkGrey
            label="View all addresses"
            onClick={() => setOpenAddressList(true)}
          />
        </div>
      </div>
      {!disabled && (
        <div className="submit-button">
          <ButtonLight
            onClick={() => {
              setDisabled(!disabled);
              setUserDetails({
                username: userInfo?.data?.data?.username,
                phone: userInfo?.data?.data?.phone?.toString(),
              });
              setValiddata(INITIAL_VALID);
            }}
          >
            Cancel
          </ButtonLight>
          <CustomPrimaryButton
            type="submit"
            disabled={shouldDisable()}
            onClick={(e) => onRegister()}
          >
            Update details
          </CustomPrimaryButton>
        </div>
      )}
    </div>
  );
}
